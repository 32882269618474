import React, { useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function WomInsult(props) {
  const { nodes, materials, animations } = useGLTF('/patient/WomInsult.glb')
  const { ref, actions, names } = useAnimations(animations)



  useEffect(() => {
    props.setAnim(animations)
    if(actions && names) {
      actions[names[props.index]].reset().fadeIn(0.5).play()
      return () => actions[names[props.index]].fadeOut(0.5)
    }
  }, [props.index, actions, names])

  return (
    <group ref={ref} {...props} dispose={null}>
      <group position={[0, 0.79, -0.01]} rotation={[0.16, 0, 0]}>
        <group position={[0, 0.15, 0]} rotation={[0.06, 0, 0]}>
          <group position={[0, 0.12, 0]} rotation={[-0.38, 0, 0]}>
            <group position={[0, 0.14, 0]} rotation={[0.3, 0, 0]}>
              <group position={[0, 0.13, 0]} rotation={[0.21, 0, 0]}>
                <group position={[0, 0.03, 0]} rotation={[-0.06, 0, 0]}>
                  <group position={[0, 0.04, 0]} rotation={[-0.3, 0, 0]}>
                    <group name="face" rotation={[0.01, 0, 0]}>
                      <group position={[0, 0.11, 0.11]} rotation={[3.08, 0, 0]} />
                      <group position={[0.07, 0.07, 0.02]} rotation={[-0.31, -0.16, -0.25]}>
                        <group position={[0, 0.04, -0.01]} rotation={[-2.77, -0.09, -0.05]} />
                      </group>
                      <group position={[-0.07, 0.07, 0.02]} rotation={[-0.31, 0.16, 0.25]}>
                        <group position={[0, 0.04, -0.01]} rotation={[-2.77, 0.09, 0.05]} />
                      </group>
                      <group position={[0.05, 0.11, 0.08]} rotation={[-1.21, 1.1, 2.24]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.36, -0.02, 0.09]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.58, 0.03, -0.09]} />
                        </group>
                      </group>
                      <group position={[-0.05, 0.11, 0.08]} rotation={[-1.21, -1.1, -2.24]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.36, 0.02, -0.09]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.58, -0.03, 0.09]} />
                        </group>
                      </group>
                      <group position={[0.02, 0.03, 0.1]} rotation={[-1.21, -0.16, -0.83]} />
                      <group position={[0.06, 0.11, 0.07]} rotation={[1.2, -0.35, 0.45]} />
                      <group position={[-0.02, 0.03, 0.1]} rotation={[-1.21, 0.16, 0.83]} />
                      <group position={[-0.06, 0.11, 0.07]} rotation={[1.2, 0.35, -0.45]} />
                      <group position={[0.03, 0.1, 0.08]} rotation={[-1.57, 0, -Math.PI]}>
                        <group position={[-0.01, 0.01, 0]} rotation={[-0.63, -0.94, -1.34]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.37, 0.72, -0.09]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.37, 0.31, -0.49]} />
                          </group>
                        </group>
                        <group position={[0.01, 0.02, 0]} rotation={[0.53, -0.61, 1.9]}>
                          <group position={[0, 0.01, 0]} rotation={[0.6, 0.32, -0.34]}>
                            <group position={[0, 0.01, 0]} rotation={[0.41, 0.36, 0.39]} />
                          </group>
                        </group>
                      </group>
                      <group position={[-0.03, 0.1, 0.08]} rotation={[-1.57, 0, Math.PI]}>
                        <group position={[0.01, 0.01, 0]} rotation={[-0.63, 0.94, 1.34]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.37, -0.72, 0.09]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.37, -0.31, 0.49]} />
                          </group>
                        </group>
                        <group position={[-0.01, 0.02, 0]} rotation={[0.53, 0.61, -1.9]}>
                          <group position={[0, 0.01, 0]} rotation={[0.6, -0.32, 0.34]}>
                            <group position={[0, 0.01, 0]} rotation={[0.41, -0.36, -0.39]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0.06, 0.09, 0.06]} rotation={[3.11, -0.95, 1.02]} />
                      <group position={[-0.06, 0.09, 0.06]} rotation={[3.11, 0.95, -1.02]} />
                      <group position={[0, 0.08, 0.04]} rotation={[2.6, 0, 0]}>
                        <group position={[0, 0.07, -0.04]} rotation={[2.07, 0.08, -1.28]} />
                        <group position={[0, 0.08, -0.03]} rotation={[2.28, -0.15, -1.32]} />
                        <group position={[0, 0.1, 0]} rotation={[-2.26, 0, 0]} />
                        <group position={[0, 0.07, -0.04]} rotation={[2.07, -0.08, 1.28]} />
                        <group position={[0, 0.08, -0.03]} rotation={[2.28, 0.15, 1.32]} />
                        <group position={[0.07, 0, 0]} rotation={[0.42, -0.02, 0.22]}>
                          <group position={[0, 0.05, 0]} rotation={[-0.44, -0.1, 0.42]} />
                        </group>
                        <group position={[-0.07, 0, 0]} rotation={[0.42, 0.02, -0.22]}>
                          <group position={[0, 0.05, 0]} rotation={[-0.44, 0.1, -0.42]} />
                        </group>
                        <group position={[0, 0.07, -0.02]} rotation={[2.46, 0, 0]}>
                          <group position={[0, 0.02, 0]} rotation={[-0.46, 0, 0]} />
                        </group>
                      </group>
                      <group position={[0, 0.06, 0.1]} rotation={[-1.57, 0, Math.PI]}>
                        <group position={[0, 0.02, 0.01]} rotation={[1.03, 0, -Math.PI]} />
                      </group>
                      <group position={[0.01, 0.11, 0.11]} rotation={[0.46, -1.06, -2.99]} />
                      <group position={[-0.01, 0.11, 0.11]} rotation={[0.46, 1.06, 2.99]} />
                    </group>
                  </group>
                </group>
              </group>
              <group position={[0.03, 0.14, 0.03]} rotation={[-1.75, 0.1, -1.34]}>
                <group position={[0, 0.12, 0]} rotation={[1.76, 1.38, -2.55]}>
                  <group position={[0, 0.26, 0]} rotation={[0.81, 0.15, 0.04]}>
                    <group position={[0, 0.24, 0]} rotation={[-0.04, -0.17, -0.16]}>
                      <group position={[-0.01, 0.04, 0.03]} rotation={[2.36, 1.48, -2.23]}>
                        <group position={[0, 0.06, 0]} rotation={[0.11, -0.17, 0.06]}>
                          <group position={[0, 0.03, 0]} rotation={[0.19, -0.17, -0.01]} />
                        </group>
                        <group position={[-0.01, -0.01, 0.02]} rotation={[-1.06, 1.13, 1.49]}>
                          <group position={[0, 0.04, 0]} rotation={[0.14, 0.01, 0.06]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.04, 0.01]} rotation={[1.98, 1.51, -2.04]}>
                        <group position={[0, 0.06, 0]} rotation={[0.17, -0.24, 0.01]}>
                          <group position={[0, 0.03, 0]} rotation={[0.15, -0.23, 0]} />
                        </group>
                      </group>
                      <group position={[-0.02, 0.04, 0]} rotation={[0.68, 1.48, -0.82]}>
                        <group position={[0, 0.06, 0]} rotation={[0.09, -0.29, -0.09]}>
                          <group position={[0, 0.03, 0]} rotation={[0.12, -0.16, -0.05]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.04, -0.02]} rotation={[0.51, 1.42, -0.74]}>
                        <group position={[0, 0.05, 0]} rotation={[0.07, -0.46, -0.16]}>
                          <group position={[0, 0.02, 0]} rotation={[0.18, -0.05, -0.08]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[-0.03, 0.14, 0.03]} rotation={[-1.75, -0.1, 1.34]}>
                <group position={[0, 0.12, 0]} rotation={[1.76, -1.38, 2.55]}>
                  <group position={[0, 0.26, 0]} rotation={[0.81, -0.15, -0.04]}>
                    <group position={[0, 0.24, 0]} rotation={[-0.04, 0.17, 0.16]}>
                      <group position={[0.01, 0.04, 0.03]} rotation={[2.36, -1.48, 2.23]}>
                        <group position={[0, 0.06, 0]} rotation={[0.11, 0.17, -0.06]}>
                          <group position={[0, 0.03, 0]} rotation={[0.19, 0.17, 0.01]} />
                        </group>
                        <group position={[0.01, -0.01, 0.02]} rotation={[-1.06, -1.13, -1.49]}>
                          <group position={[0, 0.04, 0]} rotation={[0.14, -0.01, -0.06]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.04, 0.01]} rotation={[1.98, -1.51, 2.04]}>
                        <group position={[0, 0.06, 0]} rotation={[0.17, 0.24, -0.01]}>
                          <group position={[0, 0.03, 0]} rotation={[0.15, 0.23, 0]} />
                        </group>
                      </group>
                      <group position={[0.02, 0.04, 0]} rotation={[0.68, -1.48, 0.82]}>
                        <group position={[0, 0.06, 0]} rotation={[0.09, 0.29, 0.09]}>
                          <group position={[0, 0.03, 0]} rotation={[0.12, 0.16, 0.05]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.04, -0.02]} rotation={[0.51, -1.42, 0.74]}>
                        <group position={[0, 0.05, 0]} rotation={[0.07, 0.46, 0.16]}>
                          <group position={[0, 0.02, 0]} rotation={[0.18, 0.05, 0.08]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
        <group position={[0.12, 0.01, 0.03]} rotation={[2.87, 0, -0.07]}>
          <group position={[0, 0.36, 0]} rotation={[0.2, 0.01, -0.03]}>
            <group position={[0, 0.35, 0]} rotation={[-1.01, -0.08, 0.05]} />
          </group>
        </group>
        <group position={[-0.12, 0.01, 0.03]} rotation={[2.87, 0, 0.07]}>
          <group position={[0, 0.36, 0]} rotation={[0.2, -0.01, 0.03]}>
            <group position={[0, 0.35, 0]} rotation={[-1.01, 0.08, -0.05]} />
          </group>
        </group>
      </group>
      <primitive object={nodes.root} />
      <primitive object={nodes['MCH-eye_commonparent']} />
      <primitive object={nodes['MCH-lip_armBL001']} />
      <primitive object={nodes['MCH-lip_armBR001']} />
      <primitive object={nodes['MCH-lip_armTL001']} />
      <primitive object={nodes['MCH-lip_armTR001']} />
      <primitive object={nodes['MCH-torsoparent']} />
      <primitive object={nodes['MCH-hand_ikparentL']} />
      <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
      <primitive object={nodes['MCH-hand_ikparentR']} />
      <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
      <primitive object={nodes['MCH-foot_ikparentL']} />
      <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
      <primitive object={nodes['MCH-foot_ikparentR']} />
      <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
      <skinnedMesh
        geometry={nodes.hairs_bun_style2.geometry}
        material={materials['Material.002']}
        skeleton={nodes.hairs_bun_style2.skeleton}
      />
      <skinnedMesh
        geometry={nodes.woman_end_Old_forblend001_1.geometry}
        material={materials['Eye_brown.001']}
        skeleton={nodes.woman_end_Old_forblend001_1.skeleton}
      />
      <skinnedMesh
        geometry={nodes.woman_end_Old_forblend001_2.geometry}
        material={materials['bodyMaterial.001']}
        skeleton={nodes.woman_end_Old_forblend001_2.skeleton}
      />
      {/* <skinnedMesh
        geometry={nodes.woman_end_Old_forblend001_3.geometry}
        material={materials.eyes_glass}
        skeleton={nodes.woman_end_Old_forblend001_3.skeleton}
      /> */}
      <skinnedMesh
        geometry={nodes.woman_end_Old_forblend001.geometry}
        material={materials['FABRIC_1_FRONT_2067.002']}
        skeleton={nodes.woman_end_Old_forblend001.skeleton}
      />
      <skinnedMesh
        geometry={nodes.woman_end_Old_forblend002.geometry}
        material={materials['young_asian_female.001']}
        skeleton={nodes.woman_end_Old_forblend002.skeleton}
      />
      <skinnedMesh
        geometry={nodes.woman_end_Old_forblend.geometry}
        material={materials.teethMaterial}
        skeleton={nodes.woman_end_Old_forblend.skeleton}
      />
      <skinnedMesh
        geometry={nodes.woman_end_Old_forblend_1.geometry}
        material={materials.tongue01Material}
        skeleton={nodes.woman_end_Old_forblend_1.skeleton}
      />
      <group position={[0.4, 0, -0.01]} rotation={[0, Math.PI / 2, 0]}>
        <mesh geometry={nodes.Cube001.geometry} material={materials.couchLeg_black} />
        <mesh geometry={nodes.Cube001_1.geometry} material={materials.couchLeg_white} />
      </group>
    </group>
  )
}

// useGLTF.preload('/WomInsult.glb')
