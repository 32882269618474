import React, { ReactElement } from 'react'
import { iResItems } from '../../../pages/demo/demo-diagnosis/DiagnosisSuccessPage'
import Slot from '../../common/Slots/Slot'
import ResultItem from './ResultItem'

interface Props {
  title: string
  results: iResItems
}

export default function ResultSlot({ title, results }: Props): ReactElement {
  return (
    <Slot title={title} color='info'>
      {results.map((item, i) => (
        <ResultItem key={i} item={item} />
      ))}
    </Slot>
  )
}
