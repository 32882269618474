export enum SagaActionType {
  START_LOAD_PRESCRIBED_MEDICATION = 'START_LOAD_PRESCRIBED_MEDICATION',
  START_LOAD_TASK = 'START_LOAD_TASK',
  START_LOAD_HISTORY = 'START_LOAD_HISTORY',
  START_LOAD_CHECK_HISTORY = 'START_LOAD_CHECK_HISTORY',
  START_LOAD_GROUP_OF_RESEARCH = 'START_LOAD_GROUP_OF_RESEARCH',
  START_LOAD_TYPES_OF_RESEARCH = 'START_LOAD_TYPES_OF_RESEARCH',
  START_LOAD_RESEARCH_RESULTS = 'START_LOAD_RESEARCH_RESULTS',
  START_LOAD_RESEARCH_HISTORY = 'START_LOAD_RESEARCH_HISTORY',
  START_LOAD_TASK_RESULTS = 'START_LOAD_TASK_RESULTS',
  START_LOAD_DIAGNOSIS_TYPES = 'START_LOAD_DIAGNOSIS_TYPES',
  START_LOAD_DIAGNOSIS_FOMRMS = 'START_LOAD_DIAGNOSIS_FOMRMS',
  FINISH_TASK = 'FINISH_TASK',
}
