import { AppBar, Backdrop, Grid, IconButton, Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import React from 'react'
import { Link } from 'react-router-dom'
import { NavbarHeight } from '../../constants/default'
import { MenuItemData } from '../../types'
import { pxToRem } from '../../utils'
import UserBarMobile from '../Layout/AppBar/UserBarMobile'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: `${NavbarHeight}px`,
    boxShadow: 'none'
    // [theme.breakpoints.down('xs')]: {
    //   background: theme.palette.primary.dark
    // }
  },
  rootOpen: {
    zIndex: 1200,
    height: 'fit-content',
    overflowY: 'auto',
    minHeight: '100%'
    // background: theme.palette.primary.dark
  },
  navWrap: {
    flex: 1,
    padding: '27px 30px'
  },
  toolbar: {
    minHeight: NavbarHeight,
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 9
  },
  logo: {
    fontSize: pxToRem(20),
    fontWeight: 900,
    lineHeight: pxToRem(30),
    letterSpacing: 0.15,
    color: theme.palette.primary.contrastText
    // [theme.breakpoints.down('xs')]: {
    //   color: '#fff'
    // }
  },
  menuButton: {
    color: theme.palette.primary.contrastText
    // [theme.breakpoints.down('xs')]: {
    //   color: '#fff'
    // }
  },
  link: {
    color: '#fff',
    textTransform: 'uppercase',
    marginBottom: 30,
    '& a': {
      color: '#fff',
      textDecoration: 'none'
    },
    '& a:hover': {
      textDecoration: 'none'
    },
    '&:last-child': {
      marginBottom: 0
    }
  },
  backdrop: {
    zIndex: 1199,
    background: 'rgba(0, 0, 0, 0.45)',
    maxHeight: 'calc(100vh - 65px)'
  },
  menuLink: {
    textDecoration: 'none'
  }
}))

type Props = {
  menuItems: Array<MenuItemData>
  children?: React.ReactElement
}

const TopNavBar = (props: Props) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleMenuOpen = () => {
    setOpen(!open)
  }

  const handleMenuClose = () => {
    setOpen(false)
  }
  return (
    <>
      <AppBar
        position={'fixed'}
        classes={{ root: open ? classes.rootOpen : classes.root }}
      >
        <Toolbar className={classes.toolbar}>
          <Link to='/newChat' className={classes.menuLink}>
            <IconButton edge='start' className={classes.logo}>
              NeuroDoc
            </IconButton>
          </Link>
          {props.children}
          <IconButton
            onClick={handleMenuOpen}
            edge='end'
            className={classes.menuButton}
            aria-label='menu'
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        {open && (
          <Grid className={classes.navWrap}>
            {props.menuItems.map((item, index) => (
              <div key={index} className={classes.link}>
                <Link to={item.routePath}>{item.title}</Link>
              </div>
            ))}
            <UserBarMobile />
          </Grid>
        )}
      </AppBar>
      {/* <Backdrop className={classes.backdrop} open={open} onClick={handleMenuClose} /> */}
    </>
  )
}

export default TopNavBar
