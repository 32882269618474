import React from 'react'
import AuthLayout from '../../components/Layout/AuthLayout'
import { makeStyles } from '@material-ui/core/styles'
import { pxToRem } from '../../utils'
import NewPasswordForm from '../../components/auth/Forms/NewPasswordForm'
import SocialButtonsPanel from '../../components/auth/SocialButtonsPanel'

const useStyles = makeStyles(theme => ({
  wrapper: {
    textAlign: 'center',
    color: '#C4C4C4',
    fontSize: pxToRem(20),
    fontWeight: 500,
    textTransform: 'uppercase'
  }
}))

export default function NewPasswordPage() {
  const c = useStyles()
  return (
    <AuthLayout>
      <div className={c.wrapper}>Создание нового пароля</div>
      <NewPasswordForm />
      <SocialButtonsPanel />
    </AuthLayout>
  )
}
