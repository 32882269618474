import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { AsyncState } from 'react-use/lib/useAsync'
import {
  conditionIdConditionCode,
  Diagnosis_ReqData,
  getTypeofPatientStatus,
  PatientStatusCode,
  pushPreDiagnosis,
  TestingStage,
  getTestingstageTaskOnTask
} from '../../api/chatApi/chatApi'
import { useLocation } from 'react-router-dom';
import RatingDialog from '../../components/Chat/RatingDialog'
import CustomButton from '../../components/common/CustomButton'
import CustomHeader1 from '../../components/common/CustomHeader1'
import Slot from '../../components/common/Slots/Slot'
import SlotContainerMasonry from '../../components/common/Slots/SlotContainerMasonry'
import TooltipDialog from '../../components/common/TooltipDialog'
import MainLayout from '../../components/Layout/MainLayout'
import DiagnosisFields, {
  DiagnosisFieldData
} from '../../components/PageBlocks/Diagnosis/MainDiagnosisField'
import PathophysiologicalComponent from '../../components/PageBlocks/PreDiagnosis/PathophysiologicalModel'
import PreDiagnosisTooltip from '../../components/Tooltips/PreDiagnosisTooltip'
import useDiagnosisState from '../../hooks/diagnosis/useDiagnosisState'
import useDiagnosisTypes from '../../hooks/diagnosis/useDiagnosisTypes'
import useTestingStage from '../../hooks/testingTask/useTestingStage'
import useTooltipState from '../../hooks/useTooltipState'
import { RootState } from '../../store'
import { SagaActionType } from '../../store/sagas/sagaActions'
import { mapAsyncState, RoutePath } from '../../types'
import NewChatWizardTopMenu from '../startChatWizard/NewChatWizardTopMenu'
import CompetingDiagnosis from '../../components/PageBlocks/Diagnosis/CompetingDiagnosisField'
import ComplicationDiagnosisField from '../../components/PageBlocks/Diagnosis/ComplicationDiagnosisField'
import AccompanyingDiagnosisField from '../../components/PageBlocks/Diagnosis/AccompanyingDiagnosisField'
import MainDiagnosisField from '../../components/PageBlocks/Diagnosis/MainDiagnosisField'
import chatApi from '../../api/chatApi'
import useIncreaseTaskStage from "../../hooks/testingTask/useIncreaseTaskStage";
import TooltipAccordion from '../../components/TooltipAccordion'
import { usePointProcentStore } from './store'
import { useTranslation } from 'react-i18next'



const PreDiagnosisPage: React.FunctionComponent = () => {
  const asyncStateDiagnosisTypes = useDiagnosisTypes()
  const diagnosisTypes = asyncStateDiagnosisTypes?.value
  const testingStage = useTestingStage()
  const [toggle, setToggle] = useState(false)
  const [status, setStatus] = useState<boolean>(false)
  const [open, setOpen] = React.useState(false)
  const [diagnosis, setDiagnosis] = useState<any>([])
  const [currentInputId, setCurrentInputId] = useState<number>()
  const [tooltipState, openTooltip] = useTooltipState()
  const [isButtonActive, setIsButtonActive] = useState(false);
  const getPointProcent = usePointProcentStore(state => state.getPoints)
  const [loading, increase, setAsyncTaskStage] = useIncreaseTaskStage(RoutePath.preDiagnosisInitial, 4);
  const dispatch = useDispatch()
  const history = useHistory()
  const [stages, setStages] = useState([]);
  const { routhPathCode } = useNextStagePath(stages);
  const taskStage = useTestingStage()
  const {
    condition,
    onChageCondition,
    isFormValid,
    onChangeDiagnosis,
    pushDiagnosis,
    pushDiagnosisAsyncState
  } = useDiagnosisState(true)

  const preDiagnosisForm = useSelector<
    RootState,
    AsyncState<Diagnosis_ReqData> | undefined
  >(state =>
    mapAsyncState(
      s => s.find(it => it.isProvisional)!!,
      state.chat.diagnosisForms
    )
  )



  useEffect(() => {
    dispatch({ type: SagaActionType.START_LOAD_DIAGNOSIS_FOMRMS })
  }, [dispatch])



  // Функция для получения этапов
  async function fetchAllStages() {
    try {
      const result = await getTestingstageTaskOnTask();
      setStages(result.data.testingstage_task_on_task);
    } catch (error) {
      console.error('Ошибка при загрузке этапов:', error);
    }
  }

  useEffect(() => {
    fetchAllStages();
  }, []);

  function useNextStagePath(stages) {
    const location = useLocation();
    const currentPath = location.pathname;

    return useMemo(() => {
      if (!stages || stages.length === 0) {
        return { routePath: '', routhPathCode: '' };
      }
      const sortedStages = stages.sort((a, b) => a.activeStage - b.activeStage);
      const currentIndex = sortedStages.findIndex(stage => stage.routePath === currentPath);

      if (currentIndex !== -1 && currentIndex + 1 < sortedStages.length) {
        return {
          routePath: sortedStages[currentIndex + 1].routePath,
          routhPathCode: sortedStages[currentIndex + 1].routhPathCode
        };
      }

      return { routePath: '', routhPathCode: '' };
    }, [stages, currentPath]);
  }


  const onClickSendDiagnosis = () => {
    if (stages && stages.length > 0) {
      pushDiagnosis().then(() => {
        if (routhPathCode) {
          if (routhPathCode === 'research') {
            history.replace(RoutePath.researchInitial);
          } else {
            history.replace(RoutePath[routhPathCode])
          }
        } else {
          console.error('Ошибка при переходе на следующий этап');
        }
      });
    } else {
      pushDiagnosis().then(() => history.push(RoutePath.researchInitial))
    }

  }

  const [currTaskState, setCurrTaskState] = useState<number | null>(null)
  useEffect(() => {
    getDifficultLevel()
    setAsyncTaskStage()
  }, [])

  async function getDifficultLevel() {
    try {
      const response = await chatApi.unfinishedTask()
      setCurrTaskState(response?.result?.task.typeOfDifficultLevelId)
    } catch (err) {
      console.log(`При получении уровня сложности произошла ошибка: ${err}`)
    }
  }

  const type8 = () => {
    if (condition === 'Light') {
      return 1
    }
    if (condition === 'Normal') {
      return 2
    }
    if (condition === 'Hard') {
      return 3
    }
    if (condition === 'Fatal') {
      return 4
    }
  }

  const pushPreDiagnos = async () => {
    const obj: any = {};
    diagnosis.map((el, index) => {
      obj[`typeofinformationlevelid_${el.input}`] = el.input === 4 ? el.id : [el.id];
      obj.typeofinformationlevelid_8 = type8()
    });

    await pushPreDiagnosis(obj)
      .catch(err => console.log(err))
  }


  useEffect(() => {
    const getDialogStatus = async () => {
      const response = await chatApi.getSettingsPiloting();
      const statusValue = response.result[0].value;
      setStatus(statusValue === 'true');
    };

    getDialogStatus();
  }, []);
  function showRatingDialog() {
    if (status) {
      pushPreDiagnos()
      setOpen(true)
    } else {
      onClickSendDiagnosis()
      // setAsyncTaskStage()
    }
  }

  function hideRatingDialog() {
    onClickSendDiagnosis()
    setOpen(false)
    // increase()
    // setAsyncTaskStage()
  }


  function onFocusHandler(id: number) {
    setCurrentInputId(id)
  }


  if (diagnosisTypes) {
    const currentFields = diagnosis.filter(item => item.input === diagnosisTypes[1]?.id)
  }



  useEffect(() => {
    const setupStatus = async () => {
      try {
        const statusNumber = await getTypeofPatientStatus();
        const result = statusNumber.data.typeofpatientstatus_id;
        let condition: PatientStatusCode;

        if (result === 1) {
          condition = PatientStatusCode.Light;
        } else if (result === 2) {
          condition = PatientStatusCode.Normal;
        } else if (result === 3) {
          condition = PatientStatusCode.Hard;
        } else if (result === 4) {
          condition = PatientStatusCode.Fatal;
        } else {
          condition = PatientStatusCode.Light;
        }
        onChageCondition(condition);

      } catch (error) {
        console.log(error);
      }
    };

    setupStatus();
  }, []);

  const [diagnosisCodeValue, setDiagnosisCodeValue] = useState('');

  const handleDiagnosisCodeChange = (value) => {
    setDiagnosisCodeValue(value);
  };


  useEffect(() => {
    getPointProcent()
  }, [])

  const taskName = '5'

  const {t} = useTranslation()

  return (
    <>
      {diagnosisTypes ? (
        <MainLayout
          desktopHeader={<NewChatWizardTopMenu title={t("trenPredDigTitle")} />}
          title={t("trenPredDigTitle")}
          onClickTooltip={openTooltip}
        >

          {open ? <RatingDialog open={open} onSubmit={hideRatingDialog} /> : null}
          <TooltipDialog {...tooltipState}>
            <PreDiagnosisTooltip />
          </TooltipDialog>
          <TooltipAccordion taskName={taskName} />

          <div style={{ paddingBottom: `25px` }}>
            {taskStage <= TestingStage.PROVISIONAL_DIAGNOSIS + 1 ? (
              <React.Fragment>
                <SlotContainerMasonry>
                  {/* <Slot title={diagnosisTypes && diagnosisTypes[0]?.description} color='primary'> */}
                  <Slot title={diagnosisTypes && t("trenPredDig1")} color='primary'>
                    <MainDiagnosisField
                      diagnosis={[]}
                      maxLines={1}
                      level={currTaskState}
                      toggle={setToggle}
                      onChange={fields => diagnosisTypes && onChangeDiagnosis(diagnosisTypes[0]?.id, fields)}
                      onClickInput={() => diagnosisTypes && onFocusHandler(diagnosisTypes[0].id)}
                      idInput={diagnosisTypes && diagnosisTypes[0]?.id}
                      currentInputId={currentInputId}
                      setDiagnosis={setDiagnosis}
                      items={diagnosis}
                      setIsButtonActive={setIsButtonActive}
                      diagnosisCodeValue={diagnosisCodeValue}
                      onDiagnosisCodeChange={handleDiagnosisCodeChange}
                    />
                  </Slot>
                  {currTaskState !== 2 &&
                    // <Slot title={diagnosisTypes && diagnosisTypes[1]?.description} color='primary'>
                    <Slot title={diagnosisTypes && t("trenPredDig2")} color='primary'>
                      <CompetingDiagnosis
                        diagnosis={[]}
                        maxLines={1}
                        level={currTaskState}
                        onChange={fields => {
                          if (diagnosisTypes) {
                            const currentFields = diagnosis.filter(item => item.input === diagnosisTypes[1]?.id);
                            const allFields = [...currentFields, ...fields];
                            onChangeDiagnosis(diagnosisTypes[1]?.id, allFields);
                          }
                        }}
                        onClickInput={() => diagnosisTypes && onFocusHandler(diagnosisTypes[1].id)}
                        idInput={diagnosisTypes && diagnosisTypes[1]?.id}
                        currentInputId={currentInputId}
                        setDiagnosis={setDiagnosis}
                        items={diagnosis}
                      />
                    </Slot>
                  }
                  {currTaskState !== 2 &&
                    // <Slot title={diagnosisTypes && diagnosisTypes[2]?.description} color='primary'>
                    <Slot title={diagnosisTypes && t("trenPredDig3")} color='primary'>
                      <ComplicationDiagnosisField
                        diagnosis={[]}
                        maxLines={1}
                        level={currTaskState}
                        onChange={fields => {
                          if (diagnosisTypes) {
                            const currentFields = diagnosis.filter(item => item.input === diagnosisTypes[2]?.id);
                            const allFields = [...currentFields, ...fields];
                            onChangeDiagnosis(diagnosisTypes[2]?.id, allFields);
                          }
                        }}
                        onClickInput={() => diagnosisTypes && onFocusHandler(diagnosisTypes[2].id)}
                        idInput={diagnosisTypes && diagnosisTypes[2]?.id}
                        currentInputId={currentInputId}
                        setDiagnosis={setDiagnosis}
                        items={diagnosis}
                      />
                    </Slot>
                  }
                  {currTaskState !== 2 &&
                    // <Slot title={diagnosisTypes && diagnosisTypes[3]?.description} color='primary'>
                    <Slot title={diagnosisTypes && t("trenPredDig4")} color='primary'>
                      <AccompanyingDiagnosisField
                        diagnosis={[]}
                        maxLines={1}
                        level={currTaskState}
                        onChange={fields => {
                          if (diagnosisTypes) {
                            const currentFields = diagnosis.filter(item => item.input === diagnosisTypes[3]?.id);
                            const allFields = [...currentFields, ...fields];
                            onChangeDiagnosis(diagnosisTypes[3]?.id, allFields);
                          }
                        }}
                        onClickInput={() => diagnosisTypes && onFocusHandler(diagnosisTypes[3].id)}
                        idInput={diagnosisTypes && diagnosisTypes[3]?.id}
                        currentInputId={currentInputId}
                        setDiagnosis={setDiagnosis}
                        items={diagnosis}
                      />
                    </Slot>
                  }
                  <div>
                    {/* <PatientConditionSlot
                      value={condition}
                      onChange={onChageCondition}
                    /> */}

                    {/*<PathophysiologicalComponent />*/}
                  </div>
                </SlotContainerMasonry>
                {currTaskState === 3 ? (<CustomButton
                  onClick={showRatingDialog}
                  disabled={toggle ? false : true}
                >
                  {t("trenPredDig5")}
                </CustomButton>) : (
                  <CustomButton
                    onClick={showRatingDialog}
                    disabled={toggle ? false : true}
                    loading={pushDiagnosisAsyncState?.loading}
                  >
                    {t("trenPredDig5")}
                  </CustomButton>
                )
                }



              </React.Fragment>
            ) : (
              <React.Fragment>
                <CustomHeader1>{t("trenPredDig6")}</CustomHeader1>
                <SlotContainerMasonry>
                  {preDiagnosisForm?.value &&
                    diagnosisTypes &&
                    diagnosisTypes.map((it, indx) => {
                      const fieldData = preDiagnosisForm.value.diagnosis
                        .filter(di => {
                          return (di.typeOfInformationLevel || -1) === it.id
                        })
                        .map(di => {
                          return {
                            key: new Date().getTime(),
                            diagnosis: di.name,
                            diagnosisCode: di.code
                          } as DiagnosisFieldData
                        })
                      return (
                        fieldData.length > 0 && (
                          <Slot key={indx} title={it.description} color='primary'>
                            <DiagnosisFields
                              readOnly
                              diagnosis={fieldData}
                              onChange={fields => onChangeDiagnosis(it.id, fields)}
                              onClickInput={() => onFocusHandler(it.id)}
                              idInput={it.id}
                              currentInputId={currentInputId}
                              setDiagnosis={setDiagnosis}
                              items={diagnosis}
                            />
                          </Slot>
                        )
                      )
                    })}
                  {preDiagnosisForm?.value && (
                    <div>
                      {/* <PatientConditionSlot
                        value={conditionIdConditionCode.get(
                          preDiagnosisForm.value.typeOfPatientStatusId
                        )}
                        onChange={onChageCondition}
                      /> */}

                      <PathophysiologicalComponent />
                    </div>
                  )}
                </SlotContainerMasonry>
                {testingStage !== TestingStage.PROVISIONAL_DIAGNOSIS ?
                  (<div style={{ paddingBottom: "30px" }}>
                    <Link to='/research' style={{ textDecoration: 'none' }}>
                      <CustomButton>
                      {t("trenPredDig7")}
                      </CustomButton>
                    </Link>
                  </div>) : null}
              </React.Fragment>
            )}
          </div>
        </MainLayout>
      ) : (
        <p>{t("trenPredDig8")}</p>
      )}
    </>
  )
}

export default PreDiagnosisPage
