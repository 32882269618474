import React, { useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function WomanAtrophy(props) {
  const { nodes, materials, animations } = useGLTF('patient/Middle_age_woman_atrophy.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)


  useEffect(() => {
    props.setAnim(animations)
    actions.rigAction.play()
  }, [actions, names, mixer])

  return (
    <group ref={ref} {...props} dispose={null}>
      <group name="rig">
        <primitive object={nodes.root} />
        <primitive object={nodes['MCH-torsoparent']} />
        <skinnedMesh
          geometry={nodes.cloth2_1.geometry}
          material={materials['FABRIC_1_FRONT_2071.001']}
          skeleton={nodes.cloth2_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.cloth2_2.geometry}
          material={materials['FABRIC_3_FRONT_2085.001']}
          skeleton={nodes.cloth2_2.skeleton}
        />
        <skinnedMesh
          geometry={nodes.cloth2_3.geometry}
          material={materials['FABRIC_2_FRONT_2078.001']}
          skeleton={nodes.cloth2_3.skeleton}
        />
        <skinnedMesh
          geometry={nodes.middleage_woman001_1.geometry}
          material={materials['Eye_ice.001']}
          skeleton={nodes.middleage_woman001_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.middleage_woman001_2.geometry}
          material={materials.bodyMaterial}
          skeleton={nodes.middleage_woman001_2.skeleton}
        />
        <skinnedMesh
          geometry={nodes.middleage_woman001_3.geometry}
          material={materials.teethMaterial}
          skeleton={nodes.middleage_woman001_3.skeleton}
        />
        {/* <skinnedMesh
          geometry={nodes.middleage_woman001_4.geometry}
          material={materials.eyes_glass}
          skeleton={nodes.middleage_woman001_4.skeleton}
        /> */}
        <skinnedMesh
          geometry={nodes.middleage_woman003.geometry}
          material={materials.middleage_asian_female}
          skeleton={nodes.middleage_woman003.skeleton}
        />
      </group>
    </group>
  )
}

// useGLTF.preload('/Middle_age_woman_atrophy.glb')
