import React, { useEffect, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function PregnantOld(props) {
  const { nodes, materials, animations } = useGLTF('patient/pregnantBAnim.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)

  useEffect(() => {
    props.setAnim(animations)
    actions.rigAction.play()
  }, [actions, names, mixer])
  
  return (
    <group ref={ref} {...props} dispose={null}>
      <group position={[0, 0.87, -0.01]} rotation={[0.25, 0, 0]}>
        <group position={[0, 0.13, 0]} rotation={[-0.46, 0, 0]}>
          <group position={[0, 0.11, 0]} rotation={[0.17, 0, 0]}>
            <group position={[0, 0.15, 0]} rotation={[0.09, 0, 0]}>
              <group position={[0, 0.16, 0]} rotation={[0.49, 0, 0]}>
                <group position={[0, 0.05, 0]} rotation={[-0.14, 0, 0]}>
                  <group position={[0, 0.05, 0]} rotation={[-0.46, 0, 0]}>
                    <group rotation={[-0.01, 0, 0]}>
                      <group position={[0, 0.1, 0.11]} rotation={[2.96, 0, 0]}>
                        <group position={[0, 0.02, 0]} rotation={[-0.1, 0, 0]}>
                          <group position={[0, 0.03, 0]} rotation={[1.04, 0, 0.01]}>
                            <group rotation={[0.42, 0, -0.01]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.02, 0.11]} rotation={[-1.46, -0.02, -1.3]} />
                      <group position={[0, 0.01, 0.1]} rotation={[-1.32, -0.19, -1.13]} />
                      <group position={[0, -0.01, 0.06]} rotation={[1.82, 0, 0]}>
                        <group position={[0, 0.03, 0]} rotation={[-0.69, 0, 0]} />
                      </group>
                      <group position={[0.07, 0.05, 0.02]} rotation={[0.03, -0.21, 0.03]}>
                        <group position={[0, 0.04, 0]} rotation={[-1.27, 0.03, -0.5]}>
                          <group position={[0, 0.02, 0]} rotation={[-1.64, -0.74, 0.2]}>
                            <group position={[0, 0.03, 0]} rotation={[-0.62, -0.52, 0.05]} />
                          </group>
                        </group>
                      </group>
                      <group position={[-0.07, 0.05, 0.02]} rotation={[0.03, 0.21, -0.03]}>
                        <group position={[0, 0.04, 0]} rotation={[-1.27, -0.03, 0.5]}>
                          <group position={[0, 0.02, 0]} rotation={[-1.64, 0.74, -0.2]}>
                            <group position={[0, 0.03, 0]} rotation={[-0.62, 0.52, -0.05]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.02, 0.11]} rotation={[-1.46, 0.02, 1.3]} />
                      <group position={[0, 0.01, 0.1]} rotation={[-1.32, 0.19, 1.13]} />
                      <group position={[0.06, 0.1, 0.08]} rotation={[-0.94, 0.97, 2.13]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.35, 0.79, 0.16]}>
                          <group position={[0, 0.02, 0]} rotation={[-0.06, 0.46, -0.52]} />
                        </group>
                      </group>
                      <group position={[0.05, 0.09, 0.08]} rotation={[-0.77, 0.96, 2]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.28, 0.88, -0.18]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.32, 0.42, -0.59]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.24, 0.56, -0.03]}>
                              <group position={[0, 0.02, 0]} rotation={[0.83, -0.29, -2.62]}>
                                <group position={[0, 0.01, 0]} rotation={[0.15, 0.18, 0.06]}>
                                  <group position={[0, 0.01, 0]} rotation={[0.4, 0.29, 0.38]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.06, 0.1, 0.08]} rotation={[-0.94, -0.97, -2.13]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.35, -0.79, -0.16]}>
                          <group position={[0, 0.02, 0]} rotation={[-0.06, -0.46, 0.52]} />
                        </group>
                      </group>
                      <group position={[-0.05, 0.09, 0.08]} rotation={[-0.77, -0.96, -2]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.28, -0.88, 0.18]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.32, -0.42, 0.59]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.24, -0.56, 0.03]}>
                              <group position={[0, 0.02, 0]} rotation={[0.83, 0.29, 2.62]}>
                                <group position={[0, 0.01, 0]} rotation={[0.15, -0.18, -0.06]}>
                                  <group position={[0, 0.01, 0]} rotation={[0.4, -0.29, -0.38]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.02, 0.16, 0.1]} rotation={[-2.91, 1.53, -0.52]}>
                        <group position={[-0.01, -0.01, 0.02]} rotation={[0.06, -0.23, 0.11]}>
                          <group position={[-0.02, 0, 0.02]} rotation={[-0.23, -0.56, -0.14]}>
                            <group position={[-0.01, 0, 0.03]} rotation={[0.49, -0.71, 0.35]}>
                              <group position={[0, 0.07, 0]} rotation={[0.07, -0.26, 0.19]}>
                                <group position={[0, 0.06, 0]} rotation={[-0.4, -0.69, 0.69]}>
                                  <group position={[0, 0.07, 0]} rotation={[-1.88, 0.04, 1.07]}>
                                    <group position={[0, 0.03, 0]} rotation={[-1.51, -0.48, -0.57]}>
                                      <group position={[0, 0.04, 0]} rotation={[0.37, 0.48, 0.29]}>
                                        <group position={[0, 0.06, 0]} rotation={[1.31, 0.54, 0.12]}>
                                          <group position={[0, 0.04, 0]} rotation={[-2.22, 0.78, 2.27]}>
                                            <group position={[0, 0.02, 0]} rotation={[-0.34, 0.93, -0.1]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.02, 0.16, 0.1]} rotation={[-2.91, -1.53, 0.52]}>
                        <group position={[0.01, -0.01, 0.02]} rotation={[0.06, 0.23, -0.11]}>
                          <group position={[0.02, 0, 0.02]} rotation={[-0.23, 0.56, 0.14]}>
                            <group position={[0.01, 0, 0.03]} rotation={[0.49, 0.71, -0.35]}>
                              <group position={[0, 0.07, 0]} rotation={[0.07, 0.26, -0.19]}>
                                <group position={[0, 0.06, 0]} rotation={[-0.4, 0.69, -0.69]}>
                                  <group position={[0, 0.07, 0]} rotation={[-1.88, -0.04, -1.07]}>
                                    <group position={[0, 0.03, 0]} rotation={[-1.51, 0.48, 0.57]}>
                                      <group position={[0, 0.04, 0]} rotation={[0.37, -0.48, -0.29]}>
                                        <group position={[0, 0.06, 0]} rotation={[1.31, -0.54, -0.12]}>
                                          <group position={[0, 0.04, 0]} rotation={[-2.22, -0.78, -2.27]}>
                                            <group position={[0, 0.02, 0]} rotation={[-0.34, -0.93, 0.1]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.07, 0.09, 0.05]} rotation={[3.03, -1.08, 0.85]}>
                        <group position={[0, 0.06, 0]} rotation={[2.97, -0.62, 1.43]}>
                          <group position={[0, 0.04, 0]} rotation={[2.72, 0.76, 1.22]} />
                        </group>
                      </group>
                      <group position={[-0.07, 0.09, 0.05]} rotation={[3.03, 1.08, -0.85]}>
                        <group position={[0, 0.06, 0]} rotation={[2.97, 0.62, -1.43]}>
                          <group position={[0, 0.04, 0]} rotation={[2.72, -0.76, -1.22]} />
                        </group>
                      </group>
                      <group position={[0, 0.02, 0.08]} rotation={[-1.27, 0, 0]}>
                        <group position={[0, 0.02, 0]} rotation={[-0.37, 0, 0]} />
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[0.02, 0.14, 0.05]} rotation={[-1.61, 0.02, -1.32]}>
                <group position={[-0.01, 0.15, -0.02]} rotation={[1.06, 1.31, -1.94]}>
                  <group position={[0, 0.26, 0]} rotation={[0.68, 0.16, -0.06]}>
                    <group position={[0, 0.27, 0]} rotation={[-0.04, 0.05, -0.06]}>
                      <group position={[-0.01, 0.02, 0.02]} rotation={[2.66, 1.46, -2.49]}>
                        <group position={[0, 0.06, 0]} rotation={[0.12, -0.08, 0.07]}>
                          <group position={[0, 0.03, 0]} rotation={[0.19, -0.1, 0.11]} />
                        </group>
                        <group position={[-0.01, -0.01, 0.01]} rotation={[-1.23, 1, 1.58]}>
                          <group position={[0, 0.03, 0]} rotation={[0.09, 0.08, 0.04]} />
                        </group>
                      </group>
                      <group position={[-0.02, 0.02, 0]} rotation={[2.28, 1.43, -2.24]}>
                        <group position={[0, 0.07, 0]} rotation={[0.14, -0.36, -0.02]}>
                          <group position={[0, 0.03, 0]} rotation={[0.16, -0.1, 0.01]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.02, -0.01]} rotation={[1.4, 1.45, -1.49]}>
                        <group position={[0, 0.07, 0]} rotation={[0.11, -0.4, -0.02]}>
                          <group position={[0, 0.03, 0]} rotation={[0.18, -0.15, -0.05]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.02, -0.03]} rotation={[1.06, 1.39, -1.28]}>
                        <group position={[0, 0.06, 0]} rotation={[0.07, -0.6, 0.06]}>
                          <group position={[0, 0.02, 0]} rotation={[0.22, -0.1, -0.19]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[-0.02, 0.14, 0.05]} rotation={[-1.61, -0.02, 1.32]}>
                <group position={[0.01, 0.15, -0.02]} rotation={[1.06, -1.31, 1.94]}>
                  <group position={[0, 0.26, 0]} rotation={[0.68, -0.16, 0.06]}>
                    <group position={[0, 0.27, 0]} rotation={[-0.04, -0.05, 0.06]}>
                      <group position={[0.01, 0.02, 0.02]} rotation={[2.66, -1.46, 2.49]}>
                        <group position={[0, 0.06, 0]} rotation={[0.12, 0.08, -0.07]}>
                          <group position={[0, 0.03, 0]} rotation={[0.19, 0.1, -0.11]} />
                        </group>
                        <group position={[0.01, -0.01, 0.01]} rotation={[-1.23, -1, -1.58]}>
                          <group position={[0, 0.03, 0]} rotation={[0.09, -0.08, -0.04]} />
                        </group>
                      </group>
                      <group position={[0.02, 0.02, 0]} rotation={[2.28, -1.43, 2.24]}>
                        <group position={[0, 0.07, 0]} rotation={[0.14, 0.36, 0.02]}>
                          <group position={[0, 0.03, 0]} rotation={[0.16, 0.1, -0.01]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.02, -0.01]} rotation={[1.4, -1.45, 1.49]}>
                        <group position={[0, 0.07, 0]} rotation={[0.11, 0.4, 0.02]}>
                          <group position={[0, 0.03, 0]} rotation={[0.18, 0.15, 0.05]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.02, -0.03]} rotation={[1.06, -1.39, 1.28]}>
                        <group position={[0, 0.06, 0]} rotation={[0.07, 0.6, -0.06]}>
                          <group position={[0, 0.02, 0]} rotation={[0.22, 0.1, 0.19]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
        <group position={[0.12, 0.04, 0.03]} rotation={[2.83, 0, -0.06]}>
          <group position={[0, 0.44, 0]} rotation={[0.15, 0.01, -0.04]}>
            <group position={[0, 0.38, 0]} rotation={[-1.18, -0.09, 0.04]} />
          </group>
        </group>
        <group position={[-0.12, 0.04, 0.03]} rotation={[2.83, 0, 0.06]}>
          <group position={[0, 0.44, 0]} rotation={[0.15, -0.01, 0.04]}>
            <group position={[0, 0.38, 0]} rotation={[-1.18, 0.09, -0.04]} />
          </group>
        </group>
      </group>
      <group name="rig">
        <primitive object={nodes.root} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
        <primitive object={nodes['MCH-torsoparent']} />
        <primitive object={nodes['MCH-hand_ikparentL']} />
        <primitive object={nodes['MCH-hand_ikparentR']} />
        <primitive object={nodes['MCH-foot_ikparentL']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
        <primitive object={nodes['MCH-foot_ikparentR']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
        <skinnedMesh
          geometry={nodes.BezierCurve.geometry}
          material={materials.hairstyle_ponytale1}
          skeleton={nodes.BezierCurve.skeleton}
        />
        {/* <skinnedMesh
          geometry={nodes.Pregnant_B_eyes_1.geometry}
          material={materials.eye_glass}
          skeleton={nodes.Pregnant_B_eyes_1.skeleton}
        /> */}
        <skinnedMesh
          geometry={nodes.Pregnant_B_eyes_2.geometry}
          material={materials['Eye_grey.002']}
          skeleton={nodes.Pregnant_B_eyes_2.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Pregnant_B_eyes_3.geometry}
          material={materials.bodyMaterial}
          skeleton={nodes.Pregnant_B_eyes_3.skeleton}
        />
        <skinnedMesh
          geometry={nodes.pregnantB_cloth0209.geometry}
          material={materials.underwear_velvet_FRONT_1869}
          skeleton={nodes.pregnantB_cloth0209.skeleton}
        />
        <skinnedMesh
          geometry={nodes.pregnantB_cloth0209001_1.geometry}
          material={materials.velvet_FRONT_1878}
          skeleton={nodes.pregnantB_cloth0209001_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.pregnantB_cloth0209001_2.geometry}
          material={materials.silk_FRONT_1862}
          skeleton={nodes.pregnantB_cloth0209001_2.skeleton}
        />
        <skinnedMesh
          geometry={nodes.PREGNENT_B_blend001.geometry}
          material={materials.middleage_caucasian_female}
          skeleton={nodes.PREGNENT_B_blend001.skeleton}
        />
      </group>
    </group>
  )
}

// useGLTF.preload('patient/pregnantBAnim.glb')
