import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  ava: {
    // boxShadow: `1px 0px 3px ${theme.palette.primary.dark}`,
    border: '2px solid white',
    // backgroundColor: 'white',
    // color: (p: Props) => (p.type == 'progress' ? theme.palette.primary.main : theme.palette.error.main),
    // borderColor: (p: Props) => (p.type == 'progress' ? theme.palette.primary.main : theme.palette.error.main),
    backgroundColor: (p: Props) =>
      p.type === 'progress'
        ? theme.palette.primary.main
        : theme.palette.error.main,
    fontWeight: 900,
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 12,
    display: 'none'
  }
}))

interface Props {
  value: number
  type: 'progress' | 'errors'
}

export default function IndicatroCircular({
  value,
  type
}: Props): ReactElement {
  const classes = useStyles({ type: type } as Props)
  return (
    <div>
      <Avatar className={classes.ava}>{value}</Avatar>
    </div>
  )
}
