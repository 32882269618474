import React, { useEffect, useState } from 'react';
import "@carbon/styles/css/styles.css";
import "@carbon/charts/styles.css";
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { allUsers, getInformationSpecificBall, Medicine_ResponseData } from '../../api/chatApi/chatApi';
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  DataTable,
  Dropdown,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  Theme,
  ToastNotification,
  ComboBox, Loading
} from '@carbon/react';
import chatApi from "../../api/chatApi";
import jwt from 'jsonwebtoken'
import { TablePagination } from '@material-ui/core';
import CustomTablePagination from '../CustomPagination';

const useStyles = makeStyles((theme: any) => ({
  requiredWarning: {
    '& .cds--toast-notification__details': {
      display: 'flex !important',
      alignItems: 'center !important',
      height: 50
    },
  },
  championTablePageContainer: {
    background: '#F4F4F4',
    // height: '100vh',
    marginBottom: 20,
    borderRadius: 4,
    '@media (max-width: 800px)': {
      width: '100%',
      overflowX: 'scroll',
    },
  },
  championTablePageContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '98%',
    margin: '0 auto',
    height: ''
  },
  championTableFilterBlock: {
    // width: 360,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 10px',
    background: '#FFFFFF',
    marginRight: '1%',
    width: '100%',
    marginBottom: '1%',
    borderRadius: 4,
    '@media (max-width: 800px)': {
      flexDirection: 'column'
    },
  },
  championSelectBlock: {
    display: 'flex',
    // marginBottom: 8,
    // marginRight: '50px',
    alignItems: 'flex-end',
    width: '100%',
    '& .cds--dropdown': {
      minWidth: 200
    },
    '& .cds--list-box': {
      // width: 225
    },
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      marginBottom: 15
    },
  },
  championSelectBlockText: {
    whiteSpace: 'pre',
    fontSize: 14,
    fontFamily: 'Helvetica Neue',
    alignSelf: 'center',
    marginRight: 15,
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      marginBottom: 15,
      alignSelf: 'start'
    },
  },
  championSelectElement: {
    marginRight: '15px',
    display: 'flex',
    alignItems: 'baseline',
    '@media (max-width: 800px)': {
      flexDirection: 'column'
    },
  },
  championSelectBlockMultiSelect: {
    display: 'flex',
    marginBottom: 8,
    width: 300
  },
  championTable: {
    width: '100%',
    marginRight: '1%',
    background: '#FFFFFF',
  },
  maxBallsBlock: {
    background: '#FFFFFF',
    padding: '20px 10px',
    display: 'flex',
    marginBottom: 16,
    // boxShadow: '0px -5px 5px -5px rgba(34, 60, 80, 0.48) inset'
  },
  myResultsBlock: {
    padding: '15px 10px',
    background: '#FFFFFF',
    height: 230,
  },
  maxBalls: {
    borderRight: '2px solid #DADADA',
    width: 180,
  },
  allParticipants: {
    marginLeft: 30,
  },
  titleBlocks: {
    fontFamily: 'Helvetica Neue',
    fontSize: 20,
    lineHeight: '23px',
    fontWeight: 400,
    marginBottom: '6%',
  },
  statisticsBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '24px',
    marginTop: '24px',
  },
  datePickerContainer: {
    display: 'flex',
    width: '100%',
    marginRight: 50,
    '& div.cds--date-picker': {
      display: 'flex',
      maxWidth: 225,
      width: '100%'
    },
    '@media (max-width: 800px)': {
      '& div.cds--date-picker': {
        flexDirection: 'column'
      },
    },
    '& .cds--date-picker__input': {
      maxWidth: 225,
      width: '100%',
      minWidth: 140
    },
    '& .cds--date-picker--range > .cds--date-picker-container': {
      width: '100%',
      marginRight: '15px',
    }
  },
  formationButtonBlock: {
    display: 'flex',
    justifyContent: 'start',
    textAlign: 'center',
    maxWidth: 225,
  },
  customBtn: {
    borderRadius: 4,
    height: 40,
    width: 175,
    maxWidth: 225,
    background: '#0f62fe',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    fontSize: 15,
    '&:hover': {
      background: '#0050e6'
    },
    '@media (max-width: 800px)': {
      width: 225,
    },
  },

  comboBoxContainer: {
    marginRight: 15,
    width: '100%',
    display: 'flex',
    // alignItems: 'baseline',
    '@media (max-width: 800px)': {
      marginBottom: 15,
      flexDirection: 'column',
      alignItems: 'baseline',
    },
  },
  comboboxText: {
    whiteSpace: 'pre',
    fontSize: 14,
    fontFamily: 'Helvetica Neue',
    alignSelf: 'center',
    marginRight: 5,
    '@media (max-width: 800px)': {
      alignSelf: 'start',
    },
  },
  customComboBox: {
    width: 325,
    maxWidth: 345,
    minWidth: 300,
    '@media (max-width: 800px)': {
      maxWidth: 225,
      minWidth: 200
    },
    '& .cds--combo-box': {
      borderRadius: 4
    }
  },
  emptyTableText: {
    whiteSpace: 'pre',
    fontSize: 20,
    fontFamily: 'Helvetica Neue',
    fontWeight: 500,
    '@media (max-width: 800px)': {
      whiteSpace: 'break-spaces',
      textAlign: 'center',
    },
  },
  tableContainer: {
    width: '100%',
    overflowY: 'auto',
    marginTop: 10,

    '& .cds--data-table-content': {
      maxHeight: '65vh',
    }
  }
  //
}))

interface SpecificBallPageInterface {
  countCompleteTask: number
  countPatientTask: number
  countFailedTask: number
  allUsersArray: unknown[]
}

interface OneUser {
  fio: string
  jhi_user_id: number
  login: string
  organization: []
  trainer: []
  user_id: number
}

interface UserInfo {
  active: boolean,
  id: number,
  login: string,
  name: string,
  photo: string,
  surname: string
}

interface formatListInterface {
  point: string
  id: number
  medicine: string
  typeofdifficultlevel: string
  universitylevel: string
  name: string
  completetime: string
  points: [
    completetime: string,
    id: number,
    point: string,
  ]
}
interface pointsInterface {
  completetime: string,
  id: number,
  point: string,
}

export default function SpecificBallPage({
  countCompleteTask,
  countPatientTask,
  countFailedTask,
  allUsersArray,
}: SpecificBallPageInterface): JSX.Element {

  const style = useStyles()

  const [allPatient, setAllPatient] = useState([])
  const [filteredPatient, setFilteredPatient] = useState([])
  const [name, setName] = useState('')
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null)
  const [userInfo, setUserInfo] = useState<UserInfo>()
  // const [allUsersArray, setAllUsersArray] = useState<unknown[]>([])
  const [currentPatient, setCurrentPatient] = useState({})
  const [allMedicine, setAllMedicicne] = useState<Medicine_ResponseData[]>([])
  const [page, setPage] = useState(0);
  const [currentMedicine, setCurrentMedicine] = useState<Medicine_ResponseData>()
  const [body, setBody] = useState({
    start_date: '',
    stop_date: '',
    login: '',
    medicineid: 0 || null
  })
  const [lolipop, setLolipop] = useState({
    data: [],
    options: {
      title: "",
      axes: {
        bottom: {
          title: "Дата",
          scaleType: "labels",
          mapsTo: "group"
        },
        left: {
          mapsTo: "value",
          title: "количество очков"
        },
      },
      height: "600px"
    }
  })
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [errorState, setErrorState] = useState(false)
  const [requiredFieldsState, setRequiredFieldsState] = useState(false)

  useEffect(() => {
    if (requiredFieldsState) {
      setTimeout(() => setRequiredFieldsState(false), 3000)
    }
  }, [requiredFieldsState])

  const headerData = [
    {
      key: 'id',
      header: 'Номер'
    },
    {
      key: 'medicine',
      header: 'Раздел медицины'
    },
    {
      key: 'typeofdifficultlevel',
      header: 'Уровень сложности'
    },
    {
      key: 'universitylevel',
      header: 'Академический уровень'
    },
    {
      key: 'name',
      header: 'Пациент'
    },
    {
      key: 'point',
      header: 'Очки'
    }
  ]

  const roleDecode = jwt.decode(localStorage.getItem('role'))
  const role = roleDecode.auth.split(',')

  useEffect(() => {
    if (allUsersArray) {
      getUserInfoHandler()
    }
    // getAllUsersHandler()
    getAllMedicineHandler()
  }, [])

  function changeNameHandler(event: any) {
    getInformationSpecificBallHandler()
    setName(event)
    specificBallsNameFilter(allPatient, event.name)
    filterPatientList(event.name)
  }

  function filterPatientList(name: string) {
    let filteredList = []
    filteredList = filteredPatient.filter(filter => {
      if (filter.name.includes(name)) {
        console.log(filter);
        return filter
      }
    })
    setFilteredPatient(filteredList)
  }

  function changeTypeOfMedicine(type: Medicine_ResponseData) {
    setCurrentMedicine(type)
    setBody({ ...body, medicineid: type.id })
  }

  async function getInformationSpecificBallHandler() {
    specificBallsNameFilter([], '')
    setAllPatient([])
    formattingListOfPatient([])
    try {
      if (!endTime ||
        !startTime ||
        !userInfo
        // !currentMedicine
      ) {
        setRequiredFieldsState(true);
        return
      }
      setIsLoading(true)
      console.log(body)
      const res = await getInformationSpecificBall(body)
      // setFilteredPatient(res.data.information_specific_ball)
      res.data.information_specific_ball.length === 0 ? setErrorState(true) : setErrorState(false)
      specificBallsNameFilter(res.data.information_specific_ball, name)
      setAllPatient(res.data.information_specific_ball)
      formattingListOfPatient(res.data.information_specific_ball)

    } catch (err) {
      setErrorState(true)
      specificBallsNameFilter([], '')
      setAllPatient([])
      formattingListOfPatient([])
      console.log('при получении списка произошла ошибка');
    } finally {
      setTimeout(() => setErrorState(false), 5000)
      setIsLoading(false)
    }
  }


  function formattingListOfPatient(list: formatListInterface[]) {
    let formatList: formatListInterface[] = []
    list.map((item) => {
      item.points?.map((element: any) => {
        formatList.push({
          ...item,
          point: element.point,
          completetime: element.completetime,
          id: element.id
        })
      })
    })
    formatList.sort((a, b) => {
      return a.id - b.id
    })
    setFilteredPatient(formatList)
  }

  const handleStartDateChange = (date: Date) => {
    setStartTime(date)
    const res = new Date(date).toLocaleDateString().split(/\.|\//).reverse().join('-')
    setBody({
      ...body,
      start_date: res,
      medicineid: body.medicineid || null
    })
  };

  const handleEndDateChange = (date: Date) => {
    setEndTime(date)
    const res = new Date(date).toLocaleDateString().split(/\.|\//).reverse().join('-')
    setBody({
      ...body,
      stop_date: res,
      medicineid: body.medicineid || null
    })
  };



  function changeCurrentPatient(patient) {
    setCurrentPatient(patient)
  }

  function specificBallsNameFilter(filterArray, searchName: string) {
    let points = []
    filterArray.filter(ball => {
      if (ball.name.toLowerCase().includes(searchName.toLowerCase())) {
        ball.points.map((point, index) => {
          points.push({
            name: ball.points[index].id,
            key: ball.name,
            group: ball.points[index].completetime,
            value: Math.abs(ball.points[index].point)
          })
        })

      }
    })
    setLolipop({ data: [...points], options: lolipop.options })
  }

  async function getUserInfoHandler() {
    try {
      const response = await chatApi.getUserInfo()
      setUserInfo(response.result)
      setBody({ ...body, login: response?.result?.login })
      if (role.includes('ROLE_ADMIN') || role.includes('ROLE_ADMIN_TRAINER') || role.includes('ROLE_ADMIN_ORG')) {
        allUsersArray.filter(user => user)
      } else {
        allUsersArray.filter(user => user.login.includes(response.result.login))
      }
    } catch (err) {
      console.log('при получении данных о пользователе произошла ошибка', err)
    }
  }

  async function getAllMedicineHandler() {
    try {
      const response = await chatApi.loadMedicine()
      console.log('getAllMedicine', response.result)
      setAllMedicicne(response.result)
    } catch (err) {
      console.log('при получении данных произошла ошибка,', err)
    }
  }

  function changeUserHandler(value) {
    setUserInfo(value);
    setBody({ ...body, login: value ? value.login : null, });
  }

  function itemToString(item) {
    return item && item.fio !== " " ? item.fio : item?.login ?? '';
  }

  const [isLoading, setIsLoading] = useState(false)

  function Loader() {
    if (isLoading) {
      return (
        <Loading className={'some-class'} withOverlay={false} />
      )
    } else return null
  }

  const indexOfFirstItem = page * currentPageSize;
  const indexOfLastItem = indexOfFirstItem + currentPageSize;
  const currentItems = filteredPatient.slice(indexOfFirstItem, indexOfLastItem);


  return (
    <div className={style.championTablePageContainer}>
      {
        errorState &&
        <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
          <ToastNotification
            kind="warning"
            hideCloseButton={false}
            lowContrast={false}
            style={{
              paddingBottom: 7,
              borderRadius: 4,
              width: 300,
              backgroundColor: '#656565',
              color: 'white'
            }}
            timeout={4000}
            title="Нет результатов"
          />
        </div>
      }
      {requiredFieldsState && (
        <div style={{ position: "fixed", right: 0, zIndex: 1000 }}>
          <ToastNotification
            kind="warning"
            hideCloseButton={true}
            lowContrast={false}
            style={{
              paddingBottom: 7,
              borderRadius: 4,
              width: 300,
              backgroundColor: '#656565',
              color: 'white'
            }}
            timeout={4000}
            title=""
          ><span>Обязательные поля не заполнены</span></ToastNotification>
        </div>
      )}
      <div style={{ width: "98%", padding: "20px 0", margin: "0 auto" }}>
        <p style={{ fontFamily: 'Helvetica Neue', fontWeight: 400, fontSize: 32 }}>
          Получение информации о конкретном балле</p>
      </div>
      <div
        style={{
          background: '#FFFFFF',
          fontSize: '104%',
          width: '98%',
          padding: '15px 10px',
          margin: 'auto auto 12px auto',
          borderRadius: 4
        }}>
        <p>Получение информации о прохождении ситуационных задач выбранным пользователем</p>
      </div>

      <div className={style.championTablePageContent}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={style.championTableFilterBlock}>
            <div className={style.championSelectBlock}>
              <div className={style.championSelectElement}>
                {allUsersArray && allUsersArray.length > 0 ? (
                  <div className={style.comboBoxContainer}>
                    <p className={style.comboboxText}>
                      Отображать результаты студента
                    </p>
                    <ComboBox
                      id={'specific-ball-page'}
                      className={style.customComboBox}
                      placeholder={"Выберите пользователя"}
                      items={allUsersArray ? allUsersArray : []}
                      itemToString={(item: OneUser) =>
                        item && item.login !== null && item.fio !== " "
                          ? item.fio
                          : item?.login
                      }
                      onChange={({ selectedItem }) =>
                        changeUserHandler(selectedItem)
                      }
                      selectedItem={userInfo}
                    />
                  </div>
                ) : (
                  <p style={{ whiteSpace: "pre", marginRight: "5%" }}>
                    {userInfo?.name} {userInfo?.surname}
                  </p>
                )}
              </div>
              <div className={style.championSelectBlock}>
                <p
                  className={style.championSelectBlockText}
                >за период
                </p>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    id="date-picker-inline"
                    placeholder="с"
                    value={startTime}
                    onChange={handleStartDateChange}
                  />
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    minDate={startTime}
                    id="date-picker-inline"
                    placeholder="по"
                    value={endTime === 'Invalid Date' ? startTime : endTime}
                    onChange={handleEndDateChange}
                    animateYearScrolling={true}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className={style.formationButtonBlock}>
              <button
                onClick={getInformationSpecificBallHandler}
                className={style.customBtn}
              >Применить фильтры
              </button>
            </div>
          </div>
        </div>

        {isLoading
          ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <Loader />
          </div >
          : filteredPatient && filteredPatient.length !== 0 ?
            <div className={style.tableContainer}>
              <Theme theme={'g10'}>
                {
                  filteredPatient &&
                  <DataTable
                    rows={currentItems}
                    headers={headerData}
                    isSortable
                  >
                    {({ rows, headers, getHeaderProps, getTableProps }) => {
                      return (
                        <TableContainer title="">
                          <Table {...getTableProps()} style={{ background: '#FFFFFF' }}>
                            <TableHead style={{
                              backgroundColor: "#FFFFFF",
                              position: "sticky",
                              top: 0,
                              zIndex: 1000,
                              borderRadius: 4,
                              boxShadow: '0 0px 4px 0 rgba(0,0,0,.25)'
                            }}>
                              <TableRow>
                                {headers.map((header) => {
                                  return (
                                    <TableHeader {...getHeaderProps({ header })}>
                                      {header.header}
                                    </TableHeader>
                                  )
                                })}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row) => {
                                return (
                                  <TableRow key={row.id}>
                                    {row.cells.map((cell) => (
                                      <TableCell key={cell.id}>{cell.value}</TableCell>
                                    ))}
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )
                    }}
                  </DataTable>
                }

              </Theme>

            </div> :
            <div style={{ margin: '25px auto 0 auto', paddingBottom: 20 }}>
              <p className={style.emptyTableText}
              >Чтобы увидеть информацию о конкретном балле заполните фильтры
              </p>
            </div>
        }
        {/*  {filteredPatient && name !== '' &&*/}
        {/*    <div style={{width: '90%'}}>*/}
        {/*    <LollipopChart*/}
        {/*    data={lolipop.data}*/}
        {/*    options={lolipop.options}>*/}
        {/*    </LollipopChart>*/}
        {/*    </div>*/}
        {/*}*/}
      </div>
      {<div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          paddingRight: 10,
          paddingBottom: 7,
          marginBottom: 10,
          backgroundColor: '#F4F4F4',
          borderRadius: 4
        }}>
        {filteredPatient.length > 0 &&
          <CustomTablePagination
            count={filteredPatient.length}
            page={page}
            rowsPerPage={currentPageSize}
            onPageChange={(newPage) => setPage(newPage)}
            onRowsPerPageChange={(newPageSize) => {
              setCurrentPageSize(newPageSize);
              setPage(0);
            }}
          />}
      </div>
      }

    </div>
  )
}
