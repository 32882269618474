import Button, { ButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { FunctionComponent } from 'react'

const useStyles = makeStyles(() => {
  return {
    spinner: {
      marginLeft: 8,
      color: `rgba(0,0,0,.50)`
    }
  }
})

const SpinnerAdornment = (props: any) => {
  const styles = useStyles()
  return (
    <CircularProgress
      color='primary'
      variant={'indeterminate'}
      className={styles.spinner}
      size={20}
    />
  )
}

interface Props extends ButtonProps {
  loading?: boolean
}

const ButtonWithProgress: FunctionComponent<Props> = ({
  children,
  loading,
  ...rest
}) => {
  return (
    <Button {...rest}>
      {children}
      {loading && <SpinnerAdornment {...rest} />}
    </Button>
  )
}

export default ButtonWithProgress
