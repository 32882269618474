import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { authReducer } from './auth';

import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { chatReducer } from './chat'; // defaults to localStorage for web
import createSagaMiddleware from 'redux-saga';
import { researchReducer } from './research';
import { localSettingsReducer } from './localSettings';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  auth: persistReducer({ key: 'auth', storage }, authReducer),
  settings: persistReducer({ key: 'settings', storage }, localSettingsReducer),
  chat: chatReducer,
  research: researchReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const composeEnhancers =
  typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware)
  // other store enhancers if any
);

export const store = createStore(rootReducer, {}, enhancer);

// @ts-ignore
export const persistor = persistStore(store);
export const runSaga = sagaMiddleware.run;
