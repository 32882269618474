import React from 'react'
import { useTranslation } from 'react-i18next'

export default function PreDiagnosisTooltip() {
  const {t} = useTranslation()
  return (
    // ПРЕДВАРИТЕЛЬНЫЙ ДИАГНОЗ
    <div>
      {t("trenModalDopDig")}
    </div>
  )
}
