import React, { useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function ManOld(props) {
  const { nodes, materials, animations } = useGLTF('./patient/PACIENT_B.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)

  useEffect(() => {
    props.setAnim(animations)
    actions.rigAction.play()
  }, [actions, names, mixer])

  return (
    <group ref={ref} {...props} dispose={null}>
      <group name="rig">
        <primitive object={nodes.root} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
        <primitive object={nodes['MCH-torsoparent']} />
        <primitive object={nodes['MCH-hand_ikparentL']} />
        <primitive object={nodes['MCH-hand_ikparentR']} />
        <primitive object={nodes['MCH-foot_ikparentL']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
        <primitive object={nodes['MCH-foot_ikparentR']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
        <skinnedMesh
          geometry={nodes.PACIENT_B.geometry}
          material={materials.short04}
          skeleton={nodes.PACIENT_B.skeleton}
        />
        <skinnedMesh
          geometry={nodes.PACIENT_B001.geometry}
          material={materials.male_casualsuit06}
          skeleton={nodes.PACIENT_B001.skeleton}
        />
        <skinnedMesh
          geometry={nodes.PACIENT_B003.geometry}
          material={materials.materialMaterial}
          skeleton={nodes.PACIENT_B003.skeleton}
        />
        <skinnedMesh
          geometry={nodes.PACIENT_B004.geometry}
          material={materials.old_caucasian_male}
          skeleton={nodes.PACIENT_B004.skeleton}
        />
        <skinnedMesh
          geometry={nodes.PACIENT_B005.geometry}
          material={materials.Eye_grey}
          skeleton={nodes.PACIENT_B005.skeleton}
        />
      </group>
    </group>
  )
}

// useGLTF.preload('patient/PACIENT_B.glb')
