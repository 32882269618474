import { createStyles, makeStyles } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { DefaultComponentProps } from '@material-ui/core/OverridableComponent'
import ButtonWithProgress from './ButtonWithProgress'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      flex: 1,
      padding: (p: Props) => (p.padded ? theme.spacing(2) : 0)
    }
  })
)
interface Props extends DefaultComponentProps<any> {
  children: string
  padded?: boolean
  disabled?: boolean
  loading?: boolean
  className?: string;
}

export default function CustomButton({
  children,
  padded,
  loading,
  className,
  ...props
}: Props): ReactElement {
  const classes = useStyles({ padded } as Props)

  return (
    <div className={`${classes.root} ${className || ''}`}>
      <ButtonWithProgress
        variant='contained'
        color='primary'
        onClick={props.onClick}
        disabled={props.disabled}
        loading={loading}
        style={{ fontFamily: 'Helvetica Neue', fontWeight: 400, minWidth: 230 }}
      >
        {children}
      </ButtonWithProgress>
    </div>
  )
}
