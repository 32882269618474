import { ThemeProvider } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import ruLocale from 'date-fns/locale/ru'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { SnackbarProvider } from 'notistack'
import React, { Suspense, useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ruRU } from '@material-ui/core/locale'
import { createTheme } from '@material-ui/core/styles'
import 'devextreme/dist/css/dx.light.css';

import { useSelector } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { buildConfig } from './BuildConfig'
import PrivateRoute from './components/PrivateRoute'
import EmailConfirmPage from './pages/auth/EmailConfirmPage'
import EmailPasswordResetPage from './pages/auth/EmailPasswordResetPage'
import LoginPage from './pages/auth/LoginPage'
import NewPasswordPage from './pages/auth/NewPasswordPage'
import PasswordResetPage from './pages/auth/PasswordResetPage'
import RegistrationInfoPage from './pages/auth/RegistrationInfoPage'
import RegistrationPage from './pages/auth/RegistrationPage'
import PreDiagnosisPage from './pages/chat/PreDiagnosisPage'
import SetDiagnosisPage from './pages/demo/demo-diagnosis/DiagnosisSetPage'
import DiagnosisSuccessPage from './pages/demo/demo-diagnosis/DiagnosisSuccessPage'
import DiagnosticFaliedPage from './pages/demo/demo-diagnosis/DiagnosticFaliedPage'
import ResearchEmptyPage from './pages/demo/demo-research/ResearchEmptyPage'
import ResearchFilledPage from './pages/demo/demo-research/ResearchFilledPage'
import ResearchInstrumentalDialogPage from './pages/demo/demo-research/ResearchInstrumentalDialogPage'
import ResearchLabDialogPage from './pages/demo/demo-research/ResearchLabDialogPage'
import ResearchPhotoDialogPage from './pages/demo/demo-research/ResearchPhotoDialogPage'
import DemoResultPage from './pages/demo/demo-result/DemoResultPage'
import TreatmentAddPage from './pages/demo/demo-treatment/TreatmentAddPage'
import TreatmentResultPage from './pages/demo/demo-treatment/TreatmentResultPage'
import TreatmentSetPage from './pages/demo/demo-treatment/TreatmentSetPage'
import FallbackPage from './pages/FallbackPage'
import NotFound404Page from './pages/NotFound404Page'
import ChooseAcademLevelPage from './pages/startChatWizard/ChooseAcademLevelPage'
import TestModel from './pages/models/TestModel'
import HaveCurrentTaskGuard from './router/HaveCurrentTaskGuard'
import RootRedirect from './router/RootRedirect'
import { RootState } from './store'
import { ThemeTypeCode } from './store/localSettings'
import theme_dark from './theme_dark'
import theme_light from './theme_light'
import { RoutePath } from './types'
import { YMInitializer } from 'react-yandex-metrika'
import MobileModal from './components/MobileModal'
import CognitiveTest from './pages/cognitive/CognitiveTest'
import CognitiveHistory from './pages/cognitive/CognitiveHistory'
import StressLoad from './pages/stressLoad/StressLoad'
import AboutCompany from './pages/InfoPage/AboutCompany'
import InfoUniversity from './pages/InfoPage/InfoUniversity'
import Сonnection from './pages/InfoPage/Сonnection'
import StartPageAdmin from './pages/adminPanel/StartPageAdmin'
import ChampionshipsList from './pages/championships/championshipsList/ChampionshipsList'
import ChampionshipsNew from './pages/championships/championshipsNew/championshipsNew'
import ChampionshipsItem from './pages/championships/championshipsItem/championshipsItem'
import MainPage from "./pages/mainPage/MainPage";
import ChampionTablePage from "./pages/championTestPage/ChampionTablePage";
import RatingTable from './pages/ratingTable/RatingTable';
import ErrorTable from './pages/errorTable/ErrorTable';
import SpecificBallPage from './pages/specificBall/SpecificBallPage';
import DepersonalizedRatingData from './pages/depersonalizedRatingData/DepersonalizedRatingData';
import ChampionshipRating from './pages/championships/championshipRating/ChampionsipRating'
import AnalyticsTable from './pages/analyticTable/AnalyticTable';
import ModelPoint from "./pages/modelPoint/ModelPoint";
import MainMethodological from './pages/methodologicalMaterials/MainMethodological'
import AccreditationPage from './pages/accreditationTest/AccreditationPage';
import LoadHandbooks from './pages/loadHandbooks/LoadHandbooks'
import Profile from './pages/profile/Profile'
import OrganizationProfilePage from './pages/profile/organizationProfile/organizationProfilePage';
import TeacherProfilePage from './pages/profile/teacherProfile/TeacherProfilePage';
import InformationSystems from './pages/informationSystems/InformationSystems'
import LoginFormMed from './components/auth/Forms/LoginFormMed'
import LegendPage from './components/PageBlocks/Legend/LegendPage'



const NewChatPage = React.lazy(() =>
  import('./pages/startChatWizard/NewChatPage')
)
const Cognitive = React.lazy(() => import('./pages/cognitive/Cognitive'))
const ChatPage = React.lazy(() => import('./pages/chat/ChatPage'))
const InspectionPage = React.lazy(() => import('./pages/chat/InspectionPage'))
const ExaminationPage = React.lazy(() => import('./pages/chat/ExaminationPage'))
const ResearchPage = React.lazy(() => import('./pages/chat/ResearchPage'))
const ConsultationPage = React.lazy(() =>
  import('./pages/chat/ConsultationPage')
)
const ConclusionPage = React.lazy(() => import('./pages/chat/ConclusionPage'))
const DiagnosisPage = React.lazy(() => import('./pages/chat/DiagnosisPage'))
const TherapyPage = React.lazy(() => import('./pages/chat/TherapyPage'))
const TaskFinalResultPage = React.lazy(() =>
  import('./pages/chat/TaskFinalResultPage')
)
const Rating = React.lazy(() => import('./pages/rating/Rating'))
const Constructors = React.lazy(() =>
  import('./pages/constructors/Constructors')
)
const Championship = React.lazy(() =>
  import('./pages/championships/Championships')
)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retryOnMount: true
    }
  }
})



const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' }
    }
  },
  ruRU
)

export default function App() {
  const themeCode = useSelector<RootState, ThemeTypeCode>(state => {
    return state.settings.theme
  })

  useEffect(() => {
    if (localStorage.getItem('translate') === null) {
      localStorage.setItem('translate', 'en')
    }
  }, [])

  return (
    <IntlProvider
      locale={'ru'}
      messages={{
        Час_в: `{time, plural,
          one {Час}
          many {Часа}
          other {Часов}
        }`,
        Час_раза_в: `{count, plural, 
          other {Час}
        }`,
        Час_по: `{count, plural, 
          one {Часу}
          few {Часа}
          other {Часов}
        }`,
        Час_в_течение: `{count, plural, 
          one {Часа}
          other {Часов}
        }`,
        //
        День_раза_в: `{count, plural, 
          other {День}
        }`,
        День_по: `{count, plural, 
          one {Дню}
          few {Дня}
          other {Дней}
        }`,
        День_в_течение: `{count, plural, 
          one {Дня}
          many {Дней}
          other {Дней}
        }`,
        //
        Неделя_раза_в: `{count, plural, 
          other {Неделю}
        }`,
        Неделя_по: `{count, plural, 
          one {Неделе}
          few {Недели}
          other {Недель}
        }`,
        Неделя_в_течение: `{count, plural, 
          one {Недели}
          other {Недель}
        }`,
        //
        Месяц_раза_в: `{count, plural, 
          other {Месяц}
        }`,
        Месяц_по: `{count, plural, 
          one {Месяцу}
          few {Месяца}
          many {Месяцев}
          other {Месяцу}
        }`,
        Месяц_в_течение: `{count, plural, 
          one {Месяца}
          many {Месяцев}
          other {Месяца}
        }`,
        //
        Год_раза_в: `{count, plural, 
          other {Год}
        }`,
        Год_по: `{count, plural, 
          one {Году}
          few {Года}
          other {Лет}
        }`,
        Год_в_течение: `{count, plural, 
          one {Года}
          other {Лет}
        }`,
        //
        count: `{count} {count, plural, 
          one {раз}
          many {раз}
          other {раза}
        }`,
        dosage: `Дозировка: {dosage}{dosageunit}`,
        medicalMedication: `Группа: {group}, Дозировка: {dose}{doseUnit}, {count} в день, {daypart}, в {countperiod}, {}`,
        scheduleItemDescription: `{targetuse}, {count} в {countperiod}, по {duration}, в течение {time}`,
        dietItemDescription: `В течение {time}`,
        foodItemDescription: `в течение {time}`
      }}
    >
      {/* <YMInitializer
        accounts={[87333080]}
        options={{
          defer: true,
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        }}
      /> */}


      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
            <SnackbarProvider maxSnack={3}>
              <BrowserRouter>
                <React.Fragment>
                  <Suspense fallback={<FallbackPage />}>
                    <Switch>
                      <Route exact path={RoutePath.root}>
                        <RootRedirect />
                      </Route>


                      {/*auth zone  */}
                      <Route
                        exact
                        path={RoutePath.register}
                        component={RegistrationPage}
                      />
                      <Route
                        exact
                        path={RoutePath.registerInfo}
                        component={RegistrationInfoPage}
                      />
                      <Route
                        exact
                        path={RoutePath.emailConfirm}
                        component={EmailConfirmPage}
                      />
                      <Route
                        exact
                        path={RoutePath.newPassword}
                        component={NewPasswordPage}
                      />
                      <Route path={RoutePath.login} component={LoginPage} />
                      <Route path={`${RoutePath.med}/:data`} component={LoginFormMed} />
                      <Route
                        exact
                        path={RoutePath.passReset}
                        component={PasswordResetPage}
                      />
                      <Route
                        exact
                        path={RoutePath.passResetConfirm}
                        component={EmailPasswordResetPage}
                      />

                      {/*main zone*/}
                      <PrivateRoute>
                        <Switch>
                          <Route exact path={RoutePath.testRoot} component={MainPage} />
                          <Route exact path={RoutePath.orgDescriptionPage} component={OrganizationProfilePage} />
                          <Route exact path={RoutePath.orgTeachersPage} component={TeacherProfilePage} />
                          <Route exact path={RoutePath.ratingTable} component={RatingTable} />
                          <Route exact path={RoutePath.studentsRating} component={ChampionTablePage} />
                          <Route exact path={RoutePath.errorTable} component={ErrorTable} />
                          <Route exact path={RoutePath.specificBallTable} component={SpecificBallPage} />
                          <Route exact path={RoutePath.modelPoint} component={ModelPoint} />
                          <Route exact path={RoutePath.accreditationPage} component={AccreditationPage} />
                          <Route exact
                            path={RoutePath.depersonalizedRatingData}
                            component={DepersonalizedRatingData}
                          />
                          <Route exact path={RoutePath.analyticsTable} component={AnalyticsTable} />
                          <Route
                            exact
                            path={RoutePath.newChat}
                            component={NewChatPage}
                          />
                          <Route
                            exact
                            path={RoutePath.Cognitive}
                            component={Cognitive}
                          />
                          <Route
                            exact
                            path={RoutePath.CognitiveTest}
                            component={CognitiveTest}
                          />
                          <Route
                            exact
                            path={RoutePath.CognitiveHistory}
                            component={CognitiveHistory}
                          />

                          <Route exact path={RoutePath.chat}>
                            <HaveCurrentTaskGuard component={ChatPage} />
                          </Route>

                          <Route exact path={RoutePath.inspection}>
                            <HaveCurrentTaskGuard component={InspectionPage} />
                          </Route>

                          <Route exact path={RoutePath.examination}>
                            <HaveCurrentTaskGuard component={ExaminationPage} />
                          </Route>

                          <Route exact path={RoutePath.conclusion}>
                            <HaveCurrentTaskGuard component={ConclusionPage} />
                          </Route>
                          <Route exact path={RoutePath.preDiagnosis}>
                            <HaveCurrentTaskGuard component={PreDiagnosisPage} />
                          </Route>

                          <Route path={`${RoutePath.research}`}>
                            <HaveCurrentTaskGuard component={ResearchPage} />
                          </Route>

                          <Route path={`${RoutePath.consultationPage}`}>
                            <HaveCurrentTaskGuard component={ConsultationPage} />
                          </Route>

                          <Route path={`${RoutePath.research}/:groupId`}>
                            <HaveCurrentTaskGuard component={ResearchPage} />
                          </Route>

                          <Route exact path={RoutePath.stress}>
                            <HaveCurrentTaskGuard component={StressLoad} />
                          </Route>

                          <Route exact path={RoutePath.diagnosis}>
                            <HaveCurrentTaskGuard component={DiagnosisPage} />
                          </Route>
                          <Route exact path={RoutePath.therapy}>
                            <HaveCurrentTaskGuard component={TherapyPage} />
                          </Route>
                          <Route exact path={RoutePath.finalTaskResult}>
                            <HaveCurrentTaskGuard
                              component={TaskFinalResultPage}
                            />
                          </Route>
                          <Route exact path={RoutePath.testModel}>
                            <HaveCurrentTaskGuard component={TestModel} />
                          </Route>
                          <Route exact path={RoutePath.legend}>
                            <HaveCurrentTaskGuard component={LegendPage} />
                          </Route>
                          {/* DEMOPAGES */}
                          {/* <Route exact path={RoutePath.preDiagnosis}>
                    <PreDiagnosisPage />
                  </Route> */}
                          <Route exact path={RoutePath.chooseAcademLevel}>
                            <ChooseAcademLevelPage />
                          </Route>
                          <Route exact path={RoutePath.researchEmpty}>
                            <ResearchEmptyPage />
                          </Route>
                          <Route exact path={RoutePath.researchLabDialog}>
                            <ResearchLabDialogPage />
                          </Route>
                          <Route exact path={RoutePath.researchInstrumentalDialog}>
                            <ResearchInstrumentalDialogPage />
                          </Route>
                          <Route exact path={RoutePath.researchFilled}>
                            <ResearchFilledPage />
                          </Route>
                          <Route exact path={RoutePath.researchPhotoDialog}>
                            <ResearchPhotoDialogPage />
                          </Route>
                          <Route exact path={RoutePath.diagnosisSet}>
                            <SetDiagnosisPage />
                          </Route>
                          <Route exact path={RoutePath.diagnosisSuccess}>
                            <DiagnosisSuccessPage />
                          </Route>
                          <Route exact path={RoutePath.diagnosisFailed}>
                            <DiagnosticFaliedPage />
                          </Route>
                          <Route exact path={RoutePath.treatmentSet}>
                            <TreatmentSetPage />
                          </Route>
                          <Route exact path={RoutePath.treatmentAddDialog}>
                            <TreatmentAddPage />
                          </Route>
                          <Route exact path={RoutePath.treatmentResult}>
                            <TreatmentResultPage />
                          </Route>
                          <Route exact path={RoutePath.demoResult}>
                            <DemoResultPage />
                          </Route>
                          <Route exact path={RoutePath.rating}>
                            <Rating />
                          </Route>
                          <Route exact path={RoutePath.constructors}>
                            <Constructors />
                          </Route>
                          <Route exact path={RoutePath.admin}>
                            <StartPageAdmin />
                          </Route>
                          <Route exact path={RoutePath.aboutCompany}>
                            <AboutCompany />
                          </Route>
                          <Route exact path={RoutePath.infoUniversity}>
                            <InfoUniversity />
                          </Route>
                          <Route exact path={RoutePath.connection}>
                            <Сonnection />
                          </Route>
                          <Route exact path={RoutePath.championshipRating}>
                            <ChampionshipRating />
                          </Route>
                          <Route exact path={RoutePath.championshipList}>
                            <ChampionshipsList />
                          </Route>
                          <Route exact path={RoutePath.championshipNew}>
                            <ChampionshipsNew />
                          </Route>
                          <Route exact path={RoutePath.championshipItem}>
                            <ChampionshipsItem />
                          </Route>
                          <Route exact path={RoutePath.championship}>
                            <Championship />
                          </Route>
                          <Route exact path={RoutePath.methodological}>
                            <MainMethodological />
                          </Route>
                          <Route exact path={RoutePath.handbooks}>
                            <LoadHandbooks />
                          </Route>
                          <Route exact path={RoutePath.profile}>
                            <Profile />
                          </Route>
                          <Route exact path={RoutePath.informationSystems}>
                            <InformationSystems />
                          </Route>
                          <Route path='*'>
                            <NotFound404Page />
                          </Route>
                        </Switch>
                        {/* 404 page */}
                      </PrivateRoute>
                    </Switch>
                  </Suspense>
                </React.Fragment>
              </BrowserRouter>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
        {buildConfig.buildType === 'development' ? (
          <ReactQueryDevtools initialIsOpen={false} />
        ) : null}
      </QueryClientProvider>
      {/* <MobileModal /> */}
    </IntlProvider>
  )
}
