import { Box, Container } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import React, { ReactNode } from 'react'
import { pxToRem } from '../../utils'

const useStyles = makeStyles(theme => ({
  root: {
    background: '#efefef',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  card: {
    borderRadius: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: 800,
    boxShadow: '0px 4px 45px rgba(36, 44, 59, 0.45)',
    background: 'white',
    minWidth: 500,
    padding: pxToRem(56),
    height: 610
  },
  title: {
    textAlign: 'center',
    color: '#C4C4C4',
    fontSize: pxToRem(20),
    fontWeight: 500,
    textTransform: 'uppercase',
    marginBottom: pxToRem(16)
  }
}))

type Props = {
  children: ReactNode
  title: string
}

export default function ConfirmationLayout(props: Props) {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <CssBaseline />
      <Container component='main' className={classes.card}>
        <div className={classes.title}>{props.title}</div>
        {props.children}
      </Container>
    </Box>
  )
}
