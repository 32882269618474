import React, { useState } from 'react';
import {
  DataTable, DatePicker, DatePickerInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow, Theme,
} from '@carbon/react';
import { makeStyles } from '@material-ui/core/styles';
import { Select } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  championTablePageContainer: {
    background: '#F4F4F4',
    height: '100vh'
  },
  filtersBlock: {
    display: 'flex',
    background: '#FFFFFF',
    marginBottom: 16,
    height: 64,
    boxShadow: "inset 0px -1px 0px #DADADA",
  },
  filtersBlockElement: {
    display: 'flex',
    borderRadius: 4,
    alignItems: 'center',
    padding: '12px 12px',

  },
  statisticsBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '24px',
    marginTop: '24px',
  },
  statisticsBlockElement: {
    padding: '8px 12px',
    borderLeft: '1px solid #DADADA',
  },
  titleBlocks: {
    fontFamily: 'Helvetica Neue',
    fontSize: 20,
    lineHeight: '23px',
    fontWeight: 400,
    marginBottom: '6%',
  },
  dataInBlocks: {
    fontFamily: 'Helvetica Neue',
    fontSize: 16,
    lineHeight: '14px',
    fontWeight: 400,
    color: '#000000'
  },
  championTablePageContent: {
    display: 'flex',
    width: '98%',
    margin: '0 auto',
    height: ''
  },
  championTableFilterBlock: {
    width: 360,
    padding: '15px 10px',
    background: '#FFFFFF',
    marginRight: '1%',
    height: 510,
  },
  championSelectBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
    alignItems: 'center',
  },
  datePickerContainer: {
    display: 'flex',
    alignItems: 'end',
    '& div.cds--date-picker': {
      display: 'flex',
      flexDirection: 'column',
    }
  },

}))

interface RatingTableInterface {
  countCompleteTask: number
  countPatientTask: number
  countFailedTask: number
}

export default function RatingTable({
  countPatientTask,
  countFailedTask,
  countCompleteTask
}: RatingTableInterface): JSX.Element {
  const style = useStyles()
  //  const [allRatings, setAllRatings] = useState([])
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')

  const allRatings = [
    {
      id: 0,
      patient: 'Иванов И.С',
      direction: 'Гинекология',
      course: "1",
      lvl: "Легкий",
      points: "45/60",
      date: "12.11.2022",
    },
    {
      id: 1,
      patient: 'Петров К.А',
      direction: 'Гинекология',
      course: "1",
      lvl: "Легкий",
      points: "45/60",
      date: "12.11.2022",
    },
    {
      id: 2,
      patient: 'Рудова Д.Л',
      direction: 'Гинекология',
      course: "1",
      lvl: "Легкий",
      points: "45/60",
      date: "12.11.2022",
    },
    {
      id: 3,
      patient: 'Стрельцов Б.С',
      direction: 'Гинекология',
      course: "1",
      lvl: "Легкий",
      points: "45/60",
      date: "12.11.2022",
    },
    {
      id: 4,
      patient: 'Васюткин Г.А',
      direction: 'Гинекология',
      course: "1",
      lvl: "Легкий",
      points: "45/60",
      date: "12.11.2022",
    }
  ]

  const headerData = [
    {
      key: 'patient',
      header: 'Пациент',
    },
    {
      key: 'direction',
      header: 'Направление',
    },
    {
      key: 'course',
      header: 'Курс',
    },
    {
      key: 'lvl',
      header: 'Уровень',
    },
    {
      key: 'points',
      header: 'Баллы',
    },
    {
      key: 'date',
      header: 'Баллы',
    },
    // {
    // 	key: 'id',
    // 	header: 'id'
    // }
  ];

  const personalStatistics = [
    {
      points: 2876,
      placeRating: '1/563',
      solvedTasks: '30/50',
      serverresponseobject: '60%'
    }
  ]

  function changeStartTime(event: React.ChangeEvent<HTMLInputElement>) {
    setStartTime(event.target.value)
  }

  function changeEndTime(event: React.ChangeEvent<HTMLInputElement>) {
    setEndTime(event.target.value)
  }

  return (
    <>
      {/*<div className={style.filtersBlock}>*/}
      {/*  <div className={style.filtersBlockElement}>*/}
      {/*    <p>Отображать задачи по</p>*/}
      {/*    <Select></Select>*/}
      {/*  </div>*/}
      {/*  <div className={style.filtersBlockElement}>*/}
      {/*    <p>для курса</p>*/}
      {/*    <Select></Select>*/}
      {/*  </div>*/}
      {/*  <div className={style.filtersBlockElement}>*/}
      {/*    <p>за период</p>*/}
      {/*    <Select></Select>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div>*/}
      {/*  {personalStatistics.map(personal => {*/}
      {/*    return (*/}
      {/*      <div className={style.statisticsBlock}>*/}
      {/*        <div className={style.statisticsBlockElement}>*/}
      {/*          <p className={style.titleBlocks}>Баллов набрано</p>*/}
      {/*          <p className={style.dataInBlocks}>{personal.points}</p>*/}
      {/*        </div>*/}
      {/*        <div className={style.statisticsBlockElement}>*/}
      {/*          <p className={style.titleBlocks}>Место в рейтинге</p>*/}
      {/*          <p className={style.dataInBlocks}>{personal.placeRating}</p>*/}
      {/*        </div>*/}
      {/*        <div className={style.statisticsBlockElement}>*/}
      {/*          <p className={style.titleBlocks}>Решено задач</p>*/}
      {/*          <p className={style.dataInBlocks}>{personal.solvedTasks}</p>*/}
      {/*        </div>*/}
      {/*        <div className={style.statisticsBlockElement}>*/}
      {/*          <p className={style.titleBlocks}>Область медицины освоена на</p>*/}
      {/*          <p className={style.dataInBlocks}>{personal.serverresponseobject}</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    )*/}
      {/*  })}*/}
      {/*</div>*/}
      <div className={style.championTablePageContent}>

        <div className={style.championTableFilterBlock}>
          <p className={style.titleBlocks}>Решавших задачи</p>
          <div className={style.statisticsBlock}>
            <p className={style.titleBlocks}>Статистика</p>
            <div>
              <p>{`Пациенты: ${countPatientTask}`}</p>
              <p>{`Завершенные задачи: ${countCompleteTask}`}</p>
              <p>{`Завершенные задачи: ${countCompleteTask}`}</p>
              <p>{`Заваленные тесты: ${countFailedTask}`}</p>
            </div>
          </div>
          <div className={style.championSelectBlock}>
            <p>за период</p>
            <DatePicker className={style.datePickerContainer} locale='ru' datePickerType="range" dateFormat="m/d/Y" >
              <DatePickerInput
                placeholder="с"
                value={startTime}
                onChange={changeStartTime}
              />
              <DatePickerInput
                placeholder="до"
                value={endTime}
                onChange={changeEndTime}
              />
            </DatePicker>
          </div>
        </div>
      </div>
      <Theme theme={'g10'}>
        <DataTable rows={allRatings} headers={headerData} isSortable >
          {({ rows, headers, getHeaderProps, getTableProps }) => {
            return (
              <TableContainer title="DataTable">
                <Table {...getTableProps()} style={{ background: '#FFFFFF' }}>
                  <TableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <TableHeader {...getHeaderProps({ header })}>
                          {header.header}
                        </TableHeader >
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.id}>
                        {row.cells.map((cell) => (
                          <TableCell key={cell.id}>{cell.value}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          }}
        </DataTable>
      </Theme>
    </>
  )
}
