import { DataAction } from './common';

export type AuthState = {
  jwtToken: string | undefined;
};

export enum AuthAction {
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGOUT = 'LOGOUT',
}

const initState = { jwtToken: undefined };

export function authReducer(state: AuthState = initState, action: DataAction<AuthAction, any>) {
  switch (action.type) {
    case AuthAction.LOGIN_SUCCESS:
      return { jwtToken: action.data.id_token };
    case AuthAction.LOGOUT:
      return { jwtToken: undefined };
    default:
      return state;
  }
}
