import React, { useState, useEffect } from "react";
//@ts-ignore
import { Accordion, AccordionItem, Button } from '@carbon/react';
import { makeStyles, createStyles } from '@material-ui/core';
import { getRegisterUsers } from "../../../api/chatApi/chatApi";

const useStyles = makeStyles(() =>
  createStyles({
    block: {
      fontFamily: 'Helvetica Neue',
      paddingLeft: 10
    },
    task: {
      marginBottom: 20,
      fontSize: 20,
      fontWeight: 400
    },
    lorem: {
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      color: '#000000',
      marginBottom: 20
    }
  })
)

const ChampionshipTaskStage = ({ task, next, max, status }) => {
  const style = useStyles()
  const idChamp = localStorage.getItem('idChamp')
  const [taskCheck, setTaksCheck] = useState<any>([])

  const getRegisters = async () => {
    await getRegisterUsers(Number(idChamp), status())
      .then(res => {
        setTaksCheck(res.data.registration_user)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getRegisters()
  }, []);


  return (
    <div className={style.block}>
      {max &&
        <div className={style.lorem}>Максимум участников: {max}</div>}
      <div className={style.task}>Задачи этапа</div>

      <Accordion>
        {task.map((el, index) => {
          return (
            <AccordionItem key={index} title={`${index + 1}. ${el.name}`}>
              <div>{el.description}</div>
              <div style={{ marginTop: 10 }}>
                {taskCheck.participation_stage === true && taskCheck.number === el.task_stage ?
                  el.championship_task_end ?
                    <Button size='sm' disabled>Завершен</Button>
                    :
                    <Button size='sm' onClick={() => next(el.task_id)}>Перейти</Button>
                  :
                  null
                }
              </div>
            </AccordionItem>
          )
        })}
      </Accordion>
    </div>
  );
}

export default ChampionshipTaskStage;