import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Slider, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ProgressIndicator, ProgressStep } from '@carbon/react';
import chatApi from '../../api/chatApi';
import CustomButton from '../../components/common/CustomButton';
import RatingDialog from '../../components/Chat/RatingDialog';
import Test from './Test';
import './Stress.css';
import { getTestCognitive } from "../../api/chatApi/chatApi";

const useStyles = makeStyles(theme => ({
  mainRoot: {
    fontFamily: 'Helvetica',
    '& .MuiTypography-body1': {
      fontFamily: 'Helvetica',
      fontWeight: 300
    }
  },
  root: {
    marginLeft: '20px',
    marginTop: '40px',
    width: '60%',
    height: 8,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'DADADA',
    fontFamily: 'Helvetica',
  },
  labelContainer: {
    width: '60%',
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '10px',

  },
  label: {
    color: '#1876D1',
    fontFamily: 'Helvetica',
    fontWeight: 'bold'
  },
  labelInactive: {
    color: 'grey',
    fontFamily: 'Helvetica',
    fontWeight: 'bold',
  },
  slide: {
    width: "350px",
    position: 'absolute',
    left: 40,
  },
  errorSlide: {
    width: "350px",
    position: 'absolute',
    left: 40,
    color: 'red',
  },
  markLabel: {
    marginLeft: '1%',
    color: 'primary',
    fontSize: 14,
  },
  markLabelActive: {
    color: 'primary',
  },
  markLabelError: {
    color: 'red',
    fontSize: 14,
  },
  errorSliderText: {
    fontFamily: 'Helvetica',
    fontSize: '14px',
    color: 'red'
  },
  mainQuestionContainer: {
    display: 'flex',
    justifyContent: 'left',
    fontSize: '1.2rem',
    fontWeight: 500,
    margin: 20,
    backgroundColor: 'rgba(33, 150, 243, 0.1)',
    marginTop: '20px',
    paddingLeft: '25px',
    paddingTop: '15px',
    paddingBottom: '15px',
    color: "#000000",
    height: '84px',
    width: '695px',
    alignItems: 'center',
    fontFamily: 'Helvetica',
  },
  firstQuestionContainer: {
    display: 'flex',
    justifyContent: 'left',
    fontSize: '1.1rem',
    width: "50%",
    margin: 10,
    marginTop: 32,
    marginLeft: '25px',
    fontFamily: 'Helvetica',
    fontWeight: 400
  },
  progressBarContainer: {
    marginTop: 40,
    marginLeft: 25,
    marginBottom: 40,
    width: '750px',
    fontFamily: 'Helvetica',
  },
  progressIndicator: {
    fontSize: '12px',

  },
  progressIndicatorStep: {
    fontSize: '12px',
    width: '100%',
  },
  bar: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '757575',
  },
  formControl: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: 7,
    marginLeft: '25px',
    fontFamily: 'Helvetica',
  },
  radioBtns: {
    marginLeft: '15px',
    marginTop: '-5px',
    display: 'flex',
    flexDirection: 'column',
    '& .Mui-checked': {
      color: '#4994ED',
    },
  },
  errorRadioBtns: {
    marginLeft: '15px',
    marginTop: '-5px',
    display: 'flex',
    flexDirection: 'column',
    color: 'red'
  },
  errorRadioText: {
    marginLeft: 40,
    fontFamily: 'Helvetica',
    fontSize: '14px',
    color: 'red'
  },
  testStartContainer: {
    display: 'flex',
    fontSize: '1.2rem',
    flexDirection: 'column',
    justifyContent: 'left',
    marginTop: '20px',
    width: '100%',
    padding: '20px',
    position: 'relative',
    fontFamily: 'Helvetica'
  },
  testStartBtn: {
    marginTop: 40
  },
  conclusionContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  slideContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 20,
    height: 60,
    flexWrap: 'wrap',
    marginBottom: 20,
    position: 'relative',
    fontFamily: 'Helvetica',
  },
  questionTextContainer: {
    display: 'flex',
    justifyContent: 'left',
    fontSize: '1.1rem',
    margin: 10,
    marginTop: 22,
    marginLeft: '25px',
    width: '50%',
    wordBreak: 'break-word',
    fontFamily: 'Helvetica',
    fontWeight: 400

  },
  customBar: {
    width: '30px',
  },
  nextBtns: {
    paddingBottom: '30px',
  },
  thumb: {
    color: 'transparent',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  customMark: {
    backgroundColor: '#bfbfbf',
    height: 10,
    width: 10,
    borderRadius: '50%',
    marginTop: -15,
    marginLeft: -4,
  },
  activeCustomMark: {
    backgroundColor: '#1876D1',
    height: 14,
    width: 14,
    borderRadius: '50%',
    marginTop: -16,
    marginLeft: -4,
  },

  active: {},
  customMarkLabel: {
    marginTop: 4,
    marginLeft: -4
  },
  rail: {
    height: 3,
  },
  track: {
    height: 6,
  },


}));

const StressLoad = (props) => {
  const [random, setRandom] = useState(1);
  const [answers, setAnswers] = useState([]);
  const [answerData, setDataAnswer] = useState({});
  const [data, setData] = useState([]);
  const [totalQuestion, setTotalQuestion] = useState(null);
  const [step, setStep] = useState(2);
  const [dataQuestion, setDataQuestion] = useState({});
  const [valueText, setValueText] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [dates, setDates] = useState(null);
  const [numberQuestion, setNumberQuestion] = useState(1);
  const [idQuestion, setIdQuestion] = useState(0);
  const [result, setResult] = useState();
  const [bal, setBal] = useState('');
  const [nextDiagnos, setNextDiagnos] = useState(false);
  const [tog, setTog] = useState(true);
  const [dataTest, setDataTest] = useState([]);
  const [answersState, setAnswersState] = useState({});
  // const [value, setValue] = useState(0);
  const styles = useStyles();
  const [points, setPoints] = useState([])
  const [linearProgress, setLinearProgress] = useState(true)
  const [progress, setProgress] = useState(25);
  const [questionsArr, setQuestionsArr] = useState([])
  const [isVisible, setIsVisible] = useState(true);
  const [testStart, setTestStart] = useState(false)
  const [sliderKey, setSliderKey] = useState(Date.now());
  const [sliderValue, setSliderValue] = useState(-1);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const areAllQuestionsAnswered = () => {
    const isSliderAnswered = sliderValue !== -1;
    const areRadioQuestionsAnswered = questionsArr.every(question =>
      answersState[question.questionID] && answersState[question.questionID].value !== undefined
    );
    return isSliderAnswered && areRadioQuestionsAnswered;
  };

  useEffect(() => {
    const allAnswered = areAllQuestionsAnswered();
    setButtonDisabled(!allAnswered);
  }, [sliderValue, answersState, questionsArr]);


  useEffect(() => {
    getTestCognitive()
      .then(res => {
        setDataTest(res.data.cognitive_test)
      })
  }, []);

  useEffect(() => {
    const stepArr = props.arr[Number(random) - 1];
    setData(stepArr);
    answerQuestion(stepArr[0]);
  }, [props.arr, random]);


  useEffect(() => {
    const dates = new Date();
    setDates(dates);
    if (step - 1 < totalQuestion) {
      nextQuestion(data[0], step);
      // getQuestions(data[0])
    }
  }, [step, totalQuestion, data]);

  // useEffect(() => {
  //   getQuestions()
  // }, [])


  const getQuestions = async () => {
    if (data[0]) {
      try {
        const questions = await chatApi.getStressQuestion(data[0])
        setQuestionsArr(questions.result)
      } catch {
        console.log('Ошибка при загрузке вопросов');
      }
    } else {
      console.log('Ошибка при загрузке вопросов');
    }
  }

  const handleChange = (event, currentQuestionId, newValue, type) => {
    if (type === "slider") {
      // setValue(newValue);
      setAnswersState(prevState => ({
        ...prevState,
        [currentQuestionId]: {
          type: "slider",
          value: newValue,
          error: false,
        }
      }));
    } else {
      setAnswersState(prevState => ({
        ...prevState,
        [currentQuestionId]: {
          type: "radio",
          id: newValue,
          value: event.target.value,
          error: false,
        }
      }));
    }
  };

  const validateBeforeNextStep = () => {
    let allQuestionsValid = true;
    setAnswersState((prevState) => {
      const newState = { ...prevState };
      for (let questionId in newState) {
        if (newState[questionId].value === undefined) {
          newState[questionId].error = true;
          allQuestionsValid = false;
        }
      }
      return newState;
    });
    return allQuestionsValid;
  };


  const nextQuestion = async (data, step) => {
    try {
      const res = await chatApi.loadQuestionsStep(data, step);
      const dates = res.result[0];
      setIdQuestion(dates.questionID);
      setDataAnswer(dates.answers);
      setAnswers(Object.values(dates.answers));
      setDataQuestion(dates);
    } catch (error) {
      console.error('Произошла ошибка при загрузке вопросов:', error);
    }
  };

  const answerQuestion = async data => {
    try {
      const res = await chatApi.loadQuestionsStress(data);
      setTotalQuestion(res.result);
    } catch (error) {
      console.error("Произошла ошибка при загрузке вопросов:", error);
    }
  };

  const sendResults = async () => {
    const idPhrase = props.arr.filter(el => el[1] === data[1]);
    const datesEnd = new Date();
    const promises = Object.entries(answersState).map(async ([idQuestion, answer]) => {
      const isSliderAnswer = answer.type === "slider";
      const idAnswer = isSliderAnswer ? (Number(answer.value) === 0 ? 1 : Number(answer.value)) : Number(answer.id);


      await chatApi.stressHistory({
        idPhrase: idPhrase[0][0],
        idQuestion: Number(idQuestion),
        idAnswer,
        timeBeg: dates,
        timeFin: datesEnd,
        usename: props.id,
      });

    });
    try {
      await Promise.all(promises);
      setTog(false);
    } catch (e) {
      console.log(e);
    }
  }

  const nextStep = async () => {
    const idPhrase = props.arr.filter(el => el[1] === data[1]);
    const datesEnd = new Date();
    const promises = Object.entries(answersState).map(async ([idQuestion, answer]) => {
      const isSliderAnswer = answer.type === "slider";
      const idAnswer = isSliderAnswer ? (Number(answer.value) === 0 ? 1 : Number(answer.value)) : Number(answer.id);

      await chatApi.stressHistory({
        idPhrase: idPhrase[0][0],
        idQuestion: Number(idQuestion),
        idAnswer,
        timeBeg: dates,
        timeFin: datesEnd,
        usename: props.id,
      });
    });
    try {
      await Promise.all(promises);
      setTog(false);
    } catch (e) {
      console.log(e);
    }
    setAnswersState({});
    // setValue(0);
    setStep(prevStep => prevStep + 1);
    setValueText('');
    setProgress(prevProgress => (prevProgress + 25))
    if (step === totalQuestion) {
      setToggle(true);
    }
  };


  const goToDiagnosis = async () => {
    await endStep()
    end()
  }

  const endStep = async () => {
    if (validateBeforeNextStep()) {
      try {
        await sendResults();
        setTog(true);
        const res = await chatApi.loadAnalysisStress();
        setPoints(res.result)
        setResult(true);
        setBal(res.result.grade);

      } catch (error) {
        console.log(error);
        setTog(true)
        // setResult(true);

      }
      props.scrollToTop()
      setLinearProgress(false)
    }
  }

  useEffect(() => {
    if (data.length > 0) {
      getQuestions(data[0]);
    }
  }, [data]);

  const nextSituation = () => {
    if (validateBeforeNextStep()) {
      nextStep();
      setRandom((prev) => prev + 1);
      next()
      resetSlider();
      props.scrollToTop();
    }
  }

  const next = () => {
    setRandom(prev => prev + 1);
    setNumberQuestion(prev => prev + 1);
    setToggle(false);
    setResult('');
  };

  const end = () => {
    setNextDiagnos(true);
    setAnswersState({});
    // setValue(0);
  };

  const openTest = () => {
    setTestStart(true)
    setIsVisible(false)
  }

  useEffect(() => {
    const initialAnswersState = questionsArr.reduce((state, question) => {
      return {
        ...state,
        [question.questionID]: {
          value: undefined,
          error: false,
        }
      }
    }, {});
    setAnswersState(initialAnswersState);
  }, [questionsArr]);

  const firstKey = Object.keys(answersState)[0]
  const firstKeyError = answersState[firstKey]


  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    handleChange(event, questionsArr[0]?.questionID, newValue, 'slider');
  };

  const resetSlider = () => {
    setSliderKey(Date.now());
    setSliderValue();
  };


  const dataForProgress = [
    'Cитуация',
    'Cитуация',
    'Cитуация',
    'Cитуация',
  ]

  const customMarks = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
  ].map(mark => ({
    ...mark,
    isActive: mark.value === sliderValue,
    label: (
      <div key={mark.value}>
        <div className={mark.value === sliderValue ? styles.activeCustomMark : styles.customMark} />
        <Typography className={styles.customMarkLabel}>{mark.label}</Typography>
      </div>
    ),
  }));

  useEffect(() => {
    if (testStart && result && !dataTest && !props.openDialog) {
      props.onSubmit()
    }
  }, [testStart, result, dataTest, props.openDialog])

  return (
    <>
      <div>
        {isVisible && (
          <Box className={styles.testStartContainer}>
            На приёме у врача бывают «трудные» пациенты. И это всегда стресс! Как предотвратить конфликт и жалобу? Как общаться эффективно?
            В этом врачу помогает эмоциональный интеллект (ЭИ) или умения сохранять спокойствие, понимать свои переживания и эмоциональное состояние пациента.
            Пройди тренинг по ЭИ, оцени свои умения на текущий момент и получи рекомендации к развитию.
            Тебе будут предложены по очереди четыре ситуации. Это - фразы пациента, которые вызывают у врача определённые чувства и реакции. Ответь на четыре вопроса в каждой ситуации.
            <Box className={styles.testStartBtn}>
              <CustomButton onClick={openTest}>Пройти тренинг</CustomButton>
            </Box>
          </Box>
        )}
      </div>
      <div className={styles.mainRoot}>
        {testStart ? (
          result ? (
            nextDiagnos ? (
              <RatingDialog open={true} onSubmit={props.onSubmit} />
              // dataTest ? (
              //   <Test data={dataTest} setDataTest={setDataTest} />
              // ) : props.openDialog ? (
              //   <RatingDialog open={true} onSubmit={props.onSubmit} />
              // ) : null
            ) 
            : (
              <Box>
                {linearProgress ? (
                  <LinearProgress
                    classes={{
                      root: styles.root,
                      bar: styles.bar,
                    }}
                    variant="determinate"
                    value={progress}
                  >
                  </LinearProgress>
                ) : null}
              </Box>
            )
          ) : (
            <>
              {linearProgress ? (
                <Box className={styles.progressBarContainer}>
                  <ProgressIndicator vertical={false} currentIndex={step - 2}
                    className={styles.progressIndicator}
                  >
                    {dataForProgress.map((el, index) => {
                      return (
                        <ProgressStep
                          key={index}
                          label={`${el} ${index + 1}`}
                          className={styles.progressIndicatorStep}
                        />
                      )
                    })}
                  </ProgressIndicator>
                </Box>) : null}
              <div className={styles.mainQuestionContainer}>
                {data[1]}
              </div>
              <>
                <div className={styles.firstQuestionContainer}>
                  {questionsArr[0]?.questionText}
                </div>
                <div className={styles.slideContainer}>
                  <Box
                    className={styles.slide}
                  >
                    <Slider
                      key={sliderKey}
                      value={sliderValue}
                      aria-label="Temperature"
                      getAriaValueText={setValueText}
                      valueLabelDisplay="auto"
                      step={1}
                      marks={customMarks}
                      min={0}
                      max={5}
                      onChange={handleSliderChange}
                      className=
                      {firstKeyError?.error ?
                        (styles.errorSlide, styles.colorSecondary) : (styles.slide, styles.markLabel, styles.markLabelActive)}
                      classes={{
                        thumb: styles.thumb,
                        active: styles.active,
                        mark: styles.mark,
                        markActive: styles.markActive,
                        markLabel: styles.markLabel,
                        markLabelActive: styles.markLabelActive,
                        colorSecondary: styles.colorSecondary,
                        rail: styles.rail,
                        track: styles.track,
                      }}
                    />
                    {firstKeyError?.error ? (<Box className={styles.errorSliderText}>Поставьте оценку</Box>) : null}
                  </Box>
                </div>
              </>
              {questionsArr.slice(1).map((question) => (
                <div key={question.questionID}>
                  <div className={styles.questionTextContainer}>
                    {question.questionText}</div>
                  <RadioGroup
                    aria-labelledby="stress-load-radio-buttons-group"
                    name="stress-load-radio-buttons-group"
                    value={answersState[question.questionID]?.value || ''}
                    className={styles.formControl}
                  >
                    {Object.entries(question.answers).map(([answerId, answerText]) => (
                      <FormControl className={styles.radioBtns} >
                        <FormControlLabel
                          value={answerText}
                          control={
                            <Radio key={answerId.id} checked={answersState[question.questionID]?.value === answerText}
                              className={answersState[question.questionID]?.error ? styles.errorRadioBtns : styles.radioBtns}
                            />}
                          label={answerText}
                          onChange={(event) => handleChange(event, question.questionID, answerId, "radio")}
                        />
                      </FormControl>
                    ))}
                  </RadioGroup>
                  {answersState[question.questionID]?.error ? (<Box className={styles.errorRadioText}>Выберите один из вариантов</Box>)
                    : <Box></Box>}
                </div>
              ))}
              {toggle ? (<Box className={styles.nextBtns}>
                <CustomButton onClick={goToDiagnosis} disabled={buttonDisabled}>
                  {'Завершить шаг'}
                </CustomButton></Box>) : (<Box className={styles.nextBtns}><CustomButton onClick={nextSituation} disabled={buttonDisabled}>
                  {'К следующей ситуации'}
                </CustomButton></Box>)}
            </>
          )
        ) : null}
      </div>
    </>
  );
};
export default StressLoad;
