import React, { useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'


export default function ManInsult(props) {
  const { nodes, materials, animations } = useGLTF('patient/oldmale_endomorph_INSULT.glb')
  const { ref, actions, names } = useAnimations(animations)



  useEffect(() => {
    props.setAnim(animations)
    if(actions && names) {
      actions[names[props.index]].reset().fadeIn(0.5).play()
      return () => actions[names[props.index]].fadeOut(0.5)
    }
  }, [props.index, actions, names])

  return (
    <group ref={ref} {...props} dispose={null}>
      <group position={[0, 0.85, 0]} rotation={[0.1, 0, 0]}>
        <group position={[0, 0.14, 0]} rotation={[0.02, 0, 0]}>
          <group position={[0, 0.12, 0]} rotation={[-0.22, 0, 0]}>
            <group position={[0, 0.15, 0]} rotation={[0.13, 0, 0]}>
              <group position={[0, 0.17, 0]} rotation={[0.26, 0, 0]}>
                <group position={[0, 0.04, 0]} rotation={[-0.04, 0, 0]}>
                  <group position={[0, 0.03, 0]} rotation={[-0.23, 0, 0]}>
                    <group name="face" rotation={[0.04, 0, 0]}>
                      <group position={[0, 0.12, 0.11]} rotation={[2.64, 0, 0]} />
                      <group position={[0.07, 0.08, 0.02]} rotation={[-0.2, -0.18, -0.25]}>
                        <group position={[0, 0.04, -0.03]} rotation={[-2.97, 0.43, -0.21]} />
                      </group>
                      <group position={[-0.07, 0.08, 0.02]} rotation={[-0.2, 0.18, 0.25]}>
                        <group position={[0, 0.04, -0.03]} rotation={[-2.97, -0.43, 0.21]} />
                      </group>
                      <group position={[0.06, 0.13, 0.09]} rotation={[-1.05, 0.94, 2]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.53, -0.02, 0.06]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.23, 0, 0.04]} />
                        </group>
                      </group>
                      <group position={[-0.06, 0.13, 0.09]} rotation={[-1.05, -0.94, -2]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.53, 0.02, -0.06]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.23, 0, -0.04]} />
                        </group>
                      </group>
                      <group position={[0.03, 0.04, 0.11]} rotation={[-1.11, -0.32, -0.73]} />
                      <group position={[0.07, 0.14, 0.07]} rotation={[1.37, -0.31, 0.53]} />
                      <group position={[-0.03, 0.04, 0.11]} rotation={[-1.11, 0.32, 0.73]} />
                      <group position={[-0.07, 0.14, 0.07]} rotation={[1.37, 0.31, -0.53]} />
                      <group position={[0.03, 0.11, 0.08]} rotation={[-1.62, 0, -Math.PI]}>
                        <group position={[-0.02, 0.01, 0]} rotation={[-0.62, -1.09, -1.29]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.48, 0.77, 0.03]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.32, 0.35, -0.48]} />
                          </group>
                        </group>
                        <group position={[0.02, 0.02, 0]} rotation={[0.19, -0.51, 1.29]}>
                          <group position={[0, 0.01, 0]} rotation={[0.33, 0.24, 0.46]}>
                            <group position={[0, 0.01, 0]} rotation={[0.28, 0.17, 0.19]} />
                          </group>
                        </group>
                      </group>
                      <group position={[-0.03, 0.11, 0.08]} rotation={[-1.62, 0, -Math.PI]}>
                        <group position={[0.02, 0.01, 0]} rotation={[-0.62, 1.09, 1.29]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.48, -0.77, -0.03]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.32, -0.35, 0.48]} />
                          </group>
                        </group>
                        <group position={[-0.02, 0.02, 0]} rotation={[0.19, 0.51, -1.29]}>
                          <group position={[0, 0.01, 0]} rotation={[0.33, -0.24, -0.46]}>
                            <group position={[0, 0.01, 0]} rotation={[0.28, -0.17, -0.19]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0.07, 0.11, 0.06]} rotation={[2.91, -1.07, 0.91]} />
                      <group position={[-0.07, 0.11, 0.06]} rotation={[2.91, 1.07, -0.91]} />
                      <group position={[0, 0.08, 0.03]} rotation={[2.45, 0, 0]}>
                        <group position={[0, 0.08, -0.05]} rotation={[2.21, 0.12, -1.35]} />
                        <group position={[0, 0.09, -0.04]} rotation={[2.34, -0.02, -1.25]} />
                        <group position={[0, 0.11, 0]} rotation={[-1.65, 0, 0]} />
                        <group position={[0, 0.08, -0.05]} rotation={[2.21, -0.12, 1.35]} />
                        <group position={[0, 0.09, -0.04]} rotation={[2.34, 0.02, 1.25]} />
                        <group position={[0.07, 0, 0]} rotation={[0.55, -0.04, 0.2]}>
                          <group position={[0, 0.05, 0]} rotation={[-0.68, -0.13, 0.37]} />
                        </group>
                        <group position={[-0.07, 0, 0]} rotation={[0.55, 0.04, -0.2]}>
                          <group position={[0, 0.05, 0]} rotation={[-0.68, 0.13, -0.37]} />
                        </group>
                        <group position={[0, 0.08, -0.02]} rotation={[2.42, 0, 0]}>
                          <group position={[0, 0.02, 0]} rotation={[-0.18, 0, 0]} />
                        </group>
                      </group>
                      <group position={[0, 0.06, 0.11]} rotation={[-1.62, 0, -Math.PI]}>
                        <group position={[0, 0.03, 0.01]} rotation={[1.24, 0, Math.PI]} />
                      </group>
                      <group position={[0.01, 0.13, 0.11]} rotation={[0.7, -1.25, -2.76]} />
                      <group position={[-0.01, 0.13, 0.11]} rotation={[0.7, 1.25, 2.76]} />
                    </group>
                  </group>
                </group>
              </group>
              <group position={[0.03, 0.16, 0.04]} rotation={[-1.6, 0, -1.26]}>
                <group position={[0, 0.15, 0]} rotation={[1.46, 1.33, -2.37]}>
                  <group position={[0, 0.29, 0]} rotation={[0.86, 0.2, 0.06]}>
                    <group position={[0, 0.27, 0]} rotation={[0, -0.01, -0.14]}>
                      <group position={[-0.01, 0.02, 0.02]} rotation={[2.51, 1.44, -2.33]}>
                        <group position={[0, 0.08, 0]} rotation={[0, -0.22, 0.03]}>
                          <group position={[0, 0.03, 0]} rotation={[0.25, -0.17, -0.06]} />
                        </group>
                        <group position={[-0.01, 0, 0.02]} rotation={[-1.3, 0.98, 1.49]}>
                          <group position={[0, 0.04, 0]} rotation={[0.16, -0.1, 0.1]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.02, 0.01]} rotation={[2.03, 1.46, -2.06]}>
                        <group position={[0, 0.08, 0]} rotation={[0.15, -0.25, 0.01]}>
                          <group position={[0, 0.03, 0]} rotation={[0.1, -0.28, -0.03]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.02, -0.01]} rotation={[1.11, 1.44, -1.26]}>
                        <group position={[0, 0.07, 0]} rotation={[0.05, -0.33, -0.06]}>
                          <group position={[0, 0.03, 0]} rotation={[0.21, -0.14, -0.01]} />
                        </group>
                      </group>
                      <group position={[0, 0.03, -0.02]} rotation={[0.75, 1.39, -1]}>
                        <group position={[0, 0.06, 0]} rotation={[0.03, -0.51, -0.13]}>
                          <group position={[0, 0.02, 0]} rotation={[0.24, -0.06, -0.06]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[-0.03, 0.16, 0.04]} rotation={[-1.6, 0, 1.26]}>
                <group position={[0, 0.15, 0]} rotation={[1.46, -1.33, 2.37]}>
                  <group position={[0, 0.29, 0]} rotation={[0.86, -0.2, -0.06]}>
                    <group position={[0, 0.27, 0]} rotation={[0, 0.01, 0.14]}>
                      <group position={[0.01, 0.02, 0.02]} rotation={[2.51, -1.44, 2.33]}>
                        <group position={[0, 0.08, 0]} rotation={[0, 0.22, -0.03]}>
                          <group position={[0, 0.03, 0]} rotation={[0.25, 0.17, 0.06]} />
                        </group>
                        <group position={[0.01, 0, 0.02]} rotation={[-1.3, -0.98, -1.49]}>
                          <group position={[0, 0.04, 0]} rotation={[0.16, 0.1, -0.1]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.02, 0.01]} rotation={[2.03, -1.46, 2.06]}>
                        <group position={[0, 0.08, 0]} rotation={[0.15, 0.25, -0.01]}>
                          <group position={[0, 0.03, 0]} rotation={[0.1, 0.28, 0.03]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.02, -0.01]} rotation={[1.11, -1.44, 1.26]}>
                        <group position={[0, 0.07, 0]} rotation={[0.05, 0.33, 0.06]}>
                          <group position={[0, 0.03, 0]} rotation={[0.21, 0.14, 0.01]} />
                        </group>
                      </group>
                      <group position={[0, 0.03, -0.02]} rotation={[0.75, -1.39, 1]}>
                        <group position={[0, 0.06, 0]} rotation={[0.03, 0.51, 0.13]}>
                          <group position={[0, 0.02, 0]} rotation={[0.24, 0.06, 0.06]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
        <group position={[0.14, -0.02, 0]} rotation={[2.92, 0.01, -0.07]}>
          <group position={[0, 0.37, 0]} rotation={[0.25, 0.01, -0.02]}>
            <group position={[0, 0.36, 0]} rotation={[-1.1, -0.08, 0.05]} />
          </group>
        </group>
        <group position={[-0.14, -0.02, 0]} rotation={[2.92, -0.01, 0.07]}>
          <group position={[0, 0.37, 0]} rotation={[0.25, -0.01, 0.02]}>
            <group position={[0, 0.36, 0]} rotation={[-1.1, 0.08, -0.05]} />
          </group>
        </group>
      </group>
      <primitive object={nodes.root} />
      <primitive object={nodes['MCH-eye_commonparent']} />
      <primitive object={nodes['MCH-lip_armBL001']} />
      <primitive object={nodes['MCH-lip_armBR001']} />
      <primitive object={nodes['MCH-lip_armTL001']} />
      <primitive object={nodes['MCH-lip_armTR001']} />
      <primitive object={nodes['MCH-torsoparent']} />
      <primitive object={nodes['MCH-hand_ikparentL']} />
      <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
      <primitive object={nodes['MCH-hand_ikparentR']} />
      <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
      <primitive object={nodes['MCH-foot_ikparentL']} />
      <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
      <primitive object={nodes['MCH-foot_ikparentR']} />
      <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
      <skinnedMesh
        geometry={nodes.man_endomorph_forblend003.geometry}
        material={materials.Eye_brown}
        skeleton={nodes.man_endomorph_forblend003.skeleton}
      />
      <skinnedMesh
        geometry={nodes.man_endomorph_forblend003_1.geometry}
        material={materials['bodyMaterial.002']}
        skeleton={nodes.man_endomorph_forblend003_1.skeleton}
      />
      {/* <skinnedMesh
        geometry={nodes.man_endomorph_forblend003_2.geometry}
        material={materials.eyes_glass}
        skeleton={nodes.man_endomorph_forblend003_2.skeleton}
      /> */}
      <skinnedMesh
        geometry={nodes.man_endomorph_forblend004.geometry}
        material={materials.old_asian_female}
        skeleton={nodes.man_endomorph_forblend004.skeleton}
      />
      <skinnedMesh
        geometry={nodes.man_endomorph_forblend004_1.geometry}
        material={materials.FABRIC_1_FRONT_164566}
        skeleton={nodes.man_endomorph_forblend004_1.skeleton}
      />
      <skinnedMesh
        geometry={nodes.man_endomorph_forblend.geometry}
        material={materials['teethMaterial.002']}
        skeleton={nodes.man_endomorph_forblend.skeleton}
      />
      <skinnedMesh
        geometry={nodes.man_endomorph_forblend_1.geometry}
        material={materials.tongue}
        skeleton={nodes.man_endomorph_forblend_1.skeleton}
      />
      <skinnedMesh
        geometry={nodes.man_endomorph_hairs.geometry}
        material={materials.short02}
        skeleton={nodes.man_endomorph_hairs.skeleton}
      />
      <group position={[-0.45, 0, -0.09]} rotation={[0, -Math.PI / 2, 0]}>
        <mesh geometry={nodes.Cube001.geometry} material={materials.couchLeg_black} />
        <mesh geometry={nodes.Cube001_1.geometry} material={materials.couchLeg_white} />
      </group>
    </group>
  )
}

// useGLTF.preload('/oldmale_endomorph_INSULT.glb')
