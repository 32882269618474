import { TextField } from '@material-ui/core'
import React, { ReactElement } from 'react'

export default function TextFieldsExample(): ReactElement {
  return (
    <div style={{ marginTop: 10 }}>
      <TextField
        type='mainDiagnosis'
        label='Заболевание'
        style={{ width: '70%' }}
        // fullWidth
        placeholder='Заболевание'
        variant='outlined'
      />
      <TextField
        type='mainDiagnosis'
        label='Код МКБ'
        style={{ width: '28%', marginLeft: '2%' }}
        // fullWidth
        placeholder='Код МКБ'
        variant='outlined'
      />
    </div>
  )
}
