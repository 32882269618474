import { createStyles, makeStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import React, { useState } from 'react'

const useStyles = makeStyles(theme =>
  createStyles({
    titleWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    titleContainer: {
      display: 'flex',
      cursor: 'pointer',
      height: 22,
      alignItems: 'center',
      borderRadius: ` 0 0 3px 3px`,
      minWidth: 230,
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 500,
      background: (p: Props) =>
        p.color === 'primary'
          ? theme.palette.primary.main
          : p.color === 'attention'
          ? theme.palette.error.main
          : p.color === 'info'
          ? theme.palette.primary.light
          : theme.palette.divider,
      color: (p: Props) =>
        p.color === 'info'
          ? theme.palette.text.primary
          : theme.palette.primary.contrastText,
      '& > div': { minWidth: 22 }
    },
    title: {
      textAlign: 'center',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      flex: 1
    },
    arrow: {
      height: 22,
      padding: 0,
      margin: 0
    },
    selectBlock: {
      position: 'absolute',
      zIndex: 99,
      width: 230,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      marginTop: 22,
      padding: theme.spacing(1),
      borderRadius: '0 0 4px 4px',
      '& > li': {
        listStyle: 'none',
        cursor: 'pointer'
      },
      '& :hover': {
        textDecoration: 'underline'
      }
    }
  })
)

interface Props {
  titles: { title: string; color?: string }[]
  color?: 'primary' | 'secondary' | 'info' | 'attention'
}

const SlotTitles = ({ titles }: Props) => {
  const [activeTitle, setActiveTitle] = useState(titles[0].title)
  const [color, setColor] = useState(titles[0].color)
  const [open, setOpen] = useState(false)

  const classes = useStyles({ titles, color } as Props)

  const handleOpenTogge = () => {
    setOpen(!open)
  }

  const handleChooseTitle = (item: { title: string; color?: string }) => {
    setActiveTitle(item.title)
    item.color ? setColor(item.color) : setColor('info')
    setOpen(false)
  }

  return (
    <React.Fragment>
      <div className={classes.titleWrapper}>
        <div className={classes.titleContainer} onClick={handleOpenTogge}>
          {titles && (
            <React.Fragment>
              <div></div>
              <div className={classes.title}>{activeTitle}</div>
              <div className={classes.arrow}>
                {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </div>
            </React.Fragment>
          )}
          {open && (
            <div className={classes.selectBlock}>
              {titles.map((item, i) => (
                <li key={i} onClick={() => handleChooseTitle(item)}>
                  {item.title}
                </li>
              ))}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default SlotTitles
