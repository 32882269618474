import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthAction } from '../store/auth';
import { RoutePath } from '../types';

type HookType = () => () => void;

const useLogout: HookType = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClickLogout = useCallback(() => {
    dispatch({ type: AuthAction.LOGOUT, data: null });
    history.replace(RoutePath.login);
  }, [history, dispatch]);

  return handleClickLogout;
};

export default useLogout;
