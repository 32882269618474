import {
  createStyles,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  SvgIcon,
  TextField
} from '@material-ui/core'
import HealingIcon from '@material-ui/icons/Healing'
import DeleteIcon from '@material-ui/icons/CancelOutlined'
import React, { ReactElement } from 'react'
import Slot from '../../common/Slots/Slot'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    }
  })
)

interface Props<T1, T2> {
  title?: string
  iconComponent?: typeof SvgIcon
  iconComponent2?: typeof SvgIcon
  titles?: { title: string; color?: string }[]
  treatment?: { title: string; text: string; item: T1 }[]
  treatment2?: { title: string; text: string; item: T2 }[]
  addLabel?: string
  addLabel2?: string
  onClickAdd?: () => void
  onClickEdit?: (item: T1) => void
  onClickAdd2?: () => void
  onClickEdit2?: (item: T2) => void
  deleteItem: (item) => void
}

export default function TreatmentSetSlot<T, E>({
  title,
  iconComponent,
  iconComponent2,
  treatment,
  treatment2,
  onClickAdd,
  onClickEdit,
  onClickAdd2,
  onClickEdit2,
  deleteItem,
  ...props
}: Props<T, E>): ReactElement {
  const classes = useStyles()
  const {t} = useTranslation()
  return (
    <Slot title={title} color='primary'>
      <List className={classes.root}>
        {treatment?.map((item, i) => (
          <Item
            key={i}
            iconComponent={iconComponent ? iconComponent : HealingIcon}
            onClickEdit={onClickEdit}
            item={item}
            deleteItem={deleteItem}
          />
        ))}
        {treatment2?.map((item, i) => (
          <Item
            key={i}
            iconComponent={iconComponent2 ? iconComponent2 : HealingIcon}
            onClickEdit={onClickEdit2}
            item={item}
            deleteItem={deleteItem}
          />
        ))}
      </List>

      <TextField
        size='small'
        type='setTreatment'
        label={props.addLabel ? props.addLabel : t("trenTreatment3")}
        fullWidth
        placeholder={props.addLabel ? props.addLabel : t("trenTreatment3")}
        value=''
        variant='outlined'
        onClick={onClickAdd}
      />

      {onClickAdd2 ? (
        <TextField
          style={{ marginTop: `10px` }}
          size='small'
          type='setTreatment'
          label={props.addLabel2 ? props.addLabel2 : t("trenTreatment3")}
          fullWidth
          placeholder={props.addLabel2 ? props.addLabel2 : t("trenTreatment3")}
          value=''
          variant='outlined'
          onClick={onClickAdd2}
        />
      ) : null}
    </Slot>
  )
}

type ItemProps<T> = {
  iconComponent: typeof SvgIcon
  onClickEdit: ((item: T) => void) | undefined
  item: { title: string; text: string; item: T }
  deleteItem: (item) => void
}

const Item = <T extends {}>(props: ItemProps<T>) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><ListItem
      button
      style={{ padding: `4px`, borderRadius: `2px` }}
      onClick={() => props.onClickEdit?.(props.item.item)}
    >
      <props.iconComponent
        style={{ margin: `0 18px 0 0`, fontSize: 23, verticalAlign: 'middle' }} />
      <ListItemText primary={props.item.title} secondary={props.item.text} />
    </ListItem>
      {/* <DeleteIcon style={{ cursor: 'pointer', marginLeft: 10 }} onClick={() => props.deleteItem(props.item)} /> */}
    </div>
  )
}
