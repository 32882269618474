import React, { useEffect, useState } from 'react'
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Theme,
  Pagination,
  ToastNotification,
  ComboBox,
  Loading,
  Button,
} from '@carbon/react';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { makeStyles } from "@material-ui/core/styles";
import { getErrorPlan } from '../../api/chatApi/chatApi';
import chatApi from "../../api/chatApi";
import jwt from 'jsonwebtoken'
import { TablePagination } from '@material-ui/core';
import CustomTablePagination from '../CustomPagination';

const useStyles = makeStyles((theme: any) => ({

  requiredWarning: {
    '& .cds--toast-notification__details': {
      display: 'flex !important',
      alignItems: 'center !important',
      height: 50
    },
  },
  championTablePageContainer: {
    background: '#F4F4F4',
    // height: '100vh',
    borderRadius: 4,
    '@media (max-width: 800px)': {
      width: '100%',
      overflowX: 'scroll',
    },
  },
  championTablePageContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '98%',
    margin: '0 auto',
    height: ''
  },
  championTableFilterBlock: {
    // width: 360,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 10px',
    background: '#FFFFFF',
    marginRight: '1%',
    width: '100%',
    marginBottom: '1%',
    borderRadius: 4,
    '@media (max-width: 800px)': {
      flexDirection: 'column'
    },
  },
  championSelectBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    '& .cds--dropdown': {
      minWidth: 200
    },
    '& .cds--list-box': {
      // width: 225
    },
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      marginBottom: 15
    },
  },
  championSelectBlockText: {
    whiteSpace: 'pre',
    fontSize: 14,
    fontFamily: 'Helvetica Neue',
    alignSelf: 'center',
    marginRight: 15,
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      marginBottom: 15,
      alignSelf: 'start'
    },
  },
  championSelectElement: {
    marginRight: '15px',
    display: 'flex',
    alignItems: 'baseline',
    '@media (max-width: 800px)': {
      flexDirection: 'column'
    },
  },
  championSelectBlockMultiSelect: {
    display: 'flex',
    marginBottom: 8,
    width: 300
  },
  championTable: {
    width: '100%',
    marginRight: '1%',
    overflowY: 'auto',
    background: '#FFFFFF',
    '& .cds--data-table-content': {
      maxHeight: '65vh',
    }
  },
  maxBallsBlock: {
    background: '#FFFFFF',
    padding: '20px 10px',
    display: 'flex',
    marginBottom: 16,
    borderRadius: 4,
  },
  myResultsBlock: {
    padding: '15px 10px',
    background: '#FFFFFF',
    height: 230,
  },
  maxBalls: {
    borderRight: '2px solid #DADADA',
    width: 180,
  },
  allParticipants: {
    marginLeft: 30,
  },
  titleBlocks: {
    fontFamily: 'Helvetica Neue',
    fontSize: 20,
    lineHeight: '23px',
    fontWeight: 400,
    marginBottom: '6%',
  },
  statisticsBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '24px',
    marginTop: '24px',
  },
  datePickerContainer: {
    display: 'flex',
    width: '100%',
    marginRight: 50,
    '& div.cds--date-picker': {
      display: 'flex',
      maxWidth: 225,
      width: '100%'
    },
    '@media (max-width: 800px)': {
      '& div.cds--date-picker': {
        flexDirection: 'column'
      },
    },
    '& .cds--date-picker__input': {
      maxWidth: 225,
      width: '100%',
      minWidth: 140
    },
    '& .cds--date-picker--range > .cds--date-picker-container': {
      width: '100%',
      marginRight: '15px',
    }
  },
  formationButtonBlock: {
    display: 'flex',
    justifyContent: 'start',
    textAlign: 'center',
    maxWidth: 225,
  },
  customBtn: {
    borderRadius: 4,
    height: 40,
    width: 175,
    maxWidth: 225,
    background: '#0f62fe',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    fontSize: 15,
    '&:hover': {
      background: '#0050e6'
    },
    '@media (max-width: 800px)': {
      width: 225,
    },
  },

  comboBoxContainer: {
    marginRight: 15,
    width: '100%',
    display: 'flex',
    // alignItems: 'baseline',
    '@media (max-width: 800px)': {
      marginBottom: 15,
      flexDirection: 'column',
      alignItems: 'baseline',
    },
  },
  comboboxText: {
    whiteSpace: 'pre',
    fontSize: 14,
    fontFamily: 'Helvetica Neue',
    alignSelf: 'center',
    marginRight: 5,
    '@media (max-width: 800px)': {
      alignSelf: 'start',
    },
  },
  customComboBox: {
    width: 325,
    maxWidth: 345,
    minWidth: 300,
    '@media (max-width: 800px)': {
      maxWidth: 225,
      minWidth: 200
    },
    '& .cds--combo-box': {
      borderRadius: 4
    }
  },
  emptyTableText: {
    whiteSpace: 'pre',
    fontSize: 20,
    fontFamily: 'Helvetica Neue',
    fontWeight: 500,
    '@media (max-width: 800px)': {
      whiteSpace: 'break-spaces',
      textAlign: 'center',
    },
  },

}))

interface ErrorTableInterface {
  countCompleteTask: number
  countPatientTask: number
  countFailedTask: number
  allUsersArray: unknown[]
}

interface getErrorPlanInterface {
  id: number,
  kind: string,
  name: string,
  points: number,
  subject: number,
}

interface UserInfo {
  active: boolean,
  id: number,
  login: string,
  name: string,
  photo: string,
  surname: string
}

interface OneUser {
  fio: string
  jhi_user_id: number
  login: string
  organization: []
  trainer: []
  user_id: number
}


export default function ErrorTable({
  allUsersArray,
}: ErrorTableInterface): JSX.Element {
  const style = useStyles();

  const [allErrorPlan, setAllErrorPlan] = useState<getErrorPlanInterface[]>([]);
  const [startTime, setStartTime] = useState<Date | null | string>(null);
  const [endTime, setEndTime] = useState<Date | null | string>(null)
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [minPoint, setMinPoint] = useState(0);
  const [worstTheme, setWorstTheme] = useState("");
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [body, setBody] = useState({
    start_date: '',
    stop_date: '',
    login: ''
  })
  const [errorState, setErrorState] = useState(false);
  const [requiredFieldsState, setRequiredFieldsState] = useState(false)
  const [screenWidth, setScreenWidth] = useState<number>();
  const [page, setPage] = useState(0);
  const [isEmptyResponce, setIsEmptyResponce] = useState(false)

  useEffect(() => {
    if (requiredFieldsState) {
      setTimeout(() => setRequiredFieldsState(false), 3000)
    }
  }, [requiredFieldsState])

  function windowScreenWidthHandler() {
    const windowSize = window.screen.width;
    setScreenWidth(windowSize);
  }

  useEffect(() => {
    windowScreenWidthHandler();
  }, [window.screen.width]);


  const windowSize = window.screen.width;

  const handleStartDateChange = (date: Date) => {
    setStartTime(date)
    const res = new Date(date).toLocaleDateString().split(/\.|\//).reverse().join('-')
    setBody({
      ...body,
      start_date: res,
    })
  };

  const handleEndDateChange = (date: Date) => {
    setEndTime(date)
    const res = new Date(date).toLocaleDateString().split(/\.|\//).reverse().join('-')
    setBody({
      ...body,
      stop_date: res
    })
  };


  function changeUserHandler(value) {
    setUserInfo(value);
    setBody({ ...body, login: value ? value.login : null });
  }

  const headerData = [
    {
      key: "name",
      header: "Раздел медицины",
    },
    {
      key: "subject",
      header: "Предмет",
    },
    {
      key: "kind",
      header: "Тема",
    },
    {
      key: "points",
      header: "Процент освоения",
    },
  ];

  useEffect(() => {
    //getErrorPlanHandler()
    if (allUsersArray) {
      getUserInfoHandler();
    }
  }, []);

  const roleDecode = jwt.decode(localStorage.getItem("role"));
  const role = roleDecode.auth.split(",");



  async function getErrorPlanHandler() {
    setAllErrorPlan([])
    try {
      if (!endTime || !startTime || !userInfo) {
        setRequiredFieldsState(true);
        return
      }
      const response = await getErrorPlan(body);
      let arr: any = [];
      let arrMin: number[] = [];
      let min: number;
      response.data.prror_plan.length === 0
        ? setErrorState(true)
        : setErrorState(false);
      response.data.prror_plan.map((res, index) => {
        arr.push({ ...res, id: index });
        arrMin.push(res.points);
      });
      min = Math.min.apply(null, arrMin);
      setMinPoint(min);
      arr.map((item) => {
        if (min === item.points) {
          setWorstTheme(item.kind);
        }
      });
      setAllErrorPlan(arr);
      if (arr.length === 0) {
        setIsEmptyResponce(true)
      }
    } catch (err) {
      setErrorState(true);
      setAllErrorPlan([])
      console.log("при получении списка произошла ошибка", err);
    } finally {
      setTimeout(() => setErrorState(false), 5000);
      setIsLoading(false);
    }
  }

  async function getUserInfoHandler() {
    try {
      const response = await chatApi.getUserInfo();
      setUserInfo(response.result);
      setBody({ ...body, login: response.result.login })
      if (
        role.includes("ROLE_ADMIN") ||
        role.includes("ROLE_ADMIN_TRAINER") ||
        role.includes("ROLE_ADMIN_ORG")
      ) {
        allUsersArray.filter((user) => user);
      } else {
        allUsersArray.filter((user) =>
          user.login.includes(response.result.login)
        );
      }
    } catch (err) {
      console.log("при получении данных о пользователе произошла ошибка", err);
    }
  }
  const [isLoading, setIsLoading] = useState(false);

  function Loader() {
    if (isLoading) {
      return <Loading className={"some-class"} withOverlay={false} />;
    } else {
      return null
    }
  }


  const currentData = currentPageSize === -1
    ? allErrorPlan
    : allErrorPlan.slice(firstRowIndex, firstRowIndex + currentPageSize);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setCurrentPageSize(newPageSize);
    setPage(0);
  };


  return (
    <div className={style.championTablePageContainer}>
      {errorState && (
        <div style={{ position: "fixed", right: 0, zIndex: 1000 }}>
          <ToastNotification
            kind="warning"
            hideCloseButton={false}
            lowContrast={false}
            iconDescription="Нет результатов"
            style={{
              paddingBottom: 12,
              borderRadius: 4,
              width: 300,
              backgroundColor: '#0050e6',
              color: 'white'
            }}
            timeout={4000}
            title="Нет результатов"
          />
        </div>
      )}
      {requiredFieldsState && (
        <div style={{ position: "fixed", right: 20, zIndex: 1000 }}>
          <ToastNotification
            kind="warning"
            hideCloseButton={true}
            lowContrast={false}
            className={style.requiredWarning}
            style={{
              paddingBottom: 7,
              borderRadius: 4,
              width: 300,
              backgroundColor: '#656565',
              color: 'white'
            }}
            timeout={4000}
            title=""
          ><span>Обязательные поля не заполнены</span></ToastNotification>
        </div>
      )}

      <div style={{ width: "98%", padding: "20px 0", margin: "0 auto" }}>
        <p style={{ fontFamily: "Helvetica Neue", fontWeight: 400, fontSize: 32 }}>
          Выводы о системных ошибках
        </p>
        {/*Выдача выводов о системных ошибках и связи этих ошибок с неусвоением определенных образовательных единиц;*/}
      </div>

      <div
        style={{
          background: "#FFFFFF",
          fontSize: "104%",
          width: "98%",
          padding: "15px 10px",
          margin: "auto auto 12px auto",
          borderRadius: 4,
        }}
      >
        <p>
          В этом разделе система показывает процент освоения определенных
          образовательный единиц выбранным пользователем.
        </p>
      </div>

      <div className={style.championTablePageContent}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={style.championTableFilterBlock}>
            <div className={style.championSelectBlock}>
              <div className={style.championSelectElement}>
                {allUsersArray && allUsersArray.length > 0 ? (
                  <div className={style.comboBoxContainer}>
                    <p className={style.comboboxText}>
                      Отображать результаты студента
                    </p>
                    <ComboBox
                      id={'error-table'}
                      className={style.customComboBox}
                      placeholder={"Выберите пользователя"}
                      items={allUsersArray ? allUsersArray : []}
                      itemToString={(item: OneUser) =>
                        item && item.login !== null && item.fio !== " "
                          ? item.fio
                          : item?.login
                      }
                      onChange={({ selectedItem }) =>
                        changeUserHandler(selectedItem)
                      }
                      selectedItem={userInfo}
                    />
                  </div>
                ) : (
                  <p style={{ whiteSpace: "pre", marginRight: "5%" }}>
                    {userInfo?.name} {userInfo?.surname}
                  </p>
                )}
                <div className={style.championSelectBlock}>
                  <p className={style.championSelectBlockText}>за период</p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="dd.MM.yyyy"
                      id="date-picker-inline"
                      placeholder="с"
                      value={startTime}
                      onChange={handleStartDateChange}
                    />
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="dd.MM.yyyy"
                      minDate={startTime}
                      id="date-picker-inline"
                      placeholder="по"
                      value={endTime}
                      onChange={handleEndDateChange}
                      animateYearScrolling={true}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={style.formationButtonBlock}>
                <button
                  onClick={getErrorPlanHandler}
                  className={style.customBtn}
                >
                  Применить фильтры
                </button>
              </div>
            </div>
          </div>

          <div className={style.maxBallsBlock}>
            <div className={style.maxBalls}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  color: "#9A9A9A",
                }}
              >
                Минимальный балл
              </p>
              <p>{minPoint && minPoint !== Infinity ? minPoint : 0}</p>
            </div>
            <div className={style.allParticipants}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  color: "#9A9A9A",
                }}
              >
                Худшая тема
              </p>
              <p>{worstTheme}</p>
            </div>
          </div>
          {isLoading
            ? <div
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
              <Loader />
            </div>
            : allErrorPlan && allErrorPlan.length !== 0 ? (
              <div className={style.championTable}>
                <div style={{ overflowX: "scroll", width: "100%" }}>
                  <Theme theme={"g10"}>
                    <DataTable
                      rows={currentData}
                      headers={headerData}
                      isSortable
                    >
                      {({ rows, headers, getHeaderProps, getTableProps }) => {
                        return (
                          <TableContainer
                            title=""
                          >
                            <Table
                              {...getTableProps()}
                              style={{ background: "#FFFFFF" }}
                            >
                              <TableHead style={{
                                backgroundColor: "#FFFFFF",
                                position: "sticky",
                                top: 0,
                                zIndex: 1000,
                                boxShadow: '0 0px 4px 0 rgba(0,0,0,.25)'
                              }}>
                                <TableRow>
                                  {headers.map((header) => (
                                    <TableHeader {...getHeaderProps({ header })}>
                                      {header.header}
                                    </TableHeader>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows.map((row) => {
                                  return (
                                    <TableRow key={row.id}>
                                      {row.cells.map((cell, index) => (
                                        <TableCell key={cell.id}>
                                          {index === 3
                                            ? `${cell.value} %`
                                            : cell.value}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        );
                      }}
                    </DataTable>
                  </Theme>
                </div>
              </div>
            ) : isEmptyResponce ? (
              <div style={{ margin: "25px auto 0 auto" }}>
                <p className={style.emptyTableText}>
                  Для выбранных параметров нет данных для отображения
                </p>
              </div>
            ) : <div style={{ margin: "25px auto 0 auto" }}>
              <p className={style.emptyTableText}>
                Чтобы увидеть информацию о системных ошибках заполните фильтры
              </p>
            </div>}
        </div>
        {!isLoading &&
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              paddingRight: 10,
              paddingBottom: 7,
              marginBottom: 10,
              backgroundColor: '#F4F4F4',
              borderRadius: 4
            }}>
            {allErrorPlan.length > 0 &&
              <CustomTablePagination
                count={allErrorPlan.length}
                page={page}
                rowsPerPage={currentPageSize}
                onPageChange={(newPage) => setPage(newPage)}
                onRowsPerPageChange={(newPageSize) => {
                  setCurrentPageSize(newPageSize);
                  setPage(0);
                }}
              />}
          </div>
        }
      </div>
    </div>
  );
}
