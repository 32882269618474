import {
  createStyles,
  List,
  ListItem,
  ListItemSecondaryAction,
  makeStyles,
  Typography
} from '@material-ui/core'
import React, { ReactElement } from 'react'
import { iTreatResItems } from '../../../pages/demo/demo-treatment/TreatmentResultPage'
import Slot from '../../common/Slots/Slot'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },
    item: {
      paddingRight: theme.spacing(3)
    },
    text: {
      color: theme.palette.text.secondary,
      '& > span': { color: theme.palette.error.main }
    },
    correct: { color: theme.palette.success.main },
    wrong: { color: theme.palette.error.main },
    points: { fontWeight: 400 }
  })
)

interface Props {
  title?: string
  treatmentResults: iTreatResItems
}

export default function TreatmentResultSlot({
  title,
  treatmentResults
}: Props): ReactElement {
  const classes = useStyles()
  return (
    <Slot title={title} color='primary'>
      <List className={classes.root}>
        {treatmentResults.map((item, i) => (
          <ListItem key={i}>
            <div className={classes.item}>
              <Typography variant='subtitle1'>{item.title}</Typography>
              <div className={classes.text}>{item.text}</div>
            </div>

            <ListItemSecondaryAction>
              <div className={item.success ? classes.correct : classes.wrong}>
                <Typography variant='h5' className={classes.points}>
                  {item.points}
                </Typography>
              </div>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Slot>
  )
}
