import { ChatHistory, CheckHistory, Task } from '../api/DomainTypes';
import { AsyncState } from '../common/Utils';
import { ChatMessage, CheckResult, MessageAuthor, MessageType } from '../types';
import { TaskResult_ResponseData, DiagnosisTypes_ResponseData, Diagnosis_ReqData, PrescribedMedication__ResponseData, TestingStage } from '../api/chatApi/chatApi';

export type AlertData = {
  message: string;
  type: 'error' | 'success';
};

export type CurrentTaskProceedState = {
  currentHistory?: AsyncState<ChatHistory>;
  currentTaskState?: AsyncState<Task>;
  currentCheckHistory?: AsyncState<CheckHistory>;
  currentTaskResult?: AsyncState<TaskResult_ResponseData>;
  diagnosisTypes?: AsyncState<Array<DiagnosisTypes_ResponseData>>;
  diagnosisForms?: AsyncState<Array<Diagnosis_ReqData>>;
  prescribedMedication?: AsyncState<Array<PrescribedMedication__ResponseData>>;
};

export enum ChatActionType {
  SET_PRESCRIBED_MEDICATION = 'SET_PRESCRIBED_MEDICATION',
  SET_TASK_RESULT = 'SET_TASK_RESULT',
  INCREMENT_TESTING_STATE = 'INCREMENT_TESTING_STATE',
  TO_HEALING_TESTING_STAGE = 'TO_HEALING_TESTING_STAGE',
  SET_CHAT_HISTORY = 'SET_CHAT_HISTORY',
  NEW_QUESTION_ANSWER_LOADED = 'NEW_QUESTION_ANSWER_LOADED',
  SET_CURRENT_TASK_STATE = 'SET_CURRENT_TASK_STATE',
  SET_CHECK_HISTORY = 'SET_CHECK_HISTORY',
  NEW_CHECK_ANSWER_LOADED = 'NEW_CHECK_ANSWER_LOADED',
  SET_DIAGNOSIS_TYPES = 'SET_DIAGNOSIS_TYPES',
  SET_DIAGNOSIS_FORMS = 'SET_DIAGNOSIS_FORMS',
  EXIT_CHAT = 'EXIT_CHAT',
}

interface NewQuestionAnswerLoadedAction {
  type: typeof ChatActionType.NEW_QUESTION_ANSWER_LOADED;
  data: {
    question: ChatMessage;
    answer: {
      message: string;
      author: typeof MessageAuthor.SYSTEM;
      type: typeof MessageType.message;
      attachment: [];
      penalty: number;
      points: number;
    };
  };
}

interface IncrementTestingState {
  type: typeof ChatActionType.INCREMENT_TESTING_STATE;
}

interface ToHealingTestingStage {
  type: typeof ChatActionType.TO_HEALING_TESTING_STAGE;
}

interface ExitChatAction {
  type: typeof ChatActionType.EXIT_CHAT;
}

interface SetDiagnosisTypesAction {
  type: typeof ChatActionType.SET_DIAGNOSIS_TYPES;
  data: AsyncState<Array<DiagnosisTypes_ResponseData>>;
}

interface SetTaskResultAction {
  type: typeof ChatActionType.SET_TASK_RESULT;
  data: AsyncState<TaskResult_ResponseData>;
}

interface NewCheckAnswerLoadedAction {
  type: typeof ChatActionType.NEW_CHECK_ANSWER_LOADED;
  data: CheckResult;
}

interface SetCurrentStateAction {
  type: typeof ChatActionType.SET_CURRENT_TASK_STATE;
  data: AsyncState<Task>;
}

interface SetChatHistoryAction {
  type: typeof ChatActionType.SET_CHAT_HISTORY;
  data: AsyncState<ChatHistory>;
}

interface SetCheckHistoryAction {
  type: typeof ChatActionType.SET_CHECK_HISTORY;
  data: AsyncState<CheckHistory>;
}

interface SetDiagnosisForms {
  type: typeof ChatActionType.SET_DIAGNOSIS_FORMS;
  data: AsyncState<Array<Diagnosis_ReqData>>;
}

interface SetPrescribedMedication {
  type: typeof ChatActionType.SET_PRESCRIBED_MEDICATION;
  data: AsyncState<Array<PrescribedMedication__ResponseData>>;
}

export type ChatActionTypes =
  | SetChatHistoryAction
  | NewQuestionAnswerLoadedAction
  | SetCurrentStateAction
  | SetCheckHistoryAction
  | NewCheckAnswerLoadedAction
  | SetTaskResultAction
  | SetDiagnosisTypesAction
  | ExitChatAction
  | IncrementTestingState
  | SetDiagnosisForms
  | SetPrescribedMedication
  | ToHealingTestingStage;

const initialState: CurrentTaskProceedState = {
  currentTaskState: undefined,
  currentHistory: undefined,
  currentCheckHistory: undefined,
  currentTaskResult: undefined,
  diagnosisTypes: undefined,
  diagnosisForms: undefined,
};

export function chatReducer(state: CurrentTaskProceedState = initialState, action: ChatActionTypes): CurrentTaskProceedState {
  switch (action.type) {
    case ChatActionType.TO_HEALING_TESTING_STAGE: {
      return {
        ...state,
        currentTaskState: {
          ...state.currentTaskState,
          value: { ...state.currentTaskState?.value, testingStage: TestingStage.HEALING },
        } as AsyncState<Task>,
      };
    }
    case ChatActionType.SET_DIAGNOSIS_FORMS: {
      return { ...state, diagnosisForms: action.data };
    }
    case ChatActionType.INCREMENT_TESTING_STATE: {
      if (state.currentTaskState?.value === undefined) return state;
      return {
        ...state,
        currentTaskState: {
          ...state.currentTaskState,
          value: {
            ...state.currentTaskState.value,
            testingStage: state.currentTaskState.value.testingStage + 1,
          },
        } as AsyncState<Task>,
      };
    }
    case ChatActionType.SET_DIAGNOSIS_TYPES: {
      return {
        ...state,
        diagnosisTypes: { ...state.diagnosisTypes, ...action.data } as AsyncState<Array<DiagnosisTypes_ResponseData>>,
      };
    }
    case ChatActionType.SET_TASK_RESULT: {
      return {
        ...state,
        currentTaskResult: { ...state.currentTaskResult, ...action.data } as AsyncState<TaskResult_ResponseData>,
      };
    }
    case ChatActionType.SET_CHAT_HISTORY: {
      return {
        ...state,
        currentHistory: { ...state.currentHistory, ...action.data } as AsyncState<ChatHistory>,
      };
    }
    case ChatActionType.NEW_QUESTION_ANSWER_LOADED: {
      const history = state?.currentHistory?.value;

      const points = (history?.points ? history?.points : 0) + action.data.answer.points;
      const penalty = (history?.penalty ? history?.penalty : 0) + action.data.answer.penalty;
      const oldMessages = history?.messages ? history?.messages : [];
      const messages = [...oldMessages, action.data.question, action.data.answer];
      return {
        ...state,
        currentHistory: {
          value: { ...history, points: points, penalty: penalty, messages: messages },
        } as AsyncState<ChatHistory>,
      };
    }
    case ChatActionType.SET_CURRENT_TASK_STATE: {
      return {
        ...state,
        currentTaskState: { ...action.data },
      };
    }
    case ChatActionType.SET_CHECK_HISTORY: {
      return {
        ...state,
        currentCheckHistory: { ...state.currentHistory, ...action.data } as AsyncState<CheckHistory>,
      };
    }
    case ChatActionType.NEW_CHECK_ANSWER_LOADED: {
      const checkHistory = state?.currentCheckHistory?.value;
      const points = (checkHistory?.points ? checkHistory?.points : 0) + action.data.points;
      const penalty = (checkHistory?.penalty ? checkHistory?.penalty : 0) + action.data.penalty;
      const oldMessages = checkHistory?.messages ? checkHistory?.messages : [];
      const messages = [...oldMessages, action.data];
      return {
        ...state,
        currentCheckHistory: {
          value: { ...checkHistory, points: points, penalty: penalty, messages: messages },
        } as AsyncState<CheckHistory>,
      };
    }
    case ChatActionType.SET_PRESCRIBED_MEDICATION: {
      return {
        ...state,
        prescribedMedication: { ...action.data },
      };
    }
    case ChatActionType.EXIT_CHAT: {
      return initialState;
    }
    default:
      return state;
  }
}
