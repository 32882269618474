import { useMemo, useState, useCallback, useEffect } from 'react';
import { conditionCodeToConditionId, getTypeofPatientStatus, InformationLevelId_DiagnosisPrimary, InformationLevelId_DiagnosisType, PatientStatusCode } from '../../api/chatApi/chatApi';
import { useDispatch } from 'react-redux';
import chatApi from '../../api/chatApi';
import { useAsyncFn } from 'react-use';
import { DiagnosisFieldData } from '../../components/PageBlocks/Diagnosis/CompetingDiagnosisField';
import { AsyncState } from 'react-use/lib/useAsyncFn';
import { ChatActionType } from '../../store/chat';


type HookType = (
  isProvisional: boolean
) => {
  condition: PatientStatusCode | undefined;
  diagnosis: Map<InformationLevelId_DiagnosisType, Array<DiagnosisFieldData>>;
  onChangeDiagnosis: (diagnosisTypeId: number, fields: Array<DiagnosisFieldData>) => void;
  onChageCondition: (condition: PatientStatusCode) => void;
  isFormValid: boolean;
  pushDiagnosisAsyncState: AsyncState<void>;
  pushDiagnosis: () => Promise<void>;
};

const useDiagnosisState: HookType = isProvisional => {
  const dispatch = useDispatch();
  const [condition, setCondition] = useState<PatientStatusCode | undefined>(undefined);
  const [resultCondition, setResultCondition] = useState('')
  const [diagnosis, setDiagnosis] = useState<Map<InformationLevelId_DiagnosisType, Array<DiagnosisFieldData>>>(new Map());

  const isFormValid = useMemo<boolean>(() => {
    const mainDiagnosis = diagnosis.get(InformationLevelId_DiagnosisPrimary);
    const diagnosisOk = !!mainDiagnosis && mainDiagnosis.length > 0;
    const conditionOk = resultCondition !== undefined;
    return diagnosisOk && conditionOk;
  }, [diagnosis, resultCondition]);


  const filteredMap = new Map();

  diagnosis.forEach((value, key) => {
    const filteredArray = value.filter((item, index, self) =>
      index === self.findIndex(t => t.id === item.id && t.diagnosis === item.diagnosis)
    );
    filteredMap.set(key, filteredArray);

  });



  const [pushDiagnosisAsyncState, pushDiagnosis] = useAsyncFn(
    async () => {
      const currentDiagnosis = diagnosis;
      const diagnosisItems = Array.from(currentDiagnosis).flatMap(([key, value]) => {
        if (value.length > 0) {
          const items = [];
          for (const it of value) {
            const name = it.diagnosis || it.name || "";
            if (name === "") {
              continue;
            }
            const diagnosisItem = {
              code: it.diagnosisCode ?? it.code,
              description: undefined,
              id: undefined,
              name: name,
              typeOfMedicalDictionaryId: undefined,
              typeOfInformationLevel: key,
            };

            items.push(diagnosisItem);
          }


          return items;
        } else {
          return [];
        }
      });

      await chatApi.pushDisgnosisResult({
        isFinished: true,
        isProvisional: isProvisional,
        typeOfPatientStatusId: conditionCodeToConditionId.get(condition as PatientStatusCode)!,
        diagnosis: diagnosisItems
      });

      dispatch({ type: ChatActionType.INCREMENT_TESTING_STATE });
    },
    [diagnosis, isFormValid, isProvisional]
  );



  const onChangeDiagnosis = useCallback(
    (diagnosisTypeId: number, newFields: Array<DiagnosisFieldData>) => {
      setDiagnosis((prevDiagnosis) => {
        const currentFields = prevDiagnosis.get(diagnosisTypeId as InformationLevelId_DiagnosisType) || [];
        // console.log("prevDiagnosis", prevDiagnosis);

        const updatedFields = [
          ...currentFields,
          ...newFields.filter(
            newField => !currentFields.some(
              currentField => currentField.name === newField.name
            )
          )
        ];
        const uniqueFields = updatedFields.reduce((unique, item) => {
          const isDuplicate = unique.some(uniqueItem => uniqueItem.name === item.name);
          if (!isDuplicate) {
            unique.push(item);
          }
          return unique;
        }, [] as DiagnosisFieldData[]);


        const updatedDiagnosis = new Map(prevDiagnosis);
        updatedDiagnosis.set(diagnosisTypeId as InformationLevelId_DiagnosisType, uniqueFields);

        return updatedDiagnosis;
      });
    },
    []
  );

  useEffect(() => {
    const setupStatus = async () => {
      try {
        const statusNumber = await getTypeofPatientStatus();
        setResultCondition(statusNumber.data.typeofpatientstatus_id)


      } catch (error) {
        console.log(error);
      }
    };

    setupStatus();
  }, [])

  return {
    condition: condition,
    diagnosis: diagnosis,
    onChangeDiagnosis: onChangeDiagnosis,
    onChageCondition: setCondition,
    isFormValid: isFormValid,
    pushDiagnosisAsyncState: pushDiagnosisAsyncState,
    pushDiagnosis: pushDiagnosis,
  };
};

export default useDiagnosisState;
