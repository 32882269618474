import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import React from 'react'
import { RoutePath } from '../types'

const PrivateRoute = (props: any) => {
  const jwtToken = useSelector<any>(state => state.auth.jwtToken)

  return jwtToken ? props.children : <Redirect to={RoutePath.login} />
}

export default PrivateRoute
