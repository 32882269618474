import React from 'react'
import { useTranslation } from 'react-i18next'

export default function DiagnosisTooltip() {
  const {t} = useTranslation()
  return (
    <div>
      {t("trenModalInspection1")} <span>{t("trenModalInspection2")}</span> {t("trenModalInspection3")}
    </div>
  )
}
