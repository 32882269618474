import React, { useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Women16(props) {
  const { nodes, materials, animations } = useGLTF('patient/wman16_22042021_anim.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)

  useEffect(() => {
    props.setAnim(animations)
    actions.Action_Polina.play()
  }, [actions, names, mixer])
 
  return (
    <group ref={ref} {...props} dispose={null}>
      <group name="Scene">
        <group name="metarig" scale={3.68}>
          <group name="spine" position={[0, 0.9, -0.05]} rotation={[0.25, 0, 0]}>
            <group name="spine001" position={[0, 0.14, 0]} rotation={[-0.13, 0, 0]}>
              <group name="spine002" position={[0, 0.12, 0]} rotation={[-0.16, 0, 0]}>
                <group name="spine003" position={[0, 0.15, 0]} rotation={[-0.29, 0, 0]}>
                  <group name="spine004" position={[0, 0.17, 0]} rotation={[0.89, 0, -0.03]}>
                    <group name="spine005" position={[0, 0.05, 0]} rotation={[-0.06, 0, 0.03]}>
                      <group name="spine006" position={[0, 0.05, 0]} rotation={[-0.51, 0, -0.01]}>
                        <group name="face" position={[0, 0.03, -0.02]} rotation={[0, 0, 0.01]}>
                          <group name="nose_1" position={[0, 0.08, 0.13]} rotation={[2.61, 0, 0]}>
                            <group name="nose001_1" position={[0, 0.02, 0]} rotation={[-0.02, 0, -0.01]}>
                              <group name="nose002_1" position={[0, 0.02, 0]} rotation={[0.63, 0.01, 0.02]}>
                                <group name="nose003_1" position={[0, 0.01, 0]} rotation={[0.83, 0, 0.01]}>
                                  <group name="nose004_1" position={[0, 0.01, 0]} rotation={[-0.72, 0.01, -0.01]} />
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="lipTL" position={[0, 0.01, 0.13]} rotation={[-1.54, -0.02, -1.3]}>
                            <group name="lipTL001_1" position={[0, 0.02, 0]} rotation={[-0.25, -0.27, 0.51]} />
                          </group>
                          <group name="lipBL" position={[0, 0, 0.13]} rotation={[-1.4, -0.19, -1.13]}>
                            <group name="lipBL001_1" position={[0, 0.02, 0]} rotation={[-0.02, 0.15, 0.08]} />
                          </group>
                          <group name="jaw_1" position={[0, -0.04, 0.06]} rotation={[1.54, 0, 0]}>
                            <group name="chin_1" position={[0, 0.05, 0]} rotation={[-1.09, 0, 0]}>
                              <group name="chin001_1" position={[0, 0.02, 0]} rotation={[-0.61, 0, 0]} />
                            </group>
                          </group>
                          <group name="earL_1" position={[0.07, 0.05, 0.02]} rotation={[0.01, -0.21, -0.5]}>
                            <group name="earL001" position={[0, 0.02, 0]} rotation={[-1.27, -0.47, 0.12]}>
                              <group name="earL002_1" position={[0, 0.02, 0]} rotation={[-1.89, -0.23, 0.05]}>
                                <group name="earL003_1" position={[0, 0.03, 0]} rotation={[-0.53, -0.64, 0.23]}>
                                  <group name="earL004_1" position={[0, 0.03, 0]} rotation={[-2.79, -0.05, 0.41]} />
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="earR_1" position={[-0.07, 0.05, 0.02]} rotation={[0.01, 0.21, 0.5]}>
                            <group name="earR001" position={[0, 0.02, 0]} rotation={[-1.27, 0.47, -0.12]}>
                              <group name="earR002_1" position={[0, 0.02, 0]} rotation={[-1.89, 0.23, -0.05]}>
                                <group name="earR003_1" position={[0, 0.03, 0]} rotation={[-0.53, 0.64, -0.23]}>
                                  <group name="earR004_1" position={[0, 0.03, 0]} rotation={[-2.79, 0.05, -0.41]} />
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="lipTR" position={[0, 0.01, 0.13]} rotation={[-1.54, 0.02, 1.3]}>
                            <group name="lipTR001_1" position={[0, 0.02, 0]} rotation={[-0.25, 0.27, -0.51]} />
                          </group>
                          <group name="lipBR" position={[0, 0, 0.13]} rotation={[-1.4, 0.19, 1.13]}>
                            <group name="lipBR001_1" position={[0, 0.02, 0]} rotation={[-0.02, -0.15, -0.08]} />
                          </group>
                          <group name="browBL_1" position={[0.06, 0.08, 0.11]} rotation={[-0.95, 0.89, 1.95]}>
                            <group name="browBL001_1" position={[0, 0.01, 0]} rotation={[-0.5, 0.74, 0.06]}>
                              <group name="browBL002_1" position={[0, 0.01, 0]} rotation={[0.04, 0.33, -0.6]}>
                                <group name="browBL003_1" position={[0, 0.02, 0]} rotation={[-0.5, 0.55, -0.33]} />
                              </group>
                            </group>
                          </group>
                          <group name="lidTL_1" position={[0.05, 0.07, 0.1]} rotation={[-0.65, 0.96, 1.43]}>
                            <group name="lidTL001_1" position={[0, 0.01, 0]} rotation={[-0.71, 0.79, 0.31]}>
                              <group name="lidTL002_1" position={[0, 0.01, 0]} rotation={[-0.26, 0.31, -0.68]}>
                                <group name="lidTL003_1" position={[0, 0.01, 0]} rotation={[-0.62, 0.49, 0.18]}>
                                  <group name="lidBL_1" position={[0, 0.01, 0]} rotation={[1.06, -0.27, -2.39]}>
                                    <group name="lidBL001_1" position={[0, 0.01, 0]} rotation={[0.25, 0.09, -0.37]}>
                                      <group name="lidBL002_1" position={[0, 0.01, 0]} rotation={[0.06, 0.23, 0.66]}>
                                        <group name="lidBL003_1" position={[0, 0.01, 0]} rotation={[0.57, 0.11, 0.15]} />
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="browBR_1" position={[-0.06, 0.08, 0.11]} rotation={[-0.95, -0.89, -1.95]}>
                            <group name="browBR001_1" position={[0, 0.01, 0]} rotation={[-0.5, -0.74, -0.06]}>
                              <group name="browBR002_1" position={[0, 0.01, 0]} rotation={[0.04, -0.33, 0.6]}>
                                <group name="browBR003_1" position={[0, 0.02, 0]} rotation={[-0.5, -0.55, 0.33]} />
                              </group>
                            </group>
                          </group>
                          <group name="lidTR_1" position={[-0.05, 0.07, 0.1]} rotation={[-0.65, -0.96, -1.43]}>
                            <group name="lidTR001_1" position={[0, 0.01, 0]} rotation={[-0.71, -0.79, -0.31]}>
                              <group name="lidTR002_1" position={[0, 0.01, 0]} rotation={[-0.26, -0.31, 0.68]}>
                                <group name="lidTR003_1" position={[0, 0.01, 0]} rotation={[-0.62, -0.49, -0.18]}>
                                  <group name="lidBR_1" position={[0, 0.01, 0]} rotation={[1.06, 0.27, 2.39]}>
                                    <group name="lidBR001_1" position={[0, 0.01, 0]} rotation={[0.25, -0.09, 0.37]}>
                                      <group name="lidBR002_1" position={[0, 0.01, 0]} rotation={[0.06, -0.23, -0.66]}>
                                        <group name="lidBR003_1" position={[0, 0.01, 0]} rotation={[0.57, -0.11, -0.15]} />
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="foreheadL" position={[0.01, 0.14, 0.11]} rotation={[-2.2, 1.47, -1.22]}>
                            <group name="foreheadL001" position={[-0.01, -0.01, 0.03]} rotation={[0.04, -0.26, -0.07]}>
                              <group name="foreheadL002" position={[-0.01, 0, 0.02]} rotation={[-0.11, -0.54, 0.16]}>
                                <group name="templeL" position={[-0.02, 0.01, 0.03]} rotation={[0.31, -0.76, 0.12]}>
                                  <group name="jawL_1" position={[0, 0.07, 0]} rotation={[-0.03, -0.27, 0.14]}>
                                    <group name="jawL001_1" position={[0, 0.06, 0]} rotation={[-0.22, -0.77, 0.83]}>
                                      <group name="chinL_1" position={[0, 0.07, 0]} rotation={[-1.96, -0.44, 1.27]}>
                                        <group name="cheekBL" position={[0, 0.04, 0]} rotation={[-1.2, -0.08, -0.58]}>
                                          <group name="cheekBL001_1" position={[0, 0.04, 0]} rotation={[0.67, 0.46, 0.19]}>
                                            <group name="browTL_1" position={[0, 0.06, 0]} rotation={[1.32, 0.56, 0.37]}>
                                              <group name="browTL001_1" position={[0, 0.04, 0]} rotation={[3.13, 1.12, -3.02]}>
                                                <group name="browTL002_1" position={[0, 0.03, 0]} rotation={[-0.51, 1.06, -0.66]}>
                                                  <group name="browTL003_1" position={[0, 0.03, 0]} rotation={[-0.25, 0.41, -0.57]} />
                                                </group>
                                              </group>
                                            </group>
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="foreheadR" position={[-0.01, 0.14, 0.11]} rotation={[-2.2, -1.47, 1.22]}>
                            <group name="foreheadR001" position={[0.01, -0.01, 0.03]} rotation={[0.04, 0.26, 0.07]}>
                              <group name="foreheadR002" position={[0.01, 0, 0.02]} rotation={[-0.11, 0.54, -0.16]}>
                                <group name="templeR" position={[0.02, 0.01, 0.03]} rotation={[0.31, 0.76, -0.12]}>
                                  <group name="jawR_1" position={[0, 0.07, 0]} rotation={[-0.03, 0.27, -0.14]}>
                                    <group name="jawR001_1" position={[0, 0.06, 0]} rotation={[-0.22, 0.77, -0.83]}>
                                      <group name="chinR_1" position={[0, 0.07, 0]} rotation={[-1.96, 0.44, -1.27]}>
                                        <group name="cheekBR" position={[0, 0.04, 0]} rotation={[-1.2, 0.08, 0.58]}>
                                          <group name="cheekBR001_1" position={[0, 0.04, 0]} rotation={[0.67, -0.46, -0.19]}>
                                            <group name="browTR_1" position={[0, 0.06, 0]} rotation={[1.32, -0.56, -0.37]}>
                                              <group name="browTR001_1" position={[0, 0.04, 0]} rotation={[3.13, -1.12, 3.02]}>
                                                <group name="browTR002_1" position={[0, 0.03, 0]} rotation={[-0.51, -1.06, 0.66]}>
                                                  <group name="browTR003_1" position={[0, 0.03, 0]} rotation={[-0.25, -0.41, 0.57]} />
                                                </group>
                                              </group>
                                            </group>
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="eyeL_1" position={[0.03, 0.07, 0.1]} rotation={[-Math.PI / 2, 0, -Math.PI]} />
                          <group name="eyeR_1" position={[-0.03, 0.07, 0.1]} rotation={[-Math.PI / 2, 0, -Math.PI]} />
                          <group name="cheekTL" position={[0.06, 0.07, 0.06]} rotation={[3.07, -1.01, 1.15]}>
                            <group name="cheekTL001_1" position={[0, 0.06, 0]} rotation={[3.07, -0.66, 1.85]}>
                              <group name="noseL_1" position={[0, 0.03, 0]} rotation={[2.71, 0.79, 1.13]}>
                                <group name="noseL001_1" position={[0, 0.03, 0]} rotation={[1.71, 0.51, 2.51]} />
                              </group>
                            </group>
                          </group>
                          <group name="cheekTR" position={[-0.06, 0.07, 0.06]} rotation={[3.07, 1.01, -1.15]}>
                            <group name="cheekTR001_1" position={[0, 0.06, 0]} rotation={[3.07, 0.66, -1.85]}>
                              <group name="noseR_1" position={[0, 0.03, 0]} rotation={[2.71, -0.79, -1.13]}>
                                <group name="noseR001_1" position={[0, 0.03, 0]} rotation={[1.71, -0.51, -2.51]} />
                              </group>
                            </group>
                          </group>
                          <group name="teethT_1" position={[0, 0.01, 0.12]} rotation={[-Math.PI / 2, 0, 0]} />
                          <group name="teethB_1" position={[0, 0, 0.11]} rotation={[-Math.PI / 2, 0, 0]} />
                          <group name="tongue_1" position={[0, -0.02, 0.12]} rotation={[-1.35, 0, 0]}>
                            <group name="tongue001_1" position={[0, 0.02, 0]} rotation={[-0.37, 0, 0]}>
                              <group name="tongue002_1" position={[0, 0.03, 0]} rotation={[0.26, 0, 0]} />
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="shoulderL_1" position={[0.02, 0.12, 0.06]} rotation={[-1.25, 0, -1.07]}>
                    <group name="upper_armL" position={[0.03, 0.17, -0.04]} rotation={[1.36, 1.1, -2.26]}>
                      <group name="forearmL" position={[0, 0.23, 0]} rotation={[0.72, 0.18, 0.02]}>
                        <group name="handL" position={[0, 0.26, 0]} rotation={[0.1, 0.01, -0.13]}>
                          <group name="palm01L" position={[-0.01, 0.04, 0.02]} rotation={[2.02, 1.44, -1.99]}>
                            <group name="f_index01L_1" position={[0, 0.06, 0]} rotation={[0.11, -0.22, 0.11]}>
                              <group name="f_index02L_1" position={[0, 0.03, 0]} rotation={[0.22, -0.17, 0.04]}>
                                <group name="f_index03L_1" position={[0, 0.02, 0]} rotation={[0.06, -0.24, -0.16]} />
                              </group>
                            </group>
                            <group name="thumb01L_1" position={[-0.01, -0.01, 0.02]} rotation={[-0.9, 1.03, 1.24]}>
                              <group name="thumb02L_1" position={[0, 0.04, 0]} rotation={[0.06, 0.07, 0.05]}>
                                <group name="thumb03L_1" position={[0, 0.03, 0]} rotation={[0.18, 0.06, 0.11]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm02L" position={[-0.01, 0.04, 0.01]} rotation={[1.62, 1.43, -1.75]}>
                            <group name="f_middle01L_1" position={[0, 0.07, 0]} rotation={[0.16, -0.25, 0.05]}>
                              <group name="f_middle02L_1" position={[0, 0.03, 0]} rotation={[0.13, -0.24, 0.01]}>
                                <group name="f_middle03L_1" position={[0, 0.03, 0]} rotation={[0.07, -0.14, -0.01]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm03L" position={[-0.01, 0.04, -0.01]} rotation={[1.09, 1.42, -1.34]}>
                            <group name="f_ring01L_1" position={[0, 0.06, 0]} rotation={[0.13, -0.35, -0.03]}>
                              <group name="f_ring02L_1" position={[0, 0.03, 0]} rotation={[0.13, -0.11, -0.02]}>
                                <group name="f_ring03L_1" position={[0, 0.03, 0]} rotation={[0.09, -0.3, 0.05]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm04L" position={[0, 0.03, -0.02]} rotation={[0.92, 1.37, -1.26]}>
                            <group name="f_pinky01L_1" position={[0, 0.06, 0]} rotation={[0.09, -0.53, -0.1]}>
                              <group name="f_pinky02L_1" position={[0, 0.02, 0]} rotation={[0.19, -0.06, -0.03]}>
                                <group name="f_pinky03L_1" position={[0, 0.02, 0]} rotation={[0.08, -0.07, -0.01]} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="shoulderR_1" position={[-0.02, 0.12, 0.06]} rotation={[-1.25, 0, 1.07]}>
                    <group name="upper_armR" position={[-0.03, 0.17, -0.04]} rotation={[1.36, -1.1, 2.26]}>
                      <group name="forearmR" position={[0, 0.23, 0]} rotation={[0.72, -0.18, -0.02]}>
                        <group name="handR" position={[0, 0.26, 0]} rotation={[0.1, -0.01, 0.13]}>
                          <group name="palm01R" position={[0.01, 0.04, 0.02]} rotation={[2.02, -1.44, 1.99]}>
                            <group name="f_index01R_1" position={[0, 0.06, 0]} rotation={[0.11, 0.22, -0.11]}>
                              <group name="f_index02R_1" position={[0, 0.03, 0]} rotation={[0.22, 0.17, -0.04]}>
                                <group name="f_index03R_1" position={[0, 0.02, 0]} rotation={[0.06, 0.24, 0.16]} />
                              </group>
                            </group>
                            <group name="thumb01R_1" position={[0.01, -0.01, 0.02]} rotation={[-0.9, -1.03, -1.24]}>
                              <group name="thumb02R_1" position={[0, 0.04, 0]} rotation={[0.06, -0.07, -0.05]}>
                                <group name="thumb03R_1" position={[0, 0.03, 0]} rotation={[0.18, -0.06, -0.11]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm02R" position={[0.01, 0.04, 0.01]} rotation={[1.62, -1.43, 1.75]}>
                            <group name="f_middle01R_1" position={[0, 0.07, 0]} rotation={[0.16, 0.25, -0.05]}>
                              <group name="f_middle02R_1" position={[0, 0.03, 0]} rotation={[0.13, 0.24, -0.01]}>
                                <group name="f_middle03R_1" position={[0, 0.03, 0]} rotation={[0.07, 0.14, 0.01]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm03R" position={[0.01, 0.04, -0.01]} rotation={[1.09, -1.42, 1.34]}>
                            <group name="f_ring01R_1" position={[0, 0.06, 0]} rotation={[0.13, 0.35, 0.03]}>
                              <group name="f_ring02R_1" position={[0, 0.03, 0]} rotation={[0.13, 0.11, 0.02]}>
                                <group name="f_ring03R_1" position={[0, 0.03, 0]} rotation={[0.09, 0.3, -0.05]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm04R" position={[0, 0.03, -0.02]} rotation={[0.92, -1.37, 1.26]}>
                            <group name="f_pinky01R_1" position={[0, 0.06, 0]} rotation={[0.09, 0.53, 0.1]}>
                              <group name="f_pinky02R_1" position={[0, 0.02, 0]} rotation={[0.19, 0.06, 0.03]}>
                                <group name="f_pinky03R_1" position={[0, 0.02, 0]} rotation={[0.08, 0.07, 0.01]} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="breastL_1" position={[0.12, -0.02, -0.01]} rotation={[-1.25, 0, Math.PI]} />
                  <group name="breastR_1" position={[-0.12, -0.02, -0.01]} rotation={[-1.25, 0, Math.PI]} />
                </group>
              </group>
            </group>
            <group name="pelvisL" rotation={[-1.95, -0.72, -2.08]} />
            <group name="pelvisR" rotation={[-1.95, 0.72, 2.08]} />
            <group name="thighL" position={[0.11, 0, 0]} rotation={[2.78, 0, -0.07]}>
              <group name="shinL" position={[0, 0.42, 0]} rotation={[0.23, 0.01, -0.05]}>
                <group name="footL" position={[0, 0.4, 0]} rotation={[-1.23, -0.12, 0.04]}>
                  <group name="toeL_1" position={[0, 0.15, 0]} rotation={[2.8, 0, -Math.PI]} />
                  <group name="heel02L" position={[-0.03, 0.02, 0.08]} rotation={[2.68, 0, -Math.PI / 2]} />
                </group>
              </group>
            </group>
            <group name="thighR" position={[-0.11, 0, 0]} rotation={[2.78, 0, 0.07]}>
              <group name="shinR" position={[0, 0.42, 0]} rotation={[0.23, -0.01, 0.05]}>
                <group name="footR" position={[0, 0.4, 0]} rotation={[-1.23, 0.12, -0.04]}>
                  <group name="toeR_1" position={[0, 0.15, 0]} rotation={[2.8, 0, Math.PI]} />
                  <group name="heel02R" position={[0.03, 0.02, 0.08]} rotation={[2.68, 0, Math.PI / 2]} />
                </group>
              </group>
            </group>
          </group>
        </group>
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <skinnedMesh name="hairs_lvl_2_2005_Плоскость006" geometry={nodes.hairs_lvl_2_2005_Плоскость006.geometry} material={materials.hairs_polina_1} skeleton={nodes.hairs_lvl_2_2005_Плоскость006.skeleton} />
          <skinnedMesh name="high-polyMesh" geometry={nodes['high-polyMesh'].geometry} material={materials['high-poly']} skeleton={nodes['high-polyMesh'].skeleton} />
          <skinnedMesh name="polina_body" geometry={nodes.polina_body.geometry} material={materials['female_generic.obj']} skeleton={nodes.polina_body.skeleton} />
          <skinnedMesh name="polina_cloth" geometry={nodes.polina_cloth.geometry} material={materials.polina_cloth} skeleton={nodes.polina_cloth.skeleton} />
          <skinnedMesh name="polina_eyelashes" geometry={nodes.polina_eyelashes.geometry} material={materials.eyelashes} skeleton={nodes.polina_eyelashes.skeleton} />
        </group>
        <group name="WGTS_rig">
          <group name="WGT-rig_breastL" position={[0.12, 1.28, -0.01]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.12} />
          <group name="WGT-rig_breastR" position={[-0.12, 1.28, -0.01]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.12} />
          <group name="WGT-rig_browBL" position={[0.06, 1.66, 0.08]} rotation={[Math.PI / 2, 0, -0.01]} scale={0.01} />
          <group name="WGT-rig_browBL001" position={[0.05, 1.67, 0.09]} rotation={[Math.PI / 2, 0, 0.03]} scale={0.01} />
          <group name="WGT-rig_browBL002" position={[0.04, 1.67, 0.1]} rotation={[Math.PI / 2, 0, -0.2]} scale={0.01} />
          <group name="WGT-rig_browBL003" position={[0.02, 1.67, 0.1]} rotation={[Math.PI / 2, 0, -0.05]} scale={0.01} />
          <group name="WGT-rig_browBL004" position={[0.01, 1.66, 0.09]} rotation={[Math.PI / 2, 0, -0.05]} scale={0.01} />
          <group name="WGT-rig_browBR" position={[-0.06, 1.66, 0.08]} rotation={[Math.PI / 2, 0, 0.01]} scale={0.01} />
          <group name="WGT-rig_browBR001" position={[-0.05, 1.67, 0.09]} rotation={[Math.PI / 2, 0, -0.03]} scale={0.01} />
          <group name="WGT-rig_browBR002" position={[-0.04, 1.67, 0.1]} rotation={[Math.PI / 2, 0, 0.2]} scale={0.01} />
          <group name="WGT-rig_browBR003" position={[-0.02, 1.67, 0.1]} rotation={[Math.PI / 2, 0, 0.05]} scale={0.01} />
          <group name="WGT-rig_browBR004" position={[-0.01, 1.66, 0.09]} rotation={[Math.PI / 2, 0, 0.05]} scale={0.01} />
          <group name="WGT-rig_browTL" position={[0.07, 1.64, 0.04]} rotation={[Math.PI / 2, 0, 0.78]} scale={0.01} />
          <group name="WGT-rig_browTL001" position={[0.06, 1.67, 0.07]} rotation={[Math.PI / 2, 0, -1.34]} scale={0.01} />
          <group name="WGT-rig_browTL002" position={[0.05, 1.68, 0.09]} rotation={[Math.PI / 2, 0, -0.15]} scale={0.01} />
          <group name="WGT-rig_browTL003" position={[0.02, 1.67, 0.1]} rotation={[Math.PI / 2, 0, 0.22]} scale={0.01} />
          <group name="WGT-rig_browTR" position={[-0.07, 1.64, 0.04]} rotation={[Math.PI / 2, 0, -0.78]} scale={0.01} />
          <group name="WGT-rig_browTR001" position={[-0.06, 1.67, 0.07]} rotation={[Math.PI / 2, 0, 1.34]} scale={0.01} />
          <group name="WGT-rig_browTR002" position={[-0.05, 1.68, 0.09]} rotation={[Math.PI / 2, 0, 0.15]} scale={0.01} />
          <group name="WGT-rig_browTR003" position={[-0.02, 1.67, 0.1]} rotation={[Math.PI / 2, 0, -0.22]} scale={0.01} />
          <group name="WGT-rig_cheekBL001" position={[0.05, 1.59, 0.06]} rotation={[Math.PI / 2, 0, -0.09]} scale={0.01} />
          <group name="WGT-rig_cheekBR001" position={[-0.05, 1.59, 0.06]} rotation={[Math.PI / 2, 0, 0.09]} scale={0.01} />
          <group name="WGT-rig_cheekTL001" position={[0.04, 1.62, 0.08]} rotation={[Math.PI / 2, 0, -0.04]} scale={0.01} />
          <group name="WGT-rig_cheekTR001" position={[-0.04, 1.62, 0.08]} rotation={[Math.PI / 2, 0, 0.04]} scale={0.01} />
          <group name="WGT-rig_chest" position={[0, 1.3, -0.01]} rotation={[1.25, 0, 0]} scale={0.19} />
          <group name="WGT-rig_chin" position={[0, 1.54, 0.09]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_chin001" position={[0, 1.56, 0.1]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_chin002" position={[0, 1.58, 0.09]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_chinL" position={[0.03, 1.55, 0.09]} rotation={[Math.PI / 2, 0, 0.08]} scale={0.01} />
          <group name="WGT-rig_chinR" position={[-0.03, 1.55, 0.09]} rotation={[Math.PI / 2, 0, -0.08]} scale={0.01} />
          <group name="WGT-rig_earL" position={[0.07, 1.63, 0]} rotation={[1.7, -0.49, 0.24]} scale={0.02} />
          <group name="WGT-rig_earL002" position={[0.09, 1.65, -0.02]} rotation={[Math.PI / 2, 0, -0.07]} scale={0.01} />
          <group name="WGT-rig_earL003" position={[0.09, 1.62, -0.02]} rotation={[Math.PI / 2, 0, 0.2]} scale={0.01} />
          <group name="WGT-rig_earL004" position={[0.08, 1.6, 0]} rotation={[Math.PI / 2, 0, -0.58]} scale={0.01} />
          <group name="WGT-rig_earR" position={[-0.07, 1.63, 0]} rotation={[1.7, 0.49, -0.24]} scale={0.02} />
          <group name="WGT-rig_earR002" position={[-0.09, 1.65, -0.02]} rotation={[Math.PI / 2, 0, 0.07]} scale={0.01} />
          <group name="WGT-rig_earR003" position={[-0.09, 1.62, -0.02]} rotation={[Math.PI / 2, 0, -0.2]} scale={0.01} />
          <group name="WGT-rig_earR004" position={[-0.08, 1.6, 0]} rotation={[Math.PI / 2, 0, 0.58]} scale={0.01} />
          <group name="WGT-rig_eyeL" position={[0.03, 1.65, 0.27]} rotation={[Math.PI / 2, 0, 0]} scale={0.02} />
          <group name="WGT-rig_eyeR" position={[-0.03, 1.65, 0.27]} rotation={[Math.PI / 2, 0, 0]} scale={0.02} />
          <group name="WGT-rig_eyes" position={[0, 1.65, 0.27]} rotation={[Math.PI / 2, 0, 0]} scale={0.02} />
          <group name="WGT-rig_f_index01L" position={[0.5, 1.05, 0.24]} rotation={[-2.49, -0.14, 0.95]} scale={0.03} />
          <group name="WGT-rig_f_index01L001" position={[0.5, 1, 0.29]} rotation={[0.91, -0.11, 0.77]} scale={0.01} />
          <group name="WGT-rig_f_index01R" position={[-0.5, 1.05, 0.24]} rotation={[-2.49, 0.14, -0.95]} scale={0.03} />
          <group name="WGT-rig_f_index01R001" position={[-0.5, 1, 0.29]} rotation={[0.91, 0.11, -0.77]} scale={0.01} />
          <group name="WGT-rig_f_index01_masterL" position={[0.5, 1.05, 0.24]} rotation={[-2.41, -0.03, 1.28]} scale={0.12} />
          <group name="WGT-rig_f_index01_masterR" position={[-0.5, 1.05, 0.24]} rotation={[-2.41, 0.03, -1.28]} scale={0.12} />
          <group name="WGT-rig_f_index02L" position={[0.5, 1.03, 0.26]} rotation={[-2.42, 0.07, 0.96]} scale={0.02} />
          <group name="WGT-rig_f_index02R" position={[-0.5, 1.03, 0.26]} rotation={[-2.42, -0.07, -0.96]} scale={0.02} />
          <group name="WGT-rig_f_index03L" position={[0.5, 1.02, 0.27]} rotation={[-2.23, 0.11, 0.95]} scale={0.02} />
          <group name="WGT-rig_f_index03R" position={[-0.5, 1.02, 0.27]} rotation={[-2.23, -0.11, -0.95]} scale={0.02} />
          <group name="WGT-rig_f_middle01L" position={[0.52, 1.03, 0.22]} rotation={[-2.25, -0.2, 1.3]} scale={0.03} />
          <group name="WGT-rig_f_middle01L001" position={[0.53, 0.97, 0.27]} rotation={[0.94, 0.02, 1.33]} scale={0.01} />
          <group name="WGT-rig_f_middle01R" position={[-0.52, 1.03, 0.22]} rotation={[-2.25, 0.2, -1.3]} scale={0.03} />
          <group name="WGT-rig_f_middle01R001" position={[-0.53, 0.97, 0.27]} rotation={[0.94, -0.02, -1.33]} scale={0.01} />
          <group name="WGT-rig_f_middle01_masterL" position={[0.52, 1.03, 0.22]} rotation={[-2.23, -0.14, 1.45]} scale={0.14} />
          <group name="WGT-rig_f_middle01_masterR" position={[-0.52, 1.03, 0.22]} rotation={[-2.23, 0.14, -1.45]} scale={0.14} />
          <group name="WGT-rig_f_middle02L" position={[0.52, 1.01, 0.24]} rotation={[-2.22, -0.08, 1.31]} scale={0.03} />
          <group name="WGT-rig_f_middle02R" position={[-0.52, 1.01, 0.24]} rotation={[-2.22, 0.08, -1.31]} scale={0.03} />
          <group name="WGT-rig_f_middle03L" position={[0.53, 0.99, 0.26]} rotation={[-2.2, -0.02, 1.31]} scale={0.02} />
          <group name="WGT-rig_f_middle03R" position={[-0.53, 0.99, 0.26]} rotation={[-2.2, 0.02, -1.31]} scale={0.02} />
          <group name="WGT-rig_f_pinky01L" position={[0.52, 1.02, 0.18]} rotation={[-1.95, -0.31, 1.57]} scale={0.02} />
          <group name="WGT-rig_f_pinky01L001" position={[0.53, 0.97, 0.2]} rotation={[1.19, 0.03, 1.59]} scale={0.01} />
          <group name="WGT-rig_f_pinky01R" position={[-0.52, 1.02, 0.18]} rotation={[-1.95, 0.31, -1.57]} scale={0.02} />
          <group name="WGT-rig_f_pinky01R001" position={[-0.53, 0.97, 0.2]} rotation={[1.19, -0.03, -1.59]} scale={0.01} />
          <group name="WGT-rig_f_pinky01_masterL" position={[0.52, 1.02, 0.18]} rotation={[-1.95, -0.19, 1.75]} scale={0.09} />
          <group name="WGT-rig_f_pinky01_masterR" position={[-0.52, 1.02, 0.18]} rotation={[-1.95, 0.19, -1.75]} scale={0.09} />
          <group name="WGT-rig_f_pinky02L" position={[0.53, 1, 0.18]} rotation={[-1.95, -0.11, 1.57]} scale={0.02} />
          <group name="WGT-rig_f_pinky02R" position={[-0.53, 1, 0.18]} rotation={[-1.95, 0.11, -1.57]} scale={0.02} />
          <group name="WGT-rig_f_pinky03L" position={[0.53, 0.99, 0.19]} rotation={[-1.95, -0.03, 1.57]} scale={0.02} />
          <group name="WGT-rig_f_pinky03R" position={[-0.53, 0.99, 0.19]} rotation={[-1.95, 0.03, -1.57]} scale={0.02} />
          <group name="WGT-rig_f_ring01L" position={[0.52, 1.03, 0.2]} rotation={[-2.1, -0.29, 1.53]} scale={0.03} />
          <group name="WGT-rig_f_ring01L001" position={[0.54, 0.96, 0.24]} rotation={[1.02, 0.09, 1.64]} scale={0.01} />
          <group name="WGT-rig_f_ring01R" position={[-0.52, 1.03, 0.2]} rotation={[-2.1, 0.29, -1.53]} scale={0.03} />
          <group name="WGT-rig_f_ring01R001" position={[-0.54, 0.96, 0.24]} rotation={[1.02, -0.09, -1.64]} scale={0.01} />
          <group name="WGT-rig_f_ring01_masterL" position={[0.52, 1.03, 0.2]} rotation={[-2.1, -0.21, 1.67]} scale={0.13} />
          <group name="WGT-rig_f_ring01_masterR" position={[-0.52, 1.03, 0.2]} rotation={[-2.1, 0.21, -1.67]} scale={0.13} />
          <group name="WGT-rig_f_ring02L" position={[0.53, 1, 0.21]} rotation={[-2.07, -0.16, 1.54]} scale={0.03} />
          <group name="WGT-rig_f_ring02R" position={[-0.53, 1, 0.21]} rotation={[-2.07, 0.16, -1.54]} scale={0.03} />
          <group name="WGT-rig_f_ring03L" position={[0.54, 0.98, 0.22]} rotation={[-2.12, -0.09, 1.53]} scale={0.03} />
          <group name="WGT-rig_f_ring03R" position={[-0.54, 0.98, 0.22]} rotation={[-2.12, 0.09, -1.53]} scale={0.03} />
          <group name="WGT-rig_foot_fkL" position={[0.19, 0.09, -0.06]} rotation={[-2.68, 0, 0]} scale={0.15} />
          <group name="WGT-rig_foot_fkR" position={[-0.19, 0.09, -0.06]} rotation={[-2.68, 0, 0]} scale={0.15} />
          <group name="WGT-rig_foot_heel_ikL" position={[0.19, 0.09, -0.06]} scale={0.08} />
          <group name="WGT-rig_foot_heel_ikR" position={[-0.19, 0.09, -0.06]} scale={0.08} />
          <group name="WGT-rig_foot_ikL" position={[0.19, 0.09, -0.06]} scale={0.15} />
          <group name="WGT-rig_foot_ikR" position={[-0.19, 0.09, -0.06]} scale={0.15} />
          <group name="WGT-rig_foot_spin_ikL" position={[0.19, 0.02, 0.08]} scale={0.08} />
          <group name="WGT-rig_foot_spin_ikR" position={[-0.19, 0.02, 0.08]} scale={0.08} />
          <group name="WGT-rig_foot_tweakL" position={[0.19, 0.09, -0.06]} rotation={[-2.68, 0, 0]} scale={0.04} />
          <group name="WGT-rig_foot_tweakR" position={[-0.19, 0.09, -0.06]} rotation={[-2.68, 0, 0]} scale={0.04} />
          <group name="WGT-rig_forearm_fkL" position={[0.34, 1.22, -0.03]} rotation={[-2.51, -0.55, 2.69]} scale={0.26} />
          <group name="WGT-rig_forearm_fkR" position={[-0.34, 1.22, -0.03]} rotation={[-2.51, 0.55, -2.69]} scale={0.26} />
          <group name="WGT-rig_forearm_tweakL" position={[0.34, 1.22, -0.03]} rotation={[-2.51, -0.55, 2.69]} scale={0.06} />
          <group name="WGT-rig_forearm_tweakL001" position={[0.41, 1.16, 0.06]} rotation={[-2.51, -0.55, 2.69]} scale={0.06} />
          <group name="WGT-rig_forearm_tweakR" position={[-0.34, 1.22, -0.03]} rotation={[-2.51, 0.55, -2.69]} scale={0.06} />
          <group name="WGT-rig_forearm_tweakR001" position={[-0.41, 1.16, 0.06]} rotation={[-2.51, 0.55, -2.69]} scale={0.06} />
          <group name="WGT-rig_hand_fkL" position={[0.47, 1.09, 0.14]} rotation={[-2.51, -0.39, 2.68]} scale={0.07} />
          <group name="WGT-rig_hand_fkR" position={[-0.47, 1.09, 0.14]} rotation={[-2.51, 0.39, -2.68]} scale={0.07} />
          <group name="WGT-rig_hand_ikL" position={[0.47, 1.09, 0.14]} rotation={[-2.51, -0.39, 2.68]} scale={0.07} />
          <group name="WGT-rig_hand_ikR" position={[-0.47, 1.09, 0.14]} rotation={[-2.51, 0.39, -2.68]} scale={0.07} />
          <group name="WGT-rig_hand_tweakL" position={[0.47, 1.09, 0.14]} rotation={[-2.51, -0.39, 2.68]} scale={0.02} />
          <group name="WGT-rig_hand_tweakR" position={[-0.47, 1.09, 0.14]} rotation={[-2.51, 0.39, -2.68]} scale={0.02} />
          <group name="WGT-rig_head" position={[0, 1.55, -0.01]} rotation={[1.57, -0.01, 0]} scale={0.22} />
          <group name="WGT-rig_hips" position={[0, 0.9, -0.05]} rotation={[1.82, 0, 0]} scale={0.15} />
          <group name="WGT-rig_jaw" position={[0, 1.54, 0.03]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_jawL" position={[0.06, 1.63, 0.03]} rotation={[Math.PI / 2, 0, 0.17]} scale={0.01} />
          <group name="WGT-rig_jawL001" position={[0.05, 1.58, 0.03]} rotation={[Math.PI / 2, 0, -0.09]} scale={0.01} />
          <group name="WGT-rig_jawR" position={[-0.06, 1.63, 0.03]} rotation={[Math.PI / 2, 0, -0.17]} scale={0.01} />
          <group name="WGT-rig_jawR001" position={[-0.05, 1.58, 0.03]} rotation={[Math.PI / 2, 0, 0.09]} scale={0.01} />
          <group name="WGT-rig_jaw_master" position={[0, 1.63, 0.03]} rotation={[-2.16, 0, 0]} scale={0.11} />
          <group name="WGT-rig_lidBL" position={[0.02, 1.65, 0.08]} rotation={[Math.PI / 2, 0, -0.21]} scale={0.01} />
          <group name="WGT-rig_lidBL001" position={[0.02, 1.64, 0.08]} rotation={[Math.PI / 2, 0, -0.06]} scale={0.01} />
          <group name="WGT-rig_lidBL002" position={[0.03, 1.64, 0.09]} rotation={[Math.PI / 2, 0, -0.11]} scale={0.01} />
          <group name="WGT-rig_lidBL003" position={[0.04, 1.64, 0.08]} rotation={[Math.PI / 2, 0, 0.21]} scale={0.01} />
          <group name="WGT-rig_lidBR" position={[-0.02, 1.65, 0.08]} rotation={[Math.PI / 2, 0, 0.21]} scale={0.01} />
          <group name="WGT-rig_lidBR001" position={[-0.02, 1.64, 0.08]} rotation={[Math.PI / 2, 0, 0.06]} scale={0.01} />
          <group name="WGT-rig_lidBR002" position={[-0.03, 1.64, 0.09]} rotation={[Math.PI / 2, 0, 0.11]} scale={0.01} />
          <group name="WGT-rig_lidBR003" position={[-0.04, 1.64, 0.08]} rotation={[Math.PI / 2, 0, -0.21]} scale={0.01} />
          <group name="WGT-rig_lidTL" position={[0.05, 1.65, 0.08]} rotation={[Math.PI / 2, 0, -0.22]} scale={0.01} />
          <group name="WGT-rig_lidTL001" position={[0.04, 1.65, 0.08]} rotation={[Math.PI / 2, 0, -0.22]} scale={0.01} />
          <group name="WGT-rig_lidTL002" position={[0.03, 1.66, 0.09]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_lidTL003" position={[0.02, 1.65, 0.08]} rotation={[Math.PI / 2, 0, -0.11]} scale={0.01} />
          <group name="WGT-rig_lidTR" position={[-0.05, 1.65, 0.08]} rotation={[Math.PI / 2, 0, 0.22]} scale={0.01} />
          <group name="WGT-rig_lidTR001" position={[-0.04, 1.65, 0.08]} rotation={[Math.PI / 2, 0, 0.22]} scale={0.01} />
          <group name="WGT-rig_lidTR002" position={[-0.03, 1.66, 0.09]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_lidTR003" position={[-0.02, 1.65, 0.08]} rotation={[Math.PI / 2, 0, 0.11]} scale={0.01} />
          <group name="WGT-rig_lipB" position={[0, 1.57, 0.1]} rotation={[Math.PI / 2, 0, 0.08]} scale={0.01} />
          <group name="WGT-rig_lipBL001" position={[0.02, 1.58, 0.09]} rotation={[Math.PI / 2, 0, -0.07]} scale={0.01} />
          <group name="WGT-rig_lipBR001" position={[-0.02, 1.58, 0.09]} rotation={[Math.PI / 2, 0, 0.07]} scale={0.01} />
          <group name="WGT-rig_lipT" position={[0, 1.59, 0.1]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_lipTL001" position={[0.02, 1.59, 0.1]} rotation={[Math.PI / 2, 0, -0.02]} scale={0.01} />
          <group name="WGT-rig_lipTR001" position={[-0.02, 1.59, 0.1]} rotation={[Math.PI / 2, 0, 0.02]} scale={0.01} />
          <group name="WGT-rig_lipsL" position={[0.03, 1.58, 0.09]} rotation={[Math.PI / 2, 0, -0.02]} scale={0.01} />
          <group name="WGT-rig_lipsR" position={[-0.03, 1.58, 0.09]} rotation={[Math.PI / 2, 0, 0.02]} scale={0.01} />
          <group name="WGT-rig_master_eyeL" position={[0.03, 1.65, 0.07]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.02} />
          <group name="WGT-rig_master_eyeR" position={[-0.03, 1.65, 0.07]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.02} />
          <group name="WGT-rig_neck" position={[0, 1.47, -0.06]} rotation={[2.12, -0.01, -0.03]} scale={0.1} />
          <group name="WGT-rig_nose" position={[0, 1.65, 0.1]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_nose001" position={[0, 1.64, 0.11]} rotation={[Math.PI / 2, 0, 0.02]} scale={0.01} />
          <group name="WGT-rig_nose002" position={[0, 1.62, 0.12]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_nose003" position={[0, 1.61, 0.12]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_nose004" position={[0, 1.6, 0.11]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_nose005" position={[0, 1.59, 0.1]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_noseL" position={[0.01, 1.64, 0.09]} rotation={[Math.PI / 2, 0, 0.18]} scale={0.01} />
          <group name="WGT-rig_noseL001" position={[0.02, 1.61, 0.09]} rotation={[Math.PI / 2, 0, -0.49]} scale={0.01} />
          <group name="WGT-rig_noseR" position={[-0.01, 1.64, 0.09]} rotation={[Math.PI / 2, 0, -0.18]} scale={0.01} />
          <group name="WGT-rig_noseR001" position={[-0.02, 1.61, 0.09]} rotation={[Math.PI / 2, 0, 0.49]} scale={0.01} />
          <group name="WGT-rig_nose_master" position={[0, 1.6, 0.11]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.02} />
          <group name="WGT-rig_palmL" position={[0.5, 1.07, 0.15]} rotation={[-2.09, -0.41, 1.15]} scale={0.06} />
          <group name="WGT-rig_palmR" position={[-0.5, 1.07, 0.15]} rotation={[-2.09, 0.41, -1.15]} scale={0.06} />
          <group name="WGT-rig_root" scale={0.89} />
          <group name="WGT-rig_shin_fkL" position={[0.14, 0.49, -0.01]} rotation={[-1.45, -0.13, -0.21]} scale={0.4} />
          <group name="WGT-rig_shin_fkR" position={[-0.14, 0.49, -0.01]} rotation={[-1.45, 0.13, 0.21]} scale={0.4} />
          <group name="WGT-rig_shin_tweakL" position={[0.14, 0.49, -0.01]} rotation={[-1.45, -0.13, -0.21]} scale={0.1} />
          <group name="WGT-rig_shin_tweakL001" position={[0.17, 0.29, -0.03]} rotation={[-1.45, -0.13, -0.21]} scale={0.1} />
          <group name="WGT-rig_shin_tweakR" position={[-0.14, 0.49, -0.01]} rotation={[-1.45, 0.13, 0.21]} scale={0.1} />
          <group name="WGT-rig_shin_tweakR001" position={[-0.17, 0.29, -0.03]} rotation={[-1.45, 0.13, 0.21]} scale={0.1} />
          <group name="WGT-rig_shoulderL" position={[0.02, 1.44, 0.01]} rotation={[0, -1.07, 0]} scale={0.17} />
          <group name="WGT-rig_shoulderR" position={[-0.02, 1.44, 0.01]} rotation={[0, 1.07, 0]} scale={0.17} />
          <group name="WGT-rig_spine_fk" position={[0, 1.03, -0.02]} rotation={[1.82, 0, 0]} scale={0.14} />
          <group name="WGT-rig_spine_fk001" position={[0, 1.15, 0]} rotation={[1.69, 0, 0]} scale={0.12} />
          <group name="WGT-rig_spine_fk002" position={[0, 1.15, 0]} rotation={[1.54, 0, 0]} scale={0.15} />
          <group name="WGT-rig_spine_fk003" position={[0, 1.3, -0.01]} rotation={[1.25, 0, 0]} scale={0.17} />
          <group name="WGT-rig_teethB" position={[0, 1.58, 0.08]} scale={0.04} />
          <group name="WGT-rig_teethT" position={[0, 1.58, 0.09]} scale={0.04} />
          <group name="WGT-rig_thigh_fkL" position={[0.11, 0.9, -0.05]} rotation={[-1.68, -0.07, -0.24]} scale={0.42} />
          <group name="WGT-rig_thigh_fkR" position={[-0.11, 0.9, -0.05]} rotation={[-1.68, 0.07, 0.24]} scale={0.42} />
          <group name="WGT-rig_thigh_ikL" position={[0.11, 0.9, -0.05]} rotation={[-1.68, -0.07, -0.24]} scale={0.42} />
          <group name="WGT-rig_thigh_ikR" position={[-0.11, 0.9, -0.05]} rotation={[-1.68, 0.07, 0.24]} scale={0.42} />
          <group name="WGT-rig_thigh_ik_targetL" position={[-0.04, 0.47, 0.79]} rotation={[0.03, -0.22, 0]} scale={0.1} />
          <group name="WGT-rig_thigh_ik_targetR" position={[0.04, 0.47, 0.79]} rotation={[0.03, 0.22, 0]} scale={0.1} />
          <group name="WGT-rig_thigh_parentL" position={[0.11, 0.9, -0.05]} rotation={[-1.68, -0.07, -0.08]} scale={0.1} />
          <group name="WGT-rig_thigh_parentR" position={[-0.11, 0.9, -0.05]} rotation={[-1.68, 0.07, 0.08]} scale={0.1} />
          <group name="WGT-rig_thigh_tweakL" position={[0.11, 0.9, -0.05]} rotation={[-1.68, -0.07, -0.24]} scale={0.1} />
          <group name="WGT-rig_thigh_tweakL001" position={[0.13, 0.69, -0.03]} rotation={[-1.68, -0.07, -0.24]} scale={0.1} />
          <group name="WGT-rig_thigh_tweakR" position={[-0.11, 0.9, -0.05]} rotation={[-1.68, 0.07, 0.24]} scale={0.1} />
          <group name="WGT-rig_thigh_tweakR001" position={[-0.13, 0.69, -0.03]} rotation={[-1.68, 0.07, 0.24]} scale={0.1} />
          <group name="WGT-rig_thumb01L" position={[0.46, 1.09, 0.18]} rotation={[-2.67, 0.26, 0.12]} scale={0.04} />
          <group name="WGT-rig_thumb01L001" position={[0.43, 1.04, 0.26]} rotation={[0.79, -0.28, -0.5]} scale={0.01} />
          <group name="WGT-rig_thumb01R" position={[-0.46, 1.09, 0.18]} rotation={[-2.67, -0.26, -0.12]} scale={0.04} />
          <group name="WGT-rig_thumb01R001" position={[-0.43, 1.04, 0.26]} rotation={[0.79, 0.28, 0.5]} scale={0.01} />
          <group name="WGT-rig_thumb01_masterL" position={[0.46, 1.09, 0.18]} rotation={[-2.58, 0.27, 0]} scale={0.15} />
          <group name="WGT-rig_thumb01_masterR" position={[-0.46, 1.09, 0.18]} rotation={[-2.58, -0.27, 0]} scale={0.15} />
          <group name="WGT-rig_thumb02L" position={[0.45, 1.08, 0.21]} rotation={[-2.58, 0.28, 0.09]} scale={0.03} />
          <group name="WGT-rig_thumb02R" position={[-0.45, 1.08, 0.21]} rotation={[-2.58, -0.28, -0.09]} scale={0.03} />
          <group name="WGT-rig_thumb03L" position={[0.44, 1.06, 0.24]} rotation={[-2.35, 0.28, 0.03]} scale={0.03} />
          <group name="WGT-rig_thumb03R" position={[-0.44, 1.06, 0.24]} rotation={[-2.35, -0.28, -0.03]} scale={0.03} />
          <group name="WGT-rig_toeL" position={[0.19, 0.02, 0.08]} rotation={[-3.03, 0, -Math.PI]} scale={0.06} />
          <group name="WGT-rig_toeR" position={[-0.19, 0.02, 0.08]} rotation={[-3.03, 0, Math.PI]} scale={0.06} />
          <group name="WGT-rig_tongue" position={[0, 1.56, 0.09]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_tongue001" position={[0, 1.56, 0.07]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_tongue002" position={[0, 1.56, 0.04]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_tongue003" position={[0, 1.56, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_tongue_master" position={[0, 1.56, 0.07]} rotation={[-2.92, 0, 0]} scale={0.02} />
          <group name="WGT-rig_torso" position={[0, 0.96, -0.03]} scale={0.35} />
          <group name="WGT-rig_tweak_spine" position={[0, 0.9, -0.05]} rotation={[1.82, 0, 0]} scale={0.07} />
          <group name="WGT-rig_tweak_spine001" position={[0, 1.03, -0.02]} rotation={[1.69, 0, 0]} scale={0.06} />
          <group name="WGT-rig_tweak_spine002" position={[0, 1.15, 0]} rotation={[1.54, 0, 0]} scale={0.08} />
          <group name="WGT-rig_tweak_spine003" position={[0, 1.3, -0.01]} rotation={[1.25, 0, 0]} scale={0.09} />
          <group name="WGT-rig_tweak_spine004" position={[0, 1.47, -0.06]} rotation={[2.15, -0.03, 0]} scale={0.03} />
          <group name="WGT-rig_tweak_spine005" position={[0, 1.51, -0.03]} rotation={[2.08, 0, 0]} scale={0.02} />
          <group name="WGT-rig_upper_arm_fkL" position={[0.19, 1.4, -0.04]} rotation={[-1.63, -0.69, -3.06]} scale={0.23} />
          <group name="WGT-rig_upper_arm_fkR" position={[-0.19, 1.4, -0.04]} rotation={[-1.63, 0.69, 3.06]} scale={0.23} />
          <group name="WGT-rig_upper_arm_ikL" position={[0.19, 1.4, -0.04]} rotation={[-1.63, -0.69, -3.06]} scale={0.23} />
          <group name="WGT-rig_upper_arm_ikR" position={[-0.19, 1.4, -0.04]} rotation={[-1.63, 0.69, 3.06]} scale={0.23} />
          <group name="WGT-rig_upper_arm_ik_targetL" position={[0.42, 1.04, -0.45]} rotation={[2.74, 0.18, -0.82]} scale={0.06} />
          <group name="WGT-rig_upper_arm_ik_targetR" position={[-0.42, 1.04, -0.45]} rotation={[2.74, -0.18, 0.82]} scale={0.06} />
          <group name="WGT-rig_upper_arm_parentL" position={[0.19, 1.4, -0.04]} rotation={[-1.63, -0.69, -0.73]} scale={0.06} />
          <group name="WGT-rig_upper_arm_parentR" position={[-0.19, 1.4, -0.04]} rotation={[-1.63, 0.69, 0.73]} scale={0.06} />
          <group name="WGT-rig_upper_arm_tweakL" position={[0.19, 1.4, -0.04]} rotation={[-1.63, -0.69, -3.06]} scale={0.06} />
          <group name="WGT-rig_upper_arm_tweakL001" position={[0.27, 1.31, -0.04]} rotation={[-1.63, -0.69, -3.06]} scale={0.06} />
          <group name="WGT-rig_upper_arm_tweakR" position={[-0.19, 1.4, -0.04]} rotation={[-1.63, 0.69, 3.06]} scale={0.06} />
          <group name="WGT-rig_upper_arm_tweakR001" position={[-0.27, 1.31, -0.04]} rotation={[-1.63, 0.69, 3.06]} scale={0.06} />
          <group name="WGT-rig_VIS_thigh_ik_poleL" position={[0.14, 0.49, -0.01]} rotation={[Math.PI / 2, 0, -0.1]} scale={0.81} />
          <group name="WGT-rig_VIS_thigh_ik_poleR" position={[-0.14, 0.49, -0.01]} rotation={[Math.PI / 2, 0, 0.1]} scale={0.81} />
          <group name="WGT-rig_VIS_upper_arm_ik_poleL" position={[0.34, 1.22, -0.03]} rotation={[Math.PI / 2, 0, -2.17]} scale={0.46} />
          <group name="WGT-rig_VIS_upper_arm_ik_poleR" position={[-0.34, 1.22, -0.03]} rotation={[Math.PI / 2, 0, 2.17]} scale={0.46} />
        </group>
      </group>
    </group>
  )
}

// useGLTF.preload('/wman16_22042021_anim.glb')
