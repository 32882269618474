import { makeStyles, Theme, createStyles } from '@material-ui/core'
import React from 'react'

type Props = {
  text: string
  // type: 'text' | 'video' | 'audio' | 'photo' | null;
  typePhoto: boolean
  typeSound: boolean
  typeText: boolean
  typeVideo: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      margin: 0
    }
  })
)

const CognitiveBlockByType = (props: Props) => {
  const classes = useStyles()

  if (props.typeText) return <p className={classes.text}>{props.text}</p>
  if (props.typeVideo)
    return (
      <video width='400' height='250' controls={true}>
        <source
          src={props.text}
          type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        />
      </video>
    )
  if (props.typeSound)
    return (
      <figure>
        <audio controls src={props.text}>
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      </figure>
    )
  if (props.typePhoto)
    return (
      <img width='400' height='250' src={props.text} alt='' />
      // <b>{props.text}</b>
    )
  return null
}

export default CognitiveBlockByType
