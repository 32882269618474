export function pxToRem(px: number) {
  return `${px / 16}rem`;
}

export function pxToRemGutter(top: number, right?: number, bottom?: number, left?: number) {
  return `${pxToRem(top)} ${right !== undefined ? pxToRem(right) : ''} ${bottom !== undefined ? pxToRem(bottom) : ''} ${left !== undefined ? pxToRem(left) : ''}`;
}

export function capitalize(s: string) {
  return s && s[0].toUpperCase() + s.slice(1);
}
