import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { ReactElement } from 'react'
import { useAsync } from 'react-use'
import chatApi from '../../api/chatApi'
import { DifficultLevel } from '../../api/DomainTypes'
import ChatHint from './ChatHint'
import HoverImage from './HoverImage'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(5),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        padding: '0'
      }
    },
    levelsBlock: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      alignItems: 'center',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-start'
        // flexDirection: 'column',
      }
    },
    levelCard: {
      minWidth: '256px',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto',
        // maxWidth: '50%',
        flexBasis: '33%'
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
        // maxWidth: '50%',
        flexBasis: '100%'
      }
    }
  })
)

type Props = {
  difficultLevels: Array<DifficultLevel>
  onChoose?: (item: DifficultLevel) => void
}

export default function ChooserAcademicLevel({
  difficultLevels,
  onChoose
}: Props): ReactElement {
  const classes = useStyles()

  const universityLevelAsyncState: any = useAsync(async () => {
    let res: any = await chatApi.getUniversityLevel()
    difficultLevels.forEach((el: any) => {
      res?.result.forEach((el2: any) => {
        if (el.code === el2.code) {
          el2.stepText = el.stepText
        }
      })
    })
    res.result.sort((a: any, b: any) => a.id - b.id)
    return res.result
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.levelsBlock}>
        {universityLevelAsyncState?.value?.map((item: any) => {
          return (
            <div
              className={classes.levelCard}
              key={item.id}
              onClick={() => onChoose && onChoose(item)}
            >
              <HoverImage width={140} item={item} />
            </div>
          )
        })}
      </div>
      {/* <ChatHint title="Выберите уровень сложности" arrowSide="up" /> */}
    </div>
  )
}
