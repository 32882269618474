import { Button, makeStyles } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React, { ReactElement } from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    // padding: theme.spacing(2),
    textTransform: 'uppercase',
    color: theme.palette.text.hint,
    minHeight: '40px',
    maxHeight: '40px',
    marginTop: '25px'
  },
  title: {
    fontSize: theme.spacing(4),
    textAlign: 'center',
    flex: 1
  },
  sideblock: {
    fontSize: 14,
    minWidth: 100
  }
}))
interface Props {
  title?: string
  children?: string | React.ReactNode

  onClickBackBtn?: () => void
}

export default function CustomHeader1({
  title,
  children,
  onClickBackBtn
}: Props): ReactElement {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {onClickBackBtn ? (
        <div className={classes.sideblock}>
          <Button
            color='primary'
            startIcon={<ArrowBackIcon />}
            onClick={onClickBackBtn}
          >
            Back
          </Button>
        </div>
      ) : (
        <div className={classes.sideblock}>
          <Button color='primary' onClick={onClickBackBtn}>
            &nbsp;
          </Button>
        </div>
      )}
      <div className={classes.title}>
        {title}
        {children}
      </div>
      {onClickBackBtn && <div className={classes.sideblock}></div>}
    </div>
  )
}
