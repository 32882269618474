import { GroupOfResearch_ResponseData, ResearchResults_ResponseData } from '../api/chatApi/chatApi';
import { concat, uniqBy, prop, compose } from 'ramda';

export type CurrentTaskResearchState = {
  groupsIsLoading: boolean;
  groupsOfResearch: Array<GroupOfResearch_ResponseData>;
  groupOfResearchLoading?: number;
  researchResults?: Array<ResearchResults_ResponseData>;
};

export enum ResearchActionType {
  SET_GROUPS_OF_RESEARCH_LOADING = 'SET_GROUPS_OF_RESEARCH_LOADING',
  SET_GROUPS_OF_RESEARCH = 'SET_GROUPS_OF_RESEARCH',
  ADD_RESEARCH_RESULTS = 'ADD_RESEARCH_RESULTS',
  SET_RESEARCH_RESULTS = 'SET_RESEARCH_RESULTS',
}
interface SetGroupsOfResearchAction {
  type: typeof ResearchActionType.SET_GROUPS_OF_RESEARCH;
  data: { groupsOfResearch: Array<GroupOfResearch_ResponseData> };
}
interface SetGroupsOfResearchLoadingAction {
  type: typeof ResearchActionType.SET_GROUPS_OF_RESEARCH_LOADING;
  data: boolean;
}

interface AddResearchResultsAction {
  type: typeof ResearchActionType.ADD_RESEARCH_RESULTS;
  data: Array<ResearchResults_ResponseData>;
}

interface SetResearchResultsAction {
  type: typeof ResearchActionType.SET_RESEARCH_RESULTS;
  data: Array<ResearchResults_ResponseData>;
}

export type ResearchAction = SetGroupsOfResearchAction | AddResearchResultsAction | SetGroupsOfResearchLoadingAction | SetResearchResultsAction;

const initialState: CurrentTaskResearchState = {
  groupsIsLoading: false,
  groupOfResearchLoading: undefined,
  groupsOfResearch: [],
  researchResults: undefined,
};

export function researchReducer(state: CurrentTaskResearchState = initialState, action: ResearchAction): CurrentTaskResearchState {
  switch (action.type) {
    case ResearchActionType.SET_GROUPS_OF_RESEARCH_LOADING: {
      return { ...state, groupsIsLoading: action.data };
    }
    case ResearchActionType.SET_GROUPS_OF_RESEARCH: {
      const existingGroupMap = new Map(state.groupsOfResearch.map(it => [it.id, it]));
      const withOldTypes = action.data.groupsOfResearch.map(it => {
        const old = existingGroupMap.get(it.id);
        if (old) {
          return {
            ...it,
            typesOfResearch: compose(uniqBy(prop('id')), concat(old.typesOfResearch || []))(it.typesOfResearch || []),
          };
        } else {
          return it;
        }
      });
      return {
        ...state,
        groupsOfResearch: compose(uniqBy(prop('id')), concat(withOldTypes))(state.groupsOfResearch) as Array<GroupOfResearch_ResponseData>,
      };
    }
    case ResearchActionType.ADD_RESEARCH_RESULTS: {
      return {
        ...state,
        researchResults: compose(uniqBy(prop('typeOfResearchId')), concat(action.data))(state.researchResults || []) as Array<ResearchResults_ResponseData>,
      };
    }
    case ResearchActionType.SET_RESEARCH_RESULTS: {
      return { ...initialState };
    }

    default:
      return state;
  }
}
