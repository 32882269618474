import { makeStyles } from '@material-ui/core'
import React, { ReactElement } from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    width: 300,
    margin: (p: Props) =>
      p.margin ? theme.spacing(p.margin) : theme.spacing(2),
    background: theme.palette.background.paper,
    border: '2px dashed',
    borderRadius: 20,
    borderColor: theme.palette.divider,
    cursor: 'pointer',
    fontSize: 20,
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    textAlign: 'center',
    '&:hover': {
      border: '2px solid',
      borderColor: theme.palette.primary.main
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center'

  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
}))

interface Props {
  children?: string | React.ReactNode
  onClick?: any
  item?: any
  margin?: number
}

export default function CustomCard({
  children,
  onClick,
  item,
  margin
}: Props): ReactElement {
  const classes = useStyles({ margin } as Props)

  const handleClick = () => {
    onClick && onClick(item)
  }
  return (
    <div className={classes.root} onClick={handleClick}>
      <div className={classes.contentWrapper}>
        {children}
      </div>
    </div>
  )
}
