import { AsyncState } from 'react-use/lib/useAsyncFn';
import { put, call } from 'redux-saga/effects';
import { BaseResponse } from '../../api/chatApi/chatApi';

/**
 * функция вставляет в стейт AsyncState сначала загрузку, успех, ошибку
 * @param action - строка тип redux экшена для вставки AsyncState значения
 * @param ctxAndFn - [контекстФункции, функция асинхронного запроса получения данных] -
 * @param args - аргументы асинхронной функции
 */
export function* doAsyncRequest<AsyncStateValueType, ReduxActionType, Ctx, Fn extends (this: Ctx, ...args: any[]) => any>(
  action: ReduxActionType,
  ctxAndFn: [Ctx, Fn],
  args: Parameters<Fn>
) {
  try {
    const stateLoading: AsyncState<AsyncStateValueType> = { loading: true };
    yield put({
      type: action,
      data: stateLoading,
    });

    const [ctx, fn] = ctxAndFn;
    const taskResult: BaseResponse<AsyncStateValueType> = yield call(fn.bind(ctx), ...args);
    const state: AsyncState<AsyncStateValueType> = { loading: false, value: taskResult.result };
    yield put({
      type: action,
      data: state,
    });
  } catch (e) {
    const state: AsyncState<AsyncStateValueType> = { loading: false };
    yield put({
      type: action,
      data: state,
    });
  }
}
