import { BaseResponse, ChatApi } from '../chatApi/chatApi';
import { ChatMessage, CheckResult, MessageAuthor, MessageType } from '../../types';
import { ChatHistory, CheckHistory, DIFFICULT_DATA_BY_CODE, DifficultLevel, Patient, Task } from '../DomainTypes';
import { any } from 'ramda';

/**
 * Обертка над chatApi для доп манипуляций с данными
 * очень желательно оборачивать ответы в BaseResponse чтоб одной функцией загружать в стор из саги
 */
export default class ChatService {
  constructor(private api: ChatApi) { }

  async loadChatHistory(): Promise<BaseResponse<ChatHistory>> {
    const history = await this.api.getCurrentJournalHistory();

    let points = 0;
    let penalty = 0;
    const chatMessages = history.result
      .sort((a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime())
      .flatMap(it => {
        const req: ChatMessage = {
          author: MessageAuthor.USER,
          message: it.request.unMarkedText,
          type: MessageType.message,
          attachment: [],
          tdMediaId: it.response.tdMediaId
        };
        const res: ChatMessage = {
          author: MessageAuthor.SYSTEM,
          message: it.response.text,
          type: MessageType.message,
          attachment: [],
          tdMediaId: it.response.tdMediaId
        };
        points = points + it.response.points;
        penalty = penalty + it.response.penalty;
        return [req, res];
      });



    return {
      success: history.success,
      description: history.description,
      result: {
        messages: chatMessages,
        penalty: penalty,
        points: points,
      },
    };
  }

  async loadCheckHistory(): Promise<BaseResponse<CheckHistory>> {
    const history = await this.api.loadCurrentCheckHistory();
    let points = 0;
    let penalty = 0;
    const checkResults = history.result.map(it => {
      const checkRes: CheckResult = {
        paramName: it.request.unMarkedText,
        anamnesisId: it.request.unMarkedText,
        value: it.response.text,
        points: it.response.points,
        penalty: it.response.penalty,
      };
      points = points + it.response.points;
      penalty = penalty + it.response.penalty;
      return checkRes;
    });

    return {
      success: history.success,
      description: history.description,
      result: {
        messages: checkResults,
        penalty: penalty,
        points: points,
      },
    };
  }

  async loadDifficultLevels(): Promise<Array<DifficultLevel>> {
    const res = await this.api.loadTypesOfDifficultLevels();
    return res.result
      .map<DifficultLevel>(item => {
        const hardCodeData = DIFFICULT_DATA_BY_CODE[item.code];
        return {
          ...item,
          image: hardCodeData?.image,
          imageHover: hardCodeData?.imageHover,
          title: item.name,
          stepsDescription: hardCodeData.stepsDescription,
        };
      })
      .sort((it1, it2) => it1.coefficient - it2.coefficient);
  }

  async loadDifficultLevelsByUniversityLevel(universityLevel: string): Promise<Array<DifficultLevel>> {
    const res: any = await this.api.loadTypesOfDifficultLevelsByUniversityLevel(universityLevel);

    return res?.result
      .map((item: any) => {
        const hardCodeData = DIFFICULT_DATA_BY_CODE[item.code];
        return {
          ...item,
          image: hardCodeData?.image,
          imageHover: hardCodeData?.imageHover,
          title: item.name,
          stepsDescription: hardCodeData.stepsDescription,
        };
      })
      .sort((it1: any, it2: any) => it1.coefficient - it2.coefficient);
  }

  async loadCurrentTaskInfo(): Promise<BaseResponse<Task>> {
    const proceed = await this.api.unfinishedTask();
    const currTask = proceed.result.task;

    const patient: Patient = {
      firstName: currTask.patientModel.patient.firstName,
      id: currTask.patientModel.patient.id,
      photo: currTask.patientModel.patient.photo,
      secondName: currTask.patientModel.patient.secondName,
    };

    return {
      success: proceed.success,
      description: proceed.description,
      result: {
        patient: patient,
        id: currTask.id,
        taskProceedId: proceed.result.id,
        difficultCode: currTask.typeOfDifficultLevel.code,
        testingStage: proceed.result.testingStageId,
      },
    };
  }

  async checkObjectiveState(anamnesisUid: string): Promise<CheckResult> {
    const res = await this.api.checkObjectiveState(anamnesisUid);
    return {
      paramName: anamnesisUid,
      penalty: res.result.penalty,
      points: res.result.points,
      value: res.result.text,
      anamnesisId: anamnesisUid,
    };

  }
}
