import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { PANEL_MENU } from '../../constants/default'
import { MenuItemData, ProgressBarType } from '../../types'
import MobileIndicators from '../common/MobileIndicators'
import TopNavBar from '../common/TopNavBar'
import StepsDropdown from './StepsDropdown'

const successPointsSelector = (state: any) => {
  const totalSuccessPoints = state.chat.totalSuccessPoints
  return {
    text: `${totalSuccessPoints}`,
    maxVal: 100,
    currentVal: totalSuccessPoints
  }
}

const penaltyPointsSelector = (state: any) => {
  const totalPenaltyPoints = state.chat.totalPenaltyPoints
  return {
    text: `${totalPenaltyPoints}`,
    maxVal: 100,
    currentVal: totalPenaltyPoints
  }
}

const useStyles = makeStyles(theme => {
  return createStyles({
    menuTitle: {
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 500,
      fontsize: '20px',
      lineHeight: '23px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      letterSpacing: '0.15px',
      textTransform: 'uppercase',
      color: theme.palette.primary.contrastText
    }
  })
})

type Props = {
  title?: string
  menuItems?: Array<MenuItemData>
}

export default function ChatPagesMenuMobile(props: Props) {
  const style = useStyles()
  const successPoints: ProgressBarType = useSelector(successPointsSelector)
  const penaltyPoints: ProgressBarType = useSelector(penaltyPointsSelector)

  return (
    <div>
      <TopNavBar menuItems={PANEL_MENU}>
        {props.title ? (
          <span className={style.menuTitle}>{props.title}</span>
        ) : (
          <StepsDropdown menuItems={props.menuItems ? props.menuItems : []} />
        )}
      </TopNavBar>
      <MobileIndicators
        progress={successPoints.currentVal}
        errors={penaltyPoints.currentVal}
      />
    </div>
  )
}
