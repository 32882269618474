import React, { useEffect, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'



export function Girl14(props) {
  const { nodes, materials, animations } = useGLTF('patient/girl_22022022.glb')


  const { ref, actions, names, mixer } = useAnimations(animations)
  const [index, setIndex] = useState(4)

  // console.log(names);
  useEffect(() => {
    props.setAnim(animations)
    actions.rigAction_girls.play()
  }, [actions, names, mixer])

  return (
    <group ref={ref} {...props} dispose={null}>
      <group name="Scene">
        <group name="metarig">
          <group name="spine" position={[0, 0.68, 0]} rotation={[0.18, 0, 0]}>
            <group name="spine001" position={[0, 0.11, 0]} rotation={[0.03, 0, 0]}>
              <group name="spine002" position={[0, 0.08, 0]} rotation={[-0.43, 0, 0]}>
                <group name="spine003" position={[0, 0.11, 0]} rotation={[0.23, 0, 0]}>
                  <group name="spine004" position={[0, 0.13, 0]} rotation={[0.32, 0, 0]}>
                    <group name="spine005" position={[0, 0.03, 0]} rotation={[0.04, 0, 0.09]}>
                      <group name="spine006" position={[0, 0.03, 0]} rotation={[-0.33, 0.03, -0.08]}>
                        <group name="face" position={[0, 0.04, 0.03]} rotation={[-0.04, 0, 0]}>
                          <group name="nose_1" position={[0, 0.04, 0.05]} rotation={[2.67, 0, 0]}>
                            <group name="nose001_1" position={[0, 0.02, 0]} rotation={[-0.33, 0, 0]}>
                              <group name="nose002_1" position={[0, 0.01, 0]} rotation={[1.34, 0, 0]}>
                                <group name="nose003_1" position={[0, 0.01, 0]} rotation={[0.96, 0, 0]}>
                                  <group name="nose004_1" position={[0, 0.01, 0]} rotation={[2.18, 0, 0]} />
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="lipTL" position={[0, -0.01, 0.06]} rotation={[-1.58, 0.12, -1.2]}>
                            <group name="lipTL001_1" position={[0, 0.01, 0]} rotation={[-0.13, -0.25, 0.43]} />
                          </group>
                          <group name="lipBL" position={[0, -0.02, 0.06]} rotation={[-1.47, -0.04, -1.1]}>
                            <group name="lipBL001_1" position={[0, 0.01, 0]} rotation={[0.21, 0.18, 0.2]} />
                          </group>
                          <group name="jaw_1" position={[0, -0.04, 0]} rotation={[1.7, 0, 0]}>
                            <group name="chin_1" position={[0, 0.05, 0]} rotation={[-1.21, 0, 0]}>
                              <group name="chin001_1" position={[0, 0.01, 0]} rotation={[-0.62, 0, 0]} />
                            </group>
                          </group>
                          <group name="earL_1" position={[0.06, 0.02, -0.02]} rotation={[-0.33, -0.11, -0.34]}>
                            <group name="earL001" position={[0, 0.02, 0]} rotation={[-1.14, -0.26, -0.05]}>
                              <group name="earL002_1" position={[0, 0.01, 0]} rotation={[-1.45, -0.29, 0.15]}>
                                <group name="earL003_1" position={[0, 0.02, 0]} rotation={[-0.76, -0.64, 0.13]}>
                                  <group name="earL004_1" position={[0, 0.02, 0]} rotation={[-2.67, 0.23, 1.07]} />
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="earR_1" position={[-0.06, 0.02, -0.02]} rotation={[-0.33, 0.11, 0.34]}>
                            <group name="earR001" position={[0, 0.02, 0]} rotation={[-1.14, 0.26, 0.05]}>
                              <group name="earR002_1" position={[0, 0.01, 0]} rotation={[-1.45, 0.29, -0.15]}>
                                <group name="earR003_1" position={[0, 0.02, 0]} rotation={[-0.76, 0.64, -0.13]}>
                                  <group name="earR004_1" position={[0, 0.02, 0]} rotation={[-2.67, -0.23, -1.07]} />
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="lipTR" position={[0, -0.01, 0.06]} rotation={[-1.58, -0.12, 1.2]}>
                            <group name="lipTR001_1" position={[0, 0.01, 0]} rotation={[-0.13, 0.25, -0.43]} />
                          </group>
                          <group name="lipBR" position={[0, -0.02, 0.06]} rotation={[-1.47, 0.04, 1.1]}>
                            <group name="lipBR001_1" position={[0, 0.01, 0]} rotation={[0.21, -0.18, -0.2]} />
                          </group>
                          <group name="browBL_1" position={[0.05, 0.05, 0.04]} rotation={[-0.99, 0.86, 2.01]}>
                            <group name="browBL001_1" position={[0, 0.01, 0]} rotation={[-0.4, 0.7, 0.02]}>
                              <group name="browBL002_1" position={[0, 0.01, 0]} rotation={[-0.06, 0.59, -0.23]}>
                                <group name="browBL003_1" position={[0, 0.01, 0]} rotation={[0.06, 0.57, -0.34]} />
                              </group>
                            </group>
                          </group>
                          <group name="lidTL_1" position={[0.04, 0.04, 0.04]} rotation={[-1.21, 1.39, 1.77]}>
                            <group name="lidTL001_1" position={[0, 0.01, 0]} rotation={[-1.12, 0.51, 0.38]}>
                              <group name="lidTL002_1" position={[0, 0.01, 0]} rotation={[-0.2, 0.34, -0.59]}>
                                <group name="lidTL003_1" position={[0, 0.01, 0]} rotation={[-0.45, 0.58, -0.12]}>
                                  <group name="lidBL_1" position={[0, 0.01, 0]} rotation={[0.93, -0.22, -2.55]}>
                                    <group name="lidBL001_1" position={[0, 0.01, 0]} rotation={[0.15, 0.18, 0.06]}>
                                      <group name="lidBL002_1" position={[0, 0.01, 0]} rotation={[0.28, 0.24, 0.47]}>
                                        <group name="lidBL003_1" position={[0, 0.01, 0]} rotation={[0.37, 0.3, 0.49]} />
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="browBR_1" position={[-0.05, 0.05, 0.04]} rotation={[-0.99, -0.86, -2.01]}>
                            <group name="browBR001_1" position={[0, 0.01, 0]} rotation={[-0.4, -0.7, -0.02]}>
                              <group name="browBR002_1" position={[0, 0.01, 0]} rotation={[-0.06, -0.59, 0.23]}>
                                <group name="browBR003_1" position={[0, 0.01, 0]} rotation={[0.06, -0.57, 0.34]} />
                              </group>
                            </group>
                          </group>
                          <group name="lidTR_1" position={[-0.04, 0.04, 0.04]} rotation={[-1.21, -1.39, -1.77]}>
                            <group name="lidTR001_1" position={[0, 0.01, 0]} rotation={[-1.12, -0.51, -0.38]}>
                              <group name="lidTR002_1" position={[0, 0.01, 0]} rotation={[-0.2, -0.34, 0.59]}>
                                <group name="lidTR003_1" position={[0, 0.01, 0]} rotation={[-0.45, -0.58, 0.12]}>
                                  <group name="lidBR_1" position={[0, 0.01, 0]} rotation={[0.93, 0.22, 2.55]}>
                                    <group name="lidBR001_1" position={[0, 0.01, 0]} rotation={[0.15, -0.18, -0.06]}>
                                      <group name="lidBR002_1" position={[0, 0.01, 0]} rotation={[0.28, -0.24, -0.47]}>
                                        <group name="lidBR003_1" position={[0, 0.01, 0]} rotation={[0.37, -0.3, -0.49]} />
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="foreheadL" position={[0.01, 0.09, 0.05]} rotation={[-2.4, 1.5, -1]}>
                            <group name="foreheadL001" position={[-0.01, 0, 0.02]} rotation={[-0.01, -0.27, -0.05]}>
                              <group name="foreheadL002" position={[-0.01, 0.01, 0.02]} rotation={[0.13, -0.56, 0.02]}>
                                <group name="templeL" position={[-0.03, 0.05, 0.04]} rotation={[0.26, -0.66, 0.56]}>
                                  <group name="jawL_1" position={[0, 0.03, 0]} rotation={[0.17, -0.22, 0.42]}>
                                    <group name="jawL001_1" position={[0, 0.03, 0]} rotation={[-0.85, -0.57, 0.46]}>
                                      <group name="chinL_1" position={[0, 0.05, 0]} rotation={[-1.82, 0.09, 1.11]}>
                                        <group name="cheekBL" position={[0, 0.03, 0]} rotation={[-1.32, -0.55, -0.64]}>
                                          <group name="cheekBL001_1" position={[0, 0.04, 0]} rotation={[0.31, 0.59, 0.47]}>
                                            <group name="browTL_1" position={[0, 0.04, 0]} rotation={[1.13, 0.44, 0.02]}>
                                              <group name="browTL001_1" position={[0, 0.03, 0]} rotation={[-2.02, 0.64, 1.96]}>
                                                <group name="browTL002_1" position={[0, 0.02, 0]} rotation={[-0.19, 0.78, -0.32]}>
                                                  <group name="browTL003" position={[0, 0.02, 0]} rotation={[-0.54, 0.59, -0.73]} />
                                                  <group name="browTL004" position={[0, 0.02, 0]} rotation={[-0.27, -0.11, -0.49]} />
                                                </group>
                                              </group>
                                            </group>
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                            <group name="foreheadL003" position={[0, 0.03, 0]} rotation={[-0.15, -0.02, 0.26]} />
                          </group>
                          <group name="foreheadR" position={[-0.01, 0.09, 0.05]} rotation={[-2.4, -1.5, 1]}>
                            <group name="foreheadR001" position={[0.01, 0, 0.02]} rotation={[-0.01, 0.27, 0.05]}>
                              <group name="foreheadR002" position={[0.01, 0.01, 0.02]} rotation={[0.13, 0.56, -0.02]}>
                                <group name="templeR" position={[0.03, 0.05, 0.04]} rotation={[0.26, 0.66, -0.56]}>
                                  <group name="jawR_1" position={[0, 0.03, 0]} rotation={[0.17, 0.22, -0.42]}>
                                    <group name="jawR001_1" position={[0, 0.03, 0]} rotation={[-0.85, 0.57, -0.46]}>
                                      <group name="chinR_1" position={[0, 0.05, 0]} rotation={[-1.82, -0.09, -1.11]}>
                                        <group name="cheekBR" position={[0, 0.03, 0]} rotation={[-1.32, 0.55, 0.64]}>
                                          <group name="cheekBR001_1" position={[0, 0.04, 0]} rotation={[0.31, -0.59, -0.47]}>
                                            <group name="browTR_1" position={[0, 0.04, 0]} rotation={[1.13, -0.44, -0.02]}>
                                              <group name="browTR001_1" position={[0, 0.03, 0]} rotation={[-2.02, -0.64, -1.96]}>
                                                <group name="browTR002_1" position={[0, 0.02, 0]} rotation={[-0.19, -0.78, 0.32]}>
                                                  <group name="browTR003" position={[0, 0.02, 0]} rotation={[-0.54, -0.59, 0.73]} />
                                                  <group name="browTR004" position={[0, 0.02, 0]} rotation={[-0.27, 0.11, 0.49]} />
                                                </group>
                                              </group>
                                            </group>
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                            <group name="foreheadR003" position={[0, 0.03, 0]} rotation={[-0.15, 0.02, -0.26]} />
                          </group>
                          <group name="eyeL_1" position={[0.03, 0.04, 0.03]} rotation={[-Math.PI / 2, 0, -3.09]} />
                          <group name="eyeR_1" position={[-0.03, 0.04, 0.03]} rotation={[-Math.PI / 2, 0, 3.09]} />
                          <group name="cheekTL" position={[0.05, 0.04, 0.01]} rotation={[3.08, -1.01, 0.9]}>
                            <group name="cheekTL001_1" position={[0, 0.04, 0]} rotation={[2.82, -0.58, 1.59]}>
                              <group name="noseL_1" position={[0, 0.03, 0]} rotation={[2.53, 0.85, 1.44]}>
                                <group name="noseL001_1" position={[0, 0.02, 0]} rotation={[1.57, 0.24, 2.66]} />
                              </group>
                            </group>
                          </group>
                          <group name="cheekTR" position={[-0.05, 0.04, 0.01]} rotation={[3.08, 1.01, -0.9]}>
                            <group name="cheekTR001_1" position={[0, 0.04, 0]} rotation={[2.82, 0.58, -1.59]}>
                              <group name="noseR_1" position={[0, 0.03, 0]} rotation={[2.53, -0.85, -1.44]}>
                                <group name="noseR001_1" position={[0, 0.02, 0]} rotation={[1.57, -0.24, -2.66]} />
                              </group>
                            </group>
                          </group>
                          <group name="teethT_1" position={[0, 0.01, 0.05]} rotation={[-1.46, 0, 0]} />
                          <group name="teethB_1" position={[0, -0.01, 0.05]} rotation={[-Math.PI / 2, 0, 0]} />
                          <group name="tongue_1" position={[0, -0.01, 0.04]} rotation={[-1.35, 0, 0]}>
                            <group name="tongue001_1" position={[0, 0.02, 0]} rotation={[-0.43, 0, 0]}>
                              <group name="tongue002_1" position={[0, 0.02, 0]} rotation={[0.04, 0, 0]} />
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="shoulderL_1" position={[0.02, 0.12, 0.04]} rotation={[-1.59, 0.02, -1.2]}>
                    <group name="upper_armL" position={[0.01, 0.12, -0.03]} rotation={[1.62, 1.27, -2.5]}>
                      <group name="forearmL" position={[0, 0.19, 0]} rotation={[0.78, 0.37, 0.06]}>
                        <group name="handL" position={[0, 0.19, 0]} rotation={[0.09, -0.06, -0.05]}>
                          <group name="palm01L" position={[0, 0.03, 0.02]} rotation={[-2.72, 1.47, 2.78]}>
                            <group name="f_index01L_1" position={[0, 0.05, 0]} rotation={[0.29, -0.21, 0.15]}>
                              <group name="f_index02L_1" position={[0, 0.02, 0]} rotation={[0.17, -0.11, 0.08]}>
                                <group name="f_index03L_1" position={[0, 0.02, 0]} rotation={[0.1, -0.25, -0.04]} />
                              </group>
                            </group>
                            <group name="thumb01L_1" position={[0, -0.01, 0]} rotation={[-1.14, 0.9, 1.45]}>
                              <group name="thumb02L_1" position={[0, 0.02, 0]} rotation={[0.07, -0.07, 0.12]}>
                                <group name="thumb03L_1" position={[0, 0.03, 0]} rotation={[0.17, 0.05, 0.15]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm02L" position={[0, 0.03, 0]} rotation={[-2.97, 1.51, 2.91]}>
                            <group name="f_middle01L_1" position={[0, 0.05, 0]} rotation={[0.33, -0.17, 0.06]}>
                              <group name="f_middle02L_1" position={[0, 0.03, 0]} rotation={[0.16, -0.27, 0.06]}>
                                <group name="f_middle03L_1" position={[0, 0.03, 0]} rotation={[-0.01, -0.15, -0.02]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm03L" position={[0, 0.03, -0.01]} rotation={[-1.36, 1.56, 1.2]}>
                            <group name="f_ring01L_1" position={[0, 0.05, 0]} rotation={[0.4, -0.24, 0.05]}>
                              <group name="f_ring02L_1" position={[0, 0.02, 0]} rotation={[0.05, -0.18, 0]}>
                                <group name="f_ring03L_1" position={[0, 0.02, 0]} rotation={[0.04, -0.38, -0.07]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm04L" position={[0, 0.03, -0.02]} rotation={[0.88, 1.5, -1.13]}>
                            <group name="f_pinky01L_1" position={[0, 0.04, 0]} rotation={[0.34, -0.44, -0.01]}>
                              <group name="f_pinky02L_1" position={[0, 0.02, 0]} rotation={[0.25, -0.09, -0.16]}>
                                <group name="f_pinky03L_1" position={[0, 0.02, 0]} rotation={[0.11, -0.23, 0.15]} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="shoulderR_1" position={[-0.02, 0.12, 0.04]} rotation={[-1.59, -0.02, 1.2]}>
                    <group name="upper_armR" position={[-0.01, 0.12, -0.03]} rotation={[1.62, -1.27, 2.5]}>
                      <group name="forearmR" position={[0, 0.19, 0]} rotation={[0.78, -0.37, -0.06]}>
                        <group name="handR" position={[0, 0.19, 0]} rotation={[0.09, 0.06, 0.05]}>
                          <group name="palm01R" position={[0, 0.03, 0.02]} rotation={[-2.72, -1.47, -2.78]}>
                            <group name="f_index01R_1" position={[0, 0.05, 0]} rotation={[0.29, 0.21, -0.15]}>
                              <group name="f_index02R_1" position={[0, 0.02, 0]} rotation={[0.17, 0.11, -0.08]}>
                                <group name="f_index03R_1" position={[0, 0.02, 0]} rotation={[0.1, 0.25, 0.04]} />
                              </group>
                            </group>
                            <group name="thumb01R_1" position={[0, -0.01, 0]} rotation={[-1.14, -0.9, -1.45]}>
                              <group name="thumb02R_1" position={[0, 0.02, 0]} rotation={[0.07, 0.07, -0.12]}>
                                <group name="thumb03R_1" position={[0, 0.03, 0]} rotation={[0.17, -0.05, -0.15]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm02R" position={[0, 0.03, 0]} rotation={[-2.97, -1.51, -2.91]}>
                            <group name="f_middle01R_1" position={[0, 0.05, 0]} rotation={[0.33, 0.17, -0.06]}>
                              <group name="f_middle02R_1" position={[0, 0.03, 0]} rotation={[0.16, 0.27, -0.06]}>
                                <group name="f_middle03R_1" position={[0, 0.03, 0]} rotation={[-0.01, 0.15, 0.02]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm03R" position={[0, 0.03, -0.01]} rotation={[-1.36, -1.56, -1.2]}>
                            <group name="f_ring01R_1" position={[0, 0.05, 0]} rotation={[0.4, 0.24, -0.05]}>
                              <group name="f_ring02R_1" position={[0, 0.02, 0]} rotation={[0.05, 0.18, 0]}>
                                <group name="f_ring03R_1" position={[0, 0.02, 0]} rotation={[0.04, 0.38, 0.07]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm04R" position={[0, 0.03, -0.02]} rotation={[0.88, -1.5, 1.13]}>
                            <group name="f_pinky01R_1" position={[0, 0.04, 0]} rotation={[0.34, 0.44, 0.01]}>
                              <group name="f_pinky02R_1" position={[0, 0.02, 0]} rotation={[0.25, 0.09, 0.16]}>
                                <group name="f_pinky03R_1" position={[0, 0.02, 0]} rotation={[0.11, 0.23, -0.15]} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="breastL_1" position={[0.08, 0, -0.01]} rotation={[-1.58, 0, -Math.PI]} />
                  <group name="breastR_1" position={[-0.08, 0, -0.01]} rotation={[-1.58, 0, -Math.PI]} />
                </group>
              </group>
            </group>
            <group name="pelvisL" rotation={[-1.88, -0.73, -2.12]} />
            <group name="pelvisR" rotation={[-1.88, 0.73, 2.12]} />
            <group name="thighL" position={[0.08, 0.01, 0.03]} rotation={[2.86, 0, -0.11]}>
              <group name="shinL" position={[0, 0.32, 0]} rotation={[0.27, 0.02, -0.01]}>
                <group name="footL" position={[0, 0.31, 0]} rotation={[-1.15, -0.1, 0.05]}>
                  <group name="toeL_1" position={[0, 0.1, 0]} rotation={[2.68, 0, Math.PI]} />
                  <group name="heel02L" position={[-0.03, 0.02, 0.06]} rotation={[2.55, 0, -1.57]} />
                </group>
              </group>
            </group>
            <group name="thighR" position={[-0.08, 0.01, 0.03]} rotation={[2.86, 0, 0.11]}>
              <group name="shinR" position={[0, 0.32, 0]} rotation={[0.27, -0.02, 0.01]}>
                <group name="footR" position={[0, 0.31, 0]} rotation={[-1.15, 0.1, -0.05]}>
                  <group name="toeR_1" position={[0, 0.1, 0]} rotation={[2.68, 0, -Math.PI]} />
                  <group name="heel02R" position={[0.03, 0.02, 0.06]} rotation={[2.55, 0, 1.57]} />
                </group>
              </group>
            </group>
          </group>
        </group>
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <skinnedMesh name="eyelashes01Mesh" geometry={nodes.eyelashes01Mesh.geometry} material={materials['eyelashes01.001']} skeleton={nodes.eyelashes01Mesh.skeleton} />
          <group name="high-polyMesh">
            <skinnedMesh name="high-poly002" geometry={nodes['high-poly002'].geometry} material={materials['high-poly.002']} skeleton={nodes['high-poly002'].skeleton} />
            {/* <skinnedMesh name="high-poly002_1" geometry={nodes['high-poly002_1'].geometry} material={materials.yeyes} skeleton={nodes['high-poly002_1'].skeleton} /> */}
          </group>
          <skinnedMesh name="KATYA_BODY" geometry={nodes.KATYA_BODY.geometry} material={materials.katya_skin} skeleton={nodes.KATYA_BODY.skeleton} />
          <skinnedMesh name="Katya_cloth" geometry={nodes.Katya_cloth.geometry} material={materials.cloth} skeleton={nodes.Katya_cloth.skeleton} />
          <skinnedMesh name="katya_hairs" geometry={nodes.katya_hairs.geometry} material={materials.katya_light_hairs} skeleton={nodes.katya_hairs.skeleton} />
        </group>
        <group name="WGTS_rig">
          <group name="WGT-rig_breastL" position={[0.08, 0.99, 0]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.09} />
          <group name="WGT-rig_breastR" position={[-0.08, 0.99, 0]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.09} />
          <group name="WGT-rig_browBL" position={[0.05, 1.26, 0.1]} rotation={[Math.PI / 2, 0, 0.03]} scale={0.01} />
          <group name="WGT-rig_browBL001" position={[0.04, 1.26, 0.11]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_browBL002" position={[0.03, 1.27, 0.11]} rotation={[Math.PI / 2, 0, -0.32]} scale={0.01} />
          <group name="WGT-rig_browBL003" position={[0.02, 1.26, 0.12]} rotation={[Math.PI / 2, 0, -0.63]} scale={0.01} />
          <group name="WGT-rig_browBL004" position={[0.01, 1.26, 0.12]} rotation={[Math.PI / 2, 0, -0.63]} scale={0.01} />
          <group name="WGT-rig_browBR" position={[-0.05, 1.26, 0.1]} rotation={[Math.PI / 2, 0, -0.03]} scale={0.01} />
          <group name="WGT-rig_browBR001" position={[-0.04, 1.26, 0.11]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_browBR002" position={[-0.03, 1.27, 0.11]} rotation={[Math.PI / 2, 0, 0.32]} scale={0.01} />
          <group name="WGT-rig_browBR003" position={[-0.02, 1.26, 0.12]} rotation={[Math.PI / 2, 0, 0.63]} scale={0.01} />
          <group name="WGT-rig_browBR004" position={[-0.01, 1.26, 0.12]} rotation={[Math.PI / 2, 0, 0.63]} scale={0.01} />
          <group name="WGT-rig_browTL" position={[0.05, 1.25, 0.08]} rotation={[Math.PI / 2, 0, -0.19]} scale={0.01} />
          <group name="WGT-rig_browTL001" position={[0.05, 1.27, 0.1]} rotation={[Math.PI / 2, 0, -0.25]} scale={0.01} />
          <group name="WGT-rig_browTL002" position={[0.03, 1.28, 0.11]} rotation={[Math.PI / 2, 0, -0.39]} scale={0.01} />
          <group name="WGT-rig_browTR" position={[-0.05, 1.25, 0.08]} rotation={[Math.PI / 2, 0, 0.19]} scale={0.01} />
          <group name="WGT-rig_browTR001" position={[-0.05, 1.27, 0.1]} rotation={[Math.PI / 2, 0, 0.25]} scale={0.01} />
          <group name="WGT-rig_browTR002" position={[-0.03, 1.28, 0.11]} rotation={[Math.PI / 2, 0, 0.39]} scale={0.01} />
          <group name="WGT-rig_cheekBL001" position={[0.05, 1.22, 0.09]} rotation={[Math.PI / 2, 0, 0.02]} scale={0.01} />
          <group name="WGT-rig_cheekBR001" position={[-0.05, 1.22, 0.09]} rotation={[Math.PI / 2, 0, -0.02]} scale={0.01} />
          <group name="WGT-rig_cheekTL001" position={[0.03, 1.23, 0.1]} rotation={[Math.PI / 2, 0, -0.08]} scale={0.01} />
          <group name="WGT-rig_cheekTR001" position={[-0.03, 1.23, 0.1]} rotation={[Math.PI / 2, 0, 0.08]} scale={0.01} />
          <group name="WGT-rig_chest" position={[0, 0.98, 0.01]} rotation={[1.58, 0, 0]} scale={0.15} />
          <group name="WGT-rig_chin" position={[0, 1.17, 0.11]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_chin001" position={[0, 1.17, 0.12]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_chin002" position={[0, 1.18, 0.12]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_chinL" position={[0.01, 1.17, 0.1]} rotation={[Math.PI / 2, 0, -0.21]} scale={0.01} />
          <group name="WGT-rig_chinR" position={[-0.01, 1.17, 0.1]} rotation={[Math.PI / 2, 0, 0.21]} scale={0.01} />
          <group name="WGT-rig_earL" position={[0.06, 1.22, 0.04]} rotation={[1.28, -0.34, 0.12]} scale={0.02} />
          <group name="WGT-rig_earL002" position={[0.07, 1.25, 0.02]} rotation={[Math.PI / 2, 0, -0.1]} scale={0.01} />
          <group name="WGT-rig_earL003" position={[0.07, 1.23, 0.02]} rotation={[Math.PI / 2, 0, 0.35]} scale={0.01} />
          <group name="WGT-rig_earL004" position={[0.06, 1.21, 0.03]} rotation={[Math.PI / 2, 0, -0.23]} scale={0.01} />
          <group name="WGT-rig_earR" position={[-0.06, 1.22, 0.04]} rotation={[1.28, 0.34, -0.12]} scale={0.02} />
          <group name="WGT-rig_earR002" position={[-0.07, 1.25, 0.02]} rotation={[Math.PI / 2, 0, 0.1]} scale={0.01} />
          <group name="WGT-rig_earR003" position={[-0.07, 1.23, 0.02]} rotation={[Math.PI / 2, 0, -0.35]} scale={0.01} />
          <group name="WGT-rig_earR004" position={[-0.06, 1.21, 0.03]} rotation={[Math.PI / 2, 0, 0.23]} scale={0.01} />
          <group name="WGT-rig_eyeL" position={[0.03, 1.25, 0.25]} rotation={[Math.PI / 2, 0, 0]} scale={0.02} />
          <group name="WGT-rig_eyeR" position={[-0.03, 1.25, 0.25]} rotation={[Math.PI / 2, 0, 0]} scale={0.02} />
          <group name="WGT-rig_eyes" position={[0, 1.25, 0.25]} rotation={[Math.PI / 2, 0, 0]} scale={0.02} />
          <group name="WGT-rig_f_index01L" position={[0.38, 0.78, 0.21]} rotation={[-2.49, -0.15, 1.22]} scale={0.02} />
          <group name="WGT-rig_f_index01L001" position={[0.37, 0.75, 0.26]} rotation={[0.78, -0.11, 1]} scale={0.01} />
          <group name="WGT-rig_f_index01R" position={[-0.38, 0.78, 0.21]} rotation={[-2.49, 0.15, -1.22]} scale={0.02} />
          <group name="WGT-rig_f_index01R001" position={[-0.37, 0.75, 0.26]} rotation={[0.78, 0.11, -1]} scale={0.01} />
          <group name="WGT-rig_f_index01_masterL" position={[0.38, 0.78, 0.21]} rotation={[-2.44, -0.02, 1.57]} scale={0.09} />
          <group name="WGT-rig_f_index01_masterR" position={[-0.38, 0.78, 0.21]} rotation={[-2.44, 0.02, -1.57]} scale={0.09} />
          <group name="WGT-rig_f_index02L" position={[0.38, 0.77, 0.23]} rotation={[-2.45, 0.03, 1.22]} scale={0.02} />
          <group name="WGT-rig_f_index02R" position={[-0.38, 0.77, 0.23]} rotation={[-2.45, -0.03, -1.22]} scale={0.02} />
          <group name="WGT-rig_f_index03L" position={[0.38, 0.76, 0.24]} rotation={[-2.36, 0.11, 1.22]} scale={0.02} />
          <group name="WGT-rig_f_index03R" position={[-0.38, 0.76, 0.24]} rotation={[-2.36, -0.11, -1.22]} scale={0.02} />
          <group name="WGT-rig_f_middle01L" position={[0.39, 0.78, 0.2]} rotation={[-2.28, -0.24, 1.33]} scale={0.03} />
          <group name="WGT-rig_f_middle01L001" position={[0.4, 0.72, 0.24]} rotation={[0.91, 0.09, 1.48]} scale={0.01} />
          <group name="WGT-rig_f_middle01R" position={[-0.39, 0.78, 0.2]} rotation={[-2.28, 0.24, -1.33]} scale={0.03} />
          <group name="WGT-rig_f_middle01R001" position={[-0.4, 0.72, 0.24]} rotation={[0.91, -0.09, -1.48]} scale={0.01} />
          <group name="WGT-rig_f_middle01_masterL" position={[0.39, 0.78, 0.2]} rotation={[-2.26, -0.17, 1.49]} scale={0.11} />
          <group name="WGT-rig_f_middle01_masterR" position={[-0.39, 0.78, 0.2]} rotation={[-2.26, 0.17, -1.49]} scale={0.11} />
          <group name="WGT-rig_f_middle02L" position={[0.39, 0.76, 0.22]} rotation={[-2.25, -0.08, 1.34]} scale={0.03} />
          <group name="WGT-rig_f_middle02R" position={[-0.39, 0.76, 0.22]} rotation={[-2.25, 0.08, -1.34]} scale={0.03} />
          <group name="WGT-rig_f_middle03L" position={[0.4, 0.74, 0.23]} rotation={[-2.23, -0.09, 1.34]} scale={0.02} />
          <group name="WGT-rig_f_middle03R" position={[-0.4, 0.74, 0.23]} rotation={[-2.23, 0.09, -1.34]} scale={0.02} />
          <group name="WGT-rig_f_pinky01L" position={[0.39, 0.77, 0.17]} rotation={[-2.05, -0.28, 1.17]} scale={0.02} />
          <group name="WGT-rig_f_pinky01L001" position={[0.4, 0.72, 0.19]} rotation={[1.13, -0.04, 1.15]} scale={0.01} />
          <group name="WGT-rig_f_pinky01R" position={[-0.39, 0.77, 0.17]} rotation={[-2.05, 0.28, -1.17]} scale={0.02} />
          <group name="WGT-rig_f_pinky01R001" position={[-0.4, 0.72, 0.19]} rotation={[1.13, 0.04, -1.15]} scale={0.01} />
          <group name="WGT-rig_f_pinky01_masterL" position={[0.39, 0.77, 0.17]} rotation={[-1.99, -0.14, 1.41]} scale={0.08} />
          <group name="WGT-rig_f_pinky01_masterR" position={[-0.39, 0.77, 0.17]} rotation={[-1.99, 0.14, -1.41]} scale={0.08} />
          <group name="WGT-rig_f_pinky02L" position={[0.4, 0.75, 0.18]} rotation={[-1.86, -0.04, 1.19]} scale={0.02} />
          <group name="WGT-rig_f_pinky02R" position={[-0.4, 0.75, 0.18]} rotation={[-1.86, 0.04, -1.19]} scale={0.02} />
          <group name="WGT-rig_f_pinky03L" position={[0.4, 0.73, 0.18]} rotation={[-2.01, 0.04, 1.19]} scale={0.01} />
          <group name="WGT-rig_f_pinky03R" position={[-0.4, 0.73, 0.18]} rotation={[-2.01, -0.04, -1.19]} scale={0.01} />
          <group name="WGT-rig_f_ring01L" position={[0.4, 0.77, 0.18]} rotation={[-2.16, -0.23, 0.93]} scale={0.02} />
          <group name="WGT-rig_f_ring01L001" position={[0.41, 0.72, 0.22]} rotation={[1.08, 0.14, 1.1]} scale={0.01} />
          <group name="WGT-rig_f_ring01R" position={[-0.4, 0.77, 0.18]} rotation={[-2.16, 0.23, -0.93]} scale={0.02} />
          <group name="WGT-rig_f_ring01R001" position={[-0.41, 0.72, 0.22]} rotation={[1.08, -0.14, -1.1]} scale={0.01} />
          <group name="WGT-rig_f_ring01_masterL" position={[0.4, 0.77, 0.18]} rotation={[-2.13, -0.2, 1]} scale={0.1} />
          <group name="WGT-rig_f_ring01_masterR" position={[-0.4, 0.77, 0.18]} rotation={[-2.13, 0.2, -1]} scale={0.1} />
          <group name="WGT-rig_f_ring02L" position={[0.4, 0.75, 0.2]} rotation={[-2.14, -0.19, 0.94]} scale={0.02} />
          <group name="WGT-rig_f_ring02R" position={[-0.4, 0.75, 0.2]} rotation={[-2.14, 0.19, -0.94]} scale={0.02} />
          <group name="WGT-rig_f_ring03L" position={[0.41, 0.73, 0.21]} rotation={[-2.06, -0.14, 0.95]} scale={0.02} />
          <group name="WGT-rig_f_ring03R" position={[-0.41, 0.73, 0.21]} rotation={[-2.06, 0.14, -0.95]} scale={0.02} />
          <group name="WGT-rig_foot_fkL" position={[0.15, 0.06, 0.01]} rotation={[-2.55, 0, 0]} scale={0.1} />
          <group name="WGT-rig_foot_fkR" position={[-0.15, 0.06, 0.01]} rotation={[-2.55, 0, 0]} scale={0.1} />
          <group name="WGT-rig_foot_heel_ikL" position={[0.15, 0.06, 0.01]} scale={0.05} />
          <group name="WGT-rig_foot_heel_ikR" position={[-0.15, 0.06, 0.01]} scale={0.05} />
          <group name="WGT-rig_foot_ikL" position={[0.15, 0.06, 0.01]} scale={0.1} />
          <group name="WGT-rig_foot_ikR" position={[-0.15, 0.06, 0.01]} scale={0.1} />
          <group name="WGT-rig_foot_spin_ikL" position={[0.15, 0.01, 0.1]} scale={0.05} />
          <group name="WGT-rig_foot_spin_ikR" position={[-0.15, 0.01, 0.1]} scale={0.05} />
          <group name="WGT-rig_foot_tweakL" position={[0.15, 0.06, 0.01]} rotation={[-2.55, 0, 0]} scale={0.03} />
          <group name="WGT-rig_foot_tweakR" position={[-0.15, 0.06, 0.01]} rotation={[-2.55, 0, 0]} scale={0.03} />
          <group name="WGT-rig_forearm_fkL" position={[0.25, 0.92, 0.01]} rotation={[-2.49, -0.55, 2.72]} scale={0.19} />
          <group name="WGT-rig_forearm_fkR" position={[-0.25, 0.92, 0.01]} rotation={[-2.49, 0.55, -2.72]} scale={0.19} />
          <group name="WGT-rig_forearm_tweakL" position={[0.25, 0.92, 0.01]} rotation={[-2.49, -0.55, 2.72]} scale={0.05} />
          <group name="WGT-rig_forearm_tweakL001" position={[0.3, 0.86, 0.07]} rotation={[-2.49, -0.55, 2.72]} scale={0.05} />
          <group name="WGT-rig_forearm_tweakR" position={[-0.25, 0.92, 0.01]} rotation={[-2.49, 0.55, -2.72]} scale={0.05} />
          <group name="WGT-rig_forearm_tweakR001" position={[-0.3, 0.86, 0.07]} rotation={[-2.49, 0.55, -2.72]} scale={0.05} />
          <group name="WGT-rig_hand_fkL" position={[0.35, 0.81, 0.14]} rotation={[-2.51, -0.45, 2.71]} scale={0.05} />
          <group name="WGT-rig_hand_fkR" position={[-0.35, 0.81, 0.14]} rotation={[-2.51, 0.45, -2.71]} scale={0.05} />
          <group name="WGT-rig_hand_ikL" position={[0.35, 0.81, 0.14]} rotation={[-2.51, -0.45, 2.71]} scale={0.05} />
          <group name="WGT-rig_hand_ikR" position={[-0.35, 0.81, 0.14]} rotation={[-2.51, 0.45, -2.71]} scale={0.05} />
          <group name="WGT-rig_hand_tweakL" position={[0.35, 0.81, 0.14]} rotation={[-2.51, -0.45, 2.71]} scale={0.01} />
          <group name="WGT-rig_hand_tweakR" position={[-0.35, 0.81, 0.14]} rotation={[-2.51, 0.45, -2.71]} scale={0.01} />
          <group name="WGT-rig_head" position={[0, 1.17, 0.04]} rotation={[1.61, 0, 0]} scale={0.17} />
          <group name="WGT-rig_hips" position={[0, 0.68, 0]} rotation={[1.75, 0, 0]} scale={0.11} />
          <group name="WGT-rig_jaw" position={[0, 1.17, 0.07]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_jawL" position={[0.05, 1.21, 0.06]} rotation={[Math.PI / 2, 0, -0.36]} scale={0.01} />
          <group name="WGT-rig_jawL001" position={[0.03, 1.18, 0.06]} rotation={[Math.PI / 2, 0, -0.48]} scale={0.01} />
          <group name="WGT-rig_jawR" position={[-0.05, 1.21, 0.06]} rotation={[Math.PI / 2, 0, 0.36]} scale={0.01} />
          <group name="WGT-rig_jawR001" position={[-0.03, 1.18, 0.06]} rotation={[Math.PI / 2, 0, 0.48]} scale={0.01} />
          <group name="WGT-rig_jaw_master" position={[0, 1.21, 0.06]} rotation={[-2.5, 0, 0]} scale={0.07} />
          <group name="WGT-rig_lidBL" position={[0.01, 1.25, 0.11]} rotation={[Math.PI / 2, 0, -0.08]} scale={0.01} />
          <group name="WGT-rig_lidBL001" position={[0.02, 1.24, 0.11]} rotation={[Math.PI / 2, 0, -0.1]} scale={0.01} />
          <group name="WGT-rig_lidBL002" position={[0.03, 1.24, 0.11]} rotation={[Math.PI / 2, 0, 0.01]} scale={0.01} />
          <group name="WGT-rig_lidBL003" position={[0.04, 1.24, 0.1]} rotation={[Math.PI / 2, 0, 0.13]} scale={0.01} />
          <group name="WGT-rig_lidBR" position={[-0.01, 1.25, 0.11]} rotation={[Math.PI / 2, 0, 0.08]} scale={0.01} />
          <group name="WGT-rig_lidBR001" position={[-0.02, 1.24, 0.11]} rotation={[Math.PI / 2, 0, 0.1]} scale={0.01} />
          <group name="WGT-rig_lidBR002" position={[-0.03, 1.24, 0.11]} rotation={[Math.PI / 2, 0, -0.01]} scale={0.01} />
          <group name="WGT-rig_lidBR003" position={[-0.04, 1.24, 0.1]} rotation={[Math.PI / 2, 0, -0.13]} scale={0.01} />
          <group name="WGT-rig_lidTL" position={[0.04, 1.25, 0.1]} rotation={[Math.PI / 2, 0, -1.29]} scale={0.01} />
          <group name="WGT-rig_lidTL001" position={[0.04, 1.25, 0.11]} rotation={[Math.PI / 2, 0, -0.18]} scale={0.01} />
          <group name="WGT-rig_lidTL002" position={[0.03, 1.25, 0.11]} rotation={[Math.PI / 2, 0, -0.04]} scale={0.01} />
          <group name="WGT-rig_lidTL003" position={[0.02, 1.25, 0.11]} rotation={[Math.PI / 2, 0, -0.1]} scale={0.01} />
          <group name="WGT-rig_lidTR" position={[-0.04, 1.25, 0.1]} rotation={[Math.PI / 2, 0, 1.29]} scale={0.01} />
          <group name="WGT-rig_lidTR001" position={[-0.04, 1.25, 0.11]} rotation={[Math.PI / 2, 0, 0.18]} scale={0.01} />
          <group name="WGT-rig_lidTR002" position={[-0.03, 1.25, 0.11]} rotation={[Math.PI / 2, 0, 0.04]} scale={0.01} />
          <group name="WGT-rig_lidTR003" position={[-0.02, 1.25, 0.11]} rotation={[Math.PI / 2, 0, 0.1]} scale={0.01} />
          <group name="WGT-rig_lipB" position={[0, 1.19, 0.12]} rotation={[Math.PI / 2, 0, -0.02]} scale={0.01} />
          <group name="WGT-rig_lipBL001" position={[0.01, 1.19, 0.12]} rotation={[Math.PI / 2, 0, -0.03]} scale={0.01} />
          <group name="WGT-rig_lipBR001" position={[-0.01, 1.19, 0.12]} rotation={[Math.PI / 2, 0, 0.03]} scale={0.01} />
          <group name="WGT-rig_lipT" position={[0, 1.2, 0.12]} rotation={[Math.PI / 2, 0, -0.11]} scale={0.01} />
          <group name="WGT-rig_lipTL001" position={[0.01, 1.2, 0.12]} rotation={[Math.PI / 2, 0, -0.03]} scale={0.01} />
          <group name="WGT-rig_lipTR001" position={[-0.01, 1.2, 0.12]} rotation={[Math.PI / 2, 0, 0.03]} scale={0.01} />
          <group name="WGT-rig_lipsL" position={[0.02, 1.19, 0.11]} rotation={[Math.PI / 2, 0, -0.03]} scale={0.01} />
          <group name="WGT-rig_lipsR" position={[-0.02, 1.19, 0.11]} rotation={[Math.PI / 2, 0, 0.03]} scale={0.01} />
          <group name="WGT-rig_master_eyeL" position={[0.03, 1.25, 0.09]} rotation={[Math.PI, -0.05, Math.PI]} scale={0.02} />
          <group name="WGT-rig_master_eyeR" position={[-0.03, 1.25, 0.09]} rotation={[-Math.PI, 0.05, -Math.PI]} scale={0.02} />
          <group name="WGT-rig_neck" position={[0, 1.11, 0.01]} rotation={[1.92, 0.04, -0.06]} scale={0.07} />
          <group name="WGT-rig_nose" position={[0, 1.25, 0.12]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_nose001" position={[0, 1.23, 0.13]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_nose002" position={[0, 1.22, 0.14]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_nose003" position={[0, 1.22, 0.13]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_nose004" position={[0, 1.22, 0.12]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_nose005" position={[0, 1.22, 0.12]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_noseL" position={[0.01, 1.24, 0.11]} rotation={[Math.PI / 2, 0, -0.03]} scale={0.01} />
          <group name="WGT-rig_noseL001" position={[0.01, 1.22, 0.12]} rotation={[Math.PI / 2, 0, -0.65]} scale={0.01} />
          <group name="WGT-rig_noseR" position={[-0.01, 1.24, 0.11]} rotation={[Math.PI / 2, 0, 0.03]} scale={0.01} />
          <group name="WGT-rig_noseR001" position={[-0.01, 1.22, 0.12]} rotation={[Math.PI / 2, 0, 0.65]} scale={0.01} />
          <group name="WGT-rig_nose_master" position={[0, 1.22, 0.12]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.02} />
          <group name="WGT-rig_palmL" position={[0.37, 0.79, 0.15]} rotation={[-2.26, -0.56, 0.94]} scale={0.04} />
          <group name="WGT-rig_palmR" position={[-0.37, 0.79, 0.15]} rotation={[-2.26, 0.56, -0.94]} scale={0.04} />
          <group name="WGT-rig_root" scale={0.7} />
          <group name="WGT-rig_shin_fkL" position={[0.12, 0.37, 0.06]} rotation={[-1.4, -0.12, -0.02]} scale={0.31} />
          <group name="WGT-rig_shin_fkR" position={[-0.12, 0.37, 0.06]} rotation={[-1.4, 0.12, 0.02]} scale={0.31} />
          <group name="WGT-rig_shin_tweakL" position={[0.12, 0.37, 0.06]} rotation={[-1.4, -0.12, -0.02]} scale={0.08} />
          <group name="WGT-rig_shin_tweakL001" position={[0.13, 0.22, 0.04]} rotation={[-1.4, -0.12, -0.02]} scale={0.08} />
          <group name="WGT-rig_shin_tweakR" position={[-0.12, 0.37, 0.06]} rotation={[-1.4, 0.12, 0.02]} scale={0.08} />
          <group name="WGT-rig_shin_tweakR001" position={[-0.13, 0.22, 0.04]} rotation={[-1.4, 0.12, 0.02]} scale={0.08} />
          <group name="WGT-rig_shoulderL" position={[0.02, 1.1, 0.05]} rotation={[-0.06, -1.2, -0.05]} scale={0.1} />
          <group name="WGT-rig_shoulderR" position={[-0.02, 1.1, 0.05]} rotation={[-0.06, 1.2, 0.05]} scale={0.1} />
          <group name="WGT-rig_spine_fk" position={[0, 0.79, 0.02]} rotation={[1.75, 0, 0]} scale={0.11} />
          <group name="WGT-rig_spine_fk001" position={[0, 0.88, 0.04]} rotation={[1.78, 0, 0]} scale={0.08} />
          <group name="WGT-rig_spine_fk002" position={[0, 0.88, 0.04]} rotation={[1.35, 0, 0]} scale={0.11} />
          <group name="WGT-rig_spine_fk003" position={[0, 0.98, 0.01]} rotation={[1.58, 0, 0]} scale={0.13} />
          <group name="WGT-rig_teethB" position={[0, 1.2, 0.11]} scale={0.03} />
          <group name="WGT-rig_teethT" position={[0, 1.22, 0.11]} rotation={[0.11, 0, 0]} scale={0.03} />
          <group name="WGT-rig_thigh_fkL" position={[0.08, 0.68, 0.03]} rotation={[-1.68, -0.11, -0.05]} scale={0.32} />
          <group name="WGT-rig_thigh_fkR" position={[-0.08, 0.68, 0.03]} rotation={[-1.68, 0.11, 0.05]} scale={0.32} />
          <group name="WGT-rig_thigh_ikL" position={[0.08, 0.68, 0.03]} rotation={[-1.68, -0.11, -0.05]} scale={0.32} />
          <group name="WGT-rig_thigh_ikR" position={[-0.08, 0.68, 0.03]} rotation={[-1.68, 0.11, 0.05]} scale={0.32} />
          <group name="WGT-rig_thigh_ik_targetL" position={[0.1, 0.35, 0.69]} rotation={[0.03, -0.03, 0]} scale={0.08} />
          <group name="WGT-rig_thigh_ik_targetR" position={[-0.1, 0.35, 0.69]} rotation={[0.03, 0.03, 0]} scale={0.08} />
          <group name="WGT-rig_thigh_parentL" position={[0.08, 0.68, 0.03]} rotation={[-1.68, -0.11, -0.12]} scale={0.08} />
          <group name="WGT-rig_thigh_parentR" position={[-0.08, 0.68, 0.03]} rotation={[-1.68, 0.11, 0.12]} scale={0.08} />
          <group name="WGT-rig_thigh_tweakL" position={[0.08, 0.68, 0.03]} rotation={[-1.68, -0.11, -0.05]} scale={0.08} />
          <group name="WGT-rig_thigh_tweakL001" position={[0.1, 0.53, 0.05]} rotation={[-1.68, -0.11, -0.05]} scale={0.08} />
          <group name="WGT-rig_thigh_tweakR" position={[-0.08, 0.68, 0.03]} rotation={[-1.68, 0.11, 0.05]} scale={0.08} />
          <group name="WGT-rig_thigh_tweakR001" position={[-0.1, 0.53, 0.05]} rotation={[-1.68, 0.11, 0.05]} scale={0.08} />
          <group name="WGT-rig_thumb01L" position={[0.34, 0.81, 0.17]} rotation={[-2.8, 0.26, 0.2]} scale={0.02} />
          <group name="WGT-rig_thumb01L001" position={[0.32, 0.78, 0.23]} rotation={[0.71, -0.29, -0.55]} scale={0.01} />
          <group name="WGT-rig_thumb01R" position={[-0.34, 0.81, 0.17]} rotation={[-2.8, -0.26, -0.2]} scale={0.02} />
          <group name="WGT-rig_thumb01R001" position={[-0.32, 0.78, 0.23]} rotation={[0.71, 0.29, 0.55]} scale={0.01} />
          <group name="WGT-rig_thumb01_masterL" position={[0.34, 0.81, 0.17]} rotation={[-2.67, 0.28, -0.03]} scale={0.12} />
          <group name="WGT-rig_thumb01_masterR" position={[-0.34, 0.81, 0.17]} rotation={[-2.67, -0.28, 0.03]} scale={0.12} />
          <group name="WGT-rig_thumb02L" position={[0.34, 0.81, 0.19]} rotation={[-2.67, 0.3, 0.17]} scale={0.03} />
          <group name="WGT-rig_thumb02R" position={[-0.34, 0.81, 0.19]} rotation={[-2.67, -0.3, -0.17]} scale={0.03} />
          <group name="WGT-rig_thumb03L" position={[0.33, 0.79, 0.21]} rotation={[-2.43, 0.29, 0.09]} scale={0.02} />
          <group name="WGT-rig_thumb03R" position={[-0.33, 0.79, 0.21]} rotation={[-2.43, -0.29, -0.09]} scale={0.02} />
          <group name="WGT-rig_toeL" position={[0.15, 0.01, 0.1]} rotation={[-3.02, 0, -3.14]} scale={0.05} />
          <group name="WGT-rig_toeR" position={[-0.15, 0.01, 0.1]} rotation={[-3.02, 0, -3.14]} scale={0.05} />
          <group name="WGT-rig_tongue" position={[0, 1.2, 0.1]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_tongue001" position={[0, 1.2, 0.09]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_tongue002" position={[0, 1.2, 0.07]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_tongue003" position={[0, 1.2, 0.04]} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <group name="WGT-rig_tongue_master" position={[0, 1.2, 0.09]} rotation={[-2.92, 0, 0]} scale={0.02} />
          <group name="WGT-rig_torso" position={[0, 0.74, 0.01]} scale={0.26} />
          <group name="WGT-rig_tweak_spine" position={[0, 0.68, 0]} rotation={[1.75, 0, 0]} scale={0.06} />
          <group name="WGT-rig_tweak_spine001" position={[0, 0.79, 0.02]} rotation={[1.78, 0, 0]} scale={0.04} />
          <group name="WGT-rig_tweak_spine002" position={[0, 0.88, 0.04]} rotation={[1.35, 0, 0]} scale={0.06} />
          <group name="WGT-rig_tweak_spine003" position={[0, 0.98, 0.01]} rotation={[1.58, 0, 0]} scale={0.06} />
          <group name="WGT-rig_tweak_spine004" position={[0, 1.11, 0.01]} rotation={[1.9, 0, 0]} scale={0.02} />
          <group name="WGT-rig_tweak_spine005" position={[0, 1.15, 0.02]} rotation={[1.94, 0.09, 0]} scale={0.02} />
          <group name="WGT-rig_upper_arm_fkL" position={[0.13, 1.07, 0.02]} rotation={[-1.52, -0.66, -2.98]} scale={0.19} />
          <group name="WGT-rig_upper_arm_fkR" position={[-0.13, 1.07, 0.02]} rotation={[-1.52, 0.66, 2.98]} scale={0.19} />
          <group name="WGT-rig_upper_arm_ikL" position={[0.13, 1.07, 0.02]} rotation={[-1.52, -0.66, -2.98]} scale={0.19} />
          <group name="WGT-rig_upper_arm_ikR" position={[-0.13, 1.07, 0.02]} rotation={[-1.52, 0.66, 2.98]} scale={0.19} />
          <group name="WGT-rig_upper_arm_ik_targetL" position={[0.29, 0.79, -0.32]} rotation={[2.78, 0.12, -0.64]} scale={0.04} />
          <group name="WGT-rig_upper_arm_ik_targetR" position={[-0.29, 0.79, -0.32]} rotation={[2.78, -0.12, 0.64]} scale={0.04} />
          <group name="WGT-rig_upper_arm_parentL" position={[0.13, 1.07, 0.02]} rotation={[-1.52, -0.66, -0.63]} scale={0.05} />
          <group name="WGT-rig_upper_arm_parentR" position={[-0.13, 1.07, 0.02]} rotation={[-1.52, 0.66, 0.63]} scale={0.05} />
          <group name="WGT-rig_upper_arm_tweakL" position={[0.13, 1.07, 0.02]} rotation={[-1.52, -0.66, -2.98]} scale={0.05} />
          <group name="WGT-rig_upper_arm_tweakL001" position={[0.19, 0.99, 0.01]} rotation={[-1.52, -0.66, -2.98]} scale={0.05} />
          <group name="WGT-rig_upper_arm_tweakR" position={[-0.13, 1.07, 0.02]} rotation={[-1.52, 0.66, 2.98]} scale={0.05} />
          <group name="WGT-rig_upper_arm_tweakR001" position={[-0.19, 0.99, 0.01]} rotation={[-1.52, 0.66, 2.98]} scale={0.05} />
          <group name="WGT-rig_VIS_thigh_ik_poleL" position={[0.12, 0.37, 0.06]} rotation={[Math.PI / 2, 0, 0.08]} scale={0.62} />
          <group name="WGT-rig_VIS_thigh_ik_poleR" position={[-0.12, 0.37, 0.06]} rotation={[Math.PI / 2, 0, -0.08]} scale={0.62} />
          <group name="WGT-rig_VIS_upper_arm_ik_poleL" position={[0.25, 0.92, 0.01]} rotation={[Math.PI / 2, 0, -2.18]} scale={0.36} />
          <group name="WGT-rig_VIS_upper_arm_ik_poleR" position={[-0.25, 0.92, 0.01]} rotation={[Math.PI / 2, 0, 2.18]} scale={0.36} />
        </group>
      </group>
    </group>
  )
}

// useGLTF.preload('/girl_22022022.glb')
