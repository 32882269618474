import TextField from '@material-ui/core/TextField'
import BtnEndIcon from '@material-ui/icons/KeyboardArrowRight'
import React from 'react'
import { useStyles } from './_Styles'
import { useAsyncFn } from 'react-use'
import chatApi from '../../../api/chatApi'
import { useHistory } from 'react-router-dom'
import ButtonWithProgress from '../../common/ButtonWithProgress'

export default function RegisterInfoForm() {
  const classes = useStyles()
  const hasError = false
  const history = useHistory()

  const [{ loading }, request] = useAsyncFn(() => {
    return chatApi
      .updateUserProfile('name', 'lastName', 'phone')
      .then(res => {
        history.replace('/chat')
      })
      .catch(err => console.log(err))
  })

  const onSubmit = async (event: React.FormEvent<any>) => {
    event.preventDefault()
    await request()
  }

  return (
    <div className={classes.paper}>
      <form className={classes.form} onSubmit={onSubmit}>
        <TextField
          error={hasError}
          disabled={loading}
          label='Имя'
          className={classes.input}
          helperText={hasError ? 'Имя должно иметь более 2х символов' : ''}
          fullWidth
        />
        <TextField
          disabled={loading}
          error={hasError}
          label='Фамилия'
          className={classes.input}
          helperText={hasError ? 'Фамилия должна иметь  более 1 символа' : ''}
          fullWidth
        />
        {/*TODO: please define the phone validation properly*/}
        <TextField
          disabled={loading}
          error={hasError}
          type='tel'
          label='Номер телефона'
          className={classes.input}
          helperText={hasError ? 'Неверно введен номер телефона' : ''}
          fullWidth
        />
        <div className={classes.submitWrapper}>
          <ButtonWithProgress
            disabled={loading}
            loading={loading}
            variant='contained'
            color='primary'
            type='submit'
            endIcon={<BtnEndIcon />}
          >
            СОХРАНИТЬ
          </ButtonWithProgress>
        </div>
      </form>
    </div>
  )
}
