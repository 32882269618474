import React, { ReactElement } from 'react'
import Slot from '../../common/Slots/Slot'
import DiagnosisResultItem from './DiagnosisResultItem'

export default function DiagnosisResultSlot(): ReactElement {
  return (
    <Slot title='Результаты диагноза' color='primary'>
      <DiagnosisResultItem
        succsess={true}
        subTitle='Основной диагноз'
        title='Множественная эндокринная неоплазия типа IIb'
      />
      <DiagnosisResultItem
        succsess={false}
        subTitle='Дополнительный диагноз'
        title='Пневмания'
      />
      <DiagnosisResultItem
        succsess={false}
        subTitle='Сопутствующий'
        title='Инфаркт'
      />
    </Slot>
  )
}
