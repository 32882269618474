import { Box, Container, Hidden } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import React, { ReactNode } from 'react'
import { AUTH_MENU, NavbarHeight } from '../../constants/default'
import { pxToRem, pxToRemGutter } from '../../utils'
import TopNavBar from '../common/TopNavBar'

const useStyles = makeStyles(theme => ({
  root: {
    background: '#fafafa',
    minHeight: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      // padding: 0,
      // margin: 0,
      // width: '100%',
      height: '100%',
      minHeight: '100%',
      paddingTop: NavbarHeight
    }
  },
  card: {
    borderRadius: pxToRem(30),
    minHeight: pxToRem(600),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    padding: 0,
    maxWidth: pxToRem(650),
    boxShadow: '0px 5px 35px rgba(36, 44, 59, 0.35)',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      padding: 0,
      margin: 0,
      boxShadow: 'none',
      borderRadius: 0,
      maxWidth: '100%',
      width: '100%',
      height: '100%',
      minHeight: '100%'
    }
  },
  contentBox: {
    flex: 1,
    width: '100%',
    borderRadius: pxToRemGutter(0, 30, 30, 0),
    padding: pxToRem(60),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      borderRadius: 0,
      width: '100%',
      minHeight: '100%',
      height: 'auto',
      padding: '1.2rem'
    }
  },
  glowingBorder: {
    // animation: '$glowing 3000ms infinite',
  },
  '@keyframes glowing': {
    // '0%': { boxShadow: '0 0 5px #9ecaed' },
    // '50%': { boxShadow: '0 0 5px #9ecaed' },
    // '100%': { boxShadow: '0 0 5px #9ecaed' },
  },
}))

type Props = {
  children: ReactNode
}

export default function AuthLayout(props: Props) {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <CssBaseline />
      <Container component='main' className={`${classes.card} ${classes.glowingBorder}`}>
        {/* <Hidden xsDown>
          <AuthSideBox />
        </Hidden> */}
        <Hidden smUp>
          <TopNavBar menuItems={AUTH_MENU} />
        </Hidden>
        <Box className={classes.contentBox}>{props.children}</Box>
      </Container>
    </Box>
  )
}
