import React from 'react'
import SlotResearchTable from './SlotResearchTable'

function createData(name: string, val: number, units: string, norma: number) {
  return { name, val, units, norma }
}

const rows = [
  createData('Гемоглобин', 6.0, 'г./с.кв.', 4.0),
  createData('Эритроциты', 9.0, 'г./с.кв.', 4.3),
  createData('Лейкоциты', 16.0, 'г./с.кв.', 6.0)
]

export default function BloodTableBlock() {
  return (
    <SlotResearchTable rows={rows} title='Кровь. Лабораторные исследования' />
  )
}
