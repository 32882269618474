import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DiagnosisTypes_ResponseData } from '../../api/chatApi/chatApi';
import { AsyncState } from '../../common/Utils';
import { RootState } from '../../store';
import { SagaActionType } from '../../store/sagas/sagaActions';

type HookType = () => AsyncState<Array<DiagnosisTypes_ResponseData>> | undefined;

const useDiagnosisTypes: HookType = () => {
  const dispatch = useDispatch();

  const diagnosisTypesAsyncState = useSelector<RootState, AsyncState<Array<DiagnosisTypes_ResponseData>> | undefined>(state => state.chat.diagnosisTypes);

  useEffect(() => {
    if (diagnosisTypesAsyncState === undefined) {
      dispatch({ type: SagaActionType.START_LOAD_DIAGNOSIS_TYPES });
    }
  }, [dispatch, diagnosisTypesAsyncState]);

  return diagnosisTypesAsyncState;
};

export default useDiagnosisTypes;
