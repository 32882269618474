import React, { useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'


export default function NeuroWomNTrue(props) {
  const { nodes, materials, animations } = useGLTF('patient/Neurologia_wom_normostenik.glb')
  const { ref, actions, names } = useAnimations(animations)



  useEffect(() => {
    props.setAnim(animations)
    if(actions && names) {
      actions[names[props.index]].reset().fadeIn(0.5).play()
      return () => actions[names[props.index]].fadeOut(0.5)
    }
  }, [props.index, actions, names])

  return (
    <group ref={ref} {...props} dispose={null}>
      <primitive object={nodes['MCH-torsoparent']} />
      <primitive object={nodes.root} />
      <skinnedMesh
        geometry={nodes.EYES_TONGUE_TEETH001.geometry}
        material={materials['Eye_brown.001']}
        skeleton={nodes.EYES_TONGUE_TEETH001.skeleton}
      />
      <skinnedMesh
        geometry={nodes.EYES_TONGUE_TEETH001_1.geometry}
        material={materials['bodyMaterial.001']}
        skeleton={nodes.EYES_TONGUE_TEETH001_1.skeleton}
      />
      {/* <skinnedMesh
        geometry={nodes.EYES_TONGUE_TEETH001_2.geometry}
        material={materials['Material.003']}
        skeleton={nodes.EYES_TONGUE_TEETH001_2.skeleton}
      /> */}
      <skinnedMesh
        geometry={nodes.hairs_bob1.geometry}
        material={materials['Material.002']}
        skeleton={nodes.hairs_bob1.skeleton}
      />
      <skinnedMesh
        geometry={nodes.EYES_TONGUE_TEETH.geometry}
        material={materials['teethMaterial.001']}
        skeleton={nodes.EYES_TONGUE_TEETH.skeleton}
      />
      <skinnedMesh
        geometry={nodes.EYES_TONGUE_TEETH_1.geometry}
        material={materials['tongue01Material.001']}
        skeleton={nodes.EYES_TONGUE_TEETH_1.skeleton}
      />
      <skinnedMesh
        geometry={nodes.woman_50_years_mezomorph2_1.geometry}
        material={materials.middleage_caucasian_female}
        skeleton={nodes.woman_50_years_mezomorph2_1.skeleton}
      />
      <skinnedMesh
        geometry={nodes.woman_50_years_mezomorph2_2.geometry}
        material={materials.FABRIC_1_FRONT_2066}
        skeleton={nodes.woman_50_years_mezomorph2_2.skeleton}
      />
      <skinnedMesh
        geometry={nodes.woman_50_years_mezomorph2_3.geometry}
        material={materials.FABRIC_1_FRONT_2059}
        skeleton={nodes.woman_50_years_mezomorph2_3.skeleton}
      />
      <mesh geometry={nodes.Cube_1.geometry} material={materials.couchLeg_black} />
      <mesh geometry={nodes.Cube_2.geometry} material={materials.couchLeg_white} />
    </group>
  )
}

// useGLTF.preload('/Neurologia_wom_normostenik.glb')
