import React from 'react';
import { Checkbox } from '@carbon/react';

interface User {
  userId: number
  jhi_user_id: number
}

type Props = {
  user: User
  isCognitiveLoad: boolean
  isStressLoad: boolean
  changeStressCheckHandler: () => void;
  changeCognitiveCheckHandler: () => void
}


export default function ModesSettings({
  user,
  isCognitiveLoad,
  changeCognitiveCheckHandler,
  isStressLoad,
  changeStressCheckHandler
}: Props) {

  return (
    <div style={{ marginBottom: 20 }}>
      {/* <div>
        <Checkbox checked={isCognitiveLoad} onChange={changeCognitiveCheckHandler} labelText={`Включить прохождение когнитивного теста`} id="checkbox-label-1" />
      </div> */}

      <div>
        <Checkbox checked={isStressLoad} onChange={changeStressCheckHandler} labelText={`Включить прохождение теста стрессовой нагрузки`} id="checkbox-label-2" />
      </div>
    </div>
  )
}
