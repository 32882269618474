import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  CHAT_MENU_ITEMS,
  CHAT_MENU_ITEMS_LIGHT,
  CHAT_MENU_ITEMS_LIGHT_NO,
  CHAT_MENU_ITEMS_LIGHT_SHORT,
  CHAT_MENU_ITEMS_NO,
  CHAT_MENU_ITEMS_LIGHT_NO_QUESTION
} from '../../../constants/default';
import { MenuItemData } from '../../../types';
import chatApi from '../../../api/chatApi';
import { bodyGetTest, getTestingstageTaskOnTask } from '../../../api/chatApi/chatApi';
import LevelPage from './LevelPage';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '15px 15px 5px'
    }
  })
);

interface answersInterface {
  quest: string;
  uid: string;
  answers: answers[];
}

interface answers {
  answer: string;
  uid: string;
}

interface Props {
  menuItems?: Array<MenuItemData>;
  menuItemLight?: Array<MenuItemData>;
  menuItemNo?: Array<MenuItemData>;
  menuItemLightNo?: Array<MenuItemData>;
  menuItemLightShort?: Array<MenuItemData>;
  menuItemLightNoQuestion?: Array<MenuItemData>;
}

export default function SidebarSteps({
  menuItems = CHAT_MENU_ITEMS,
  menuItemLight = CHAT_MENU_ITEMS_LIGHT,
  menuItemNo = CHAT_MENU_ITEMS_NO,
  menuItemLightNo = CHAT_MENU_ITEMS_LIGHT_NO,
  menuItemLightShort = CHAT_MENU_ITEMS_LIGHT_SHORT,
  menuItemLightNoQuestion = CHAT_MENU_ITEMS_LIGHT_NO_QUESTION
}: Props): ReactElement {
  const classes = useStyles();

  const [patientStatusCode, setPatientStatusCode] = useState<number>();
  const [level, setLevel] = useState<string>();
  const [questions, setQuestions] = useState<answersInterface[]>([]);
  const [medicineId, setMedicineId] = useState<number>(0);
  const [nameStagesOnTaskResult, setNameStagesOnTaskResult] = useState<any[]>([]);
  const [allStages, setAllStages] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  async function unfinishedTask() {
    try {
      const response = await chatApi.unfinishedTask();
      setMedicineId(response.result.task.medicineId);
      const body: bodyGetTest = {
        task_id: response.result.task.id,
        stage_number: 7
      };
      await getTest(body);
      setLevel(response.result.task.typeOfDifficultLevel.name);
      setPatientStatusCode(response.result.task.patientStatus.id);
    } catch (err) {
      console.log(`${err}`);
    }
  }

  async function getSteps() {
    try {
      const stagesOnTaskResponse = await getTestingstageTaskOnTask();
      const listOfStagesResponse = await chatApi.getListOfStages();

      setAllStages(listOfStagesResponse.result);

      // Очистка routePath от лишних пробелов - потом можно убрать
      const cleanedStagesOnTask = stagesOnTaskResponse?.data?.testingstage_task_on_task?.map(taskStage => ({
        ...taskStage,
        routePath: taskStage.routePath.trim()
      }));

      const nameStagesOnTask = cleanedStagesOnTask?.filter(stage =>
        listOfStagesResponse?.result?.some(taskStage => taskStage?.id === stage?.testingstage_id)
      ).map(stage => ({
        ...stage,
        routePath: cleanedStagesOnTask?.find(taskStage => taskStage?.testingstage_id === stage?.testingstage_id)?.routePath
      }));
      return nameStagesOnTask;
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    unfinishedTask();
    getSteps().then(result => {
      setNameStagesOnTaskResult(result);
      setIsLoading(false);
    });
  }, []);

  async function getTest(body: bodyGetTest) {
    const response: any = await chatApi.getTest(body);
    setQuestions(response.result);
    return response.result;
  }

  if (isLoading) {
    return <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50%' }}>
      <CircularProgress />
    </Box>;
  }

  if (nameStagesOnTaskResult && nameStagesOnTaskResult.length > 0) {
    return (
      <div className={classes.root}>
        <LevelPage nameStagesOnTask={nameStagesOnTaskResult} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {questions === null || questions.length === 0 || !questions ? (
        level === 'Высокий' || level === 'Средний' ? (
          <LevelPage menuItems={menuItemNo} />
        ) : (medicineId === 14 || medicineId === 15 ? <LevelPage menuItems={menuItemLightShort} />
          : <LevelPage menuItems={menuItemLightNo} />
        )) : medicineId === 14 || medicineId === 15 ? <LevelPage menuItems={menuItemLight} />
        : level === 'Высокий' || level === 'Средний' ? (
          <LevelPage menuItems={menuItems} />)
          : (<LevelPage menuItems={menuItemLightNoQuestion} />)}
    </div>
  );
}
