export enum ThemeTypeCode {
  light,
  dark,
}

export type LocalSettingsState = {
  theme: ThemeTypeCode;
  settingsKeyValue: { [key: string]: any | undefined };
};

export enum LocalSettingsAction {
  SET_THEME = 'SET_THEME',
  SET_SETTINGS_KEY_VALUE = 'SET_SETTINGS_KEY_VALUE',
}

export interface LocalSettingsSetThemeAction {
  type: typeof LocalSettingsAction.SET_THEME;
  data: ThemeTypeCode;
}

export interface SetSkipNewTaskTutorialAction {
  type: typeof LocalSettingsAction.SET_SETTINGS_KEY_VALUE;
  data: { [key: string]: any | undefined };
}

export type LocalSettingsActionTypes = LocalSettingsSetThemeAction | SetSkipNewTaskTutorialAction;

const initState: LocalSettingsState = { theme: ThemeTypeCode.light, settingsKeyValue: {} };

export function localSettingsReducer(state = initState, action: LocalSettingsActionTypes): LocalSettingsState {
  switch (action.type) {
    case LocalSettingsAction.SET_THEME:
      return { ...state, theme: action.data };
    case LocalSettingsAction.SET_SETTINGS_KEY_VALUE:
      return { ...state, settingsKeyValue: { ...action.data } };
    default:
      return state;
  }
}
