import { makeStyles, createStyles } from '@material-ui/core'
import React, { useState } from 'react'
import MainFooter from '../../../components/MainFooter/MainFooter'
import MainHeader from '../../../components/MainHeader/MainHeader'
import ChampItemNew from './champItemNew'
import ChampStages from './champStages'

const useStyles = makeStyles(() =>
  createStyles({
    wrapperList: {
      margin: '0 24px'
    },
    filter: {
      marginTop: 64
    },
    title: {
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 24,
      color: '#000000',
      marginBottom: 24
    }
  })
)

const stage = {
  id: 1,
  date: '',
  description: '',
  task: ''
}

const ChampionshipsNew = () => {
  const style = useStyles()
  const [nameChamp, setNameChamp] = useState<string>('')
  const [descriptionChamp, setDescriptionChamp] = useState<string>('')
  const [select1, setSelect1] = useState<any>(null)
  const [select2, setSelect2] = useState<any>(null)

  const [stages, setStages] = useState<any>([stage])

  const createStages = newStage => {
    setStages([...stages, newStage])
  }

  const removeStages = index => {
    setStages(stages.filter((_, i) => i !== index))
  }
  return (
    <>
      <MainHeader />
      <div style={{ margin: '67px 24px 0px 24px' }}>
        <div className={style.title}>Создание чемпионата</div>
        <ChampItemNew
          nameChamp={nameChamp}
          setNameChamp={setNameChamp}
          descriptionChamp={descriptionChamp}
          setDescriptionChamp={setDescriptionChamp}
          select1={select1}
          setSelect1={setSelect1}
          select2={select2}
          setSelect2={setSelect2}
        />
        <ChampStages
          data={stages}
          setStages={setStages}
          create={createStages}
          remove={removeStages}
        />
      </div>
      <MainFooter />
    </>
  )
}

export default ChampionshipsNew
