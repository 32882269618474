import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faTwitter,
  faVk
} from '@fortawesome/free-brands-svg-icons'
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { pxToRem } from '../../utils'

const buttons = [
  {
    icon: faVk,
    color: '#4D76A1'
  },
  {
    icon: faFacebookF,
    color: '#3B5998'
  },
  {
    icon: faTwitter,
    color: '#55ACEE'
  }
]

const useStyles = makeStyles(theme => ({
  wrapper: {
    textAlign: 'center',
    '& > span': {
      color: 'white',
      marginTop: pxToRem(32),
      marginRight: pxToRem(16),
      height: pxToRem(40),
      width: pxToRem(40),
      padding: pxToRem(5),
      '&:last-of-type': {
        marginRight: 0
      }
    }
  }
}))

export default function SocialButtonsPanel() {
  const styles = useStyles()

  return (
    <div className={styles.wrapper}>
      {/* {buttons.map(({ icon, color }) => (
        <IconButton
          aria-label='upload picture'
          component='span'
          key={`social_btn_${color}`}
          style={{ background: color }}
        >
          <FontAwesomeIcon icon={icon} />
        </IconButton>
      ))} */}
    </div>
  )
}
