import {
  createStyles,
  List,
  ListItem,
  ListItemSecondaryAction,
  makeStyles,
  Typography
} from '@material-ui/core'
import React, { ReactElement } from 'react'
import { iFinalResItems } from '../../../pages/demo/demo-result/DemoResultPage'
import Slot from '../../common/Slots/Slot'
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'

const useStyles = makeStyles(theme =>
  createStyles({
    flex: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 16
    },
    leftBox: {
      // background: theme.palette.background.paper,

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: (p: Props) =>
        p.finalResults.success
          ? theme.palette.success.main
          : theme.palette.error.main,
      textAlign: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      fontFamily: 'Helvetica Neue'
    },
    icon: {
      fontSize: 96,
      marginBottom: theme.spacing(1)
    },
    hugePoints: {
      fontSize: 38,
      lineHeight: 1,
      fontWeight: 600
    },
    pointItems: {
      fontSize: 18,
      fontWeight: 600,
      textTransform: 'uppercase'
    },
    list: {
      width: '100%',
      textTransform: 'uppercase'
    },
    listItem: {
      // background: 'green',
      // paddingTop: theme.spacing(0.25),
      // paddingBottom: theme.spacing(0.25),
      // padding: theme.spacing(1),
    },
    item: {
      // background: 'yellow',
      paddingRight: theme.spacing(5)
    },
    text: {
      color: theme.palette.text.secondary,
      '& > span': { color: theme.palette.error.main }
    },
    correct: { color: theme.palette.success.main },
    wrong: { color: theme.palette.error.main },
    points: { fontWeight: 400 }
  })
)

interface Props {
  title?: string
  finalResults: iFinalResItems
}

export default function FinalResultSlot({ finalResults }: Props): ReactElement {
  const classes = useStyles({ finalResults } as Props)
  const cardTitleColor = finalResults.success ? 'success' : 'attention'
  const title = finalResults.success ? 'Успешно' : 'Неудача'
  return (
    <Slot title={title} color={cardTitleColor}>
      <div className={classes.flex}>
        <div className={classes.leftBox}>
          {finalResults.success ? (
            <InsertEmoticonIcon className={classes.icon} />
          ) : (
            <SentimentVeryDissatisfiedIcon className={classes.icon} />
          )}

          <div className={classes.hugePoints}>{finalResults.points}</div>
          <div className={classes.pointItems}>баллов</div>
        </div>
        <div>
          <List className={classes.list} dense>
            {finalResults.details.map((item, i) => (
              <ListItem key={i} className={classes.listItem}>
                <div className={classes.item}>
                  <Typography variant='subtitle1'>{item.title}</Typography>
                </div>

                <ListItemSecondaryAction>
                  <div
                    className={
                      item.points > 0 ? classes.correct : classes.wrong
                    }
                  >
                    <Typography variant='h5' className={classes.points}>
                      {item.points}
                    </Typography>
                  </div>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    </Slot>
  )
}
