import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { FunctionComponent } from 'react'
import { Button } from '@carbon/react';
import { useHistory } from 'react-router-dom';
import { RoutePath } from '../types';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.default,
    overflowY: 'hidden'
  }
}))

const NotFound404Page: FunctionComponent = () => {
  const history = useHistory()
  const styles = useStyles()
  return (
    <div className={styles.root}>
      <Typography variant='h4'>Такой страницы нет</Typography>
      <Button style={{marginTop: 15, padding: 20}} onClick={() => history.push(RoutePath.testRoot)}>Перейти на главную</Button>
    </div>
  )
}

export default NotFound404Page
