import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Action from "./Action";
import ModesSettings from './ModesSettings';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: 'flex',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: 20,
      fontWeight: 500,
      margin: '10px 0'
    },
    current: {
      minWidth: 350,
      padding: 20,
      boxShadow: '0px 0px 2px rgba(9, 36, 51, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.12)',
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginBottom: 50
    },
    name: {
      fontFamily: 'Helvetica Neue',
      fontWeight: 400,
      fontSize: 20,
      color: '#2196F3',
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      fontFamily: 'Helvetica Neue',
      fontWeight: 400,
    },
    actionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      '@media (max-width: 1525px)': {
        flexDirection: 'column',
      }
    },
    settingsWrapper: {
      width: '100%',
    }
  })
);

interface User {
  userId: number
  jhi_user_id: number
}

type Props = {
  user: User
  setOrg: (arg: string) => void
  setTrn: (arg: string) => void
  isCognitiveLoad: boolean
  isStressLoad: boolean
  changeStressCheckHandler: () => void
  changeCognitiveCheckHandler: () => void
  org: number
  trn: number
  option: number
  setOption: (arg: number) => void
  spec: []
  mySp: []
  setSwitchMy: boolean
  myOrg: []
  setError: boolean
  setSuccess: boolean
  teacher: [] | null
  myTeacher: [] | null
  trnProfile: [] | null
  orgProfile: [] | null
  myTrn: [] | null
  checked: boolean
  test: () => void
  typeProgram: string | null
}
export default function ProfileSettings({
  user,
  isCognitiveLoad,
  isStressLoad,
  changeStressCheckHandler,
  changeCognitiveCheckHandler,
  spec,
  mySp,
  setSwitchMy,
  myOrg,
  setError,
  setSuccess,
  teacher,
  myTeacher,
  trnProfile,
  orgProfile,
  myTrn,
  typeProgram
}: Props) {

  const classes = useRowStyles();


  return (
    <div className={classes.settingsWrapper}>
      <div className={classes.title}>
        Настройки приложения и аккаунта
      </div>
      <ModesSettings
        user={user}
        isStressLoad={isStressLoad}
        changeStressCheckHandler={changeStressCheckHandler}
        isCognitiveLoad={isCognitiveLoad}
        changeCognitiveCheckHandler={changeCognitiveCheckHandler}
      />
      {user[0] &&
        <div className={classes.actionsContainer}>
          <Action
            name={'spec'}
            data={spec}
            mySp={mySp}
            title={'Специализации'}
            id={user[0].jhi_user_id}
            switch1={setSwitchMy}
            error={setError}
            success={setSuccess}
            myTrn={undefined}
            myOrg={undefined}
            myTeacher={undefined}
          />

          <Action
            name={'org'}
            data={orgProfile}
            myOrg={myOrg}
            title={'Организации'}
            id={user[0].jhi_user_id}
            switch1={setSwitchMy}
            error={setError}
            success={setSuccess}
            myTrn={undefined}
            mySp={undefined}
            myTeacher={undefined}
          />

        {typeProgram === '0' &&
          <Action
            name={'trn'}
            data={trnProfile}
            myTrn={myTrn}
            title={'Тренажеры'}
            id={user[0].jhi_user_id}
            switch1={setSwitchMy}
            error={setError}
            success={setSuccess}
            myOrg={undefined}
            mySp={undefined}
            myTeacher={undefined}
          />
        }

          <Action
            name={'teacher'}
            data={teacher}
            myTeacher={myTeacher}
            title={'Преподаватели'}
            id={user[0].jhi_user_id}
            switch1={setSwitchMy}
            error={setError}
            success={setSuccess}
            myTrn={undefined}
            myOrg={undefined}
            mySp={undefined}
          />
        </div>
      }
    </div>
  )
}
