import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { AsyncState } from '../common/Utils';
import { Task } from '../api/DomainTypes';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { RoutePath } from '../types';
import { SagaActionType } from '../store/sagas/sagaActions';

const useCurrentTask = () => {
  const taskState = useSelector<RootState, AsyncState<Task> | undefined>(state => state.chat.currentTaskState);

  const dispatch = useDispatch();
  const history = useHistory();
  const taskExist = !!taskState?.value;

  useEffect(() => {
    if (!taskExist) dispatch({ type: SagaActionType.START_LOAD_TASK });
  }, [dispatch, taskExist]);

  useEffect(() => {
    if (taskState && taskState.error) {
      history.replace(RoutePath.newChat);
    }
  }, [history, taskState]);

  return taskState;
};

export default useCurrentTask;
