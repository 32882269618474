import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { FunctionComponent } from 'react'
import SlotTitles from './SlotTitles'

const useStyles = makeStyles(theme =>
  createStyles({
    card: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 20,
      border: 'none',
      margin: -7,
      // height: (p: Props) => (p.fillHeight ? '100%' : '')
    },
    titleWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    titleContainer: {
      display: 'flex',
      // height: 22,
      height: '30px',
      alignItems: 'center',
      borderRadius: ` 20px 20px 0 0`,
      minWidth: 240,
      textTransform: 'uppercase',
      fontSize: 14,
      fontWeight: 500,
      background: (p: Props) =>
        p.color === 'primary'
          ? theme.palette.primary.main
          : p.color === 'attention'
            ? theme.palette.error.main
            : p.color === 'success'
              ? theme.palette.success.main
              : p.color === 'info'
                ? theme.palette.primary.light
                : theme.palette.divider,
      color: (p: Props) =>
        p.color === 'info'
          ? theme.palette.text.primary
          : theme.palette.primary.contrastText,
      '& > div': { minWidth: 22 }
    },
    title: {
      textAlign: 'center',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      flex: 1
    },

    content: {
      padding: (p: Props) => (p.notPadded ? 0 : theme.spacing(2)),
      paddingTop: (p: Props) => (p.title ? theme.spacing(1) : theme.spacing(2))
    }
  })
)

interface Props {
  children?: any
  title?: string
  titles?: { title: string; color?: string }[]
  fillHeight?: boolean
  notPadded?: boolean
  primary?: boolean
  color?: 'primary' | 'secondary' | 'info' | 'attention' | 'success'
  noBorder?: boolean
}

const SlotCondition: FunctionComponent<Props> = ({
  children,
  titles,
  title,
  ...styles
}) => {
  const classes = useStyles({ ...styles } as Props)
  return (
    <div className={classes.card}>
      {title && !titles && (
        <div>
          <div className={classes.titleContainer}>
            <div className={classes.title}>{title}</div>
          </div>
        </div>
      )}
      {titles && (
        // <div className={classes.titleWrapper}>
        // <div className={classes.titleContainer}>
        <SlotTitles titles={titles} />
        // </div>
        // </div>
      )}
      <div className={classes.content}>{children}</div>
    </div>
  )
}

export default SlotCondition
