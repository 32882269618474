import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import BtnEndIcon from '@material-ui/icons/KeyboardArrowRight'
import { useStyles } from './_Styles'
import { useAsyncFn } from 'react-use'
import chatApi from '../../../api/chatApi'
import { useHistory } from 'react-router-dom'
import ButtonWithProgress from '../../common/ButtonWithProgress'

export default function NewPasswordForm() {
  const classes = useStyles()
  const hasError = false

  const history = useHistory()
  const [pass, setPass] = useState<string>('')

  const [{ loading }, request] = useAsyncFn(() => {
    return chatApi.setNewPassword(pass).then(() => {
      history.replace('/login')
    })
  })

  const onSubmit = async (event: any) => {
    event.preventDefault()
    await request()
  }

  return (
    <div className={classes.paper}>
      <form className={classes.form} onSubmit={onSubmit}>
        <TextField
          error={hasError}
          label='Новый пароль'
          type='password'
          value={setPass}
          disabled={loading}
          className={classes.input}
          helperText={hasError ? 'Пароль должен быть длиннее 8 символов' : ''}
          fullWidth
        />
        <TextField
          error={hasError}
          label='Еще раз пароль'
          type='password'
          disabled={loading}
          className={classes.input}
          helperText={hasError ? 'Пароли не совпадают' : ''}
          fullWidth
        />
        <div className={classes.submitWrapper}>
          <ButtonWithProgress
            disabled={loading}
            loading={loading}
            variant='contained'
            color='primary'
            type='submit'
            endIcon={<BtnEndIcon />}
          >
            ИЗМЕНИТЬ ПАРОЛЬ
          </ButtonWithProgress>
        </div>
      </form>
    </div>
  )
}
