import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useTestingStage from '../../../hooks/testingTask/useTestingStage';
import { MenuItemData, MenuItemDataSteps } from '../../../types';
import SidebarStep from './SidebarStep';
import { getTestingstageTaskOnTask } from '../../../api/chatApi/chatApi'

interface LevelPageProps {
  menuItems?: MenuItemData[];
  nameStagesOnTask?: MenuItemDataSteps[];
}

const LevelPage: React.FC<LevelPageProps> = ({ menuItems = [], nameStagesOnTask = [] }) => {
  const currentStage = useTestingStage();
  const history = useHistory();
  const [stages, setStages] = useState([]);
  const { routhPathCode, activeStage } = useNextStagePath(stages);
  const onClickStep = useCallback(
    (menuItem: MenuItemData) => {
      if (currentStage >= menuItem.activateStage!!) {
        history.push(menuItem.routePath);
      }
    },
    [currentStage, history]
  );

  const onClickNewSteps = useCallback(
    (menuItem) => {
      if (currentStage >= menuItem.activeStage!!) {
        history.push(menuItem.routePath);
      }
    },
    [currentStage, history]
  );

  // Функция для получения этапов
  async function fetchAllStages() {
    try {
      const result = await getTestingstageTaskOnTask();
      setStages(result.data.testingstage_task_on_task);
    } catch (error) {
      console.error('Ошибка при загрузке этапов:', error);
    }
  }

  useEffect(() => {
    fetchAllStages();
  }, []);

  function useNextStagePath(stages) {
    const location = useLocation();
    const currentPath = location.pathname;

    return useMemo(() => {
      if (!stages || stages.length === 0) {
        return { routePath: '', routhPathCode: '' };
      }
      const sortedStages = stages.sort((a, b) => a.activeStage - b.activeStage);
      const currentIndex = sortedStages.findIndex(stage => stage.routePath === currentPath);

      if (currentIndex !== -1 && currentIndex + 1 < sortedStages.length) {
        return {
          routePath: sortedStages[currentIndex].routePath,
          routhPathCode: sortedStages[currentIndex].routhPathCode,
          activeStage: sortedStages[currentIndex].activeStage
        };
      }

      return { routePath: '', routhPathCode: '' };
    }, [stages, currentPath]);
  }

  return (
    <>
      {nameStagesOnTask.length === 0 ? (
        menuItems.map((it, inx) => {
          let status: 'inactive' | 'active' | 'complete';

          if (currentStage >= it.activateStage!! || (it.activateStage!! === 10 && currentStage >= 8) || (it.activateStage!! === 13 && currentStage >= 9)) status = 'complete';
          else if (currentStage < it.activateStage!!) status = 'inactive';

          if (history.location.pathname === it.routePath) status = 'active';

          return (
            <SidebarStep
              number={inx + 1}
              key={inx + 1}
              keys={it.key}
              title={it.title}
              status={status!!}
              onClick={() => onClickStep(it)}
            />
          );
        })
      ) : (
        nameStagesOnTask.sort((a, b) => a.activeStage - b.activeStage).map((it, inx) => {
          let status: 'inactive' | 'active' | 'complete';
          if (currentStage >= it.activeStage!!
            || (it.activeStage!! === 10 && currentStage >= 8)
            || (it.activeStage!! === 11 && currentStage >= 8)
            || (it.activeStage!! === 12 && currentStage >= 8)
            || (it.activeStage!! === 13 && currentStage >= 8)) status = 'complete';
          else if (currentStage < it.activeStage!!) status = 'inactive';
          if (history.location.pathname === it.routePath) status = 'active';
          return (
            <SidebarStep
              number={inx + 1}
              key={inx + 1}
              status={status!!}
              name={it.testingstage_descr}
              onClick={() => onClickNewSteps(it)}
            />
          );
        })
      )}
    </>
  );
};

export default LevelPage;
