import { Link, Typography } from '@material-ui/core'
import React, { ReactElement } from 'react'
import Slot from '../../common/Slots/Slot'

export default function DiagnosisPenaltyList(): ReactElement {
  return (
    <Slot title='Допущенные ошибки' color='attention'>
      <Typography variant='h5'>
        Ошибка 1 на этапе <Link>статуса</Link>
      </Typography>
      <Typography variant='h5'>
        Ошибка 2 на этапе <Link>исследования</Link>
      </Typography>
    </Slot>
  )
}
