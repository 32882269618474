import { Tab, Tabs } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { RoutePath } from '../../types'
import { makeStyles } from '@material-ui/core/styles'
import { pxToRem } from '../../utils'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  tabs: {
    width: '100%',
    minHeight: pxToRem(48),
    height: pxToRem(48)
  },
  tab: {
    borderBottom: '2px solid',
    minWidth: '50%',
    minHeight: pxToRem(48)
  }
}))

export default function AuthNavigationPanel() {
  const {t} = useTranslation()
  const {
    location: { pathname },
    push
  } = useHistory()
  const c = useStyles()

  const getValue = () => {
    switch (pathname) {
      case RoutePath.register:
        return 0
      case RoutePath.registerInfo:
        return 0
      case RoutePath.login:
        return 1
      default:
        return undefined
    }
  }

  const handleTabChange: (
    event: React.ChangeEvent<{}>,
    value: number
  ) => void = (e, value) => {
    switch (value) {
      // case 0:
      //   return push(RoutePath.register);
      case 0:
        return push(RoutePath.login)
      default:
        return push(RoutePath.root)
    }
  }

  

  return (
    <Tabs
      value={getValue()}
      onChange={handleTabChange}
      indicatorColor='primary'
      textColor='primary'
      centered
      classes={{ root: c.tabs }}
    >
      {/* <Tab className={c.tab} label="Регистрация" /> */}
      <Tab className={c.tab} label={t('login')} />
    </Tabs>
  )
}
