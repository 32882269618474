import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import React, { useState, useEffect } from 'react';
import SearchBar from 'material-ui-search-bar';
import { TablePaginationActions } from '../../adminPanel/CreateRole/options/TablePagination';
import { Box } from '@material-ui/core';
import { getMediaContent, getRegisterUsers } from '../../../api/chatApi/chatApi';
import { Loading } from '@carbon/react';


const useRowStyles = makeStyles({
  titleTable: {
    textAlign: 'center',
    fontWeight: 600,
    fontFamily: 'Helvetica Neue'
  },
  item: {
    display: 'flex',
  },
  cellText: {
    display: 'inline-flex',
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
  },
  textBody: {
    fontFamily: 'Helvetica Neue',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 20,
    fontWeight: 500,
    margin: '20px 0',
  },
  buttonSwitch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cellInput: {
    width: '100%',
    background: 'transparent',
    border: 'none',
    outline: 'none',
  },
  stages: {
    fontFamily: 'Helvetica Neue',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 23,
    color: '#000000',
    marginRight: 24,
    marginTop: 32,
    marginBottom: 20
  },
});

const ChampionshipListUsers = ({ prize, status }) => {
  const classes = useRowStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState('');
  const idChamp = localStorage.getItem('idChamp')

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getRegisters = async () => {
    await getRegisterUsers(Number(idChamp), status())
      .then(res => {
        setRows(res.data.registration_user.user)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getRegisters()
  }, []);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const requestSearch = async searchedVal => {
    if (!searchedVal) {
      return getRegisters();
    }
    await getRegisterUsers(Number(idChamp), status())
      .then(res => {
        if (res.data.registration_user.user) {
          const data = res.data.registration_user.user
          const filteredRows = data.filter(row => {
            return row.user.toLowerCase().includes(searchedVal.toLowerCase());
          });
          setRows(filteredRows);
        }

      })
      .catch(err => console.log(err))
  };

  const non = { background: '#e9e9e9' }

  const [newDatas, setNewDatas] = useState(null)

  const datas = async (el) => {
    try {
      const res = await getMediaContent(el.link)
      const filter = prize.filter(el => el.link)
      const newFilter = prize.filter(el => !el.link)
      const data = await res.data.data_base64
      if (data) {
        const result = filter.map(item => {
          return { ...item, newLink: data }
        })
        setNewDatas([...result, ...newFilter]);
      }
    } catch (err) {
      console.log(err)
    }

  }


  const newPrize = () => {
    const filter = prize.filter(el => el.link)
    filter.map((el) => datas(el))
  }

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    newPrize()
  }, []);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 500)
  }, [isLoading])

  return (
    <Box>
      {rows ?
        <div>
          <div className={classes.stages}>Список участников</div>
          <SearchBar placeholder='Поиск' value={searched} onChange={searchVal => requestSearch(searchVal)} />
          <TableContainer style={{ marginBottom: 20 }} component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead style={{ background: '#e0e0e0' }}>
                <TableRow>
                  <TableCell className={classes.titleTable}>Место</TableCell>
                  <TableCell className={classes.titleTable} align="right">
                    Имя
                  </TableCell>
                  <TableCell className={classes.titleTable} align="right">
                    Баллы
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map(
                  (row, index) => (
                    <TableRow className={classes.bodyTable} key={index} style={row.stage === 0 ? non : null}>
                      <TableCell className={classes.textBody} align="center" component="th" scope="row">
                        {row.plase}
                      </TableCell>
                      <TableCell className={classes.textBody} align="center" component="th" scope="row">
                        {row.user}
                      </TableCell>
                      <TableCell className={classes.textBody} align="center" component="th" scope="row">
                        {row.point}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
              <TableFooter>
                <TablePagination
                  className={classes.textBody}
                  rowsPerPageOptions={[5, 10, 25, { label: 'Все', value: -1 }]}
                  colSpan={3}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        :
        null}


      {!isLoading && newDatas &&
        <div>
          <div className={classes.stages}>Призовые места</div>
          {newDatas &&
            newDatas.map((el, index) => {
              return (
                <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '20px 0', fontFamily: 'Helvetica Neue ' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {el.newLink ?
                      <img style={{ width: 40, height: 40, marginRight: 10 }} src={`data:image/jpeg;base64,${el.newLink}`} alt="img1" />
                      :
                      null
                    }
                    <div className={classes.lorem} style={{ marginRight: 10 }}>{el.place} Место</div>
                  </div>
                  <div className={classes.lorem}>Необходимо очков:  {el.point_championship}</div>
                </div>

              )
            })
          }
        </div>
      }
      {isLoading &&
        <div>
          <Loading
            description="Active loading indicator" withOverlay={false}
            small={false} withOverlay={false} />
        </div>
      }



    </Box>
  );
}

export default ChampionshipListUsers;