import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import chatApi from '../../api/chatApi'
import { TestingStage } from '../../api/chatApi/chatApi'
import CustomButton from '../../components/common/CustomButton'
import TooltipDialog from '../../components/common/TooltipDialog'
import MainLayout from '../../components/Layout/MainLayout'
import DiagnosisTooltip from '../../components/Tooltips/DiagnosisTooltip'
import useTooltipState from '../../hooks/useTooltipState'
import useTestingStage from '../../hooks/testingTask/useTestingStage'
import useIncreaseTaskStage from '../../hooks/testingTask/useIncreaseTaskStage'
import { RoutePath } from '../../types'
import RatingDialog from '../../components/Chat/RatingDialog'
import Model from '../../3d_model/Model'
import { Link } from 'react-router-dom'
import TestModelTooltip from '../../components/Tooltips/TestModelTooltip'
import TooltipAccordion from '../../components/TooltipAccordion'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '97%',
      margin: '0 auto',
      color: theme.palette.text.secondary
      // backgroundColor: theme.palette.background.paper,
    },
    model: {
      position: 'relative',
      padding: '20px 0 0 0',
      minWidth: '100%',
      minHeight: '87vh',
      overflow: 'hidden'
    },
    title: {
      textAlign: 'center'
    }
  })
)

export default function TestModel() {
  // const stage = 0
  const stage = useTestingStage();
  const [increaseLoading, increase] = useIncreaseTaskStage(RoutePath.chat)

  const classes = useStyles()
  const [tooltipState, openTooltip] = useTooltipState()
  const [open, setOpen] = React.useState(true)
  const [openRating, setOpenRating] = React.useState(false)
  const testingStage = useTestingStage()


  const [status, setStatus] = useState<boolean>(false)
  useEffect(() => {
    const getDialogStatus = async () => {
      const response = await chatApi.getSettingsPiloting();
      const statusValue = response.result[0].value;
      setStatus(statusValue === 'true');
    };

    getDialogStatus();
  }, []);



  function hideRatingDialog() {
    // increase()
    setOpen(false)
  }

  const patientModel = useQuery(
    'patientModel',
    async () => {
      const currTask: any = await chatApi.unfinishedTask()
      return currTask?.result?.task?.patientModel?.view3dLink
    },
    { refetchOnMount: true }
  )

  const view3dLink = async () => {
    const currTask: any = await chatApi.unfinishedTask()
    setLink(currTask?.result?.task?.patientModel?.view3dLink)
  }
  const [link, setLink] = useState('')

  useEffect(() => {
    view3dLink()
  }, []);

  const taskName = '8'

  const {t} = useTranslation()

  return (
    <MainLayout title={t("tren1Title1")} onClickTooltip={openTooltip}>
      {openRating ? (
        <RatingDialog open={openRating} onSubmit={hideRatingDialog} />
      ) : null}

      <TooltipDialog {...tooltipState}>
        <TestModelTooltip />
      </TooltipDialog>
      <TooltipAccordion taskName={taskName} />

      <div
        className={classes.root}
        style={{ height: `85vh`, width: `100%`, position: `relative`, overflow: 'hidden', marginTop: 30 }}
      >

        {open && link ? (
          <div>
            <Model type={link} />
          </div>
        ) : (
          <>

          </>
        )}
      </div>
    </MainLayout>
  )
}
