import React, { useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Boy5(props) {
  const { nodes, materials, animations } = useGLTF('patient/boy_5year_ANIMATION1.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)

  useEffect(() => {
    props.setAnim(animations)
    actions.rigAction.play()
  }, [actions, names, mixer])
  
  return (
    <group ref={ref} {...props} dispose={null}>
      <group name="Scene">
        <group name="metarig">
          <group name="spine" position={[0, 4.44, -0.26]} rotation={[0.37, 0, 0]}>
            <group name="spine001" position={[0, 1, 0]} rotation={[-0.34, 0, 0]}>
              <group name="spine002" position={[0, 0.66, 0]} rotation={[-0.22, 0, 0]}>
                <group name="spine003" position={[0, 0.81, 0]} rotation={[0.25, 0, 0]}>
                  <group name="spine004" position={[0, 0.68, 0]} rotation={[0.38, 0, 0]}>
                    <group name="spine005" position={[0, 0.29, 0]} rotation={[-0.05, 0, 0]}>
                      <group name="spine006" position={[0, 0.22, 0]} rotation={[-0.38, 0, 0]}>
                        <group name="face" rotation={[0.08, 0, 0]}>
                          <group name="nose_1" position={[0, 0.74, 0.74]} rotation={[2.69, 0, 0]}>
                            <group name="nose001_1" position={[0, 0.16, 0]} rotation={[-0.42, 0, 0]}>
                              <group name="nose002_1" position={[0, 0.11, 0]} rotation={[1.17, 0, 0]}>
                                <group name="nose003_1" position={[0, 0.06, 0]} rotation={[0.47, 0, 0]}>
                                  <group name="nose004_1" position={[0, 0.08, 0]} rotation={[-0.9, 0, 0]} />
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="lipTL" position={[0, 0.31, 0.84]} rotation={[-1.64, -0.02, -1.3]}>
                            <group name="lipTL001_1" position={[0, 0.09, 0]} rotation={[-0.25, -0.27, 0.51]} />
                          </group>
                          <group name="lipBL" position={[0, 0.23, 0.83]} rotation={[-1.5, -0.19, -1.13]}>
                            <group name="lipBL001_1" position={[0, 0.1, 0]} rotation={[-0.02, 0.15, 0.08]} />
                          </group>
                          <group name="jaw_1" position={[0, -0.01, 0.3]} rotation={[1.52, 0, 0]}>
                            <group name="chin_1" position={[0, 0.42, 0]} rotation={[-0.83, 0, 0]}>
                              <group name="chin001_1" position={[0, 0.1, 0]} rotation={[-0.75, 0, 0]} />
                            </group>
                          </group>
                          <group name="earL_1" position={[0.58, 0.45, 0.03]} rotation={[-0.23, -0.2, -0.08]}>
                            <group name="earL001" position={[0, 0.17, 0]} rotation={[-0.86, -0.19, -0.22]}>
                              <group name="earL002_1" position={[0, 0.15, 0]} rotation={[-1.83, -0.5, 0.1]}>
                                <group name="earL003_1" position={[0, 0.21, 0]} rotation={[-0.71, -0.68, 0.45]}>
                                  <group name="earL004_1" position={[0, 0.23, 0]} rotation={[-2.58, 0.26, 0.89]} />
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="earR_1" position={[-0.58, 0.45, 0.03]} rotation={[-0.23, 0.2, 0.08]}>
                            <group name="earR001" position={[0, 0.17, 0]} rotation={[-0.86, 0.19, 0.22]}>
                              <group name="earR002_1" position={[0, 0.15, 0]} rotation={[-1.83, 0.5, -0.1]}>
                                <group name="earR003_1" position={[0, 0.21, 0]} rotation={[-0.71, 0.68, -0.45]}>
                                  <group name="earR004_1" position={[0, 0.23, 0]} rotation={[-2.58, -0.26, -0.89]} />
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="lipTR" position={[0, 0.31, 0.84]} rotation={[-1.64, 0.02, 1.3]}>
                            <group name="lipTR001_1" position={[0, 0.09, 0]} rotation={[-0.25, 0.27, -0.51]} />
                          </group>
                          <group name="lipBR" position={[0, 0.23, 0.83]} rotation={[-1.5, 0.19, 1.13]}>
                            <group name="lipBR001_1" position={[0, 0.1, 0]} rotation={[-0.02, -0.15, -0.08]} />
                          </group>
                          <group name="browBL_1" position={[0.38, 0.85, 0.63]} rotation={[-1.06, 0.91, 1.96]}>
                            <group name="browBL001_1" position={[0, 0.07, 0]} rotation={[-0.48, 0.74, 0.04]}>
                              <group name="browBL002_1" position={[0, 0.07, 0]} rotation={[-0.09, 0.4, -0.6]}>
                                <group name="browBL003_1" position={[0, 0.09, 0]} rotation={[-0.37, 0.54, -0.19]} />
                              </group>
                            </group>
                          </group>
                          <group name="lidTL_1" position={[0.36, 0.72, 0.58]} rotation={[-0.92, 0.91, 1.85]}>
                            <group name="lidTL001_1" position={[0, 0.09, 0]} rotation={[-0.3, 0.85, -0.03]}>
                              <group name="lidTL002_1" position={[0, 0.07, 0]} rotation={[-0.34, 0.41, -0.6]}>
                                <group name="lidTL003_1" position={[0, 0.08, 0]} rotation={[-0.55, 0.59, -0.14]}>
                                  <group name="lidBL_1" position={[0, 0.07, 0]} rotation={[1.33, 0.1, -2.44]}>
                                    <group name="lidBL001_1" position={[0, 0.04, 0]} rotation={[0.41, 0.17, 0.03]}>
                                      <group name="lidBL002_1" position={[0, 0.09, 0]} rotation={[0.39, 0.27, 0.37]}>
                                        <group name="lidBL003_1" position={[0, 0.08, 0]} rotation={[0.07, 0.23, 0.54]} />
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="browBR_1" position={[-0.38, 0.85, 0.63]} rotation={[-1.06, -0.91, -1.96]}>
                            <group name="browBR001_1" position={[0, 0.07, 0]} rotation={[-0.48, -0.74, -0.04]}>
                              <group name="browBR002_1" position={[0, 0.07, 0]} rotation={[-0.09, -0.4, 0.6]}>
                                <group name="browBR003_1" position={[0, 0.09, 0]} rotation={[-0.37, -0.54, 0.19]} />
                              </group>
                            </group>
                          </group>
                          <group name="lidTR_1" position={[-0.36, 0.72, 0.58]} rotation={[-0.92, -0.91, -1.85]}>
                            <group name="lidTR001_1" position={[0, 0.09, 0]} rotation={[-0.3, -0.85, 0.03]}>
                              <group name="lidTR002_1" position={[0, 0.07, 0]} rotation={[-0.34, -0.41, 0.6]}>
                                <group name="lidTR003_1" position={[0, 0.08, 0]} rotation={[-0.55, -0.59, 0.14]}>
                                  <group name="lidBR_1" position={[0, 0.07, 0]} rotation={[1.33, -0.1, 2.44]}>
                                    <group name="lidBR001_1" position={[0, 0.04, 0]} rotation={[0.41, -0.17, -0.03]}>
                                      <group name="lidBR002_1" position={[0, 0.09, 0]} rotation={[0.39, -0.27, -0.37]}>
                                        <group name="lidBR003_1" position={[0, 0.08, 0]} rotation={[0.07, -0.23, -0.54]} />
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="foreheadL" position={[0.09, 1.26, 0.69]} rotation={[-2.7, 1.52, -0.66]}>
                            <group name="foreheadL001" position={[-0.07, 0.02, 0.18]} rotation={[0.15, -0.29, -0.01]}>
                              <group name="foreheadL002" position={[-0.07, 0.05, 0.21]} rotation={[-0.01, -0.57, 0.29]}>
                                <group name="templeL" position={[-0.18, 0.23, 0.38]} rotation={[-0.06, -0.69, -0.15]}>
                                  <group name="jawL_1" position={[0, 0.37, 0]} rotation={[-0.13, -0.26, 0.4]}>
                                    <group name="jawL001_1" position={[0, 0.38, 0]} rotation={[-0.26, -0.64, 0.63]}>
                                      <group name="chinL_1" position={[0, 0.51, 0]} rotation={[-1.93, 0.24, 1.32]}>
                                        <group name="cheekBL" position={[0, 0.23, 0]} rotation={[-1.75, -0.16, -0.8]}>
                                          <group name="cheekBL001_1" position={[0, 0.23, 0]} rotation={[0.22, 0.38, 0.39]}>
                                            <group name="browTL_1" position={[0, 0.42, 0]} rotation={[1.31, 0.68, 0.23]}>
                                              <group name="browTL001_1" position={[0, 0.3, 0]} rotation={[-2.12, 0.83, 2.46]}>
                                                <group name="browTL002_1" position={[0, 0.23, 0]} rotation={[-0.01, 0.85, -0.74]}>
                                                  <group name="browTL003_1" position={[0, 0.24, 0]} rotation={[-0.41, 0.46, -0.85]} />
                                                </group>
                                              </group>
                                            </group>
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="foreheadR" position={[-0.09, 1.26, 0.69]} rotation={[-2.7, -1.52, 0.66]}>
                            <group name="foreheadR001" position={[0.07, 0.02, 0.18]} rotation={[0.15, 0.29, 0.01]}>
                              <group name="foreheadR002" position={[0.07, 0.05, 0.21]} rotation={[-0.01, 0.57, -0.29]}>
                                <group name="templeR" position={[0.18, 0.23, 0.38]} rotation={[-0.06, 0.69, 0.15]}>
                                  <group name="jawR_1" position={[0, 0.37, 0]} rotation={[-0.13, 0.26, -0.4]}>
                                    <group name="jawR001_1" position={[0, 0.38, 0]} rotation={[-0.26, 0.64, -0.63]}>
                                      <group name="chinR_1" position={[0, 0.51, 0]} rotation={[-1.93, -0.24, -1.32]}>
                                        <group name="cheekBR" position={[0, 0.23, 0]} rotation={[-1.75, 0.16, 0.8]}>
                                          <group name="cheekBR001_1" position={[0, 0.23, 0]} rotation={[0.22, -0.38, -0.39]}>
                                            <group name="browTR_1" position={[0, 0.42, 0]} rotation={[1.31, -0.68, -0.23]}>
                                              <group name="browTR001_1" position={[0, 0.3, 0]} rotation={[-2.12, -0.83, -2.46]}>
                                                <group name="browTR002_1" position={[0, 0.23, 0]} rotation={[-0.01, -0.85, 0.74]}>
                                                  <group name="browTR003_1" position={[0, 0.24, 0]} rotation={[-0.41, -0.46, 0.85]} />
                                                </group>
                                              </group>
                                            </group>
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="eyeL_1" position={[0.25, 0.71, 0.58]} rotation={[-1.67, 0, Math.PI]} />
                          <group name="eyeR_1" position={[-0.25, 0.71, 0.58]} rotation={[-1.67, 0, Math.PI]} />
                          <group name="cheekTL" position={[0.51, 0.62, 0.34]} rotation={[2.94, -0.89, 1.15]}>
                            <group name="cheekTL001_1" position={[0, 0.43, 0]} rotation={[2.93, -0.53, 1.8]}>
                              <group name="noseL_1" position={[0, 0.24, 0]} rotation={[2.59, 0.77, 1.32]}>
                                <group name="noseL001_1" position={[0, 0.21, 0]} rotation={[1.65, 0.21, 2.65]} />
                              </group>
                            </group>
                          </group>
                          <group name="cheekTR" position={[-0.51, 0.62, 0.34]} rotation={[2.94, 0.89, -1.15]}>
                            <group name="cheekTR001_1" position={[0, 0.43, 0]} rotation={[2.93, 0.53, -1.8]}>
                              <group name="noseR_1" position={[0, 0.24, 0]} rotation={[2.59, -0.77, -1.32]}>
                                <group name="noseR001_1" position={[0, 0.21, 0]} rotation={[1.65, -0.21, -2.65]} />
                              </group>
                            </group>
                          </group>
                          <group name="teethT_1" position={[0, 0.36, 0.71]} rotation={[-1.67, 0, 0]} />
                          <group name="teethB_1" position={[0, 0.22, 0.73]} rotation={[-1.67, 0, 0]} />
                          <group name="tongue_1" position={[0, 0.26, 0.74]} rotation={[-1.46, 0, 0]}>
                            <group name="tongue001_1" position={[0, 0.12, 0]} rotation={[-0.22, 0, 0]}>
                              <group name="tongue002_1" position={[0, 0.4, 0]} rotation={[-0.74, 0, 0]} />
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="shoulderL_1" position={[0.09, 0.68, 0.42]} rotation={[-1.61, -0.04, -1.07]}>
                    <group name="upper_armL" position={[0.02, 0.98, -0.14]} rotation={[1.28, 1.11, -2.3]}>
                      <group name="forearmL" position={[0, 1.44, 0]} rotation={[0.72, 0.27, 0.06]}>
                        <group name="handL" position={[0, 1.42, 0]} rotation={[-0.03, -0.21, -0.01]}>
                          <group name="palm01L" position={[-0.05, 0.19, 0.16]} rotation={[1.66, 1.32, -1.53]}>
                            <group name="f_index01L_1" position={[0, 0.38, 0]} rotation={[0.14, -0.11, 0.11]}>
                              <group name="f_index02L_1" position={[0, 0.16, 0]} rotation={[0.17, -0.16, -0.04]}>
                                <group name="f_index03L_1" position={[0, 0.12, 0]} rotation={[0.1, -0.13, -0.11]} />
                              </group>
                            </group>
                            <group name="thumb01L_1" position={[-0.09, -0.03, 0.15]} rotation={[-0.71, 1.21, 1.23]}>
                              <group name="thumb02L_1" position={[0, 0.23, 0]} rotation={[-0.05, 0.04, -0.09]}>
                                <group name="thumb03L_1" position={[0, 0.13, 0]} rotation={[0.24, -0.16, 0.09]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm02L" position={[-0.09, 0.21, 0.06]} rotation={[1.67, 1.33, -1.65]}>
                            <group name="f_middle01L_1" position={[0, 0.37, 0]} rotation={[0.15, -0.23, -0.06]}>
                              <group name="f_middle02L_1" position={[0, 0.22, 0]} rotation={[0.14, -0.18, 0.04]}>
                                <group name="f_middle03L_1" position={[0, 0.14, 0]} rotation={[0.03, -0.07, -0.06]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm03L" position={[-0.09, 0.21, -0.03]} rotation={[1.5, 1.33, -1.58]}>
                            <group name="f_ring01L_1" position={[0, 0.34, 0]} rotation={[0.05, -0.34, -0.12]}>
                              <group name="f_ring02L_1" position={[0, 0.18, 0]} rotation={[0.14, -0.1, -0.03]}>
                                <group name="f_ring03L_1" position={[0, 0.15, 0]} rotation={[0.03, -0.18, -0.02]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm04L" position={[-0.06, 0.18, -0.12]} rotation={[1.44, 1.33, -1.58]}>
                            <group name="f_pinky01L_1" position={[0, 0.32, 0]} rotation={[0.01, -0.52, -0.2]}>
                              <group name="f_pinky02L_1" position={[0, 0.15, 0]} rotation={[0.17, -0.05, -0.12]}>
                                <group name="f_pinky03L_1" position={[0, 0.1, 0]} rotation={[0.1, -0.11, -0.01]} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="shoulderR_1" position={[-0.09, 0.68, 0.42]} rotation={[-1.61, 0.04, 1.07]}>
                    <group name="upper_armR" position={[-0.02, 0.98, -0.14]} rotation={[1.28, -1.11, 2.3]}>
                      <group name="forearmR" position={[0, 1.44, 0]} rotation={[0.72, -0.27, -0.06]}>
                        <group name="handR" position={[0, 1.42, 0]} rotation={[-0.03, 0.21, 0.01]}>
                          <group name="palm01R" position={[0.05, 0.19, 0.16]} rotation={[1.66, -1.32, 1.53]}>
                            <group name="f_index01R_1" position={[0, 0.38, 0]} rotation={[0.14, 0.11, -0.11]}>
                              <group name="f_index02R_1" position={[0, 0.16, 0]} rotation={[0.17, 0.16, 0.04]}>
                                <group name="f_index03R_1" position={[0, 0.12, 0]} rotation={[0.1, 0.13, 0.11]} />
                              </group>
                            </group>
                            <group name="thumb01R_1" position={[0.09, -0.03, 0.15]} rotation={[-0.71, -1.21, -1.23]}>
                              <group name="thumb02R_1" position={[0, 0.23, 0]} rotation={[-0.05, -0.04, 0.09]}>
                                <group name="thumb03R_1" position={[0, 0.13, 0]} rotation={[0.24, 0.16, -0.09]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm02R" position={[0.09, 0.21, 0.06]} rotation={[1.67, -1.33, 1.65]}>
                            <group name="f_middle01R_1" position={[0, 0.37, 0]} rotation={[0.15, 0.23, 0.06]}>
                              <group name="f_middle02R_1" position={[0, 0.22, 0]} rotation={[0.14, 0.18, -0.04]}>
                                <group name="f_middle03R_1" position={[0, 0.14, 0]} rotation={[0.03, 0.07, 0.06]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm03R" position={[0.09, 0.21, -0.03]} rotation={[1.5, -1.33, 1.58]}>
                            <group name="f_ring01R_1" position={[0, 0.34, 0]} rotation={[0.05, 0.34, 0.12]}>
                              <group name="f_ring02R_1" position={[0, 0.18, 0]} rotation={[0.14, 0.1, 0.03]}>
                                <group name="f_ring03R_1" position={[0, 0.15, 0]} rotation={[0.03, 0.18, 0.02]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm04R" position={[0.06, 0.18, -0.12]} rotation={[1.44, -1.33, 1.58]}>
                            <group name="f_pinky01R_1" position={[0, 0.32, 0]} rotation={[0.01, 0.52, 0.2]}>
                              <group name="f_pinky02R_1" position={[0, 0.15, 0]} rotation={[0.17, 0.05, 0.12]}>
                                <group name="f_pinky03R_1" position={[0, 0.1, 0]} rotation={[0.1, 0.11, 0.01]} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="breastL_1" position={[0.45, -0.04, -0.2]} rotation={[-1.63, 0, Math.PI]} />
                  <group name="breastR_1" position={[-0.45, -0.04, -0.2]} rotation={[-1.63, 0, Math.PI]} />
                </group>
              </group>
            </group>
            <group name="pelvisL" position={[0, 0.13, 0.15]} rotation={[-1.91, -0.54, -2.03]} />
            <group name="pelvisR" position={[0, 0.13, 0.15]} rotation={[-1.91, 0.54, 2.03]} />
            <group name="thighL" position={[0.64, 0.09, 0.33]} rotation={[2.65, 0.01, -0.12]}>
              <group name="shinL" position={[0, 1.97, 0]} rotation={[0.3, 0.02, 0]}>
                <group name="footL" position={[0, 1.92, 0]} rotation={[-1.13, -0.09, 0.05]}>
                  <group name="toeL_1" position={[0, 0.87, 0]} rotation={[2.5, 0, -Math.PI]} />
                  <group name="heel02L" position={[-0.18, 0.15, 0.52]} rotation={[2.52, 0, -Math.PI / 2]} />
                </group>
              </group>
            </group>
            <group name="thighR" position={[-0.64, 0.09, 0.33]} rotation={[2.65, -0.01, 0.12]}>
              <group name="shinR" position={[0, 1.97, 0]} rotation={[0.3, -0.02, 0]}>
                <group name="footR" position={[0, 1.92, 0]} rotation={[-1.13, 0.09, -0.05]}>
                  <group name="toeR_1" position={[0, 0.87, 0]} rotation={[2.5, 0, Math.PI]} />
                  <group name="heel02R" position={[0.18, 0.15, 0.52]} rotation={[2.52, 0, Math.PI / 2]} />
                </group>
              </group>
            </group>
          </group>
        </group>
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <skinnedMesh name="boy5years001" geometry={nodes.boy5years001.geometry} material={materials['male_casualsuit06.001']} skeleton={nodes.boy5years001.skeleton} />
          <skinnedMesh name="boy5yearseyes" geometry={nodes.boy5yearseyes.geometry} material={materials['Eyebluegreen.001']} skeleton={nodes.boy5yearseyes.skeleton} />
          <skinnedMesh name="boy5years_sculpt001" geometry={nodes.boy5years_sculpt001.geometry} material={materials['young_asian_female.003']} skeleton={nodes.boy5years_sculpt001.skeleton} />
        </group>
      </group>
    </group>
  )
}

// useGLTF.preload('patient/boy_5year_ANIMATION1.glb')
