import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import { pxToRemGutter } from '../utils'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: pxToRemGutter(48, 0, 24)
    },
    text: {
      color: theme.palette.text.primary
    }
  })
)

type Props = {
  open: boolean
  title: string
  content: string
  onClose: () => void
  onSubmit: () => void
}

export default function ConfirmDialog({
  open,
  title,
  content,
  onClose,
  onSubmit
}: Props) {
  const classes = useStyles()
  const {t} = useTranslation()

  return open
    ? createPortal(
        <React.Fragment>
          <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.text}>
                {content}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color='primary'>
                {t("trenSidebar6")}
              </Button>
              <Button onClick={onSubmit} color='primary' autoFocus>
                {t("trenSidebar5")}
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>,
        document.body
      )
    : null
}
