import React from 'react'
import SlotResearchTable from './SlotResearchTable'

function createData(name: string, val: number, units: string, norma: number) {
  return { name, val, units, norma }
}

const rows = [
  createData('Мочевина', 32.0, 'г./с.кв.', 33.0),
  createData('Эритроциты', 9.0, 'г./с.кв.', 4.3),
  createData('Лейкоциты', 16.0, 'г./с.кв.', 6.0)
]

export default function UrinaTableBlock() {
  return (
    <SlotResearchTable rows={rows} title='Моча. Лабораторные исследования' />
  )
}
