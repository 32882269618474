import React, { useEffect, useState } from "react";
import { allUsersChampRating } from "../../../api/chatApi/chatApi";
import MainFooter from "../../../components/MainFooter/MainFooter";
import MainHeader from "../../../components/MainHeader/MainHeader";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SearchBar from 'material-ui-search-bar';
import { makeStyles } from '@material-ui/core/styles';

const useRowStyles = makeStyles({
  titleTable: {
    textAlign: 'center',
    fontWeight: 500,
    color: '#aeaeae',
  },
  bodyTable: {
    textAlign: 'center',
    fontWeight: 600,
  },
  item: {
    display: 'flex',
  },
  cellText: {
    display: 'inline-flex',
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
  },
  title: {
    fontFamily: 'Helvetica Neue',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 20,
    color: '#000000',
    margin: '90px 24px 0px 24px',
  },
  buttonSwitch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cellInput: {
    width: '100%',
    background: 'transparent',
    border: 'none',
    outline: 'none',
  },
  current: {
    padding: 20,
    boxShadow: '0px 0px 2px rgba(9, 36, 51, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  name: {
    fontFamily: 'Helvetica Neue',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 20,
    color: '#2196F3',
  },
  current_title: {
    fontFamily: 'Helvetica Neue',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#9A9A9A',
  },
  lorem: {
    fontFamily: 'Helvetica Neue',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: '#000000'
  },
  block: {
    borderRight: '1px solid #DADADA',
    padding: '0 10px'
  }
});

interface Current {
  id: number,
  position: number,
  raiting: string,
  raiting_change: number,
  user_name: string,
}

const ChampionshipRating = () => {
  const classes = useRowStyles();

  const [data, setData] = useState<any>([]);
  const [current, setCurrent] = useState<Current>({});
  const [searched, setSearched] = useState('');

  const getData = async () => {
    await allUsersChampRating()
      .then(res => {
        setData(res.data.all_user_championship)
        setCurrent(res.data.current_user_championship)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getData()
  }, []);

  const requestSearch = async searchedVal => {
    setSearched(searchedVal)
    if (!searchedVal) {
      return getData();
    }
    await allUsersChampRating()
      .then(res => {
        const result = res.data.all_user_championship
        const filtered = result.filter(el => {
          if (el.first_name !== null) {
            return el.first_name.toLowerCase().includes(searchedVal.toLowerCase());
          }
        })
        setData(filtered);
      })
      .catch(err => console.log(err))
  };
  return (
    <>
      <MainHeader />
      <div className={classes.title}>Рейтинг</div>
      <div style={{ margin: '50px 24px 0px 24px', display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <SearchBar style={{ marginBottom: 10 }} value={searched} placeholder='Поиск по пользователям' onChange={searchVal => requestSearch(searchVal)} />
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.titleTable}>Место</TableCell>
                  <TableCell className={classes.titleTable} align="right">
                    Имя
                  </TableCell>
                  <TableCell className={classes.titleTable} align="right">
                    Баллы
                  </TableCell>
                  <TableCell className={classes.titleTable}>Последний чемпионат</TableCell>
                  <TableCell className={classes.titleTable}>Дата окончания</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(
                  (data, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.bodyTable} align="center" component="th" scope="row">
                        {data.id}
                      </TableCell>
                      <TableCell style={{ color: '#6bb9f7' }} className={classes.bodyTable} align="center" component="th" scope="row">
                        {data.first_name}
                      </TableCell>
                      <TableCell className={classes.bodyTable} align="center" component="th" scope="row">
                        {data.raiting}
                      </TableCell>
                      <TableCell className={classes.bodyTable} align="center" component="th" scope="row">
                        {data.championship}
                      </TableCell>
                      <TableCell className={classes.bodyTable} align="center" component="th" scope="row">
                        {data.last_time}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>

            </Table>
          </TableContainer>
        </div>
        <div className={classes.current}>
          <div className={classes.name}>
            {current.user_name ? current.user_name : 'Имя: Нет на бэке'}
          </div>
          <div style={{ display: 'flex' }}>
            <div className={classes.block}>
              <div className={classes.current_title}>Рейтинг</div>
              <div className={classes.lorem}>{current.position} место</div>
            </div>

            <div className={classes.block}>
              <div className={classes.current_title}>За сутки</div>
              <div className={classes.lorem} style={{ color: '#23C808' }}>{current.raiting_change ? current.raiting_change : 'Нет на бэке'}</div>
            </div>

            <div style={{ border: 0 }} className={classes.block}>
              <div className={classes.current_title}>Баллы</div>
              <div className={classes.lorem}>{current.raiting ? current.raiting : 'Нет на бэке'}</div>
            </div>


          </div>
        </div>
      </div>
      <MainFooter />
    </>
  );
}

export default ChampionshipRating;