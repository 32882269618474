export enum BuildType {
  development = 'development',
  production = 'production',
}

export enum ApiType {
  real = 'real',
  mock = 'mock',
}

export type BuildConfig = {
  buildType: BuildType;
  apiType: ApiType;
};

export const buildConfig: BuildConfig = {
  buildType: process.env.NODE_ENV as BuildType,
  apiType: (process.env.REACT_APP_API_TYPE as ApiType) || ApiType.real,
};

// console.log(buildConfig);
