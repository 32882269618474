import React from 'react'
import ChatPagesMenu from '../../../components/Chat/ChatPagesMenu'
import DialogPhoto from '../../../components/common/DialogPhoto'
import SlotContainer from '../../../components/common/Slots/SlotContainer'
import SlotPlaceholder from '../../../components/common/Slots/SlotPlaceholder'
import MainLayout from '../../../components/Layout/MainLayout'
import InstrumentalSelectBlock from '../../../components/PageBlocks/Research/Instrumental/InstrumentalSelectBlock'
import LabSelectBlock from '../../../components/PageBlocks/Research/Lab/LabSelectBlock'
import { CHAT_MENU_ITEMS } from '../../../constants/default'

export default function ResearchPhotoDialogPage() {
  return (
    <MainLayout desktopHeader={<ChatPagesMenu menuItems={CHAT_MENU_ITEMS} />}>
      <DialogPhoto
        open={true}
        title='Рентген Легких'
        src='https://storage.yandexcloud.net/cdn.rownarts.com/tmp-assets/cyberdoctor/rentgen3.jpg'
        content='Какое-нибудь описание, если нужно'
      />

      <SlotContainer>
        <div>
          <LabSelectBlock />

          <SlotPlaceholder title='Здесь появятся данные лабораторных исследований' />
        </div>
        <div>
          <InstrumentalSelectBlock />

          <SlotPlaceholder title='Здесь появятся данные Инструментальных исследований' />
        </div>
      </SlotContainer>
    </MainLayout>
  )
}
