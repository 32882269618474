import React, { useEffect, useState } from 'react';
import chatApi from '../../api/chatApi';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableFooter from '@material-ui/core/TableFooter';
import { useHistory } from 'react-router-dom';
import { checkRole } from '../../common/checkRole';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { ToastNotification, Form, Stack, TextInput, Select, SelectItem, Button } from '@carbon/react';
import SearchBar from 'material-ui-search-bar';
import { Loading } from '@carbon/react';
import { useDebounce } from 'react-use';
import { useTranslation } from 'react-i18next';

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const useRowStyles = makeStyles({
  titleTable: {
    textAlign: 'center',
    fontWeight: 600,
    backgroundColor: '#E7F0FE'

  },
  bodyTable: {
    textAlign: 'left',
    fontWeight: 400,
    cursor: 'pointer',
    '&:hover': {
      background: '#e8e8e8',
    },
  },
  item: {
    display: 'flex',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 18,
    fontWeight: 500,
    margin: '30px 0 0',
  },
  btn: {
    position: 'relative',
    background: '#2196f3',
    color: 'white',
    textTransform: 'uppercase',
    border: 'none',
    marginTop: 20,
    padding: 20,
    fontSize: 16,
    display: 'block',
    appearance: 'none',
    borderRadius: 4,
    width: '50%',
    fontWeight: 400,
    letterSpacing: '0.5rem',
    transition: '0.3s all',
    cursor: 'pointer',
    '&:disabled': {
      opacity: 0.4,
    },
  },
  input: {
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: 4,
    border: '1px solid black',
    padding: '10px 15px',
    fontSize: 14,
  },
  wrapper: {
    padding: '0 20px 50px',
    marginTop: 90,
  },
  p: {
    color: '#bf1650',
    margin: 0,
    marginBottom: 20,
    '&:before': {
      display: 'inline',
      content: '⚠',
    },
  },
  label: {
    width: '100%',
    marginBottom: 30,
    marginRight: 30,
  },
  text: {
    fontSize: 17,
    fontWeight: 600,
    marginBottom: 30,
    display: 'flex',
  },
  newBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
  textInput: {
    borderRadius: '8px'
  },
  formWrapper: {
    width: '50%'
  }
});

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const UsersTableAdmin = () => {
  const classes = useRowStyles();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [login, setLogin] = useState('');
  const [dataUser, setDataUser] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openPin, setOpenPin] = useState(false);
  const [answer, setAnswer] = useState('');
  const [warningState, setWarningState] = useState(false)
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState('')
  const [editMode, setEditMode] = useState(true)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openEditModal = () => {
    setEditMode(false);
  };

  const getUsers = async () => {
    setLoading(true)
    await chatApi
      .getAllUsersAdmin()
      .then(res => {
        setLoading(false)
        const array = res.result;
        setUsers(array);
      })
      .catch(e => console.log(e));
  };


  const infoUser = async login => {
    await chatApi
      .getUserAdmin(login)
      .then(res => {
        setDatas({ ...datas, firstName: res.result.firstName, lastName: res.result.lastName, patronymic: res.result.patronymic, email: res.result.email })
        setDataUser(res.result);
        setOpenPin(false);
      })
      .catch(e => {
        setDataUser(null)
        setWarningState(true)
      });
  };

  useEffect(() => {
    getUsers();
    checkRole();
  }, []);


  const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
  function isEmailValid(value) {
    return EMAIL_REGEXP.test(value);
  }

  const initial = { firstName: '', lastName: '', patronymic: '', email: '', langKey: 'ru', imageUrl: '', activate: true }

  const [datas, setDatas] = useState(initial)
  const [invLogin, setInvLogin] = useState(false)
  const [invName, setInvName] = useState(false)
  const [invFam, setInvFam] = useState(false)
  const [invEmail, setInvEmail] = useState(0)

  const valLogin = () => {
    if (datas.login.length < 1) {
      setInvLogin(true)
    }
    else {
      setInvLogin(false)
    }
  }

  const valName = () => {
    if (datas.firstName.length < 1) {
      setInvName(true)
    }
    else {
      setInvName(false)
    }
  }


  const valFam = () => {
    if (datas.lastName.length < 1) {
      setInvFam(true)
    }
    else {
      setInvFam(false)
    }
  }

  const valEmail = (e) => {
    const val = isEmailValid(e)
    if (val === true) {
      setInvEmail(0)
    }
    if (e.length < 1) {
      setInvEmail(2)
    }
    if (val === false && e.length > 1) {
      setInvEmail(1)
    }
  }

  const sub = async () => {
    valName()
    valFam()
    valEmail(datas.email)
    if (invName === false
      && invFam === false
      && invEmail === 0
      && datas.firstName.length > 1
      && datas.lastName.length > 1
      && datas.email.length > 1) {
      const logins = login;
      await chatApi.editUserAdmin(logins,
        datas.firstName,
        datas.lastName,
        datas.patronymic,
        datas.email,
        datas.imageUrl,
        datas.langKey = 'ru',
        datas.activate)
        .catch(e => console.log(e));
      setDatas(initial);
      setDataUser(null);
    }
  }

  const bottom = { marginBottom: 450 }

  useDebounce(
    async () => {
      const keys = ['lastName', 'firstName', 'login']
      if (!text) {
        return getUsers();
      }
      await chatApi
        .getAllUsersAdmin()
        .then(res => {
          const newFilter = res.result.filter((row) => keys.some(key => {
            if (row[key] !== null) {
              return row[key].toLowerCase().includes(text.toLowerCase())
            }
          }));
          setUsers(newFilter);
        })
        .catch(e => console.log(e));
    },
    700,
    [text]
  )

  const {t} = useTranslation()

  return (
    <>
      <div style={{ margin: '0' }}>
        <Loading active={loading} small={false} withOverlay={true} description={t("loadingAll")} />
        <div style={dataUser ? null : bottom}>
          {
            warningState &&
            <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
              <ToastNotification
                kind="warning"
                hideCloseButton={false}
                lowContrast={false}
                title="Данных не найдено"
                timeout={10000}
              />
            </div>
          }
          <div className={classes.title}>Пользователи</div>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <SearchBar value={text} placeholder='Поиск' onChange={searchVal => setText(searchVal)} style={{ marginBottom: 10, width: 300 }} />
          </div>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead style={{ background: '#e0e0e0' }}>
                <TableRow>
                  <TableCell className={classes.titleTable} align="center">ФИО</TableCell>
                  <TableCell className={classes.titleTable} align="center">
                    Тренажеры
                  </TableCell>
                  <TableCell className={classes.titleTable} align="center">
                    Организация
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0 ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : users).map((row, index) => (
                  <TableRow className={classes.bodyTable} onClick={() => {
                    setLogin(row.login)
                    infoUser(row.login)
                    setOpenEdit(false)
                    setWarningState(false)
                  }} key={index}>
                    <TableCell component="th" scope="row">
                      {`${row.lastName ? row.lastName : ''} ${row.firstName ? row.firstName : ''} ${row.patronymic ? row.patronymic : ''} (${row.login})`}
                    </TableCell>
                    <TableCell align="left">
                      {row.trainers.map((el, index) => {
                        return (
                          <div className={classes.item} key={index}>
                            {/* <div>{el.id}</div> */}
                            <div>{el.name}</div>
                          </div>
                        );
                      })}
                    </TableCell>
                    <TableCell align="left">
                      {row.organizations.map((el, index) => {
                        return (
                          <div className={classes.item} key={index}>
                            {/* <div>{el.id}</div> */}
                            <div>{el.name}</div>
                          </div>
                        );
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    style={{ background: '#E7F0FE' }}
                    rowsPerPageOptions={[5, 10, 25, { label: 'Все', value: -1 }]}
                    colSpan={3}
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        {dataUser &&
          <div>
            <div className={classes.formWrapper}>
              {editMode && <div className={classes.title}>Пользователь: {dataUser.lastName} {dataUser.firstName}</div>}
              {!editMode && <div className={classes.title}>Редактирование данных пользователя: {dataUser.lastName} {dataUser.firstName}</div>}
              <Form>
                <Stack gap={7}>
                  <TextInput
                    disabled={editMode}
                    id="test2"
                    invalid={invLogin}
                    invalidText="Поле обязательно к заполнению."
                    value={dataUser.login || '-'}
                    labelText="Логин"
                    onChange={(e) => setDatas({ ...datas, login: e.target.value })}
                    onBlur={valLogin}
                    style={{ borderRadius: 4 }}
                  />

                  <TextInput
                    disabled={editMode}
                    id="test2"
                    invalid={invFam}
                    invalidText="Поле обязательно к заполнению."
                    value={dataUser.lastName}
                    labelText="Фамилия"
                    onChange={(e) => setDatas({ ...datas, lastName: e.target.value })}
                    onBlur={valFam}
                    style={{ borderRadius: 4 }}
                  />
                  <TextInput
                    disabled={editMode}
                    id="test2"
                    invalid={invName}
                    required
                    invalidText="Поле обязательно к заполнению."
                    labelText="Имя"
                    value={dataUser.firstName}
                    onChange={(e) => setDatas({ ...datas, firstName: e.target.value })}
                    className={classes.textInput}
                    style={{ borderRadius: 4 }}
                    onBlur={valName}
                  />
                  <TextInput
                    id="test2"
                    disabled={editMode}
                    labelText="Отчество"
                    value={dataUser.patronymic || ''}
                    style={{ borderRadius: 4 }}
                    className={classes.textInput}
                    onChange={(e) => setDatas({ ...datas, patronymic: e.target.value })}
                  />
                  <TextInput
                    disabled={editMode}
                    id="test2"
                    invalid={invEmail === 1 || invEmail === 2 ? true : false}
                    invalidText={invEmail === 1 ? 'Неккоректный email.' : 'Поле обязательно к заполнению.'}
                    labelText="Email"
                    style={{ borderRadius: 4 }}
                    value={dataUser.email}
                    onChange={(e) => setDatas({ ...datas, email: e.target.value })}
                    className={classes.textInput}
                    onBlur={(e) => valEmail(e.target.value)}
                  />
                  <TextInput
                    disabled={editMode}
                    id="test2"
                    labelText="Ссылка на фото"
                    style={{ borderRadius: 4 }}
                    value={dataUser.imageUrl}
                    onChange={(e) => setDatas({ ...datas, imageUrl: e.target.value })}
                    className={classes.textInput}
                    onBlur={(e) => valEmail(e.target.value)}
                  />
                  {/* <Select
                        defaultValue="placeholder-item"
                        id="select-1"
                        invalidText="This is an invalid error message."
                        labelText="Cтрана"
                        className={classes.textInput}
                        onChange={(e) => setDatas({ ...datas, langKey: e.target.value })}
                      >
                        <SelectItem
                          text="Ru"
                          value="ru"
                        />
                        <SelectItem
                          text="En"
                          value="en"
                        />
                        <SelectItem
                          text="De"
                          value="de"
                        />
                      </Select> */}
                  <Select
                    defaultValue="placeholder-item"
                    disabled={editMode}
                    id="select-1"
                    invalidText="This is an invalid error message."
                    labelText="Активация"
                    style={{ borderRadius: 4 }}
                    className={classes.textInput}
                    onChange={(e) => setDatas({ ...datas, activate: e.target.value })}
                  >
                    <SelectItem
                      text="Да"
                      value={true}
                    />
                    <SelectItem
                      text="Нет"
                      value={false}
                    />
                  </Select>
                  <div className={classes.newBtn}>
                    <Button disabled={!editMode} onClick={openEditModal} style={{ borderRadius: 4 }}>Редактировать</Button>
                    <div>
                      <Button
                        disabled={editMode}
                        kind="primary"
                        tabIndex={0}
                        type="submit"
                        onClick={() => setEditMode(true)}
                        style={{ borderRadius: 4 }}
                      >
                        Отменить
                      </Button>
                      <Button
                        disabled={editMode}
                        kind="primary"
                        tabIndex={0}
                        onClick={sub}
                        style={{ marginLeft: 10, borderRadius: 4 }}
                      >
                        Сохранить
                      </Button>
                    </div>
                  </div>
                </Stack>
              </Form>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default UsersTableAdmin;
