import React, { useEffect, useState } from 'react';
import chatApi from '../../api/chatApi';
import { makeStyles } from '@material-ui/core/styles';
import { checkRole } from '../../common/checkRole';
import Paper from '@material-ui/core/Paper';
import { ToastNotification, Form, Stack, TextInput, Button } from '@carbon/react';

const useRowStyles = makeStyles({
  paper: {
    width: '50%',
    margin: '30px 0 50px 0',
    justifyContent: 'center'
  },
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 20,
    fontWeight: 500,
    margin: '20px 0',
  },
  submitBtn: {
    borderRadius: 4
  },
  notification: {
    position: 'fixed',
    right: 0,
    zIndex: 1000
  },
  form: {
    padding: 50
  },
});

const initialUserState = {
  login: '',
  lastName: '',
  firstName: '',
  patronymic: '',
  email: '',
  imageUrl: '',
  password: '',
  errors: {
    login: '',
    lastName: '',
    firstName: '',
    patronymic: '',
    email: '',
    imageUrl: '',
    password: '',
  }
};


const CreateUser = () => {
  const classes = useRowStyles();
  const [user, setUser] = useState(initialUserState);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isDuplicateData, setIsDuplicateData] = useState(false)
  const [isServerError, setIsServerError] = useState(false)

  const fieldLabels = {
    login: 'Логин',
    lastName: 'Фамилия',
    firstName: 'Имя',
    patronymic: 'Отчество',
    email: 'Email',
    imageUrl: 'Фото',
    password: 'Пароль',
  };

  useEffect(() => {
    checkRole();
  }, []);

  const validateField = (name, value) => {
    switch (name) {
      case 'Логин':
        if (value.length < 3) {
          return 'Логин должен быть длиннее 2 символов';
        }
        if (!/^[A-Za-z0-9_]+$/.test(value)) {
          return 'Логин может содержать только латинские буквы, цифры и подчеркивания';
        }
        return '';
      case 'Фамилия':
        if (value.length < 2) {
          return 'Длина должна быть не менее 2 символов';
        }
        return '';
      case 'Имя':
        if (value.length < 2) {
          return 'Длина должна быть не менее 2 символов';
        }
        return '';
      case 'Email':
        if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(value)) {
          return 'Некорректный email';
        }
        return '';
      case 'Пароль':
        if (value.length < 4) {
          return 'Пароль менее 4 символов';
        }
        return '';
      default:
        return '';
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const errors = { ...user.errors, [name]: validateField(name, value) };
    setUser({ ...user, [name]: value, errors });
  };

  const handleSubmit = async () => {
    const { login, lastName, firstName, patronymic, email, photo, password } = user;
    const errors = {
      login: validateField('Логин', login),
      lastName: validateField('Фамилия', lastName),
      firstName: validateField('Имя', firstName),
      patronymic: validateField('Отчество', patronymic),
      email: validateField('Email', email),
      photo: validateField('Фото', photo),
      password: validateField('Пароль', password),
    };

    setUser({ ...user, errors });

    if (Object.values(errors).every((error) => !error)) {
      try {
        await chatApi.createUserAdmin(login, firstName, lastName, user.patronymic, email, user.imageUrl, 'ru', password);
        setSuccess(true);
        setTimeout(() => setSuccess(false), 5000);
        setUser(initialUserState);
      } catch (e) {
        if (e.response && (e.response.data.description.reason === 'user exist in jhiUser' || e.response.data.title === 'Email is already in use!')) {
          setIsDuplicateData(true)
        } else {
          setIsServerError(true)
          console.error(e);
        }
      }
    } else {
      setError(true);
    }
  };

  const handleNotificationClose = () => {
    setIsDuplicateData(false);
    setIsServerError(false)
    setError(false);
  };


  return (
    <div className={classes.root}>
      {success && (
        <div className={classes.notification}>
          <ToastNotification
            kind="success"
            title="Успешно"
            subtitle="Пользователь создан"
            timeout={5000}
          />
        </div>
      )
      }

      {error && (
        <div className={classes.notification}>
          <ToastNotification
            kind="error"
            title="Ошибка"
            subtitle="Проверьте правильность введенных данных"
            timeout={5000}
          />
        </div>
      )}
      {isDuplicateData && (
        <div className={classes.notification}>
          <ToastNotification
            kind="error"
            title="Ошибка"
            subtitle="Пользователь с таким логином и/или email уже существует"
            timeout={5000}
            onClose={handleNotificationClose}
          />
        </div>
      )}
      {isServerError && (
        <div className={classes.notification}>
          <ToastNotification
            kind="error"
            title="Ошибка"
            subtitle="Ошибка сервера"
            timeout={5000}
            onClose={handleNotificationClose}
          />
        </div>
      )}
      <Paper className={classes.paper}>
        <Form className={classes.form}>
          <Stack gap={7}>
            {Object.keys(user.errors).map((fieldName) => (
              fieldName !== 'password' ? (
                <TextInput
                  key={fieldName}
                  id={fieldName}
                  name={fieldName}
                  labelText={fieldLabels[fieldName]}
                  value={user[fieldName]}
                  onChange={handleInputChange}
                  invalid={!!user.errors[fieldName]}
                  invalidText={user.errors[fieldName]}
                  className={classes.textInput}
                />
              ) : (
                <TextInput.PasswordInput
                  key={fieldName}
                  id={fieldName}
                  name={fieldName}
                  labelText={fieldLabels[fieldName]}
                  value={user[fieldName]}
                  onChange={handleInputChange}
                  invalid={!!user.errors[fieldName]}
                  invalidText={user.errors[fieldName]}
                  className={classes.passwordInput}
                />
              )
            ))}
            <Button
              className={classes.submitBtn}
              onClick={handleSubmit}>
              Создать пользователя
            </Button>
          </Stack>
        </Form>
      </Paper>

    </div>
  );
};

export default CreateUser;
