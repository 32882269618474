import React, { useEffect, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Man1(props) {
  const { nodes, materials, animations } = useGLTF('patient/young_male_animation.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)

  useEffect(() => {
    props.setAnim(animations)
    actions.rigAction.play()
  }, [actions, names, mixer])

  return (
    <group ref={ref} {...props} dispose={null}>
      <group position={[0, 0.9, -0.02]} rotation={[0.25, 0, 0]}>
        <group position={[0, 0.14, 0]} rotation={[-0.11, 0, 0]}>
          <group position={[0, 0.13, 0]} rotation={[-0.4, 0, 0]}>
            <group position={[0, 0.16, 0]} rotation={[0.34, 0, 0]}>
              <group position={[0, 0.16, 0]} rotation={[0.53, 0, 0]}>
                <group position={[0, 0.05, 0]} rotation={[-0.15, 0.01, -0.02]}>
                  <group position={[0, 0.05, 0]} rotation={[-0.56, -0.02, 0.01]}>
                    <group rotation={[-0.27, 0, 0]}>
                      <group position={[0, 0.07, 0.13]} rotation={[3.08, 0, 0]}>
                        <group position={[0, 0.03, 0]} rotation={[-0.21, 0, 0]}>
                          <group position={[0, 0.02, 0]} rotation={[1.29, 0, 0]}>
                            <group position={[0, 0.01, 0]} rotation={[0.41, 0, 0]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.01, 0.12]} rotation={[-1.22, 0.08, -1.16]} />
                      <group position={[0, 0, 0.11]} rotation={[-1.06, -0.15, -1.23]} />
                      <group position={[0, -0.01, 0.03]} rotation={[1.89, 0, 0.01]}>
                        <group position={[0, 0.05, 0]} rotation={[-0.73, 0, -0.08]} />
                      </group>
                      <group position={[0.07, 0.08, 0.03]} rotation={[0.17, -0.18, -0.19]}>
                        <group position={[0, 0.04, 0]} rotation={[-1.48, 0.02, -0.28]}>
                          <group position={[0, 0.02, 0]} rotation={[-1.39, -0.52, -0.04]}>
                            <group position={[0, 0.02, 0]} rotation={[-0.55, -0.61, 0.35]} />
                          </group>
                        </group>
                      </group>
                      <group position={[-0.07, 0.08, 0.03]} rotation={[0.17, 0.18, 0.19]}>
                        <group position={[0, 0.04, 0]} rotation={[-1.48, -0.02, 0.28]}>
                          <group position={[0, 0.02, 0]} rotation={[-1.39, 0.52, 0.04]}>
                            <group position={[0, 0.02, 0]} rotation={[-0.55, 0.61, -0.35]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.01, 0.12]} rotation={[-1.22, -0.08, 1.16]} />
                      <group position={[0, 0, 0.11]} rotation={[-1.06, 0.15, 1.23]} />
                      <group position={[0.06, 0.09, 0.11]} rotation={[-0.66, 1, 2.15]}>
                        <group position={[0, 0.02, 0]} rotation={[-0.31, 0.8, -0.15]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.22, 0.43, -0.49]} />
                        </group>
                      </group>
                      <group position={[0.05, 0.08, 0.11]} rotation={[-0.63, 1.1, 1.85]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.54, 0.74, -0.04]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.32, 0.35, -0.35]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.19, 0.59, -0.64]}>
                              <group position={[0, 0.01, 0]} rotation={[1.04, 0.07, -2.41]}>
                                <group position={[0, 0.01, 0]} rotation={[0.51, 0.12, 0.09]}>
                                  <group position={[0, 0.01, 0]} rotation={[0.07, 0.24, 0.58]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.06, 0.09, 0.11]} rotation={[-0.66, -1, -2.15]}>
                        <group position={[0, 0.02, 0]} rotation={[-0.31, -0.8, 0.15]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.22, -0.43, 0.49]} />
                        </group>
                      </group>
                      <group position={[-0.05, 0.08, 0.11]} rotation={[-0.63, -1.1, -1.85]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.54, -0.74, 0.04]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.32, -0.35, 0.35]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.19, -0.59, 0.64]}>
                              <group position={[0, 0.01, 0]} rotation={[1.04, -0.07, 2.41]}>
                                <group position={[0, 0.01, 0]} rotation={[0.51, -0.12, -0.09]}>
                                  <group position={[0, 0.01, 0]} rotation={[0.07, -0.24, -0.58]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.02, 0.14, 0.14]} rotation={[-1.89, 1.49, -1.19]}>
                        <group position={[-0.01, 0.01, 0.03]} rotation={[-0.12, -0.28, 0.1]}>
                          <group position={[-0.02, 0, 0.02]} rotation={[0.17, -0.55, 0.15]}>
                            <group position={[-0.02, 0.01, 0.04]} rotation={[0.13, -0.71, 0.12]}>
                              <group position={[0, 0.07, 0]} rotation={[-0.03, -0.25, 0.21]}>
                                <group position={[0, 0.05, 0]} rotation={[-0.44, -0.67, 0.68]}>
                                  <group position={[0, 0.07, 0]} rotation={[-1.9, -0.01, 1.12]}>
                                    <group position={[0, 0.04, 0]} rotation={[-1.6, -0.29, -0.51]}>
                                      <group position={[0, 0.05, 0]} rotation={[1.01, 0.49, -0.01]}>
                                        <group position={[0, 0.05, 0]} rotation={[0.64, 0.49, 0.19]}>
                                          <group position={[0, 0.03, 0]} rotation={[-1.99, 0.71, 2.37]}>
                                            <group position={[0, 0.03, 0]} rotation={[-0.05, 0.89, -0.66]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.02, 0.14, 0.14]} rotation={[-1.89, -1.49, 1.19]}>
                        <group position={[0.01, 0.01, 0.03]} rotation={[-0.12, 0.28, -0.1]}>
                          <group position={[0.02, 0, 0.02]} rotation={[0.17, 0.55, -0.15]}>
                            <group position={[0.02, 0.01, 0.04]} rotation={[0.13, 0.71, -0.12]}>
                              <group position={[0, 0.07, 0]} rotation={[-0.03, 0.25, -0.21]}>
                                <group position={[0, 0.05, 0]} rotation={[-0.44, 0.67, -0.68]}>
                                  <group position={[0, 0.07, 0]} rotation={[-1.9, 0.01, -1.12]}>
                                    <group position={[0, 0.04, 0]} rotation={[-1.6, 0.29, 0.51]}>
                                      <group position={[0, 0.05, 0]} rotation={[1.01, -0.49, 0.01]}>
                                        <group position={[0, 0.05, 0]} rotation={[0.64, -0.49, -0.19]}>
                                          <group position={[0, 0.03, 0]} rotation={[-1.99, -0.71, -2.37]}>
                                            <group position={[0, 0.03, 0]} rotation={[-0.05, -0.89, 0.66]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.06, 0.08, 0.08]} rotation={[-2.9, -1.01, 0.85]}>
                        <group position={[0, 0.05, 0]} rotation={[2.86, -0.53, 1.67]}>
                          <group position={[0, 0.04, 0]} rotation={[2.46, 0.75, 1.64]} />
                        </group>
                      </group>
                      <group position={[-0.07, 0.08, 0.08]} rotation={[-2.9, 1.01, -0.85]}>
                        <group position={[0, 0.05, 0]} rotation={[2.86, 0.53, -1.67]}>
                          <group position={[0, 0.04, 0]} rotation={[2.46, -0.75, -1.64]} />
                        </group>
                      </group>
                      <group position={[0, 0.01, 0.09]} rotation={[-1, 0, 0]}>
                        <group position={[0, 0.02, 0]} rotation={[-0.38, 0, 0]} />
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[0.03, 0.16, 0.05]} rotation={[-1.65, -0.01, -1.27]}>
                <group position={[0.01, 0.17, 0]} rotation={[1.63, 1.4, -2.67]}>
                  <group position={[0, 0.27, 0]} rotation={[0.85, 0.33, 0.15]}>
                    <group position={[0, 0.3, 0]} rotation={[0.04, -0.09, -0.23]}>
                      <group position={[-0.01, 0.03, 0.02]} rotation={[2.99, 1.49, -2.87]}>
                        <group position={[0, 0.07, 0]} rotation={[0.15, -0.14, 0.11]}>
                          <group position={[0, 0.04, 0]} rotation={[0.16, -0.21, -0.06]} />
                        </group>
                        <group position={[-0.01, -0.02, 0.02]} rotation={[-1.09, 1.01, 1.47]}>
                          <group position={[0, 0.05, 0]} rotation={[0.15, 0.04, 0]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.03, 0]} rotation={[2.34, 1.51, -2.29]}>
                        <group position={[0, 0.07, 0]} rotation={[0.12, -0.32, -0.14]}>
                          <group position={[0, 0.04, 0]} rotation={[0.15, -0.26, 0.06]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.03, -0.01]} rotation={[0.64, 1.51, -0.72]}>
                        <group position={[0, 0.07, 0]} rotation={[0.11, -0.44, -0.15]}>
                          <group position={[0, 0.03, 0]} rotation={[0.07, -0.18, -0.05]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.03, -0.03]} rotation={[0.22, 1.47, -0.43]}>
                        <group position={[0, 0.06, 0]} rotation={[0.19, -0.66, -0.1]}>
                          <group position={[0, 0.03, 0]} rotation={[0, -0.1, -0.14]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[-0.03, 0.16, 0.05]} rotation={[-1.65, 0.01, 1.27]}>
                <group position={[-0.01, 0.17, 0]} rotation={[1.63, -1.4, 2.67]}>
                  <group position={[0, 0.27, 0]} rotation={[0.85, -0.33, -0.15]}>
                    <group position={[0, 0.3, 0]} rotation={[0.04, 0.09, 0.23]}>
                      <group position={[0.01, 0.03, 0.02]} rotation={[2.99, -1.49, 2.87]}>
                        <group position={[0, 0.07, 0]} rotation={[0.15, 0.14, -0.11]}>
                          <group position={[0, 0.04, 0]} rotation={[0.16, 0.21, 0.06]} />
                        </group>
                        <group position={[0.01, -0.02, 0.02]} rotation={[-1.09, -1.01, -1.47]}>
                          <group position={[0, 0.05, 0]} rotation={[0.15, -0.04, 0]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.03, 0]} rotation={[2.34, -1.51, 2.29]}>
                        <group position={[0, 0.07, 0]} rotation={[0.12, 0.32, 0.14]}>
                          <group position={[0, 0.04, 0]} rotation={[0.15, 0.26, -0.06]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.03, -0.01]} rotation={[0.64, -1.51, 0.72]}>
                        <group position={[0, 0.07, 0]} rotation={[0.11, 0.44, 0.15]}>
                          <group position={[0, 0.03, 0]} rotation={[0.07, 0.18, 0.05]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.03, -0.03]} rotation={[0.22, -1.47, 0.43]}>
                        <group position={[0, 0.06, 0]} rotation={[0.19, 0.66, 0.1]}>
                          <group position={[0, 0.03, 0]} rotation={[0, 0.1, 0.14]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
        <group position={[0.09, 0.06, 0.02]} rotation={[2.84, 0, -0.16]}>
          <group position={[0, 0.43, 0]} rotation={[0.13, 0.03, 0.04]}>
            <group position={[0, 0.44, 0]} rotation={[-1.1, -0.1, 0.05]} />
          </group>
        </group>
        <group position={[-0.09, 0.06, 0.02]} rotation={[2.84, 0, 0.16]}>
          <group position={[0, 0.43, 0]} rotation={[0.13, -0.03, -0.04]}>
            <group position={[0, 0.44, 0]} rotation={[-1.1, 0.1, -0.05]} />
          </group>
        </group>
      </group>
      <group name="rig">
        <primitive object={nodes.root} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
        <primitive object={nodes['MCH-torsoparent']} />
        <primitive object={nodes['MCH-hand_ikparentL']} />
        <primitive object={nodes['MCH-hand_ikparentR']} />
        <primitive object={nodes['MCH-foot_ikparentL']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
        <primitive object={nodes['MCH-foot_ikparentR']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
        <skinnedMesh
          geometry={nodes.young_male_CLOTH_A.geometry}
          material={materials.CLOTH}
          skeleton={nodes.young_male_CLOTH_A.skeleton}
        />
        <skinnedMesh
          geometry={nodes.young_male_CLOTH_B.geometry}
          material={materials['young_asian_male.010']}
          skeleton={nodes.young_male_CLOTH_B.skeleton}
        />
        <skinnedMesh
          geometry={nodes.young_male_sculpt006.geometry}
          material={materials['young_asian_male.005']}
          skeleton={nodes.young_male_sculpt006.skeleton}
        />
        <skinnedMesh
          geometry={nodes.youngmale.geometry}
          material={materials['Eye_ice.002']}
          skeleton={nodes.youngmale.skeleton}
        />
        <skinnedMesh
          geometry={nodes.youngmale_1.geometry}
          material={materials.bodyMaterial}
          skeleton={nodes.youngmale_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.youngmalehairs.geometry}
          material={materials.mhair01black}
          skeleton={nodes.youngmalehairs.skeleton}
        />
      </group>
    </group>
  )
}

// useGLTF.preload('patient/young_male_animation.glb')
