import React from 'react'
import ChatPagesMenu from '../../../components/Chat/ChatPagesMenu'
import SlotContainer from '../../../components/common/Slots/SlotContainer'
import MainLayout from '../../../components/Layout/MainLayout'
import DemoSlotWithPhoto from '../../../components/PageBlocks/DemoBlocks/DemoSlotWithPhoto'
import DemoSlotWithText from '../../../components/PageBlocks/DemoBlocks/DemoSlotWithText'
import InstrumentalSelectBlock from '../../../components/PageBlocks/Research/Instrumental/InstrumentalSelectBlock'
import BloodTableBlock from '../../../components/PageBlocks/Research/Lab/BloodTableBlock'
import LabSelectBlock from '../../../components/PageBlocks/Research/Lab/LabSelectBlock'
import UrinaTableBlock from '../../../components/PageBlocks/Research/Lab/UrinaTableBlock'
import { CHAT_MENU_ITEMS } from '../../../constants/default'

export default function ResearchFilledPage() {
  return (
    <MainLayout desktopHeader={<ChatPagesMenu menuItems={CHAT_MENU_ITEMS} />}>
      <SlotContainer>
        <div>
          <LabSelectBlock />

          <BloodTableBlock />

          <UrinaTableBlock />
        </div>
        <div>
          <InstrumentalSelectBlock />

          <DemoSlotWithText />
          <DemoSlotWithPhoto />
        </div>
      </SlotContainer>
    </MainLayout>
  )
}
