import CssBaseline from '@material-ui/core/CssBaseline'
// import { ThemeProvider } from '@material-ui/core/styles';
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import { persistor, runSaga, store } from './store'
import rootSaga from './store/sagas/index'

import './translation/translation'

runSaga(rootSaga)

ReactDOM.render(
  <Suspense fallback={<div>Загрузка</div>}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <App />
      </PersistGate>
    </Provider>
  </Suspense>,
  document.querySelector('#root')
)
