import React, { useState, useEffect } from "react";
import { allMethodological, allMethodologicalTask, getMediaContent, getTaskMaterial, setLibrariesReference } from "../../api/chatApi/chatApi";
import ruMessages from "devextreme/localization/messages/ru.json";
import { locale, loadMessages } from "devextreme/localization";
import MainFooter from "../../components/MainFooter/MainFooter";
import MainHeader from "../../components/MainHeader/MainHeader";
import { makeStyles } from '@material-ui/core/styles';
import { TreeView, SearchEditorOptions } from 'devextreme-react/tree-view';
import { Folder, Image, Document, Play, Video } from '@carbon/icons-react';
//@ts-ignore
import ImgChamp from '../../assets/img/social_link/ImgChamp.svg';
import { getTestCognitive } from "../../api/chatApi/chatApi";
import { useLocation } from "react-router-dom";
//@ts-ignore
import { ModalWrapper, Button, Loading } from '@carbon/react';
import { TrashCan, Edit, Add } from '@carbon/icons-react';
import MainModal from './Modal';
// @ts-ignore
import jwt from 'jsonwebtoken';
import ModalContent from "./ModalContent";

const useStyles = makeStyles({
  wrapperInfo: {
    display: 'flex',
    width: '100%',
    // flexFlow: 'row nowrap',
    // marginTop: 48,
    marginBottom: 20,
    maxHeight: 400
  },
  wrapperText: {
    backgroundColor: 'black',
    flexGrow: 5,
    marginTop: 48,
  },
  wrapperImg: {
    flexGrow: 5
  },
  titel: {
    color: 'white',
    fontFamily: 'Helvetica Neue',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 32,
    margin: '24px 0 40px 87px',
  },
  text: {
    color: 'white',
    maxWidth: 700,
    fontFamily: 'Helvetica Neue',
    fontWeight: 400,
    fontSize: 17,
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 0 24px 87px',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  el: {
    marginLeft: 10
  }
});

const MainMethodological = () => {
  const roleDecode = jwt.decode(localStorage.getItem('role'));
  const role = roleDecode.auth.split(',');
  useEffect(() => {
    loadMessages(ruMessages);
    locale(navigator.language)
  }, []);

  const classes = useStyles();
  const [data, setData] = useState([])
  const [item, setItem] = useState([])
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [switchMy, setSwitchMy] = useState<boolean>(false)


  const [openContent, setOpenContent] = useState(false)
  const [elem, setElem] = useState<any>([])

  const clickContent = (item) => {
    setElem(item)
    setOpenContent(true)
  }

  const [reRequest, setReRequest] = useState(false)

  const location = useLocation();
  const [toggle, setToggle] = useState(false)


  const getAllMethodological = async () => {
    setSwitchMy(true)
    setReRequest(false)
    setToggle(false)
    await allMethodological()
      .then(res => setData(res.data.libraries_reference))
      .catch(err => console.log(err))
      .finally(() => setSwitchMy(false))
  }



  const getGroup = async () => {
    setSwitchMy(true)
    setToggle(true)
    await getTaskMaterial(false)
      .then(res => {
        const group = res?.data.ducational_trajectory
        if (group.length > 0) {
          allMethodologicalTask(group)
            .then(res => setData(res.data.libraries_reference_group_theme))
            .catch(err => console.log(err))
        }
      })
      .catch(err => console.log(err))
      .finally(() => setSwitchMy(false))
  }

  const getGroup2 = async (loc) => {
    setSwitchMy(true)
    setReRequest(false)
    setToggle(false)
    await getTaskMaterial(true, Number(loc))
      .then(res => {
        const group = res?.data.ducational_trajectory
        allMethodologicalTask(group)
          .then(res => setData(res.data.libraries_reference_group_theme))
      })
      .catch(err => console.log(err))
      .finally(() => setSwitchMy(false))
  }

  useEffect(() => {
    if (location.state) {
      getGroup2(location.state)
    }
    else {
      getAllMethodological()
    }
  }, [reRequest]);

  const changeButton = (item) => {
    setItem(item)
    setTitle('Редактирование')
    setOpen(true)
  }

  const createButton = (item) => {
    setItem(item)
    setTitle('Создать')
    setOpen(true)
  }

  const deleteButton = async (item) => {
    await setLibrariesReference(false, 0, item.name, item.p_id, item.theme, item.type, item.id)
      .then(res => setReRequest(true))
      .catch(err => console.log(err))
  }

  const clickNew = () => {
    const itemMy = {
      p_id: -1,
      type: 1
    }
    setItem(itemMy)
    setTitle('Создать раздел верхнего уровня')
    setOpen(true)
  }



  const Item = (item) => {

    return (
      <div style={{ display: 'flex', fontFamily: 'Helvetica Neue', flexDirection: 'column' }}>
        {item.type === 1
          ?
          <div style={{ display: 'flex', marginTop: 4, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <Folder style={{ marginRight: 5 }} />
              <div>{item.name}</div>
            </div>
            {role.includes('ROLE_ADMIN') &&
              <div>
                <Add className={classes.el} onClick={() => createButton(item)} />
                <Edit className={classes.el} onClick={() => changeButton(item)} />
                <TrashCan className={classes.el} onClick={() => deleteButton(item)} />
              </div>
            }

          </div>

          :
          null
        }

        {item.type === 2
          ?
          <div style={{ display: 'flex', marginTop: 4, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <Document style={{ marginRight: 5 }} />
              <div onClick={() => clickContent(item)}>{item.name}</div>
            </div>
            {role.includes('ROLE_ADMIN') &&
              <div>
                <Edit className={classes.el} onClick={() => changeButton(item)} />
                <TrashCan className={classes.el} onClick={() => deleteButton(item)} />
              </div>
            }
          </div>

          :
          null
        }

        {item.type === 3
          ?
          <div style={{ display: 'flex', marginTop: 4, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', width: '80%' }} onClick={() => clickContent(item)}>
              <Document style={{ marginRight: 5 }} />
              <div>{item.name}</div>
            </div>
            {role.includes('ROLE_ADMIN') &&
              <div>
                <Edit className={classes.el} onClick={() => changeButton(item)} />
                <TrashCan className={classes.el} onClick={() => deleteButton(item)} />
              </div>
            }
          </div>

          :
          null
        }

        {item.type === 4
          ?
          <div style={{ display: 'flex', marginTop: 4, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <Image style={{ marginRight: 5 }} />
              <div onClick={() => clickContent(item)}>{item.name}</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {role.includes('ROLE_ADMIN') &&
                <div>
                  <Edit className={classes.el} onClick={() => changeButton(item)} />
                  <TrashCan className={classes.el} onClick={() => deleteButton(item)} />
                </div>
              }
            </div>

          </div>

          :
          null
        }

        {item.type === 5
          ?
          <div style={{ display: 'flex', marginTop: 4, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <Play style={{ marginRight: 5 }} />
              <div onClick={() => clickContent(item)}>{item.name}</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {role.includes('ROLE_ADMIN') &&
                <div>
                  <Edit className={classes.el} onClick={() => changeButton(item)} />
                  <TrashCan className={classes.el} onClick={() => deleteButton(item)} />
                </div>
              }
            </div>
          </div>

          :
          null
        }

        {item.type === 6
          ?
          <div style={{ display: 'flex', marginTop: 4, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <Video style={{ marginRight: 5 }} />
              <div onClick={() => clickContent(item)}>{item.name}</div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {role.includes('ROLE_ADMIN') &&
                <div>
                  <Edit className={classes.el} onClick={() => changeButton(item)} />
                  <TrashCan className={classes.el} onClick={() => deleteButton(item)} />
                </div>
              }
            </div>
          </div>

          :
          null
        }

      </div>

    )
  }

  const searchExpr = ["medicine_name", "name", "text"];

  return (
    <>
      <MainHeader />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ModalContent open={openContent} setOpen={setOpenContent} item={elem} />
        <Loading active={switchMy} small={false} withOverlay={true} description="Loading..." />
        <MainModal open={open} title={title} setOpen={setOpen} item={item} setReRequest={setReRequest} />
        <div className={classes.wrapperInfo}>
          <div className={classes.wrapperText}>
            <h2 className={classes.titel}>Методические материалы</h2>
            <div className={classes.text}>
              Информационно-методические материалы - это банк документов, в котором собраны все учебные пособия,
              клинические и методические рекомендации, сведения, информационные материалы, перечни и памятки,
              благодаря которым Вы сможете эффективно решать ситуационные задачи в тренажёре NeuroDoc
            </div>
          </div>
          <div className={classes.wrapperImg}>
            <img className={classes.img} src={ImgChamp} alt='img' />
          </div>
        </div>
        <div style={{ width: '90%' }}>
          <div style={{ display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <div>
              <Button
                onClick={() => getGroup()}
                style={{ maxWidth: window.innerWidth < 500 ? 300 : 394, marginBottom: 10, borderRadius: 4 }}>
                Индивидуальный профессиональный маршрут
              </Button>
              <Button style={{ marginLeft: 10, marginBottom: 10, borderRadius: 4 }}
                disabled={toggle ? false : true}
                onClick={() => location.state ? getGroup2(location.state) : getAllMethodological()}
              >
                Отмена
              </Button>
            </div>

            {role.includes('ROLE_ADMIN') &&
              <Button style={{ marginLeft: 10, marginBottom: 10, borderRadius: 4 }}
                // disabled={toggle ? false : true}
                onClick={() => clickNew()}
              >
                Добавить раздел верхнего уровня
              </Button>
            }
          </div>
          {data.length > 0
            ?
            <div style={{ margin: '0px 0px 160px 0px' }}>
              <TreeView
                keyExpr="key"
                displayExpr="name"
                itemsExpr="child"
                dataSource={data}
                searchEnabled={true}
                searchExpr={searchExpr}
                searchMode="startswith"
                itemRender={Item}
              >

                <SearchEditorOptions
                  placeholder="Поиск"
                />
              </TreeView>
              {Item(data)}
            </div>
            :
            <div style={{ marginLeft: 50, fontSize: 20, marginBottom: 40 }}>Не найдены данные</div>
          }
        </div>
      </div>
      <MainFooter />
    </>
  );
}

export default MainMethodological;
