import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  createStyles,
  makeStyles
} from '@material-ui/core'
import { wrap } from 'module'
import React from 'react'

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      color: '#000000',
      margin: '24px 0 16px 0'
    },
    stage: {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #DADADA',
      borderRadius: '2px',
      width: '500px',
      height: '255px',
      marginBottom: 20,
      marginRight: 20
    },
    num: {
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      color: '#000000',
      marginBottom: 5
    },
    btn: {
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      textTransform: 'uppercase',
      color: '#9A9A9A',
      border: '1px solid #9A9A9A',
      borderRadius: 18,
      padding: '10px 23px',
      backgroundColor: 'white',
      marginBottom: 24,
      cursor: 'pointer'
    },
    btn1: {
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      textTransform: 'uppercase',
      color: 'white',
      border: '1px solid #9A9A9A',
      borderRadius: 18,
      padding: '10px 170px',
      backgroundColor: '#9A9A9A',
      marginBottom: 50,
      cursor: 'pointer'
    }
  })
)

const ChampStages = props => {
  const style = useStyles()
  const addNewPost = e => {
    const newStage = {
      id: props.data.length + 1,
      date: '',
      description: '',
      task: ''
    }
    props.create(newStage)
  }

  const changeText = (index, names, e) => {
    props.setStages(
      props.data.map((obj, i) => {
        if (index === i) {
          obj[names] = e
        }
        return obj
      })
    )
  }

  return (
    <div>
      <div className={style.title}>Этапы</div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {props.data.map((e: any, index) => {
          return (
            <div className={style.stage}>
              <div style={{ padding: 15 }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div className={style.num}>Этап {e.id}</div>
                  <div onClick={() => props.remove(index)}>X</div>
                </div>
                <FormControl
                  style={{ width: 229, marginRight: 41, marginBottom: 10 }}
                  variant='outlined'
                >
                  <Select
                    placeholder='Формат'
                    value={props.date}
                    onChange={e => changeText(index, 'date', e.target.value)}
                  >
                    <MenuItem value='Все'>Все</MenuItem>
                    <MenuItem value='Открытые'>Открытые</MenuItem>
                    <MenuItem value='Закрытые'>Закрытые</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  value={props.description}
                  onChange={e =>
                    changeText(index, 'description', e.target.value)
                  }
                  style={{ width: 468, marginBottom: 16 }}
                  label='Описание этапа'
                  variant='outlined'
                />
                <TextField
                  value={props.task}
                  onChange={e => changeText(index, 'task', e.target.value)}
                  style={{ width: 468, marginBottom: 16 }}
                  label='Задачи'
                  variant='outlined'
                />
              </div>
            </div>
          )
        })}
      </div>
      <div>
        <button className={style.btn} onClick={addNewPost}>
          Добавить этап
        </button>
      </div>
      <div>
        <button className={style.btn1}>Создать чемпионат</button>
      </div>
    </div>
  )
}

export default ChampStages
