import React, { useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Aim(props) {
  const { nodes, materials, animations } = useGLTF('patient/PACIENT_A.blend_anim.glb')

  const { ref, actions, names, mixer } = useAnimations(animations)

  useEffect(() => {
    actions.rigAction.play()
  }, [actions, names, mixer])
  
  return (
    <group ref={ref} {...props} dispose={null}>
      <group position={[-0.01, 3.23, -0.11]} rotation={[0.25, 0, 0]}>
        <group position={[0, 0.49, 0]} rotation={[-0.13, 0, 0]}>
          <group position={[0, 0.44, 0]} rotation={[-0.16, 0, 0]}>
            <group position={[0, 0.55, 0]}>
              <group position={[0, 0.62, 0]} rotation={[0.41, 0, 0]}>
                <group position={[0, 0.21, 0]} rotation={[0.01, 0, -0.01]}>
                  <group position={[0, 0.14, 0]} rotation={[-0.4, 0, 0.01]}>
                    <group position={[0, -0.01, 0]} rotation={[-0.02, 0, 0]}>
                      <group position={[0, 0.39, 0.41]} rotation={[2.8, 0, -0.02]}>
                        <group position={[0, 0.11, 0]} rotation={[-0.1, 0, 0.05]}>
                          <group position={[0, 0.07, 0]} rotation={[1.64, -0.04, 0]}>
                            <group position={[0, 0.04, 0]} rotation={[0.35, 0, 0]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.14, 0.42]} rotation={[-1.5, -0.02, -1.3]} />
                      <group position={[0, 0.09, 0.41]} rotation={[-1.36, -0.19, -1.13]} />
                      <group position={[0, -0.06, 0.19]} rotation={[1.46, 0, 0]}>
                        <group position={[0, 0.18, 0]} rotation={[-0.81, 0, 0]} />
                      </group>
                      <group position={[0.22, 0.27, 0.05]} rotation={[0.09, -0.22, -0.32]}>
                        <group position={[0, 0.07, 0]} rotation={[-0.94, -0.43, -0.19]}>
                          <group position={[0, 0.13, 0]} rotation={[-2.32, -0.63, -0.17]}>
                            <group position={[0, 0.12, 0]} rotation={[-0.34, -0.63, 0.45]} />
                          </group>
                        </group>
                      </group>
                      <group position={[-0.22, 0.27, 0.05]} rotation={[0.09, 0.22, 0.32]}>
                        <group position={[0, 0.07, 0]} rotation={[-0.94, 0.43, 0.19]}>
                          <group position={[0, 0.13, 0]} rotation={[-2.32, 0.63, 0.17]}>
                            <group position={[0, 0.12, 0]} rotation={[-0.34, 0.63, -0.45]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.14, 0.42]} rotation={[-1.5, 0.02, 1.3]} />
                      <group position={[0, 0.09, 0.41]} rotation={[-1.36, 0.19, 1.13]} />
                      <group position={[0.21, 0.41, 0.32]} rotation={[-0.85, 0.8, 2.04]}>
                        <group position={[0, 0.05, 0]} rotation={[-0.17, 0.77, -0.16]}>
                          <group position={[0, 0.05, 0]} rotation={[-0.13, 0.4, -0.62]} />
                        </group>
                      </group>
                      <group position={[0.18, 0.37, 0.31]} rotation={[-1.17, 1.17, 2.44]}>
                        <group position={[0, 0.05, 0]} rotation={[-0.13, 0.85, -0.34]}>
                          <group position={[0, 0.04, 0]} rotation={[-0.22, 0.3, -0.86]}>
                            <group position={[0, 0.05, 0]} rotation={[-0.54, 0.59, -0.13]}>
                              <group position={[0, 0.05, 0]} rotation={[1.02, -0.16, -2.52]}>
                                <group position={[0, 0.04, 0]} rotation={[0.33, 0.17, 0.05]}>
                                  <group position={[0, 0.04, 0]} rotation={[0.31, 0.19, 0.6]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.21, 0.41, 0.32]} rotation={[-0.85, -0.8, -2.03]}>
                        <group position={[0, 0.05, 0]} rotation={[-0.17, -0.77, 0.16]}>
                          <group position={[0, 0.05, 0]} rotation={[-0.13, -0.4, 0.62]} />
                        </group>
                      </group>
                      <group position={[-0.18, 0.37, 0.31]} rotation={[-1.17, -1.17, -2.43]}>
                        <group position={[0, 0.05, 0]} rotation={[-0.13, -0.85, 0.34]}>
                          <group position={[0, 0.04, 0]} rotation={[-0.22, -0.3, 0.86]}>
                            <group position={[0, 0.05, 0]} rotation={[-0.54, -0.59, 0.13]}>
                              <group position={[0, 0.05, 0]} rotation={[1.02, 0.16, 2.52]}>
                                <group position={[0, 0.04, 0]} rotation={[0.33, -0.17, -0.05]}>
                                  <group position={[0, 0.04, 0]} rotation={[0.31, -0.19, -0.6]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.05, 0.61, 0.39]} rotation={[-2.15, 1.48, -1.2]}>
                        <group position={[-0.04, -0.02, 0.1]} rotation={[-0.02, -0.27, -0.05]}>
                          <group position={[-0.06, -0.01, 0.07]} rotation={[0.31, -0.42, 0.28]}>
                            <group position={[-0.07, 0.07, 0.13]} rotation={[-0.06, -0.79, 0.17]}>
                              <group position={[0, 0.25, 0]} rotation={[-0.01, -0.27, 0.3]}>
                                <group position={[0, 0.18, 0]} rotation={[-0.43, -0.64, 0.24]}>
                                  <group position={[0, 0.22, 0]} rotation={[-1.88, -0.24, 1.2]}>
                                    <group position={[0, 0.16, 0]} rotation={[-1.59, -0.18, -0.7]}>
                                      <group position={[0, 0.09, 0]} rotation={[0.45, 0.55, 0.34]}>
                                        <group position={[0, 0.21, 0]} rotation={[0.67, 0.32, 0.09]}>
                                          <group position={[0, 0.12, 0]} rotation={[-1.63, 0.4, 2.53]}>
                                            <group position={[0, 0.14, 0]} rotation={[0.18, 0.86, -0.91]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.06, 0.61, 0.39]} rotation={[-2.18, -1.48, 1.17]}>
                        <group position={[0.04, -0.02, 0.1]} rotation={[-0.02, 0.27, 0.05]}>
                          <group position={[0.06, -0.01, 0.07]} rotation={[0.31, 0.42, -0.28]}>
                            <group position={[0.07, 0.07, 0.13]} rotation={[-0.06, 0.79, -0.17]}>
                              <group position={[0, 0.25, 0]} rotation={[-0.01, 0.27, -0.3]}>
                                <group position={[0, 0.18, 0]} rotation={[-0.43, 0.64, -0.24]}>
                                  <group position={[0, 0.22, 0]} rotation={[-1.88, 0.24, -1.2]}>
                                    <group position={[0, 0.16, 0]} rotation={[-1.59, 0.18, 0.7]}>
                                      <group position={[0, 0.09, 0]} rotation={[0.45, -0.55, -0.34]}>
                                        <group position={[0, 0.21, 0]} rotation={[0.67, -0.32, -0.09]}>
                                          <group position={[0, 0.12, 0]} rotation={[-1.63, -0.4, -2.53]}>
                                            <group position={[0, 0.14, 0]} rotation={[0.18, -0.86, 0.91]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.22, 0.35, 0.22]} rotation={[3.08, -1.01, 0.91]}>
                        <group position={[0, 0.22, 0]} rotation={[2.92, -0.4, 1.32]}>
                          <group position={[0, 0.12, 0]} rotation={[3.01, 1.12, 0.77]} />
                        </group>
                      </group>
                      <group position={[-0.22, 0.35, 0.22]} rotation={[3.09, 1.01, -0.92]}>
                        <group position={[0, 0.22, 0]} rotation={[2.92, 0.4, -1.32]}>
                          <group position={[0, 0.12, 0]} rotation={[3.01, -1.12, -0.77]} />
                        </group>
                      </group>
                      <group position={[0, 0.08, 0.31]} rotation={[-1.31, 0, 0]}>
                        <group position={[0, 0.08, 0]} rotation={[-0.37, 0, 0]} />
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[0.15, 0.61, 0.26]} rotation={[-1.54, 0, -1.04]}>
                <group position={[0.03, 0.65, -0.06]} rotation={[1.33, 1.05, -2.3]}>
                  <group position={[0, 0.92, 0]} rotation={[0.69, 0.2, 0.09]}>
                    <group position={[0, 1, 0]} rotation={[0.08, -0.05, -0.12]}>
                      <group position={[-0.01, 0.13, 0.09]} rotation={[1.74, 1.46, -1.66]}>
                        <group position={[0, 0.22, 0]} rotation={[0.1, -0.18, 0.03]}>
                          <group position={[0, 0.11, 0]} rotation={[0.19, -0.13, 0.04]} />
                        </group>
                        <group position={[-0.05, -0.05, 0.06]} rotation={[-0.82, 1.05, 1.35]}>
                          <group position={[0, 0.14, 0]} rotation={[0.15, 0.2, -0.08]} />
                        </group>
                      </group>
                      <group position={[-0.03, 0.14, 0.03]} rotation={[1.68, 1.44, -1.74]}>
                        <group position={[0, 0.23, 0]} rotation={[0.13, -0.3, -0.02]}>
                          <group position={[0, 0.13, 0]} rotation={[0.09, -0.24, -0.07]} />
                        </group>
                      </group>
                      <group position={[-0.03, 0.14, -0.05]} rotation={[1.6, 1.38, -1.69]}>
                        <group position={[0, 0.2, 0]} rotation={[-0.01, -0.51, -0.14]}>
                          <group position={[0, 0.13, 0]} rotation={[0.13, -0.13, -0.09]} />
                        </group>
                      </group>
                      <group position={[-0.03, 0.14, -0.1]} rotation={[1.46, 1.25, -1.71]}>
                        <group position={[0, 0.19, 0]} rotation={[-0.11, -0.79, -0.1]}>
                          <group position={[0, 0.09, 0]} rotation={[0.17, -0.03, -0.15]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[-0.15, 0.61, 0.26]} rotation={[-1.54, 0, 1.04]}>
                <group position={[-0.03, 0.65, -0.06]} rotation={[1.33, -1.05, 2.3]}>
                  <group position={[0, 0.92, 0]} rotation={[0.69, -0.2, -0.09]}>
                    <group position={[0, 1, 0]} rotation={[0.08, 0.05, 0.12]}>
                      <group position={[0.01, 0.13, 0.09]} rotation={[1.74, -1.46, 1.66]}>
                        <group position={[0, 0.22, 0]} rotation={[0.1, 0.18, -0.03]}>
                          <group position={[0, 0.11, 0]} rotation={[0.19, 0.13, -0.04]} />
                        </group>
                        <group position={[0.05, -0.05, 0.06]} rotation={[-0.82, -1.05, -1.35]}>
                          <group position={[0, 0.14, 0]} rotation={[0.15, -0.2, 0.08]} />
                        </group>
                      </group>
                      <group position={[0.03, 0.14, 0.03]} rotation={[1.68, -1.44, 1.74]}>
                        <group position={[0, 0.23, 0]} rotation={[0.13, 0.3, 0.02]}>
                          <group position={[0, 0.13, 0]} rotation={[0.09, 0.24, 0.07]} />
                        </group>
                      </group>
                      <group position={[0.03, 0.14, -0.05]} rotation={[1.6, -1.38, 1.69]}>
                        <group position={[0, 0.2, 0]} rotation={[-0.01, 0.51, 0.14]}>
                          <group position={[0, 0.13, 0]} rotation={[0.13, 0.13, 0.09]} />
                        </group>
                      </group>
                      <group position={[0.03, 0.14, -0.1]} rotation={[1.46, -1.25, 1.71]}>
                        <group position={[0, 0.19, 0]} rotation={[-0.11, 0.79, 0.1]}>
                          <group position={[0, 0.09, 0]} rotation={[0.17, 0.03, 0.15]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
        <group position={[0.44, -0.01, 0.14]} rotation={[2.8, 0, -0.12]}>
          <group position={[0, 1.47, 0]} rotation={[0.19, 0.02, 0.03]}>
            <group position={[0, 1.38, 0]} rotation={[-0.95, -0.07, 0.05]} />
          </group>
        </group>
        <group position={[-0.44, -0.01, 0.14]} rotation={[2.8, 0, 0.12]}>
          <group position={[0, 1.47, 0]} rotation={[0.19, -0.02, -0.03]}>
            <group position={[0, 1.38, 0]} rotation={[-0.95, 0.07, -0.05]} />
          </group>
        </group>
      </group>
      <group name="rig">
        <primitive object={nodes.root} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
        <primitive object={nodes['MCH-torsoparent']} />
        <primitive object={nodes['MCH-hand_ikparentL']} />
        <primitive object={nodes['MCH-hand_ikparentR']} />
        <primitive object={nodes['MCH-foot_ikparentL']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
        <primitive object={nodes['MCH-foot_ikparentR']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
        <skinnedMesh
          geometry={nodes.pacient_A_1001.geometry}
          material={materials.PACIENT_A_SKIN_DIFFUSE}
          skeleton={nodes.pacient_A_1001.skeleton}
        />
        <skinnedMesh
          geometry={nodes.pacient_A_1001_1.geometry}
          material={materials.Eye_grey}
          skeleton={nodes.pacient_A_1001_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.pacient_A_1001_2.geometry}
          material={materials.bodyMaterial}
          skeleton={nodes.pacient_A_1001_2.skeleton}
        />
        <skinnedMesh
          geometry={nodes.pacient_A_1cloth001.geometry}
          material={materials.PACIENT_A_CLOTH}
          skeleton={nodes.pacient_A_1cloth001.skeleton}
        />
        <skinnedMesh
          geometry={nodes.pacient_A_1cloth002.geometry}
          material={materials.eyebrow012}
          skeleton={nodes.pacient_A_1cloth002.skeleton}
        />
      </group>
    </group>
  )
}

// useGLTF.preload('patient/PACIENT_A.blend_anim.glb')
