import React, { useEffect, useState } from 'react';
// @ts-ignore
import { Button, InlineNotification } from '@carbon/react';
import AccreditationElement from './components/AccreditationElement';
import chatApi from '../../api/chatApi';
import { getAccreditationTest, setAccreditationTest, SpecialtiesInterface, getAccreditationHistory } from '../../api/chatApi/chatApi';
import MainHeader from '../../components/MainHeader/MainHeader';
import MainFooter from '../../components/MainFooter/MainFooter';

export default function AccreditationPage(): JSX.Element {

  useEffect(() => {
    getAllDoctorsTypesHandler()
    getUserTrainers()
    getAccreditationHistoryHandler()
  }, [])

  const [typeDoctor, setTypeDoctor] = useState<SpecialtiesInterface>()

  const [testId, setTestId] = useState<number>()
  const [attestationType, setAttestationType] = useState<any>()

  const [stateTypeDoctor, setStateTypeDoctor] = useState(false)
  const [allDoctorsTypeList, setAllDoctorsTypeList] = useState<SpecialtiesInterface[]>([])
  const [allQuestions, setAllQuestions] = useState<any[]>([])
  const [listOfAnswers, setListOfAnswers] = useState<number[]>([])
  const [accreditationTestStatus, setAccreditationTestStatus] = useState(false)
  const [indexTab, setIndexTab] = useState(0)
  const [currentUserId, setCurrentUserId] = useState<number>()
  const [countingResultsList, setCountingResultsList] = useState<any[]>([])

  const [countingAttestationList, setCountingAttestationList] = useState<any[]>([])
  const [currentAccreditationTestId, setCurrentAccreditationTestId] = useState<number>()
  const [currentAnswer, setCurrentAnswer] = useState<number>()

  const testType = [
    { testType: 1, value: 'Аккредитационный тест' },
    { testType: 2, value: 'Аттестационный тест' }
  ]

  useEffect(() => {
    getAccreditationHistoryHandler()
  }, [currentUserId])

  function changeTypeDoctor(value: SpecialtiesInterface) {
    setTypeDoctor(value)
    console.log(value, testId);
    //setIndexTab(1)
  }

  function changeAttestationTypeDoctor(value: any) {
    setAttestationType(value)
    //setIndexTab(1)
  }

  async function getAllDoctorsTypesHandler(): Promise<void> {
    try {
      const response = await chatApi.getAllDoctorsType()
      setAllDoctorsTypeList(response.result)
    } catch (err) {
      console.log('при получении списка врачей произошла ошибка', err);
    }
  }

  async function getUserTrainers(): Promise<void> {
    try {
      const response = await chatApi.getUserTrainers()
      console.log(response.result);
      setCurrentUserId(response.result.id)
    } catch (err) {
      console.log('при получени данных пользователя произошла ошибка', err);
    }
  }

  async function setAccreditationTestHandler(): Promise<void> {

    if (currentAccreditationTestId) {
      await setAccreditationTest({
        accreditation_test_id: attestationType.testType, answers_id: listOfAnswers
      })
        .then(res => {
          setIndexTab(0)
          setListOfAnswers([])
          setAllQuestions([])
          getAccreditationHistoryHandler()
        })
        .catch(err => {
          setIndexTab(1)
          setAccreditationTestStatus(true)
        })
    } else {
      setAccreditationTestStatus(true)
    }
    setTimeout(() => setAccreditationTestStatus(false), 5000)
  }

  async function getAccreditationHistoryHandler(): Promise<void> {
    let accreditationArray: any[] = []
    let attestationArray: any[] = []
    setListOfAnswers([])
    let estimate = 0
    if (currentUserId) {
      await getAccreditationHistory({ user_id: currentUserId }).then((response) => {
        response?.data?.accreditation_history?.map((res: any, index: number) => {
          switch (true) {
            case res.score < 70: estimate = 2
              break
            case res.score < 80: estimate = 3
              break
            case res.score < 90: estimate = 4
              break
            case res.score >= 90: estimate = 5
              break
          }

          if (res.type_test === 1) {
            accreditationArray.push({
              kind: res.kind,
              date: res.date,
              percent: `${res.score}%`,
              estimate: estimate,
              id: index + 1
            })
          } else {
            attestationArray.push({
              kind: res.kind,
              date: res.date,
              percent: `${res.score}%`,
              estimate: estimate,
              id: index + 1
            })
          }
        })
        setCountingResultsList(accreditationArray)
        setCountingAttestationList(attestationArray)
      })
    }
  }

  async function getAccreditationTestHandler(): Promise<void> {
    let data: any[] = []
    // @ts-ignore
    await getAccreditationTest({ doctor_info: typeDoctor?.id, type_test: attestationType?.testType || testType[0].testType }).then((response: any, index: number) => {
      setIndexTab(1)
      setCurrentAccreditationTestId(response.data.accreditation_test_id)
      response.data.cognitive_test.map((res: any) => {
        setListOfAnswers([...listOfAnswers, index])
        data.push({
          ...res, answers: res.answers.map((ans: any) => {
            return {
              accreditation_answers_text: ans.accreditation_answers_text,
              accreditation_answers_id: ans.accreditation_answers_id,
              state: false,
            }
          })
        })
      })
    }).catch(err => {
      setStateTypeDoctor(true)
      setIndexTab(0)
      setTimeout(() => setStateTypeDoctor(false), 5000)
    })
    setAllQuestions(data)
  }

  console.log('allQuestions', allQuestions)

  function sendArray() {
    setAccreditationTestHandler()
  }
  function changeRadioHandler(value: any, event: React.ChangeEvent<HTMLInputElement> | any, id: number) {
    let array: any[] = listOfAnswers
    allQuestions.map((question, index: number) => {
      question.answers.map((answer: any) => {
        if (index === id) {
          array[index] = event
          setCurrentAnswer(index)
        }
      })
    })
    array.filter((item: any) => item !== undefined)
    setListOfAnswers(array)
  }

  const handleBackButtonClick = () => {
    setIndexTab(0);
    setListOfAnswers([])
  };


  return (
    <div>
      <div style={{ position: 'fixed', top: '100px', right: '0', zIndex: 999 }}>
        {accreditationTestStatus &&
          <InlineNotification
            title="Ошибка при отправке."
            subtitle="В тесте необходимо ответить на все вопросы"
          />
        }
      </div>
      <div style={{ position: 'absolute', top: '100px', right: '0', zIndex: 999 }}>
        {stateTypeDoctor &&
          <InlineNotification
            title="Ошибка."
            subtitle="Для того чтобы перейти к тесту необходимо выбрать специальность имеющую доступ к Аккредитационному тестированию"
          />
        }
      </div>
      {
        // @ts-ignore
        <MainHeader />
      }
      <div style={{ marginTop: '2%', position: 'relative', height: '100vh', overflowX: 'auto', marginBottom: 20 }}>
        <AccreditationElement
          allDoctorsTypeList={allDoctorsTypeList}
          changeTypeDoctor={changeTypeDoctor}
          changeAttestationTypeDoctor={changeAttestationTypeDoctor}
          typeDoctor={typeDoctor}
          indexTab={indexTab}
          allQuestions={allQuestions}
          changeRadioHandler={changeRadioHandler}
          sendArray={sendArray}
          countingResultsList={countingResultsList}
          countingAttestationList={countingAttestationList}
          listOfAnswers={listOfAnswers}
          currentAnswer={currentAnswer}
          getAccreditationTestHandler={getAccreditationTestHandler}
          attestationType={attestationType}
          testType={testType}
          handleBackButtonClick={handleBackButtonClick}
        />
      </div>
      <MainFooter />
    </div>
  )
}
