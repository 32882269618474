import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  createStyles,
  makeStyles
} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() =>
  createStyles({
    wrapperItem: {
      display: 'flex',
      flexDirection: 'column'
    },
    wrapperSelect: {
      display: 'flex'
    },
    filter: {
      marginTop: 64
    }
  })
)

const ChampItemNew = props => {
  const style = useStyles()
  return (
    <div className={style.wrapperItem}>
      <TextField
        value={props.nameChamp}
        onChange={e => props.setNameChamp(e.target.value)}
        style={{ width: 500, marginBottom: 16 }}
        label='Название чемпионата'
        variant='outlined'
      />
      <TextField
        value={props.descriptionChamp}
        onChange={e => props.setDescriptionChamp(e.target.value)}
        style={{ width: 500, marginBottom: 16 }}
        label='Описание чемпионата'
        variant='outlined'
      />
      <div className={style.wrapperSelect}>
        <FormControl style={{ width: 229, marginRight: 41 }} variant='outlined'>
          <Select
            placeholder='Формат'
            value={props.select1}
            onChange={e => props.setSelect1(e.target.value)}
          >
            <MenuItem value='Все'>Все</MenuItem>
            <MenuItem value='Открытые'>Открытые</MenuItem>
            <MenuItem value='Закрытые'>Закрытые</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ width: 229 }} variant='outlined'>
          <Select
            placeholder='Даты проведения'
            value={props.select2}
            onChange={e => props.setSelect2(e.target.value)}
          >
            <MenuItem value='Все'>Все</MenuItem>
            <MenuItem value='Открытые'>Открытые</MenuItem>
            <MenuItem value='Закрытые'>Закрытые</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  )
}

export default ChampItemNew
