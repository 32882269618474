import React, { useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function NeuroManNTrue(props) {
  const { nodes, materials, animations } = useGLTF('patient/Neurologia_man_normostenik2.glb')
  const { ref, actions, names } = useAnimations(animations)



  useEffect(() => {
    props.setAnim(animations)
    if(actions && names) {
      actions[names[props.index]].reset().fadeIn(0.5).play()
      return () => actions[names[props.index]].fadeOut(0.5)
    }
  }, [props.index, actions, names])

  return (
    <group ref={ref} {...props} dispose={null}>
      <primitive object={nodes['MCH-torsoparent']} />
      <primitive object={nodes.root} />
      <skinnedMesh
        geometry={nodes.old_male_forblend003.geometry}
        material={materials['Eyeblue.001']}
        skeleton={nodes.old_male_forblend003.skeleton}
      />
      <skinnedMesh
        geometry={nodes.old_male_forblend003_1.geometry}
        material={materials['bodyMaterial.001']}
        skeleton={nodes.old_male_forblend003_1.skeleton}
      />
      {/* <skinnedMesh
        geometry={nodes.old_male_forblend003_2.geometry}
        material={materials.eyes_glass}
        skeleton={nodes.old_male_forblend003_2.skeleton}
      /> */}
      <skinnedMesh
        geometry={nodes.old_male_forblend003_3.geometry}
        material={materials.mhair01black}
        skeleton={nodes.old_male_forblend003_3.skeleton}
      />
      <skinnedMesh
        geometry={nodes.old_male_forblend003_4.geometry}
        material={materials['old_caucasian_male.001']}
        skeleton={nodes.old_male_forblend003_4.skeleton}
      />
      <skinnedMesh
        geometry={nodes.old_male_forblend003_5.geometry}
        material={materials['teethMaterial.001']}
        skeleton={nodes.old_male_forblend003_5.skeleton}
      />
      <skinnedMesh
        geometry={nodes.old_male_forblend003_6.geometry}
        material={materials['tongue01Material.001']}
        skeleton={nodes.old_male_forblend003_6.skeleton}
      />
      <skinnedMesh
        geometry={nodes.old_male_forblend003_7.geometry}
        material={materials.FABRIC_1_FRONT_164566}
        skeleton={nodes.old_male_forblend003_7.skeleton}
      />
      <mesh geometry={nodes.Cube_1.geometry} material={materials.couchLeg_black} />
      <mesh geometry={nodes.Cube_2.geometry} material={materials.couchLeg_white} />
    </group>
  )
}

// useGLTF.preload('/Neurologia_man_normostenik2.glb')
