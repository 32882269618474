import React, { useEffect, useState } from 'react';
import chatApi from '../../../api/chatApi';
import { getMediaContent, getOrganizationDescription, getScoreOrganization } from '../../../api/chatApi/chatApi';
import MainDescriptionOrganization from './components/mainDescriptionOrganization';

interface organizationDescriptionInterface {
  // description: string | null
  achievements: string,
  adress: string,
  boss: string,
  description: string,
  id: number,
  img: string
  name: string
  media: [{media: string, id: number}]
}

interface scoreOrganizationInterface {
  task_procent_organization: [
    {
      name: string
      row_number: number
      task_procent: number
    }
  ]
  score: number
}

interface teachersListInterface {
  id: number
  fio: string
  description: string
}

export default function OrganizationProfilePage (): JSX.Element {

  useEffect(() => {

    let id = window.location.href.split('=')[1]
    setOrgId(+id)
  }, [])



  const [organizationDescription, setOrganizationDescription] = useState<organizationDescriptionInterface>()
  const [scoreOrganization, setScoreorganization] = useState<scoreOrganizationInterface>()
  const [organizationMedia, setOrganizationMedia] = useState([])
  const [teachersList, setTeachersList] = useState<teachersListInterface[]>([])
  const [OrgId, setOrgId] = useState(0)

  useEffect(() => {
    if(OrgId !== 0) {
      getOrganizationDescriptionHandler()
      getScoreOrganizationHandler()
    }
  }, [OrgId])

  async function getOrganizationDescriptionHandler() {
    try {
      const response = await getOrganizationDescription({organization_id: OrgId})
      setOrganizationDescription(response.data.description_organization)
      setOrganizationMedia(response.data.media)
      setTeachersList(response.data.teachers)
      console.log(response.data);
    } catch(err) {
      console.log('при получении списка произошла ошибка', err);
    }
  }

  async function getScoreOrganizationHandler() {
    try {
      const response = await getScoreOrganization({organization_id: OrgId})
      setScoreorganization(response.data)
    } catch (err) {
      console.log('при получении оценок произошла ошибка');
    }
  }

// // const [org, setOrg] = useState(null)
//   const result = async() => {
//     const datas = organizationMedia.map(async(el: any) => {
//       const res = await getMediaContent(el.media)
//       const data = await res.data.data_base64
//       return {...el, newLink: data}
//     })
//     // const los = await datas
//     // console.log(los); 
//   }



//   useEffect(() => {
//     result()
//   }, []);

  return (
    <>
      <MainDescriptionOrganization
        organizationDescription={organizationDescription}
        scoreOrganization={scoreOrganization}
        organizationMedia={organizationMedia}
        teachersList={teachersList}
      />
    </>
  )
}
