import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextArea from '@carbon/react/es/components/TextArea/TextArea';
import { IconButton, Typography } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
// @ts-ignore
import { Add } from '@carbon/icons-react';
import DeleteIcon from '@material-ui/icons/Delete';
// @ts-ignore
import { Button, Dropdown, Loading, TextInput, Modal, Pagination } from '@carbon/react';
import { getMediaContent, reviewCreate } from '../../../../api/chatApi/chatApi';
import MainFooter from '../../../../components/MainFooter/MainFooter';
import MainHeader from '../../../../components/MainHeader/MainHeader';

interface teachersListInterface {
  id: number
  fio: string
  description: string
  score_teachers?: string
}

interface reviewListInterface {
  date_: string
  feedback: string
  id: number
  score: number
  student_name: string
  teachers_name: string
}

interface teacherInterface {
  fio: string
  boss: string
  adress: string
  achievements: string
  img: string
  teachers_description: string
  teachers_organization_description_description: string
}
interface MainDescriptionTeacherInterface {
  teacher: teacherInterface | undefined
  starsValue: number
  scoreTeachers: teachersListInterface[] | undefined
  reviewList: reviewListInterface[]
  teachersList: teachersListInterface[] | undefined
  reviewText: string
  changeReviewTextHandler: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  openModalHandler: () => void
  isOpenModal: boolean
  chooseTeacherHandler: (body: reviewListInterface) => void
  selectedTeacher: any
  changeCreateRating: (event: number) => void
  createReviewHandler: () => void
  allUsersArray: any[]
  deleteReviewHandler: (id: number) => void
  media: any[]
}
const useStyles = makeStyles((theme: any) => ({

  descriptionContainer: {
    width: '100%',
    fontFamily: 'Helvetica Neue',
    margin: '5% auto 0px auto',

  },
  mainWrapper: {
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginLeft: 40
  },
  mainInfoBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  organizationName: {
    fontSize: 26,
    fontWeight: 600,
  },
  organizationBoss: {
    fontSize: 20,
    fontWeight: 500,
  },
  organizationAddressBlock: {
    marginBottom: 45
  },
  organizationAddress: {
    fontSize: 18,
    fontWeight: 500,
  },
  customDescriptionBlock: {
    width: '100%',
    border: '1px solid #DADADA',
    borderRadius: 6,
    overflowY: 'scroll',
    height: 300,
    fontSize: 18,
    padding: '10px 10px',
  },
  customTextArea: {
    '& .cds--text-area': {
      fontSize: 18
    }
  },
  descriptionText: {
    fontSize: 18
  },
  achievementsBlock: {
    width: '100%'
  },
  achievementsText: {
    fontSize: 18,
    wordBreak: 'break-all',
  },
  nonePhotoBlock: {
    border: '1px solid #DADADA',
    borderRadius: 12,
    width: 230,
    height: 120,
    textAlign: 'center',
    padding: '15px 15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#DADADA'
  },
  nonePhotoText: {
    fontSize: 18,
    lineHeight: '35px'
  },
  ratingOrg: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '25px 0 25px 0',
  },
  ratingOrgTitle: {
    fontSize: 24,
    fontWeight: 500
  },
  ratingOrgTable: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DADADA',
  },
  ratingOrgElement: {
    marginBottom: 10,
    marginTop: 10,
    fontSize: 18,
  },
}))
export default function MainDescriptionTeacher({
  teacher,
  scoreTeachers,
  starsValue,
  reviewList,
  teachersList,
  reviewText,
  changeReviewTextHandler,
  openModalHandler,
  isOpenModal,
  chooseTeacherHandler,
  selectedTeacher,
  changeCreateRating,
  createReviewHandler,
  allUsersArray,
  deleteReviewHandler,
  media
}: MainDescriptionTeacherInterface): JSX.Element {

  const style = useStyles()

  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [link, setLink] = useState('')

  const newMedia = async () => {
    const res = await getMediaContent(media[0].media)
    setLink(res.data.data_base64);
  }

  useEffect(() => {
    if (media) {
      newMedia()
    }
  }, [media]);

  return (
    <div className={style.descriptionContainer}>
      <MainHeader />
      <div className={style.mainWrapper}>
        <div className={style.mainInfoBlock}>
          <div>
            <p className={style.organizationName}>{teacher && teacher.fio}</p>
            {
              starsValue &&
              <div style={{ marginTop: '25px' }}>
                <Typography component="legend">Общий рейтинг</Typography>
                <Rating name="read-only" value={starsValue} readOnly={true} />
              </div>
            }
            <div className={style.organizationAddressBlock}>
              <p
                className={style.organizationAddress}>
                {teacher && teacher.adress}
              </p>
            </div>
            <div style={{ marginBottom: '70px' }}>
              <p
                className={style.organizationBoss}>{teacher &&
                  teacher.boss.split('<b>').join('').split('</b>').join('')}
              </p>
            </div>
          </div>
          <div>
            {link ?
              <img style={{ height: 200, width: 200, objectFit: 'contain' }} src={`data:image/jpeg;base64,${link}`} alt="organizationDescription" /> :
              <div className={style.nonePhotoBlock}>
                Нет фото для отображения
                {/* <Loading
                  description="Active loading indicator" withOverlay={false}
                  small={false} /> */}
                {/* <p className={style.nonePhotoText}>Фоторгафия отсутствует</p> */}
              </div>
            }
          </div>
        </div>
        <div>
          {/*<TextArea*/}
          {/*  labelText="Text Area label"*/}
          {/*  helperText="Optional helper text"*/}
          {/*  rows={8}*/}
          {/*  id="text-area-1"*/}
          {/*  className={style.customTextArea}*/}
          {/*  value={organizationDescription && organizationDescription.description}*/}
          {/*  disabled={true}*/}
          {/*  />*/}
          <div className={style.customDescriptionBlock}>
            <p className={style.descriptionText}>{teacher && teacher.teachers_description.split('</br>').join('')}</p>
          </div>
        </div>
        <div className={style.achievementsBlock}>
          <p className={style.achievementsText}>{teacher && teacher.achievements.split('</b>').join('').split('<b>').join('')}</p>
        </div>
        <p style={{ fontSize: 26, fontWeight: 500, marginTop: 30 }}>Рейтинг преподавателей</p>
        <div style={{ marginBottom: '50px' }}>
          <div className={style.ratingOrg}>
            <p className={style.ratingOrgTitle}>ФИО преподавателя</p>
            <p className={style.ratingOrgTitle}>Процент выполнения работ</p>
          </div>
          {scoreTeachers &&
            <div>
              {scoreTeachers.map(org => {
                return (
                  <div className={style.ratingOrgTable}>
                    <p className={style.ratingOrgElement}>{org.fio}</p>
                    <p className={style.ratingOrgElement}>{org.score_teachers}%</p>
                  </div>
                )
              })}
            </div>
          }
          {
            scoreTeachers && scoreTeachers.length > 5 &&
            <Pagination
              backwardText="Предыдущая вкладка"
              forwardText="Следующая вкладка"
              itemsPerPageText="Показывать по"
              pageRangeText={(_current: any, total: number) => `из ${total} ${total === 1 ? 'страницы' : 'страниц'}`}
              itemText={(min: number, max: number) => `${min}–${max} itsaaaems`}
              itemRangeText={(min: number, max: number, total: number) => `${min}–${max} из ${total} записей`}
              pageSize={currentPageSize}
              pageNumberText="Page Number"
              pageSizes={[
                10,
                20,
                30,
                40,
                50
              ]}
              totalItems={scoreTeachers &&
                scoreTeachers.length || 0}
              onChange={({ page, pageSize }: any) => {
                if (pageSize !== currentPageSize) {
                  setCurrentPageSize(pageSize);
                }
                setFirstRowIndex(pageSize * (page - 1));
              }}
            />
          }
        </div>

        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginBottom: 30 }}>
          <Button
            style={{ borderRadius: 4 }}
            renderIcon={Add}
            onClick={openModalHandler}
            iconDescription="Icon Description">
            Оставить отзыв</Button>
        </div>

        {reviewList && reviewList.map(review => {
          return (
            <>
              <p style={{ marginBottom: 25, fontSize: 20, fontWeight: 500 }}>Отзывы</p><div style={{ marginBottom: 25 }} key={`${review.id}`}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <p style={{ marginRight: '10px' }}>{review.student_name}</p>
                    <p>{review.date_}</p>
                  </div>
                  {allUsersArray && allUsersArray.map(user => {
                    return (
                      review.student_name.includes(user.name) &&
                      <IconButton
                        onClick={() => deleteReviewHandler(review.id)}
                      >
                        <DeleteIcon fontSize='small' />
                      </IconButton>

                    );
                  })}

                </div>
                <TextArea
                  labelText={''}
                  value={review.feedback}
                  rows={4} />
              </div></>
          )
        })
        }

        <div>
          <Modal
            open={isOpenModal}
            onClose={openModalHandler}
            onRequestClose={openModalHandler}
            onRequestSubmit={createReviewHandler}
            modalHeading="Напишите свой отзыв"
            modalLabel="Account resources"
            primaryButtonText="Добавить"
            secondaryButtonText="Отменить">

            <div>
              {/*<TextInput*/}
              {/*  data-modal-primary-focus*/}
              {/*  id="text-input-1"*/}
              {/*  labelText="Укажите имя студента"*/}
              {/*  placeholder=""*/}
              {/*  style={{ marginBottom: '1rem' }}*/}
              {/*/>*/}

              {/*{*/}
              {/*  teachersList &&*/}
              {/*  <Dropdown*/}
              {/*    id="drop"*/}
              {/*    label="Выберите преподавателя"*/}
              {/*    titleText="Выберите преподавателя"*/}
              {/*    items={teachersList}*/}
              {/*    itemToString={(item) => (item ? item.fio : '')}*/}
              {/*    onChange={({selectedItem}) => chooseTeacherHandler(selectedItem)}*/}
              {/*    selectedItem={selectedTeacher}*/}
              {/*  />*/}
              {/*}*/}
              <Rating name="half-rating" defaultValue={0} precision={0.5} onChange={(event, newValue) => changeCreateRating(newValue!)} />
              <TextArea
                labelText={'Ваш отзыв'}
                value={reviewText}
                onChange={changeReviewTextHandler}
              />
            </div>
          </Modal>
        </div>
      </div>
      <MainFooter />
    </div>
  )
}
