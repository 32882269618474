import React, { useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'


export default function WomanJaundice(props) {
  const { nodes, materials, animations } = useGLTF('/patient/woman_jaundice.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)

  useEffect(() => {
    props.setAnim(animations)
    actions.rigAction.play()
  }, [actions, names, mixer])

  return (
    <group ref={ref} {...props} dispose={null}>
      <group position={[0, 0.83, -0.02]} rotation={[0.26, 0, 0]}>
        <group position={[0, 0.12, 0]} rotation={[-0.22, 0, 0]}>
          <group position={[0, 0.11, 0]} rotation={[-0.2, 0, 0]}>
            <group position={[0, 0.14, 0]} rotation={[0.23, 0, 0]}>
              <group position={[0, 0.13, 0]} rotation={[0.26, 0, 0]}>
                <group position={[0, 0.04, 0]} rotation={[0.09, 0, 0]}>
                  <group position={[0, 0.05, 0]} rotation={[-0.48, 0, 0]}>
                    <group rotation={[-0.12, 0, 0]}>
                      <group position={[0, 0.09, 0.12]} rotation={[-3.05, 0, 0]}>
                        <group position={[0, 0.02, 0]} rotation={[-0.6, 0, 0]}>
                          <group position={[0, 0.02, 0]} rotation={[1.2, 0, 0]}>
                            <group position={[0, 0.01, 0]} rotation={[0.8, 0, 0]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.02, 0.11]} rotation={[-1.49, 0.18, -1.27]} />
                      <group position={[0, 0.01, 0.11]} rotation={[-1.3, 0.06, -1.33]} />
                      <group position={[0, -0.01, 0.04]} rotation={[1.82, 0, 0]}>
                        <group position={[0, 0.05, 0]} rotation={[-1.28, 0, 0]} />
                      </group>
                      <group position={[0.07, 0.07, 0.03]} rotation={[-0.16, -0.15, -0.27]}>
                        <group position={[0, 0.03, 0]} rotation={[-0.87, -0.22, 0.1]}>
                          <group position={[0, 0.02, 0]} rotation={[-1.79, -0.35, 0.19]}>
                            <group position={[0, 0.03, 0]} rotation={[-0.79, -0.55, 0.25]} />
                          </group>
                        </group>
                      </group>
                      <group position={[-0.07, 0.07, 0.03]} rotation={[-0.16, 0.15, 0.27]}>
                        <group position={[0, 0.03, 0]} rotation={[-0.87, 0.22, -0.1]}>
                          <group position={[0, 0.02, 0]} rotation={[-1.79, 0.35, -0.19]}>
                            <group position={[0, 0.03, 0]} rotation={[-0.79, 0.55, -0.25]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.02, 0.11]} rotation={[-1.49, -0.18, 1.27]} />
                      <group position={[0, 0.01, 0.11]} rotation={[-1.3, -0.06, 1.33]} />
                      <group position={[0.06, 0.09, 0.09]} rotation={[-0.9, 1, 2.1]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.39, 0.78, 0.09]}>
                          <group position={[0, 0.02, 0]} rotation={[-0.11, 0.43, -0.44]} />
                        </group>
                      </group>
                      <group position={[0.04, 0.08, 0.1]} rotation={[-0.82, 1.07, 1.71]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.7, 0.66, 0.06]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.4, 0.33, -0.43]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.4, 0.42, -0.25]}>
                              <group position={[0, 0.01, 0]} rotation={[1.08, 0.45, -2.38]}>
                                <group position={[0, 0.01, 0]} rotation={[0.6, 0.32, -0.34]}>
                                  <group position={[0, 0.01, 0]} rotation={[0.41, 0.36, 0.39]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.06, 0.09, 0.09]} rotation={[-0.9, -1, -2.1]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.39, -0.78, -0.09]}>
                          <group position={[0, 0.02, 0]} rotation={[-0.11, -0.43, 0.44]} />
                        </group>
                      </group>
                      <group position={[-0.04, 0.08, 0.1]} rotation={[-0.82, -1.07, -1.71]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.7, -0.66, -0.06]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.4, -0.33, 0.43]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.4, -0.42, 0.25]}>
                              <group position={[0, 0.01, 0]} rotation={[1.08, -0.45, 2.38]}>
                                <group position={[0, 0.01, 0]} rotation={[0.6, -0.32, 0.34]}>
                                  <group position={[0, 0.01, 0]} rotation={[0.41, -0.36, -0.39]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.01, 0.15, 0.12]} rotation={[-2.07, 1.49, -1.29]}>
                        <group position={[-0.02, -0.01, 0.03]} rotation={[0, -0.26, -0.04]}>
                          <group position={[-0.02, 0, 0.02]} rotation={[0.09, -0.58, 0.14]}>
                            <group position={[-0.01, 0, 0.02]} rotation={[0.34, -0.67, 0.2]}>
                              <group position={[0, 0.07, 0]} rotation={[-0.04, -0.29, 0.3]}>
                                <group position={[0, 0.05, 0]} rotation={[0.03, -0.56, 0.58]}>
                                  <group position={[0, 0.06, 0]} rotation={[-2.09, 0.04, 1.29]}>
                                    <group position={[0, 0.04, 0]} rotation={[-1.91, -0.03, -0.77]}>
                                      <group position={[0, 0.04, 0]} rotation={[0.57, 0.6, 0.35]}>
                                        <group position={[0, 0.05, 0]} rotation={[0.57, 0.51, 0.32]}>
                                          <group position={[0, 0.02, 0]} rotation={[-1.8, 0.84, 2.33]}>
                                            <group position={[0, 0.03, 0]} rotation={[-0.25, 0.96, -0.45]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.01, 0.15, 0.12]} rotation={[-2.07, -1.49, 1.29]}>
                        <group position={[0.02, -0.01, 0.03]} rotation={[0, 0.26, 0.04]}>
                          <group position={[0.02, 0, 0.02]} rotation={[0.09, 0.58, -0.14]}>
                            <group position={[0.01, 0, 0.02]} rotation={[0.34, 0.67, -0.2]}>
                              <group position={[0, 0.07, 0]} rotation={[-0.04, 0.29, -0.3]}>
                                <group position={[0, 0.05, 0]} rotation={[0.03, 0.56, -0.58]}>
                                  <group position={[0, 0.06, 0]} rotation={[-2.09, -0.04, -1.29]}>
                                    <group position={[0, 0.04, 0]} rotation={[-1.91, 0.03, 0.77]}>
                                      <group position={[0, 0.04, 0]} rotation={[0.57, -0.6, -0.35]}>
                                        <group position={[0, 0.05, 0]} rotation={[0.57, -0.51, -0.32]}>
                                          <group position={[0, 0.02, 0]} rotation={[-1.8, -0.84, -2.33]}>
                                            <group position={[0, 0.03, 0]} rotation={[-0.25, -0.96, 0.45]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.06, 0.08, 0.07]} rotation={[-3.01, -0.95, 1.02]}>
                        <group position={[0, 0.04, 0]} rotation={[2.85, -0.75, 1.72]}>
                          <group position={[0, 0.03, 0]} rotation={[2.67, 0.77, 1.41]} />
                        </group>
                      </group>
                      <group position={[-0.06, 0.08, 0.07]} rotation={[-3.01, 0.95, -1.02]}>
                        <group position={[0, 0.04, 0]} rotation={[2.85, 0.75, -1.72]}>
                          <group position={[0, 0.03, 0]} rotation={[2.67, -0.77, -1.41]} />
                        </group>
                      </group>
                      <group position={[0, 0.02, 0.09]} rotation={[-1.19, 0, 0]}>
                        <group position={[0, 0.02, 0]} rotation={[-0.37, 0, 0]} />
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[0.02, 0.13, 0.03]} rotation={[-1.68, 0.08, -1.32]}>
                <group position={[0, 0.15, -0.03]} rotation={[1.38, 1.39, -2.21]}>
                  <group position={[0, 0.22, 0]} rotation={[0.7, 0.21, 0.01]}>
                    <group position={[0, 0.24, 0]} rotation={[-0.07, -0.15, -0.1]}>
                      <group position={[-0.01, 0.03, 0.03]} rotation={[2.13, 1.43, -1.97]}>
                        <group position={[0, 0.06, 0]} rotation={[0.19, -0.15, 0.17]}>
                          <group position={[0, 0.03, 0]} rotation={[0.14, -0.17, -0.07]} />
                        </group>
                        <group position={[-0.02, -0.01, 0.02]} rotation={[-1.15, 1.18, 1.56]}>
                          <group position={[0, 0.04, 0]} rotation={[-0.01, 0.01, 0.06]} />
                        </group>
                      </group>
                      <group position={[-0.02, 0.04, 0.01]} rotation={[1.84, 1.44, -1.85]}>
                        <group position={[0, 0.06, 0]} rotation={[0.16, -0.23, 0.03]}>
                          <group position={[0, 0.03, 0]} rotation={[0.14, -0.22, 0.02]} />
                        </group>
                      </group>
                      <group position={[-0.02, 0.03, 0]} rotation={[1.15, 1.42, -1.25]}>
                        <group position={[0, 0.06, 0]} rotation={[0.07, -0.28, -0.06]}>
                          <group position={[0, 0.03, 0]} rotation={[0.13, -0.15, -0.05]} />
                        </group>
                      </group>
                      <group position={[-0.02, 0.03, -0.01]} rotation={[0.89, 1.38, -1.11]}>
                        <group position={[0, 0.06, 0]} rotation={[0.04, -0.45, -0.05]}>
                          <group position={[0, 0.02, 0]} rotation={[0.23, -0.06, -0.12]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[-0.02, 0.13, 0.03]} rotation={[-1.68, -0.08, 1.32]}>
                <group position={[0, 0.15, -0.03]} rotation={[1.38, -1.39, 2.21]}>
                  <group position={[0, 0.22, 0]} rotation={[0.7, -0.21, -0.01]}>
                    <group position={[0, 0.24, 0]} rotation={[-0.07, 0.15, 0.1]}>
                      <group position={[0.01, 0.03, 0.03]} rotation={[2.13, -1.43, 1.97]}>
                        <group position={[0, 0.06, 0]} rotation={[0.19, 0.15, -0.17]}>
                          <group position={[0, 0.03, 0]} rotation={[0.14, 0.17, 0.07]} />
                        </group>
                        <group position={[0.02, -0.01, 0.02]} rotation={[-1.15, -1.18, -1.56]}>
                          <group position={[0, 0.04, 0]} rotation={[-0.01, -0.01, -0.06]} />
                        </group>
                      </group>
                      <group position={[0.02, 0.04, 0.01]} rotation={[1.84, -1.44, 1.85]}>
                        <group position={[0, 0.06, 0]} rotation={[0.16, 0.23, -0.03]}>
                          <group position={[0, 0.03, 0]} rotation={[0.14, 0.22, -0.02]} />
                        </group>
                      </group>
                      <group position={[0.02, 0.03, 0]} rotation={[1.15, -1.42, 1.25]}>
                        <group position={[0, 0.06, 0]} rotation={[0.07, 0.28, 0.06]}>
                          <group position={[0, 0.03, 0]} rotation={[0.13, 0.15, 0.05]} />
                        </group>
                      </group>
                      <group position={[0.02, 0.03, -0.01]} rotation={[0.89, -1.38, 1.11]}>
                        <group position={[0, 0.06, 0]} rotation={[0.04, 0.45, 0.05]}>
                          <group position={[0, 0.02, 0]} rotation={[0.23, 0.06, 0.12]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
        <group position={[0.11, 0.02, 0.02]} rotation={[2.79, 0, -0.06]}>
          <group position={[0, 0.41, 0]} rotation={[0.15, 0.01, -0.04]}>
            <group position={[0, 0.35, 0]} rotation={[-0.96, -0.09, 0.06]} />
          </group>
        </group>
        <group position={[-0.11, 0.02, 0.02]} rotation={[2.79, 0, 0.06]}>
          <group position={[0, 0.41, 0]} rotation={[0.15, -0.01, 0.04]}>
            <group position={[0, 0.35, 0]} rotation={[-0.96, 0.09, -0.06]} />
          </group>
        </group>
      </group>
      <group name="rig">
        <primitive object={nodes.root} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
        <primitive object={nodes['MCH-torsoparent']} />
        <primitive object={nodes['MCH-hand_ikparentL']} />
        <primitive object={nodes['MCH-hand_ikparentR']} />
        <primitive object={nodes['MCH-foot_ikparentL']} />
        <primitive object={nodes['MCH-foot_ikparentR']} />
        {/* <skinnedMesh
          geometry={nodes.woman_Endomorph_1.geometry}
          material={materials.EyeGlass}
          skeleton={nodes.woman_Endomorph_1.skeleton}
        /> */}
        <skinnedMesh
          geometry={nodes.woman_Endomorph_2.geometry}
          material={materials.Eye_brown}
          skeleton={nodes.woman_Endomorph_2.skeleton}
        />
        <skinnedMesh
          geometry={nodes.woman_Endomorph_3.geometry}
          material={materials.bodyMaterial}
          skeleton={nodes.woman_Endomorph_3.skeleton}
        />
        <skinnedMesh
          geometry={nodes.woman_Endomorph001.geometry}
          material={materials.young_asian_female}
          skeleton={nodes.woman_Endomorph001.skeleton}
        />
        <skinnedMesh
          geometry={nodes.womanEndSuite1.geometry}
          material={materials['FABRIC_1_FRONT_2067.001']}
          skeleton={nodes.womanEndSuite1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.BezierCurve.geometry}
          material={materials.Material}
          skeleton={nodes.BezierCurve.skeleton}
        />
        <skinnedMesh
          geometry={nodes.BezierCurve_1.geometry}
          material={materials['Material.001']}
          skeleton={nodes.BezierCurve_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.womanUnderwear.geometry}
          material={materials.Underwear}
          skeleton={nodes.womanUnderwear.skeleton}
        />
      </group>
    </group>
  )
}

// useGLTF.preload('/woman_jaundice.glb')
