import React from 'react'
import ChampionshipsItem from './ChampionshipsItem'

const ChampionshipsAll = ({ championships, setToggle }) => {
  if (!championships.length) {
    return <h1 style={{ marginTop: 30 }}>Чемпионаты не найдены</h1>
  }

  return (
    <div>
      {championships?
       championships.map(championship => (
        <ChampionshipsItem key={championship.id} championship={championship} setToggle={setToggle}/>
      ))
      :
      <div>Чемпионаты не найдены</div>
      }
     
    </div>
  )
}

export default ChampionshipsAll
