import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import MainFooter from '../../components/MainFooter/MainFooter';
import { checkRole } from '../../common/checkRole';
import MainHeader from '../../components/MainHeader/MainHeader';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@material-ui/core/Box'
import CreateUser from './CreateUser';
import UsersTableAdmin from './UsersTableAdmin';
import Org from './CreateRole/options/Org';
import Trn from './CreateRole/options/Treners';
import ListRole from './listRoles/ListRole';
import TableAdmin from './TableAdmin';
import Role1 from './CreateRole/Role1';
import AdminRating from './AdminRating/AdminRating';
import TabPanel from './TabPanel';
import ImgRating from '../../assets/img/rating.svg';
import chatApi from '../../api/chatApi';
import jwt from 'jsonwebtoken';
import AssignGroups from './AssingnGroups';



const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px auto',
    marginTop: 40,
    width: '90%',
    '& .css-19kzrtu': {
      padding: 0
    }
  },
  wrapperImg: {
    flexGrow: 2,
    width: '38%',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  wrapperInfo: {
    display: 'flex',
    width: '100%',
    height: '372px',
    marginTop: 48,
  },
  wrapperText: {
    backgroundColor: 'black',
    flexGrow: 3,
    width: '62%'
  },
  title: {
    color: 'white',
    fontFamily: 'Helvetica Neue',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 32,
    margin: '24px 0 87px 88px',
  },
}));

export default function StartPageAdmin() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const [roles, setRoles] = React.useState([]);
  const [typeProgram, setTypeProgram] = useState(null)

  const dataTypeProgram = async() => {
    const type = await chatApi.getSettingsType()
    setTypeProgram(type.result[0].value)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    checkRole();
    const roleDecode = jwt.decode(localStorage.getItem('role'));
    const role = roleDecode.auth.split(',');
    setRoles(role)
    dataTypeProgram()    
  }, []);

  const tables = [
    'Пользователи',
    'Создать пользователя',
    'Назначение ролей',
    'Пользователи организаций',
    'Пользователи тренажеров',
    'Описание ролей',
    'Просмотр истории действий пользователей',
    'Категории влияния на рейтинг'
  ]

  const tables1 = [
    'Пользователи',
    'Создать пользователя',
    'Назначение ролей',
    'Пользователи организаций',
    'Пользователи тренажеров',
    'Описание ролей',
    'Просмотр истории действий пользователей',
    'Категории влияния на рейтинг',
    'Назначение задач на группы'
  ]

  const tablesAlt = [
    'Назначение ролей',
    'Пользователи организаций',
    'Описание ролей',
  ]

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: [
      'Helvetica Neue',
      '"Helvetica Neue"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#1890ff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }));



  return (
    <>
      <MainHeader />
      <Box className={classes.wrapperInfo}>
        <Box className={classes.wrapperText}>
          <h1 className={classes.title}>Администрирование</h1>
        </Box>
        <Box className={classes.wrapperImg}>
          <img className={classes.img} src={ImgRating} alt='img' />
        </Box>
      </Box>
      <Box className={classes.root}>
        {typeProgram === '0' ?
        <>
        <Box>
          <Tabs value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            className={classes.tab}
          >
            {roles.includes('ROLE_ADMIN') || roles.includes('ROLE_ADMIN_ORG') ?
            tables1.map(table => (
              <AntTab className={classes.tab} label={table} />
            ))
            : 
            tables.map(table => (
              <AntTab className={classes.tab} label={table} />
            ))
            }
            {/* {tables.map(table => (
              <AntTab className={classes.tab} label={table} />
            ))} */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <UsersTableAdmin />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CreateUser />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Role1 />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Org />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Trn />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <ListRole />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <TableAdmin />
        </TabPanel>
        <TabPanel value={value} index={7}>       
          <AdminRating />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <AssignGroups />
        </TabPanel>
        </>
        : 
        <>
        <Box>
          <Tabs value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            className={classes.tab}
          >
            {tablesAlt.map(table => (
              <AntTab className={classes.tab} label={table} />
            ))}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Role1 />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Org />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ListRole />
        </TabPanel>
        </>}
      </Box>
      <MainFooter />
    </>
  );
}
