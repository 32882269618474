import {
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Button
} from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router'
import { useQuery } from 'react-query'
import chatApi from '../../api/chatApi'
import ChatPagesMenuMobile from '../../components/Chat/ChatPagesMenuMobile'
import CognitiveAnswersBlock from '../../components/cognitive/CognitiveAnswersBlock'
import CustomButton from '../../components/common/CustomButton'
import MainLayout from '../../components/Layout/MainLayout'
import { RoutePath } from '../../types'
import CognitiveBlockByType from '../../components/cognitive/CognitiveBlockByType'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '1200px',
      margin: '20px auto',
      padding: 20
    },
    title: {
      maxWidth: '1200px',
      width: '100%',
      margin: '0 auto',
      marginTop: '16px',
      marginBottom: 16
    },
    buttonGroup: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexDirection: 'column',
      marginTop: 20
    },
    historyBtn: {
      display: 'flex',
      justifyContent: 'flex-start',
      fontSize: '0.875rem',
      boxSizing: 'border-box',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 500,
      lineHeight: 1.75,
      color: 'rgba(0, 0, 0, 0.87)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
      cursor: 'pointer',
      marginTop: 8,
      userSelect: 'none'
    },
    questionCount: {
      color: '#6f6f6f'
    }
  })
)

const CognitiveTest = () => {
  const history = useHistory()
  const classes = useStyles()

  const [questionNumber, setQuestionNumber] = React.useState(1)
  const [totalQuestion, setTotalQuestion] = React.useState()
  const [questionId, setQuestionId] = React.useState()
  const [question, setQuestion] = React.useState<any>(null)
  const [answer, setAnswer] = React.useState<string | null>()
  const [timer, setTimer] = React.useState(0)

  React.useEffect(() => {
    // if (!test.isLoading)
    setTimeout(() => setTimer(timer + 1), 1000)
  }, [timer])

  const test = useQuery(
    ['test', questionNumber],
    async () => {
      try {
        setTimer(0)
        const data = await chatApi.cognitiveUnfinishedTest()
        if (!data?.result?.test_id) {
          if (!question) {
            const generate = await chatApi.cognitiveGenerate()
            const getQuestion = await chatApi.cognitiveGetQuestion(
              generate.result.id,
              1
            )
            setQuestionId(generate.result.id)
            setTotalQuestion(generate.result.totalQuestion)
            setQuestion(getQuestion.result)
            return
          }
        } else {
          const getQuestion = await chatApi.cognitiveGetQuestion(
            data.result.test_id,
            data.result.questionId
          )
          setQuestionId(data.result.test_id)
          setTotalQuestion(getQuestion.result.total)
          setQuestion(getQuestion.result)
          if (questionNumber !== data.result.questionId)
            setQuestionNumber(data.result.questionId)
        }
        return data
      } catch (error) {
        console.error(error)
      }
    },
    { refetchOnMount: true }
  )

  const nextQuestion = async () => {
    await chatApi.cognitiveSaveAnswer(questionId, questionNumber, answer, timer)
    if (questionNumber !== totalQuestion) {
      setQuestionNumber(questionNumber + 1)
      setAnswer(null)
    }
  }

  const endTest = async () => {
    await nextQuestion()
    history.push(`${RoutePath.Cognitive}`)
  }

  return (
    <MainLayout
      mobileHeader={<ChatPagesMenuMobile title={''} />}
      title=''
      hiddenSidebar
    >
      <div className={classes.root}>
        <p className={classes.questionCount}>
          Вопрос {`${questionNumber} из ${totalQuestion}`}
        </p>
        <h2 className={classes.title}>
          <CognitiveBlockByType
            typePhoto={question?.typePhoto}
            typeSound={question?.typeSound}
            typeText={question?.typeText}
            typeVideo={question?.typeVideo}
            text={question?.text}
          />
        </h2>

        <CognitiveAnswersBlock
          answers={question?.cognitiveAnswerEntityList.sort(
            (a, b) => a.id - b.id
          )}
          selectedAnswer={setAnswer}
        />

        <div className={classes.buttonGroup}>
          {totalQuestion !== questionNumber ? (
            <Button
              variant='contained'
              color='primary'
              disabled={!answer}
              onClick={nextQuestion}
            >
              Далее
            </Button>
          ) : (
            <Button
              variant='contained'
              color='primary'
              disabled={!answer}
              onClick={endTest}
            >
              Завершить
            </Button>
          )}
        </div>
      </div>
    </MainLayout>
  )
}

export default CognitiveTest
