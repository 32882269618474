import React, { useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'



export default function WomanMastectomy(props) {
  const { nodes, materials, animations } = useGLTF('patient/woman_mastectomy.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)

  useEffect(() => {
    props.setAnim(animations)
    actions.rigAction.play()
  }, [actions, names, mixer])

  return (
    <group ref={ref} {...props} dispose={null}>
      <group position={[0, 0.92, -0.04]} rotation={[0.3, 0, 0]}>
        <group position={[0, 0.1, 0]} rotation={[0.07, 0, 0]}>
          <group position={[0, 0.1, 0]} rotation={[-0.38, 0, 0]}>
            <group position={[0, 0.15, 0]} rotation={[-0.11, 0, 0]}>
              <group position={[0, 0.16, 0]} rotation={[0.63, 0, 0]}>
                <group position={[0, 0.05, 0]} rotation={[-0.14, 0, 0]}>
                  <group position={[0, 0.05, 0]} rotation={[-0.35, 0, 0]}>
                    <group rotation={[-0.03, 0, 0]}>
                      <group position={[0, 0.1, 0.11]} rotation={[2.76, 0, 0]}>
                        <group position={[0, 0.03, 0]} rotation={[-0.22, 0, 0]}>
                          <group position={[0, 0.02, 0]} rotation={[1.22, 0, 0]}>
                            <group position={[0, 0.01, 0]} rotation={[0.43, 0, 0]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.05, 0.1]} rotation={[-1.52, -0.02, -1.3]} />
                      <group position={[0, 0.04, 0.1]} rotation={[-1.37, -0.19, -1.13]} />
                      <group position={[0, 0, 0.04]} rotation={[1.7, 0, 0]}>
                        <group position={[0, 0.05, 0]} rotation={[-1.16, 0, 0]} />
                      </group>
                      <group position={[0.07, 0.07, 0.01]} rotation={[0.03, -0.21, -0.28]}>
                        <group position={[0, 0.04, 0]} rotation={[-1.35, -0.25, -0.12]}>
                          <group position={[0, 0.02, 0]} rotation={[-1.85, -0.39, -0.1]}>
                            <group position={[0, 0.03, 0]} rotation={[-0.29, -0.62, 0.64]} />
                          </group>
                        </group>
                      </group>
                      <group position={[-0.07, 0.07, 0.01]} rotation={[0.03, 0.21, 0.28]}>
                        <group position={[0, 0.04, 0]} rotation={[-1.35, 0.25, 0.12]}>
                          <group position={[0, 0.02, 0]} rotation={[-1.85, 0.39, 0.1]}>
                            <group position={[0, 0.03, 0]} rotation={[-0.29, 0.62, -0.64]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.05, 0.1]} rotation={[-1.52, 0.02, 1.3]} />
                      <group position={[0, 0.04, 0.1]} rotation={[-1.37, 0.19, 1.13]} />
                      <group position={[0.05, 0.11, 0.09]} rotation={[-0.93, 0.91, 1.96]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.46, 0.75, 0.03]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.15, 0.42, -0.37]} />
                        </group>
                      </group>
                      <group position={[0.05, 0.1, 0.08]} rotation={[-1.17, 1.22, 2.32]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.38, 0.87, -0.25]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.37, 0.42, -0.69]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.42, 0.57, -0.13]}>
                              <group position={[0, 0.01, 0]} rotation={[1.23, 0.04, -2.49]}>
                                <group position={[0, 0.01, 0]} rotation={[0.41, 0.18, 0.08]}>
                                  <group position={[0, 0.01, 0]} rotation={[0.27, 0.21, 0.42]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.05, 0.11, 0.09]} rotation={[-0.93, -0.91, -1.96]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.46, -0.75, -0.03]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.15, -0.42, 0.37]} />
                        </group>
                      </group>
                      <group position={[-0.05, 0.1, 0.08]} rotation={[-1.17, -1.22, -2.32]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.38, -0.87, 0.25]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.37, -0.42, 0.69]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.42, -0.57, 0.13]}>
                              <group position={[0, 0.01, 0]} rotation={[1.23, -0.04, 2.49]}>
                                <group position={[0, 0.01, 0]} rotation={[0.41, -0.18, -0.08]}>
                                  <group position={[0, 0.01, 0]} rotation={[0.27, -0.21, -0.42]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.01, 0.17, 0.1]} rotation={[-2.18, 1.48, -1.23]}>
                        <group position={[-0.03, -0.01, 0.02]} rotation={[-0.03, -0.26, -0.21]}>
                          <group position={[-0.02, 0, 0.02]} rotation={[-0.04, -0.56, -0.12]}>
                            <group position={[-0.01, -0.01, 0.03]} rotation={[0.64, -0.56, 0.46]}>
                              <group position={[0, 0.05, 0]} rotation={[0.06, -0.26, 0.35]}>
                                <group position={[0, 0.07, 0]} rotation={[-0.32, -0.68, 0.61]}>
                                  <group position={[0, 0.07, 0]} rotation={[-1.95, -0.18, 1.06]}>
                                    <group position={[0, 0.05, 0]} rotation={[-1.92, 0.02, -0.67]}>
                                      <group position={[0, 0.03, 0]} rotation={[1.25, 0.5, 0.23]}>
                                        <group position={[0, 0.05, 0]} rotation={[0.65, 0.51, 0.13]}>
                                          <group position={[0, 0.04, 0]} rotation={[-1.66, 0.48, 1.91]}>
                                            <group position={[0, 0.03, 0]} rotation={[-0.84, 0.9, 0.62]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.01, 0.17, 0.1]} rotation={[-2.18, -1.48, 1.23]}>
                        <group position={[0.03, -0.01, 0.02]} rotation={[-0.03, 0.26, 0.21]}>
                          <group position={[0.02, 0, 0.02]} rotation={[-0.04, 0.56, 0.12]}>
                            <group position={[0.01, -0.01, 0.03]} rotation={[0.64, 0.56, -0.46]}>
                              <group position={[0, 0.05, 0]} rotation={[0.06, 0.26, -0.35]}>
                                <group position={[0, 0.07, 0]} rotation={[-0.32, 0.68, -0.61]}>
                                  <group position={[0, 0.07, 0]} rotation={[-1.95, 0.18, -1.06]}>
                                    <group position={[0, 0.05, 0]} rotation={[-1.92, -0.02, 0.67]}>
                                      <group position={[0, 0.03, 0]} rotation={[1.25, -0.5, -0.23]}>
                                        <group position={[0, 0.05, 0]} rotation={[0.65, -0.51, -0.13]}>
                                          <group position={[0, 0.04, 0]} rotation={[-1.66, -0.48, -1.91]}>
                                            <group position={[0, 0.03, 0]} rotation={[-0.84, -0.9, -0.62]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.06, 0.08, 0.05]} rotation={[-3.11, -1.08, 1.42]}>
                        <group position={[0, 0.05, 0]} rotation={[-3, -0.79, 2.2]}>
                          <group position={[0, 0.03, 0]} rotation={[2.7, 0.91, 1.32]} />
                        </group>
                      </group>
                      <group position={[-0.06, 0.08, 0.05]} rotation={[-3.11, 1.08, -1.42]}>
                        <group position={[0, 0.05, 0]} rotation={[-3, 0.79, -2.2]}>
                          <group position={[0, 0.03, 0]} rotation={[2.7, -0.91, -1.32]} />
                        </group>
                      </group>
                      <group position={[0, 0.04, 0.07]} rotation={[-1.33, 0, 0]}>
                        <group position={[0, 0.02, 0]} rotation={[-0.37, 0, 0]} />
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[0.02, 0.15, 0.05]} rotation={[-1.45, 0.01, -1.38]}>
                <group position={[-0.01, 0.16, -0.02]} rotation={[1.24, 1.44, -2.11]}>
                  <group position={[0, 0.26, 0]} rotation={[0.74, 0.2, -0.04]}>
                    <group position={[0, 0.25, 0]} rotation={[0, -0.19, -0.14]}>
                      <group position={[-0.01, 0.02, 0.02]} rotation={[2.37, 1.49, -2.21]}>
                        <group position={[0, 0.06, 0]} rotation={[0.13, -0.27, 0.03]}>
                          <group position={[0, 0.03, 0]} rotation={[0.18, -0.14, -0.06]} />
                        </group>
                        <group position={[-0.02, 0, 0.02]} rotation={[-0.68, 1.17, 1.26]}>
                          <group position={[0, 0.04, 0]} rotation={[0.06, -0.09, -0.12]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.02, 0.01]} rotation={[2.67, 1.52, -2.76]}>
                        <group position={[0, 0.07, 0]} rotation={[0.19, -0.24, 0.05]}>
                          <group position={[0, 0.03, 0]} rotation={[0.11, -0.18, -0.04]} />
                        </group>
                      </group>
                      <group position={[-0.02, 0.02, 0]} rotation={[1.06, 1.54, -1.25]}>
                        <group position={[0, 0.07, 0]} rotation={[0.13, -0.3, -0.01]}>
                          <group position={[0, 0.03, 0]} rotation={[0.15, -0.1, -0.04]} />
                        </group>
                      </group>
                      <group position={[-0.02, 0.02, -0.02]} rotation={[-1.39, 1.52, 1.09]}>
                        <group position={[0, 0.06, 0]} rotation={[0.4, -0.4, -0.07]}>
                          <group position={[0, 0.03, 0]} rotation={[0.01, -0.02, 0.03]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[-0.02, 0.15, 0.05]} rotation={[-1.45, -0.01, 1.38]}>
                <group position={[0.01, 0.16, -0.02]} rotation={[1.24, -1.44, 2.11]}>
                  <group position={[0, 0.26, 0]} rotation={[0.74, -0.2, 0.04]}>
                    <group position={[0, 0.25, 0]} rotation={[0, 0.19, 0.14]}>
                      <group position={[0.01, 0.02, 0.02]} rotation={[2.37, -1.49, 2.21]}>
                        <group position={[0, 0.06, 0]} rotation={[0.13, 0.27, -0.03]}>
                          <group position={[0, 0.03, 0]} rotation={[0.18, 0.14, 0.06]} />
                        </group>
                        <group position={[0.02, 0, 0.02]} rotation={[-0.68, -1.17, -1.26]}>
                          <group position={[0, 0.04, 0]} rotation={[0.06, 0.09, 0.12]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.02, 0.01]} rotation={[2.67, -1.52, 2.76]}>
                        <group position={[0, 0.07, 0]} rotation={[0.19, 0.24, -0.05]}>
                          <group position={[0, 0.03, 0]} rotation={[0.11, 0.18, 0.04]} />
                        </group>
                      </group>
                      <group position={[0.02, 0.02, 0]} rotation={[1.06, -1.54, 1.25]}>
                        <group position={[0, 0.07, 0]} rotation={[0.13, 0.3, 0.01]}>
                          <group position={[0, 0.03, 0]} rotation={[0.15, 0.1, 0.04]} />
                        </group>
                      </group>
                      <group position={[0.02, 0.02, -0.02]} rotation={[-1.39, -1.52, -1.09]}>
                        <group position={[0, 0.06, 0]} rotation={[0.4, 0.4, 0.07]}>
                          <group position={[0, 0.03, 0]} rotation={[0.01, 0.02, -0.03]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
        <group position={[0.12, 0, 0.07]} rotation={[2.8, 0, -0.06]}>
          <group position={[0, 0.44, 0]} rotation={[0.1, 0.01, -0.05]}>
            <group position={[0, 0.37, 0]} rotation={[-1.04, -0.1, 0.05]} />
          </group>
        </group>
        <group position={[-0.12, 0, 0.07]} rotation={[2.8, 0, 0.06]}>
          <group position={[0, 0.44, 0]} rotation={[0.1, -0.01, 0.05]}>
            <group position={[0, 0.37, 0]} rotation={[-1.04, 0.1, -0.05]} />
          </group>
        </group>
      </group>
      <group name="rig">
        <primitive object={nodes.root} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
        <primitive object={nodes['MCH-torsoparent']} />
        <primitive object={nodes['MCH-hand_ikparentL']} />
        <primitive object={nodes['MCH-hand_ikparentR']} />
        <primitive object={nodes['MCH-foot_ikparentL']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
        <primitive object={nodes['MCH-foot_ikparentR']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
        <skinnedMesh
          geometry={nodes.middleage_woman.geometry}
          material={materials.ponytail01}
          skeleton={nodes.middleage_woman.skeleton}
        />
        <skinnedMesh
          geometry={nodes.middleage_woman001_1.geometry}
          material={materials['Eye_ice.001']}
          skeleton={nodes.middleage_woman001_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.middleage_woman001_2.geometry}
          material={materials.bodyMaterial}
          skeleton={nodes.middleage_woman001_2.skeleton}
        />
        <skinnedMesh
          geometry={nodes.middleage_woman001_3.geometry}
          material={materials.teethMaterial}
          skeleton={nodes.middleage_woman001_3.skeleton}
        />
        {/* <skinnedMesh
          geometry={nodes.middleage_woman001_4.geometry}
          material={materials.eyes_glass}
          skeleton={nodes.middleage_woman001_4.skeleton}
        /> */}
        <skinnedMesh
          geometry={nodes.middleage_woman003.geometry}
          material={materials.middleage_asian_female}
          skeleton={nodes.middleage_woman003.skeleton}
        />
        <skinnedMesh
          geometry={nodes.middleage_womanFORCLOTH_1.geometry}
          material={materials['middleage_asian_female.001']}
          skeleton={nodes.middleage_womanFORCLOTH_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.middleage_womanFORCLOTH_2.geometry}
          material={materials['FABRIC_2_FRONT_2078.002']}
          skeleton={nodes.middleage_womanFORCLOTH_2.skeleton}
        />
      </group>
    </group>
  )
}

// useGLTF.preload('/woman_mastectomy.glb')
