import React, { ReactElement } from 'react'
import SearchInput from '../../../common/SearchInput'
import Slot from '../../../common/Slots/Slot'

export default function LabSelectBlock(): ReactElement {
  return (
    <Slot title='Лабораторные исследования' color='primary'>
      <SearchInput />
    </Slot>
  )
}
