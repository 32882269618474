import React, { useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function ManTif(props) {

  const { nodes, materials, animations } = useGLTF('patient/Pacient_TIF_ANIM.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)

  useEffect(() => {
    props.setAnim(animations)
    actions.rigAction.play()
  }, [actions, names, mixer])
  
  return (
    <group ref={ref} {...props} dispose={null}>
      <group position={[0, 0.87, -0.01]} rotation={[0.15, 0, 0]}>
        <group position={[0, 0.13, 0]} rotation={[-0.06, 0, 0]}>
          <group position={[0, 0.11, 0]} rotation={[-0.25, 0, 0]}>
            <group position={[0, 0.15, 0]} rotation={[0.13, 0, 0]}>
              <group position={[0, 0.16, 0]} rotation={[0.72, 0, 0]}>
                <group position={[0, 0.04, 0]} rotation={[-0.2, 0, 0]}>
                  <group position={[0, 0.04, 0]} rotation={[-0.56, 0, 0]}>
                    <group rotation={[-0.05, 0, 0]}>
                      <group position={[0, 0.09, 0.12]} rotation={[2.85, 0, 0]}>
                        <group position={[0, 0.03, 0]} rotation={[-0.2, 0, 0]}>
                          <group position={[0, 0.01, 0]} rotation={[1.28, 0, 0]}>
                            <group position={[0, 0.01, 0]} rotation={[0.55, 0, 0]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.03, 0.12]} rotation={[-1.44, 0.08, -1.23]} />
                      <group position={[0, 0.02, 0.12]} rotation={[-1.33, -0.02, -1.28]} />
                      <group position={[0, -0.01, 0.05]} rotation={[1.7, 0, 0]}>
                        <group position={[0, 0.04, 0]} rotation={[-0.52, 0, 0]} />
                      </group>
                      <group position={[0.07, 0.07, 0.03]} rotation={[-0.09, -0.31, -0.21]}>
                        <group position={[0, 0.03, 0]} rotation={[-1.27, -0.14, -0.11]}>
                          <group position={[0, 0.02, 0]} rotation={[-1.56, -0.46, 0.15]}>
                            <group position={[0, 0.03, 0]} rotation={[-0.61, -0.65, 0.2]} />
                          </group>
                        </group>
                      </group>
                      <group position={[-0.07, 0.07, 0.03]} rotation={[-0.09, 0.31, 0.21]}>
                        <group position={[0, 0.03, 0]} rotation={[-1.27, 0.14, 0.11]}>
                          <group position={[0, 0.02, 0]} rotation={[-1.56, 0.46, -0.15]}>
                            <group position={[0, 0.03, 0]} rotation={[-0.61, 0.65, -0.2]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.03, 0.12]} rotation={[-1.44, -0.08, 1.23]} />
                      <group position={[0, 0.02, 0.12]} rotation={[-1.33, 0.02, 1.28]} />
                      <group position={[0.05, 0.11, 0.1]} rotation={[-0.96, 1.01, 2.17]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.47, 0.77, 0.01]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.03, 0.43, -0.38]} />
                        </group>
                      </group>
                      <group position={[0.05, 0.1, 0.11]} rotation={[-0.85, 1.07, 1.91]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.6, 0.76, -0.03]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.25, 0.34, -0.38]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.13, 0.55, -0.52]}>
                              <group position={[0, 0.01, 0]} rotation={[0.86, 0.08, -2.39]}>
                                <group position={[0, 0.01, 0]} rotation={[0.38, 0.2, -0.1]}>
                                  <group position={[0, 0.01, 0]} rotation={[0.22, 0.24, 0.53]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.05, 0.11, 0.1]} rotation={[-0.96, -1.01, -2.17]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.47, -0.77, -0.01]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.03, -0.43, 0.38]} />
                        </group>
                      </group>
                      <group position={[-0.05, 0.1, 0.11]} rotation={[-0.85, -1.07, -1.91]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.6, -0.76, 0.03]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.25, -0.34, 0.38]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.13, -0.55, 0.52]}>
                              <group position={[0, 0.01, 0]} rotation={[0.86, -0.08, 2.39]}>
                                <group position={[0, 0.01, 0]} rotation={[0.38, -0.2, 0.1]}>
                                  <group position={[0, 0.01, 0]} rotation={[0.22, -0.24, -0.53]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.01, 0.17, 0.12]} rotation={[2.43, 1.54, 0.51]}>
                        <group position={[-0.02, -0.01, 0.03]} rotation={[0.11, -0.26, -0.13]}>
                          <group position={[-0.02, 0, 0.02]} rotation={[0.08, -0.56, 0.15]}>
                            <group position={[-0.01, 0, 0.02]} rotation={[0.39, -0.68, 0.27]}>
                              <group position={[0, 0.07, 0]} rotation={[0.04, -0.26, 0.28]}>
                                <group position={[0, 0.06, 0]} rotation={[-0.26, -0.69, 0.73]}>
                                  <group position={[0, 0.06, 0]} rotation={[-1.9, 0.06, 1.32]}>
                                    <group position={[0, 0.04, 0]} rotation={[-1.71, -0.11, -0.49]}>
                                      <group position={[0, 0.05, 0]} rotation={[0.76, 0.44, 0.13]}>
                                        <group position={[0, 0.05, 0]} rotation={[1.29, 0.54, 0.09]}>
                                          <group position={[0, 0.03, 0]} rotation={[-2.52, 0.91, 2.58]}>
                                            <group position={[0, 0.03, 0]} rotation={[0.09, 0.82, -0.84]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.01, 0.17, 0.12]} rotation={[2.43, -1.54, -0.51]}>
                        <group position={[0.02, -0.01, 0.03]} rotation={[0.11, 0.26, 0.13]}>
                          <group position={[0.02, 0, 0.02]} rotation={[0.08, 0.56, -0.15]}>
                            <group position={[0.01, 0, 0.02]} rotation={[0.39, 0.68, -0.27]}>
                              <group position={[0, 0.07, 0]} rotation={[0.04, 0.26, -0.28]}>
                                <group position={[0, 0.06, 0]} rotation={[-0.26, 0.69, -0.73]}>
                                  <group position={[0, 0.06, 0]} rotation={[-1.9, -0.06, -1.32]}>
                                    <group position={[0, 0.04, 0]} rotation={[-1.71, 0.11, 0.49]}>
                                      <group position={[0, 0.05, 0]} rotation={[0.76, -0.44, -0.13]}>
                                        <group position={[0, 0.05, 0]} rotation={[1.29, -0.54, -0.09]}>
                                          <group position={[0, 0.03, 0]} rotation={[-2.52, -0.91, -2.58]}>
                                            <group position={[0, 0.03, 0]} rotation={[0.09, -0.82, 0.84]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.07, 0.1, 0.06]} rotation={[3.04, -1.1, 0.84]}>
                        <group position={[0, 0.06, 0]} rotation={[3.02, -0.61, 1.78]}>
                          <group position={[0, 0.04, 0]} rotation={[2.49, 0.83, 1.55]} />
                        </group>
                      </group>
                      <group position={[-0.07, 0.1, 0.06]} rotation={[3.04, 1.1, -0.84]}>
                        <group position={[0, 0.06, 0]} rotation={[3.02, 0.61, -1.78]}>
                          <group position={[0, 0.04, 0]} rotation={[2.49, -0.83, -1.55]} />
                        </group>
                      </group>
                      <group position={[0, 0.03, 0.1]} rotation={[-1.23, 0, 0]}>
                        <group position={[0, 0.02, 0]} rotation={[-0.37, 0, 0]} />
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[0.03, 0.15, 0.03]} rotation={[-1.54, 0, -1.39]}>
                <group position={[0, 0.15, -0.02]} rotation={[0.96, 1.38, -1.89]}>
                  <group position={[0, 0.27, 0]} rotation={[0.74, 0.25, 0.05]}>
                    <group position={[0, 0.26, 0]} rotation={[-0.05, -0.3, -0.18]}>
                      <group position={[-0.01, 0.03, 0.02]} rotation={[1.91, 1.49, -1.8]}>
                        <group position={[0, 0.07, 0]} rotation={[0.12, -0.15, 0.05]}>
                          <group position={[0, 0.03, 0]} rotation={[0.21, -0.15, -0.05]} />
                        </group>
                        <group position={[-0.01, -0.01, 0.02]} rotation={[-0.89, 1.23, 1.42]}>
                          <group position={[0, 0.04, 0]} rotation={[-0.03, -0.01, -0.07]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.03, 0.01]} rotation={[1.35, 1.54, -1.45]}>
                        <group position={[0, 0.07, 0]} rotation={[0.16, -0.17, -0.01]}>
                          <group position={[0, 0.04, 0]} rotation={[0.17, -0.25, 0.04]} />
                        </group>
                      </group>
                      <group position={[-0.02, 0.03, 0]} rotation={[1.46, 1.52, -1.64]}>
                        <group position={[0, 0.06, 0]} rotation={[0.09, -0.33, -0.04]}>
                          <group position={[0, 0.04, 0]} rotation={[0.16, -0.13, -0.06]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.03, -0.02]} rotation={[1.22, 1.51, -1.44]}>
                        <group position={[0, 0.06, 0]} rotation={[0.04, -0.57, -0.17]}>
                          <group position={[0, 0.03, 0]} rotation={[0.18, 0, -0.11]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[-0.03, 0.15, 0.03]} rotation={[-1.54, 0, 1.39]}>
                <group position={[0, 0.15, -0.02]} rotation={[0.96, -1.38, 1.89]}>
                  <group position={[0, 0.27, 0]} rotation={[0.74, -0.25, -0.05]}>
                    <group position={[0, 0.26, 0]} rotation={[-0.05, 0.3, 0.18]}>
                      <group position={[0.01, 0.03, 0.02]} rotation={[1.91, -1.49, 1.8]}>
                        <group position={[0, 0.07, 0]} rotation={[0.12, 0.15, -0.05]}>
                          <group position={[0, 0.03, 0]} rotation={[0.21, 0.15, 0.05]} />
                        </group>
                        <group position={[0.01, -0.01, 0.02]} rotation={[-0.89, -1.23, -1.42]}>
                          <group position={[0, 0.04, 0]} rotation={[-0.03, 0.01, 0.07]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.03, 0.01]} rotation={[1.35, -1.54, 1.45]}>
                        <group position={[0, 0.07, 0]} rotation={[0.16, 0.17, 0.01]}>
                          <group position={[0, 0.04, 0]} rotation={[0.17, 0.25, -0.04]} />
                        </group>
                      </group>
                      <group position={[0.02, 0.03, 0]} rotation={[1.46, -1.52, 1.64]}>
                        <group position={[0, 0.06, 0]} rotation={[0.09, 0.33, 0.04]}>
                          <group position={[0, 0.04, 0]} rotation={[0.16, 0.13, 0.06]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.03, -0.02]} rotation={[1.22, -1.51, 1.44]}>
                        <group position={[0, 0.06, 0]} rotation={[0.04, 0.57, 0.17]}>
                          <group position={[0, 0.03, 0]} rotation={[0.18, 0, 0.11]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
        <group position={[0.11, 0.04, 0.02]} rotation={[2.92, 0, -0.1]}>
          <group position={[0, 0.41, 0]} rotation={[0.15, 0.02, 0]}>
            <group position={[0, 0.4, 0]} rotation={[-1.06, -0.09, 0.03]} />
          </group>
        </group>
        <group position={[-0.11, 0.04, 0.02]} rotation={[2.92, 0, 0.1]}>
          <group position={[0, 0.41, 0]} rotation={[0.15, -0.02, 0]}>
            <group position={[0, 0.4, 0]} rotation={[-1.06, 0.09, -0.03]} />
          </group>
        </group>
      </group>
      <group name="rig">
        <primitive object={nodes.root} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
        <primitive object={nodes['MCH-torsoparent']} />
        <primitive object={nodes['MCH-hand_ikparentL']} />
        <primitive object={nodes['MCH-hand_ikparentR']} />
        <primitive object={nodes['MCH-foot_ikparentL']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
        <primitive object={nodes['MCH-foot_ikparentR']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
        <skinnedMesh
          geometry={nodes.pacient_A_1004.geometry}
          material={materials.PACIENT_A_CLOTH}
          skeleton={nodes.pacient_A_1004.skeleton}
        />
        {/* <skinnedMesh
          geometry={nodes.PACIENT_TYPHUS_1.geometry}
          material={materials.eye_glass}
          skeleton={nodes.PACIENT_TYPHUS_1.skeleton}
        /> */}
        <skinnedMesh
          geometry={nodes.PACIENT_TYPHUS_2.geometry}
          material={materials.Eye_brown}
          skeleton={nodes.PACIENT_TYPHUS_2.skeleton}
        />
        <skinnedMesh
          geometry={nodes.PACIENT_TYPHUS_3.geometry}
          material={materials['bodyMaterial.004']}
          skeleton={nodes.PACIENT_TYPHUS_3.skeleton}
        />
        <skinnedMesh
          geometry={nodes.PACIENT_TYPHUS001.geometry}
          material={materials.middleage_asian_male}
          skeleton={nodes.PACIENT_TYPHUS001.skeleton}
        />
        <skinnedMesh
          geometry={nodes.PACIENT_TYPHUS002.geometry}
          material={materials['materialMaterial.001']}
          skeleton={nodes.PACIENT_TYPHUS002.skeleton}
        />
      </group>
    </group>
  )
}

// useGLTF.preload('patient/Pacient_TIF_ANIM.glb')
