import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import React from 'react'
import { useQuery } from 'react-query'
import chatApi from '../../api/chatApi'
import ChatPagesMenuMobile from '../../components/Chat/ChatPagesMenuMobile'
import MainLayout from '../../components/Layout/MainLayout'
import moment from 'moment'
import 'moment/locale/ru'
import CognitiveHistoryTable from '../../components/cognitive/CognitiveHistoryTable'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 650,
      margin: '20px auto',
      padding: 20
    },
    title: {
      maxWidth: '650px',
      width: '100%',
      margin: '0 auto',
      marginTop: '40px',
      color: '#6f6f6f',
      marginBottom: 32
    }
  })
)

const CognitiveHistory = () => {
  const classes = useStyles()

  return (
    <MainLayout
      mobileHeader={<ChatPagesMenuMobile title={''} />}
      title=''
      hiddenSidebar
    >
      <div className={classes.root}>
        <h2 className={classes.title}>История</h2>

        <CognitiveHistoryTable />
      </div>
    </MainLayout>
  )
}

export default CognitiveHistory
