import MessageIcon from '@material-ui/icons/Message';
import { ConverstionTypes, ExamLevelsTypes, HpParamsTypes, MenuItemData, ParamDataListTypes, RoutePath } from '../types';

import FiveActive from '../assets/img/academic_level/FiveActive.svg';
import FivePassive from '../assets/img/academic_level/FivePassive.svg';
import FourActive from '../assets/img/academic_level/FourActive.svg';
import FourPassive from '../assets/img/academic_level/FourPassive.svg';
import ThreeActive from '../assets/img/academic_level/ThreeActive.svg';
import ThreePassive from '../assets/img/academic_level/ThreePassive.svg';

import Hard from '../assets/img/level/Hard.svg';
import HardDisabled from '../assets/img/level/HardDisabled.svg';
import Middle from '../assets/img/level/Middle.svg';
import MiddleDisabled from '../assets/img/level/MiddleDisabled.svg';
import Easy from '../assets/img/level/Easy.svg';
import EasyDisabled from '../assets/img/level/EasyDisabled.svg';
import Random from '../assets/img/level/Random.svg';
import RandomDisabled from '../assets/img/level/RandomDisabled.svg';

import ConsciousnessIcon from '../assets/img/hp_icons/Consciousness.svg';
import FecesIcon from '../assets/img/hp_icons/Feces.svg';
import HeightIcon from '../assets/img/hp_icons/Height.svg';
import PressureIcon from '../assets/img/hp_icons/Pressure.svg';
import PulseIcon from '../assets/img/hp_icons/Pulse.svg';
import RespiratoryRateIcon from '../assets/img/hp_icons/RespiratoryRate.svg';
import SaturationIcon from '../assets/img/hp_icons/Saturation.svg';
import TemperatureIcon from '../assets/img/hp_icons/Temperature.svg';
import UrinationIcon from '../assets/img/hp_icons/Urination.svg';
import WeightIcon from '../assets/img/hp_icons/Weight.svg';
import SleepIcon from '../assets/img/hp_icons/Sleep.svg';
import Edit from '../assets/img/communication/pen.png';
import { TestingStage } from '../api/chatApi/chatApi';

export const NavbarHeight = 65;
export const SidebarWidth = 256;

export const SiteTitle = {
  fullLabel: 'NeuroDoc',
  shortLabel: 'ND',
};

export const AUTH_MENU: Array<MenuItemData> = [
  { title: 'Регистрация', routePath: RoutePath.register },
  { title: 'Вход в аккаунт', routePath: RoutePath.login },
  { title: 'Восстановить', routePath: RoutePath.passReset },
];

export const PANEL_MENU: Array<MenuItemData> = [
  { title: 'Пройти тест', routePath: RoutePath.newChat, icon: MessageIcon },
  { title: 'Конструкторы', routePath: RoutePath.constructors },
  { title: 'Статистика', routePath: RoutePath.rating },
  { title: 'Администрирование', routePath: RoutePath.admin },
  // { title: 'Главная', routePath: RoutePath.chat, icon: DashboardIcon },
  // { title: 'Тестирование', routePath: RoutePath.chat, icon: MessageIcon },
  // { title: 'Блокнот', routePath: RoutePath.chat, icon: AssignmentIcon },
  // { title: 'Завершить', routePath: RoutePath.chat, icon: AssignmentIcon },
];

export const DESKTOP_PANEL_MENU: Array<MenuItemData> = [
  { title: 'Пройти тест', routePath: RoutePath.newChat, icon: MessageIcon },
  { title: 'Конструкторы', routePath: RoutePath.constructors },
  { title: 'Статистика', routePath: RoutePath.rating },
  // { title: 'Завершить', routePath: RoutePath.chat, icon: AssignmentIcon },
];

export const CONVERSATION_TYPES: ConverstionTypes = {
  hard: { title: 'Сложный', image: Hard },
  middle: { title: 'Средний', image: Middle },
  easy: { title: 'Легкий', image: Easy },
};

export const TESTING_STAGE_TO_ROUTE: Map<TestingStage, string> = new Map([
  [TestingStage.CHAT, RoutePath.chat],
  [TestingStage.OBJECTIVE_STATUS, RoutePath.inspection],
  [TestingStage.PROVISIONAL_DIAGNOSIS, RoutePath.preDiagnosis],
  [TestingStage.RESEARCH, RoutePath.research],
  [TestingStage.DIAGNOSIS, RoutePath.diagnosis],
  [TestingStage.DIAGNOSIS_RESULT, RoutePath.diagnosis],
  [TestingStage.HEALING, RoutePath.therapy],
  [TestingStage.HEALING_RESULT, RoutePath.therapy],
  [TestingStage.GENERAL_RESULT, RoutePath.therapy],
  [TestingStage.FINISH, RoutePath.therapy],
  [TestingStage.EXAMINATION, RoutePath.examination],
]);

export const CHAT_MENU_ITEMS: Array<MenuItemData> = [
  { title: 'Осмотр пациента', key:'Inspection', routePath: RoutePath.testModel, activateStage: TestingStage.INSPECTION },
  { title: 'Жалобы и Анамнез', key:'Complaints', routePath: RoutePath.chat, activateStage: TestingStage.CHAT },
  { title: 'Статус', key:'Status', routePath: RoutePath.inspection, activateStage: TestingStage.OBJECTIVE_STATUS },
  { title: 'Предварительный диагноз', key:'Preliminary', routePath: RoutePath.preDiagnosis, activateStage: TestingStage.PROVISIONAL_DIAGNOSIS },
  { title: 'Обследование', key:'Survey', routePath: RoutePath.research, activateStage: TestingStage.RESEARCH },
  { title: 'Консультация специалиста', key:'Specialist', routePath: RoutePath.consultationPage, activateStage: TestingStage.CONSULTATION },
  { title: 'Патофизиологическая модель', key:'Model', routePath: RoutePath.examination, activateStage: TestingStage.EXAMINATION },
  { title: 'Диагноз', key:'Diagnosis', routePath: RoutePath.diagnosis, activateStage: TestingStage.DIAGNOSIS },
  { title: 'Лечение' , key:'Treatment', routePath: RoutePath.therapy, activateStage: TestingStage.HEALING, accessLevel: 3 },
  { title: 'Итог' , key:'Result', routePath: RoutePath.finalTaskResult, activateStage: TestingStage.GENERAL_RESULT },
];

export const CHAT_MENU_ITEMS_NO: Array<MenuItemData> = [
  { title: 'Осмотр пациента', key:'Inspection' , routePath: RoutePath.testModel, activateStage: TestingStage.INSPECTION },
  { title: 'Жалобы и Анамнез', key:'Complaints', routePath: RoutePath.chat, activateStage: TestingStage.CHAT },
  { title: 'Статус', key:'Status', routePath: RoutePath.inspection, activateStage: TestingStage.OBJECTIVE_STATUS },
  { title: 'Предварительный диагноз', key:'Preliminary', routePath: RoutePath.preDiagnosis, activateStage: TestingStage.PROVISIONAL_DIAGNOSIS },
  { title: 'Дополнительное обследование', key:'AdditionalExamination', routePath: RoutePath.research, activateStage: TestingStage.RESEARCH },
  { title: 'Консультация специалиста', key:'Specialist', routePath: RoutePath.consultationPage, activateStage: TestingStage.CONSULTATION },
  { title: 'Диагноз', key:'Diagnosis', routePath: RoutePath.diagnosis, activateStage: TestingStage.DIAGNOSIS },
  { title: 'Лечение' , key:'Treatment', routePath: RoutePath.therapy, activateStage: TestingStage.HEALING, accessLevel: 3 },
  { title: 'Итог' , key:'Result', routePath: RoutePath.finalTaskResult, activateStage: TestingStage.GENERAL_RESULT },
];

export const CHAT_MENU_ITEMS_LIGHT: Array<MenuItemData> = [
  { title: 'Осмотр пациента', key:'Inspection' , routePath: RoutePath.testModel, activateStage: TestingStage.INSPECTION },
  { title: 'Жалобы и Анамнез', key:'Complaints', routePath: RoutePath.chat, activateStage: TestingStage.CHAT },
  { title: 'Статус', key:'Status', routePath: RoutePath.inspection, activateStage: TestingStage.OBJECTIVE_STATUS },
  { title: 'Дополнительное обследование', key:'AdditionalExamination', routePath: RoutePath.research, activateStage: TestingStage.RESEARCH },
  { title: 'Патофизиологическая модель', key:'Model', routePath: RoutePath.examination, activateStage: TestingStage.EXAMINATION },
  { title: 'Заключение' , key:'Conclusion', routePath: RoutePath.conclusion, activateStage: TestingStage.CONCLUSION },
];

export const CHAT_MENU_ITEMS_LIGHT_SHORT: Array<MenuItemData> = [
  { title: 'Осмотр пациента', key:'Inspection' , routePath: RoutePath.testModel, activateStage: TestingStage.INSPECTION },
  { title: 'Жалобы и Анамнез', key:'Complaints', routePath: RoutePath.chat, activateStage: TestingStage.CHAT },
  { title: 'Статус', key:'Status', routePath: RoutePath.inspection, activateStage: TestingStage.OBJECTIVE_STATUS },
  { title: 'Дополнительное обследование', key:'AdditionalExamination', routePath: RoutePath.research, activateStage: TestingStage.RESEARCH },
  { title: 'Заключение', key:'Conclusion', routePath: RoutePath.conclusion, activateStage: TestingStage.CONCLUSION },
];

export const CHAT_MENU_ITEMS_LIGHT_NO: Array<MenuItemData> = [
  { title: 'Осмотр пациента', key:'Inspection' , routePath: RoutePath.testModel, activateStage: TestingStage.INSPECTION },
  { title: 'Жалобы и Анамнез', key:'Complaints', routePath: RoutePath.chat, activateStage: TestingStage.CHAT },
  { title: 'Статус', key:'Status', routePath: RoutePath.inspection, activateStage: TestingStage.OBJECTIVE_STATUS },
  { title: 'Предварительный диагноз', key:'Preliminary', routePath: RoutePath.preDiagnosis, activateStage: TestingStage.PROVISIONAL_DIAGNOSIS },
  { title: 'Дополнительное обследование', key:'AdditionalExamination', routePath: RoutePath.research, activateStage: TestingStage.RESEARCH },
  { title: 'Консультация специалиста', key:'Specialist', routePath: RoutePath.consultationPage, activateStage: TestingStage.CONSULTATION },
  { title: 'Диагноз', key:'Diagnosis', routePath: RoutePath.diagnosis, activateStage: TestingStage.DIAGNOSIS },
];

export const CHAT_MENU_ITEMS_LIGHT_NO_QUESTION: Array<MenuItemData> = [
  { title: 'Осмотр пациента', key:'Inspection' , routePath: RoutePath.testModel, activateStage: TestingStage.INSPECTION },
  { title: 'Жалобы и Анамнез', key:'Complaints', routePath: RoutePath.chat, activateStage: TestingStage.CHAT },
  { title: 'Статус', key:'Status', routePath: RoutePath.inspection, activateStage: TestingStage.OBJECTIVE_STATUS },
  { title: 'Предварительный диагноз', key:'Preliminary', routePath: RoutePath.preDiagnosis, activateStage: TestingStage.PROVISIONAL_DIAGNOSIS },
  { title: 'Дополнительное обследование', key:'AdditionalExamination', routePath: RoutePath.research, activateStage: TestingStage.RESEARCH },
  { title: 'Консультация специалиста', key:'Specialist', routePath: RoutePath.consultationPage, activateStage: TestingStage.CONSULTATION },
  { title: 'Патофизиологическая модель', key:'Model', routePath: RoutePath.examination, activateStage: TestingStage.EXAMINATION },
  { title: 'Диагноз', key:'Diagnosis', routePath: RoutePath.diagnosis, activateStage: TestingStage.DIAGNOSIS },
];

export const EXAM_ACADEMIC_LEVELS: ExamLevelsTypes = {
  three: { title: 'III КУРС', image: FiveActive, imageDisabled: FivePassive },
  four: { title: 'IV КУРС', image: FourActive, imageDisabled: FourPassive },
  five: { title: 'V КУРС', image: ThreeActive, imageDisabled: ThreePassive },
};

export const EXAM_LEVELS: ExamLevelsTypes = {
  hard: { title: 'Сложный', image: Hard, imageDisabled: HardDisabled, accessLevel: 3 },
  middle: { title: 'Средний', image: Middle, imageDisabled: MiddleDisabled, accessLevel: 3 },
  easy: { title: 'Легкий', image: Easy, imageDisabled: EasyDisabled },
  random: { title: 'Случайно', image: Random, imageDisabled: RandomDisabled },
};

export const HP_ICONS: HpParamsTypes = {
  consciousness: {
    key: 'consciousness',
    title: 'Уровень сознания',
    image: ConsciousnessIcon,
  },
  feces: { key: 'feces', title: 'Кал', image: FecesIcon },
  height: { key: 'height', title: 'Рост', image: HeightIcon },
  pressure: { key: 'pressure', title: 'Давление', image: PressureIcon },
  pulse: { key: 'pulse', title: 'Пульс', image: PulseIcon },
  respiratoryRate: {
    key: 'respiratoryRate',
    title: 'Частоты дых. движений',
    image: RespiratoryRateIcon,
  },
  saturation: { key: 'saturation', title: 'Сатурация', image: SaturationIcon },
  temperature: {
    key: 'temperature',
    title: 'Температура',
    image: TemperatureIcon,
  },
  urination: {
    key: 'urination',
    title: 'Мочеиспускание',
    image: UrinationIcon,
  },
  weight: { key: 'weight', title: 'Вес', image: WeightIcon },
  sleep: { key: 'sleep', title: 'Сон', image: SleepIcon },
  // excrementPhoto: {key: 'excrementPhoto', title: 'Кал', image: SleepIcon}
};

export const PARAM_DATA: ParamDataListTypes = {
  consciousness: null,
  feces: { image: 'https://www.mygpost.com/wp-content/uploads/2019/10/health-care.jpg' },
  height: null,
  weight: { text: '45кг' },
  pressure: null,
  pulse: null,
  respiratoryRate: { text: '30/мин' },
  saturation: null,
  temperature: { text: '36.6' },
  urination: { text: 'нормальное' },
  excrementPhoto: null
};

export const objectiveStatusTypesIds: { [name: string]: string } = {
  urination: '26e1e0df-c4d2-4e77-9bb7-df1192a3516a',
  temperature: '1eead7f9-6b32-4542-8d88-a9fc9d10d70e',
  saturation: '8513c4f0-f5a4-4aeb-883f-09046e76e91f',
  respiratoryRate: '58a9e291-a2b2-46a4-948d-63282c92e8d2',
  pulse: 'e8cbf566-fc4a-4761-9e36-544a9ec39936',
  consciousness: 'dca9c00b-0a7b-403d-a721-31eebce71030',
  height: '5a3533c9-24fc-48eb-817e-c5dc226d7809',
  weight: '5a3533c9-24fc-48eb-817e-c5dc226d7810',
  pressure: 'c53172de-45ac-43aa-a0a6-5989ca2228f1',
  feces: '277ad933-4f9f-4daa-9e3f-f020aea7c997',
  patientStatus: '75457b6a-093d-4b1d-b543-e30dbeaaadd6',
  excrementPhoto: 'b0648556-0641-4753-b13a-7b198933c6db'
};

function swap(obj: any) {
  const ret = {};
  for (let key in obj) {
    // @ts-ignore
    ret[obj[key]] = key;
  }
  return ret;
}

export const typeIdsToObjectiveStatusName: { [name: string]: string } = swap(objectiveStatusTypesIds);

export const PARAM_NAMES: string[] = ['height', 'weight', 'pressure', 'consciousness', 'pulse', 'respiratoryRate', 'saturation', 'temperature', 'feces', 'urination',];
