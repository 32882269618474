import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
//@ts-ignore
import { Form, Stack, TextInput, Button } from '@carbon/react';
import chatApi from '../../api/chatApi';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      width: '49%',
      display: 'flex',
      flexDirection: 'column',
      '@media (max-width: 1280px)': {
        width: '98%',
      },
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: 20,
      fontWeight: 500,
      margin: '10px 0',
    },
    formContainer: {
      minWidth: 200,
      padding: 20,
      boxShadow: '0px 0px 2px rgba(9, 36, 51, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.12)',
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginBottom: 50,
    }
  })
)

const CreatePasswordProfile = ({ setError, setSuccess }) => {
  const classes = useStyles()

  const initial = {
    current: '',
    new: ''
  }
  const [datas, setDatas] = useState(initial)
  const [invCurrent, setInvCurrent] = useState(false)
  const [invNew, setInvNew] = useState(false)


  const savePassword = async () => {
    setSuccess(false)
    setError(false)
    valNew()
    valCurrent()
    if (datas.current === datas.new) {
      setError(true)
    }
    else {
      await chatApi
        .savePassword(datas.current, datas.new)
        .then(() => {
          setDatas(initial)
          setSuccess(true)
          setDatas(initial);
        })
        .catch(e => {
          setError(true)
        });
    }
  };

  const valCurrent = () => {
    if (datas.current.length < 4) {
      setInvCurrent(true)
    }
    else {
      setInvCurrent(false)
    }
  }

  const valNew = () => {
    setError(false)
    if (datas.new.length < 4) {
      setInvNew(true)
    }
    else {
      setInvNew(false)
    }
  }


  return (
    <div className={classes.mainContainer}>
      <div className={classes.title}>
        Изменение пароля
      </div>
      <div className={classes.formContainer}>
        <Form>
          <Stack gap={7}>
            <TextInput.PasswordInput
              id="test2"
              invalid={invCurrent}
              type="password"
              required
              invalidText="Пароль менее 4 символов"
              labelText="Текущий пароль"
              onChange={(e) => setDatas({ ...datas, current: e.target.value })}
              onBlur={valCurrent}
            />
            <TextInput.PasswordInput
              id="test2"
              invalid={invNew}
              type="password"
              invalidText="Пароль менее 4 символов"
              labelText="Новый пароль"
              onChange={(e) => setDatas({ ...datas, new: e.target.value })}
              onBlur={valNew}
            />
            <Button
              kind="primary"
              tabIndex={0}
              onClick={() => {
                setError(false)
                savePassword()
              }}
              style={{ borderRadius: 4 }}
            >
              Сохранить
            </Button>
          </Stack>
        </Form>
      </div>

    </div>
  );
}

export default CreatePasswordProfile;
