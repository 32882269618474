import React from 'react'
import { Icon, Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import ConfirmationLayout from '../../components/Layout/ConfirmationLayout'

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    textAlign: 'center',
    marginTop: 'auto'
  }
}))

export default function EmailConfirmPage() {
  const c = useStyles()

  return (
    <ConfirmationLayout title='EMAIL “подтверждение email-адреса“'>
      <p>
        На ваш email mari@kuri.ru зарегистрирован аккаунт сайта CyberDoctor. Для
        подтверждения регистрации пройдите по ссылке:{' '}
        <Link href='https://cyberdoctor.ru/auth/confirm/dlg83tsp45453dfsfdgkdsf'>
          https://cyberdoctor.ru/auth/confirm/dlg83tsp45453dfsfdgkdsf
        </Link>
      </p>
      <p>
        Если вы не запрашивали восстановление пароля, проигнорируйте данное
        письмо.
        <br />
        <br />
      </p>
      <div className={c.buttonWrapper}>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          endIcon={<Icon>arrow_forward_ios</Icon>}
        >
          ПОТВЕРДИТЬ ИМЕЙЛ
        </Button>
      </div>
    </ConfirmationLayout>
  )
}
