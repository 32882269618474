import React from 'react'
import AuthLayout from '../../components/Layout/AuthLayout'

import AuthNavigationPanel from '../../components/auth/AuthNavigationPanel'
import RegisterInfoForm from '../../components/auth/Forms/RegisterInfoForm'

export default function RegistrationInfoPage() {
  return (
    <AuthLayout>
      <AuthNavigationPanel />
      <RegisterInfoForm />
    </AuthLayout>
  )
}
