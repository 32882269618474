import { Box, createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { SidebarWidth, SiteTitle } from '../../constants/default'
import { pxToRem, pxToRemGutter } from '../../utils'

const useStyles = makeStyles(theme => {
  return createStyles({
    menuTitle: {
      fontWeight: 500,
      lineHeight: '23px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      letterSpacing: '0.15px',
      textTransform: 'uppercase',
      color: theme.palette.primary.main
    },
    root: {
      width: '100%',
      margin: pxToRemGutter(0, 27),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    logo: {
      width: pxToRem(SidebarWidth),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: '1px solid #E5E5E5'
    },
    logo_text: {
      fontWeight: 600,
      fontSize: pxToRem(20),
      color: theme.palette.primary.main
    },
    navbar: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'uppercase',
      fontSize: pxToRem(20),
      fontWeight: 500,
      lineHeight: pxToRem(30),
      letterSpacing: 0.15
    }
  })
})

type Props = { title: string }

export default function NewChatWizardTopMenu({ title }: Props) {
  const style = useStyles()

  return (
    <>
      <div className={style.logo}>
        <span className={style.logo_text}>{SiteTitle.fullLabel}</span>
      </div>
      <div className={style.navbar}>
        <Box className={style.root}>
          <span className={style.menuTitle}>{title}</span>
        </Box>
      </div>
    </>
  )
}
