import React, { useMemo } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { MenuItemData } from '../../types'
import { NavLink, useLocation } from 'react-router-dom'
import useTestingStage from '../../hooks/testingTask/useTestingStage'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topMenu: {
      color: theme.palette.primary.contrastText
    },
    step: {
      textDecoration: 'none',
      color: theme.palette.text.hint
    },
    active: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    }
  })
)

type Props = {
  step?: number
  menuItems: Array<MenuItemData>
}

export default function StepsDropdown(props: Props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const location = useLocation()
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      const node = anchorRef.current
      if (node) {
        node.focus()
      }
    }

    prevOpen.current = open
  }, [open])

  const currentMenuItem = useMemo(() => {
    const current = props.menuItems.find(
      item => location.pathname.indexOf(item.routePath) !== -1
    )
    return current?.title
  }, [props.menuItems, location.pathname])

  const currentStage = useTestingStage()

  return (
    <div>
      {currentMenuItem ? (
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={handleToggle}
          className={classes.topMenu}
        >
          {currentMenuItem} {open ? <ExpandLess /> : <ExpandMore />}
        </Button>
      ) : null}

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='menu-list-grow'
                  onKeyDown={handleListKeyDown}
                >
                  {props.menuItems.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={handleClose}
                      disabled={item.activateStage!! > currentStage}
                    >
                      <NavLink
                        to={item.routePath}
                        activeClassName={classes.active}
                        className={classes.step}
                      >
                        {item.title}
                      </NavLink>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
