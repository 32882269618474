import React, { useEffect, useState } from 'react';

import { Paper, Button } from '@material-ui/core';
import Carousel from "react-material-ui-carousel";
import { getMediaContent } from '../../../../api/chatApi/chatApi';
//@ts-ignore
import { Loading } from '@carbon/react';
interface ProfileCarouselInterface {
  imageList: any[] | undefined
}


export default function ProfileCarousel ({
  imageList
}: ProfileCarouselInterface) {

  const Item = ({path}: any) => {
    const [link, setLink] = useState('');

    const res = async() => {
      const data = await getMediaContent(path)
      const result = await data.data.data_base64
      setLink(result)
    }
    useEffect(() => {
      res()
    }, [path]);
    
    return (
      <Paper style={{height: 400}}>
        {/*<h2>{name}</h2>*/}
        {/*<p>{description}</p>*/}
        {/*<Button>more info...</Button>*/}
        {/* <div style={{backgroundImage: `url(${path})`, height: '100%'}} /> */}
        {link ?
          <img src={`data:image/jpeg;base64,${link}`} style={{height: '100%'}} alt={'img'} />
          :
          <div style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
          <Loading
            description="Active loading indicator" withOverlay={false}
            small={false} withOverlay={false} />
          </div>
          
        }
      </Paper>
    );
  };
  return (
    <>
      <Carousel
        navButtonsAlwaysVisible={true}
        autoPlay={true}
        indicators={true}
        swipe={true}
        fullHeightHover={true}
        height={400}
      >
        {imageList && imageList.map((item, i) => (
          <Item
            key={i}
            path={item.media}

          /> //{...item}
        ))}
      </Carousel>
    </>
  )
}