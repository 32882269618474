import { makeStyles } from '@material-ui/core/styles'
import { pxToRem } from '../../../utils'
import { relative } from 'path/posix'

export const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: pxToRem(32)
  },
  input: {
    marginTop: pxToRem(16)
    // '& p': {
    //   textAlign: 'right'
    // }
  },
  checkbox: {
    '& > label': {
      justifyContent: 'center',
      textAlign: 'center',
      marginTop: pxToRem(16)
    },
    '& > p': {
      textAlign: 'center'
    }
  },
  passForgotLink: {
    marginTop: pxToRem(16),
    textAlign: 'right',
    color: '#1876D1',
    cursor: 'pointer'
  },
  helperWithReset: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  submitWrapper: {
    marginTop: pxToRem(42),
    textAlign: 'center',
  },
  submitWrapperResetPass: {
    display: 'flex',
    width: '73%',
    justifyContent: 'space-between',
    marginTop: 79,
    textAlign: 'center',
  },
  helpIcon: {
    fontSize: 14,
    marginLeft: 10
  },
  tooltipText: {
    width: '90%',
    zIndex: 1000,
    borderRadius: 4,
    fontSize: 18,
    padding: 10,
    fontFamily: 'Helvetica Neue',
    boxShadow: '0 0px 4px 0 rgba(0,0,0,.25)'
  },
  passRepairContainer: {
    position: 'relative'
  },
  codeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center,',
    alignItems: 'center'
  },
  customTooltip: {
    fontSize: '1rem',
    maxWidth: 240,
  }
}))
