import React, { useState, useEffect } from 'react';
import MainFooter from '../../components/MainFooter/MainFooter';
import MainHeader from '../../components/MainHeader/MainHeader';
import { makeStyles } from '@material-ui/core/styles';
import ImgChamp from '../../assets/img/social_link/ImgChamp.svg';
import chatApi from '../../api/chatApi';


const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: "center",
    minHeight: '100%'
  },
  wrapperInfo: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    height: '372px',
    marginTop: 48,
    marginBottom: 20,
    alignItems: 'center',
  },
  wrapperText: {
    backgroundColor: 'black',
    flexGrow: 3,
  },
  wrapperImg: {
    flexGrow: 2
  },
  title: {
    color: 'white',
    fontFamily: 'Helvetica Neue',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 32,
    margin: '24px 0 87px 86px',
  },
  text: {
    width: '90%',
    color: 'black',
    fontFamily: 'Helvetica Neue',
    fontWeight: 400,
    fontSize: 16,
    display: 'flex',
    flexWrap: 'wrap',
    margin: '10px 0 24px 0px',
    overflowY: 'auto',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  hidden: {
    display: 'none'
  },
  tetxAndImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 372,
    width: '100%'
  }
});


const AboutCompany = () => {
  const classes = useStyles();
  const [headerText, setHeaderText] = useState('');
  const [descriptionText, setdescriptionText] = useState('')
  const [titleText, setTitleText] = useState('');
  const [descriptionHide, setdescriptionHide] = useState('');
  const [keywords, setKeywords] = useState('')


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await chatApi.getDescription('aboutCompany');
      const headerText = response.result[0].h1;
      const descriptionText = response.result[0].descriptionText
      const titleText = response.result[0].title;
      const descriptionHide = response.result[0].descriptionHide
      const keywords = response.result[0].keywords

      setdescriptionText(descriptionText)
      setHeaderText(headerText);
      setTitleText(titleText)
      setdescriptionHide(descriptionHide)
      setKeywords(keywords)
    };
    fetchData();
  }, []);

  return (
    <>
      <MainHeader showComponentHelpFilled={false} />
      <head>
        <meta name="keywords" content={keywords} />
      </head>
      <body>
        <div className={classes.mainContainer}>
          <div className={classes.wrapperInfo}>
            <div className={classes.tetxAndImageContainer}>
              <div className={classes.wrapperText}>
                <h1 className={classes.title}>{headerText}</h1>
              </div>
              <div className={classes.wrapperImg}>
                <img className={classes.img} src={ImgChamp} alt='img' />
              </div>
            </div>

            <span className={classes.text}>{descriptionText}</span>
            <div className={classes.hidden}>{descriptionHide}</div>
          </div>
          <div style={{ flexShrink: 0, width: '100%' }}>
            <MainFooter />
          </div>
        </div>
      </body>

    </>
  )
}

export default AboutCompany;
