import axios from 'axios';
import TextField from '@material-ui/core/TextField'
import BtnEndIcon from '@material-ui/icons/KeyboardArrowRight'
import React, { FormEvent, FormEventHandler, useState } from 'react'
import chatApi from '../../../api/chatApi'
//@ts-ignore
import { ToastNotification } from '@carbon/react';
import { RoutePath } from '../../../types'
import { useStyles } from './_Styles'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AuthAction } from '../../../store/auth'
import ButtonWithProgress from '../../common/ButtonWithProgress'
import { useAsyncFn } from 'react-use'
import PasswordResetForm from './PasswordResetForm'
import { useTranslation } from 'react-i18next'

export default function LoginForm() {
  const {t} = useTranslation()

  const classes = useStyles()
  const hasError = false

  const [passField, setPassField] = useState<string>('')
  const [emailField, setEmailField] = useState<string>('')
  const [error, setError] = useState(false)
  const [passForgetModal, setPassForgetModal] = useState<boolean>(false)
  const dispatch = useDispatch()

  const history = useHistory()

  const [{ loading: isSending }, doLogin] = useAsyncFn(() => {
    setError(false)
    console.log('функция запустилась')
    return chatApi
      .authenticate({
        username: emailField.trim(),
        password: passField,
        rememberMe: false
      })
      .then(res => {
        console.log('запрос ушел')
        localStorage.setItem('role', res.data.id_token)
        dispatch({ type: AuthAction.LOGIN_SUCCESS, data: res.data })
        //history.replace(RoutePath.newChat)
        history.replace(RoutePath.testRoot)
        return
      })
      .catch(error => {
        setError(true)
      })
  }, [emailField, passField])

  const data = async() => {
    setError(false)
    try {
    const res = await axios.post('https://admin-lsn.ksma.ru/openid/authorize', {
      login: emailField.trim(), //Вводит пользователь
      client_id: "neurodoc", //Гвозди
      password: passField, //Вводит пользователь
      redirect_uri: "redir", //Гвозди
      state: "app-state'" //Гвозди
    })
    
    const data = await axios.post('https://admin-lsn.ksma.ru/openid/token', {
      client_id: "neurodoc", //Гвозди
      code: res.data.code, 
      client_secret:"Jehe9284KJwwcP", //Гвозди
      redirect_uri: res.data.redirect_uri, 
      state: res.data.state 
    })

    await chatApi.authenticateTypeNew({
      user_info: data.data.user_info,
      token: data.data.access_token
    })
    .then(res => {
      console.log(res.data.id_token)
      localStorage.setItem('role', res.data.id_token)
      dispatch({ type: AuthAction.LOGIN_SUCCESS, data: res.data })
      history.replace(RoutePath.testRoot)
      return
    })
  } catch (error) {
    setError(true)
    console.log(error)   
  } 
  }

  const onSubmitLogin: FormEventHandler = async (event: FormEvent) => {
    event.preventDefault()
    await doLogin()
    // await chatApi.getSettingsType()
    // .then(async(res) =>  {
    //   if(res.result[0].value === '0') {
    //     await doLogin()
    //   }
    //   else {
    //     await data()
    //   }
    // })
  }

  function openPassResetModal() {
    setPassForgetModal(!passForgetModal)
  }

  return (
    passForgetModal
      ? <PasswordResetForm openPassResetModal={openPassResetModal} />
      : <div className={classes.paper}>
        <form className={classes.form} onSubmit={onSubmitLogin}>
          {error &&
            <div style={{ position: 'fixed', right: 60, top: 60 }}>
              <ToastNotification
                kind="warning"
                hideCloseButton={false}
                lowContrast={false}
                title="Внимание"
                subtitle={
                  <div>{t("errLoginForm")}</div>
                }
                timeout={5000}
              />
            </div>
          }
            <TextField
              value={emailField}
              onChange={e => setEmailField(e.target.value)}
              disabled={isSending}
              error={hasError}
              type='text'
              label={t("loginForm")}
              name='text'
              className={classes.input}
              fullWidth
            />
          <TextField
            value={passField}
            onChange={e => setPassField(e.target.value)}
            error={hasError}
            disabled={isSending}
            type='password'
            label={t("passForm")}
            name='password'
            className={classes.input}
            helperText={hasError && <span>{t("errLoginForm")}</span>}
            fullWidth
          />

          {/* <Typography
            variant='caption'
            component='div'
            className={classes.passForgotLink}
            onClick={openPassResetModal}
          >Забыли пароль?
          </Typography> */}
          <div className={classes.submitWrapper}>
            <ButtonWithProgress
              variant='contained'
              color='primary'
              type='submit'
              loading={isSending}
              disabled={isSending}
              endIcon={<BtnEndIcon />}
              style={{ minWidth: 250 }}
            >
              {t("login")}
            </ButtonWithProgress>
          </div>
        </form>
      </div>
  )
}
