import { useSelector } from 'react-redux';
import { ProgressBarType } from '../types';
import { RootState } from '../store';

function numberOr0(n: any): number {
  return isNaN(n) ? 0 : n;
}

export function useTotalPointsProgress(): [ProgressBarType, ProgressBarType] {
  // очки за этап исследований
  const [researchPoints, researchPenalty] = useSelector<RootState, [number, number]>(state => {
    return (state.research.researchResults || []).reduce(
      ([accPoints, accPenalty], item) => {
        return [accPoints + item.points, accPenalty + item.penalty];
      },
      [0, 0]
    );
  });

  // очки за этап коммуникации и объективного статуса (маникен)
  const chatAndCheckPoints = useSelector<RootState, number>(state => {
    const chatTotalPoints = numberOr0(state.chat?.currentHistory?.value?.points);
    const checkTotalPoints = numberOr0(state.chat?.currentCheckHistory?.value?.points);

    const totalSuccessPoints = chatTotalPoints + checkTotalPoints;
    return totalSuccessPoints;
  });
  const chatAndCheckPenalty = useSelector<RootState, number>(state => {
    const chatTotalPenalty = numberOr0(state.chat?.currentHistory?.value?.penalty);
    const checkTotalPenalty = numberOr0(state.chat?.currentCheckHistory?.value?.penalty);
    const totalPenaltyPoints = chatTotalPenalty + checkTotalPenalty;
    return totalPenaltyPoints;
  });

  const totalPoints = chatAndCheckPoints + researchPoints;
  const totalPenalty = chatAndCheckPenalty + researchPenalty;

  return [
    {
      text: `${totalPoints}`,
      maxVal: 100,
      currentVal: totalPoints,
    },
    {
      text: `${totalPenalty}`,
      maxVal: 100,
      currentVal: totalPenalty,
    },
  ];
}
