import React, { useEffect, useState } from 'react';
import {
  allUsers,
  getOrganizationDescription,
  getScoreTeachers,
  getTeachersDescription, reviewCreate,
} from '../../../api/chatApi/chatApi';
import MainDescriptionTeacher from './components/mainDescriptionTeacher'
import chatApi from '../../../api/chatApi';
import { useHistory } from 'react-router-dom';

interface teachersListInterface {
  id: number
  fio: string
  description: string
}

interface teacherInterface {
  fio: string
  boss: string
  adress: string
  achievements: string
  img: string
  teachers_description: string
  teachers_organization_description_description: string
}

interface reviewListInterface {
  date_: string
  feedback: string
  id: number
  score: number
  student_name: string
  teachers_name: string
}

export default function TeacherProfilePage(): JSX.Element {

  useEffect(() => {

    let id = window.location.href.split('=')[1]
    setTeacherId(+id)
    setCreatedReview({ ...createdReview, teachers_id: +id, score: 0, feedback: '' })
  }, [])

  const [teacher, setTeacher] = useState<teacherInterface>()
  const [starsValue, setStarsValue] = useState<number>(0)
  const [scoreTeachers, setScoreTeachers] = useState<teachersListInterface[]>([])
  const [reviewList, setReviewList] = useState<reviewListInterface[]>([])
  const [teachersList, setTeachersList] = useState<teachersListInterface[]>([])
  const [reviewText, setReviewText] = useState('')
  const [createdReview, setCreatedReview] = useState<reviewCreate>()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [selectedTeacher, setSelectedTeacher] = useState()
  const [ratingInModalValue, setRatingInModalValue] = useState(0)
  const [allUsersArray, setAllUsersArray] = useState([])
  const [media, setMedia] = useState<any>(null)

  const [teacherId, setTeacherId] = useState(0)

  useEffect(() => {
    getScoreTeachersHandler()
    getTeachersDescriptionHandler()
    teacherReviewGetAllHandler(teacherId)
    getOrganizationDescriptionHandler()
    getAllUsersHandler()
  }, [teacherId])

  async function getScoreTeachersHandler() {
    try {
      const response = await getScoreTeachers({ organization_id: 2, teachers: teacherId })
      setScoreTeachers(response.data.score_teachers)
      setStarsValue(response.data.score)
      console.log('getScoreTeachersHandler', response);
    } catch (err) {
      console.log('при получении списка очков произошла ошибка');
    }
  }


  async function getTeachersDescriptionHandler() {
    try {
      const response = await getTeachersDescription({ teachers: teacherId })

      response.data.TeachersDescription.map((teacher: teacherInterface) => {
        setTeacher(teacher)
      })
      console.log('getTeachersDescriptionHandler', response);
    } catch (err) {
      console.log('при получении списка очков произошла ошибка');
    }
  }

  async function teacherReviewGetAllHandler(teacherId: number) {
    try {
      if (teacherId) {
        const response = await chatApi.teacherReviewGetAll({ teachers_id: teacherId })
        console.log('teacherReviewGetAllHandler', response);
        setReviewList(response.result)
      }
    } catch (err) {
      console.log('при получении списка очков произошла ошибка');
    }
  }

  async function getOrganizationDescriptionHandler() {
    try {
      const response = await getOrganizationDescription({ organization_id: 1 })
      setTeachersList(response.data.teachers)
      setMedia(response.data.media)
    } catch (err) {
      console.log('при получении списка произошла ошибка', err);
    }
  }

  async function createReviewHandler() {
    try {
      await chatApi.teacherReviewCreate(createdReview)
      setCreatedReview(undefined)
      setIsOpenModal(false)
    } catch (err) {
      console.log('при создании комментария произошла ошибка', err);
    } finally {
      teacherReviewGetAllHandler(teacherId)
    }
  }

  async function getAllUsersHandler() {
    try {
      const response = await allUsers()
      setAllUsersArray(response.data.all_users)
    } catch (err) {
      console.log('при получении списка пользователей произошла ошибка');
    }
  }

  function changeReviewTextHandler(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setReviewText(event.target.value)
    setCreatedReview({
      ...createdReview!,
      feedback: event.target.value
    })
  }

  function chooseTeacherHandler(event: any) {
    setSelectedTeacher(event)
    setCreatedReview({
      ...createdReview!,
      teachers_id: event.id
    })
  }

  function changeCreateRating(event: number) {
    console.log(event);
    setRatingInModalValue(event)
    setCreatedReview({
      ...createdReview!,
      score: event
    })
  }

  function openModalHandler() {
    setIsOpenModal(!isOpenModal)
  }

  async function deleteReviewHandler(id: number) {
    try {
      const response = await chatApi.deleteReviewTeacher(id)
    } catch (err) {
      console.log('при удалении произошла ошибка');
    } finally {
      teacherReviewGetAllHandler(teacherId)
    }
  }

  return (
    <>
      <MainDescriptionTeacher
        media={media}
        teacher={teacher}
        starsValue={starsValue}
        scoreTeachers={scoreTeachers}
        reviewList={reviewList}
        teachersList={teachersList}
        reviewText={reviewText}
        changeReviewTextHandler={changeReviewTextHandler}
        isOpenModal={isOpenModal}
        openModalHandler={openModalHandler}
        chooseTeacherHandler={chooseTeacherHandler}
        selectedTeacher={selectedTeacher}
        changeCreateRating={changeCreateRating}
        createReviewHandler={createReviewHandler}
        allUsersArray={allUsersArray}
        deleteReviewHandler={deleteReviewHandler}
      />
    </>
  )
}
