// import { TextField } from '@material-ui/core'
import React, { ReactElement } from 'react'
import Slot from '../../common/Slots/Slot'
import TextFieldsExample from './TextFieldsExample'
import TextFieldsEmptyExample from './TextFieldsEmptyExample'

export default function ComplicationDiagnosisSlot(): ReactElement {
  return (
    <Slot title='sОсложнение основного и конкурирующего' color='primary'>
      <TextFieldsExample />
      <TextFieldsEmptyExample />
    </Slot>
  )
}
