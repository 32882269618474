import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ComboBox from '@carbon/react/lib/components/ComboBox';
import Prize from "./Prize";
import TableRating from './TableRating';


const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: 'flex',
    },
    title: {
      minWidth: '300px',
      display: 'flex',
      fontSize: 20,
      fontWeight: 500,
      justifyContent: 'center',
      margin: '10px 0',
    },
    namePointsBlock: {
      padding: 20,
      boxShadow: '0px 0px 2px rgba(9, 36, 51, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.12)',
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginBottom: 50
    },
    name: {
      fontFamily: 'Helvetica Neue',
      fontWeight: 400,
      fontSize: 20,
      color: '#2196F3',
    },
    current_title: {
      fontFamily: 'Helvetica Neue',
      fontWeight: 400,
      fontSize: 16,
      color: '#9A9A9A',
      marginBottom: 5,
      '@media (max-width: 1090px)': {
        fontSize: '13px'
      }
    },
    lorem: {
      fontFamily: 'Helvetica Neue',
      fontWeight: 400,
      fontSize: 16,
      color: '#000000',
      display: 'flex',
      justifyContent: 'center'
    },
    mainContainer: {
      width: '100%',
      // height: '90vh',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '@media (max-width: 1228px)': {
        display: 'flex',
        flexDirection: 'column',
      }
    },
    leftContainer: {
      minWidth: '30%',
      marginRight: 20,
      '@media (max-width: 1228px)': {
        marginRight: 0,
      }
    },
    tableRatingContainer: {
      flexGrow: 1
    },
    pointsBlock: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '10px 10px 0 10px'
    },
    rightContainer: {
      width: '68%',
      marginRight: 20,
      '@media (max-width: 1228px)': {
        width: '100%',
      }
    }
  })
);

interface Current {
  id: number,
  position: number,
  raiting: string,
  raiting_change: number,
  user_name: string,
}

interface User {
  jhi_user_id: number
}

type Props = {
  checkRole: boolean
  allUser: [] | null
  isEmpty: (arg: string) => void | boolean
  changeUserHandler: (arg: string) => void
  isStressLoad: boolean
  user: User
  current: Current
  org: number
  trn: number
  option: number

}

export default function Raiting({
  checkRole,
  allUser,
  isEmpty,
  changeUserHandler,
  current,
  org,
  trn,
  option,
  user
}: Props) {

  const classes = useRowStyles();

  return (
    <div className={classes.mainContainer}>
      <div className={classes.leftContainer}>
        <div className={classes.title}>
          Рейтинг чемпионатов
        </div>
        <div className={classes.namePointsBlock}>
          <div className={classes.name}>
            {checkRole &&
              <div>
                <ComboBox
                  placeholder={'Выберите пользователя'}
                  items={allUser ? allUser : []}
                  itemToString={(item) => (isEmpty(item.fio) ? item.login : item?.fio)}
                  onChange={({ selectedItem }) => changeUserHandler(selectedItem)}
                  selectedItem={user[0]}
                />
              </div>}
          </div>
          <div className={classes.pointsBlock}>
            <div>
              <div className={classes.current_title}>Рейтинг</div>
              <div className={classes.lorem}>{current.position} место</div>
            </div>
            <div>
              <div className={classes.current_title}>За сутки</div>
              {current.raiting_change ?
                <div className={classes.lorem} style={current.raiting_change > 0 ? { color: '#23C808' } : { color: 'red' }}>{current.raiting_change > 0 ? `+ ${current.raiting_change} баллов` : `- ${current.raiting_change} баллов`}  </div>
                :
                <div className={classes.lorem}>0</div>
              }
            </div>
            <div>
              <div className={classes.current_title}>Баллы</div>
              <div className={classes.lorem}>{current.raiting ? current.raiting : '0'}</div>
            </div>
          </div>
        </div>
        <div>
          {user[0] &&
            <Prize id={user[0].jhi_user_id} />
          }
        </div>
      </div>
      <div className={classes.rightContainer}>
        {user[0] &&
          <TableRating org={Number(org)} trn={Number(trn)} option={option} id={user[0].jhi_user_id} />
        }
      </div>
    </div>
  )
}
