import { Hidden } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'
import Masonry from 'react-masonry-css'

// const labResearchItems = ['Кровь', 'Моча', 'Кал', 'Слюна', 'Эпителий']
// const InstrumentalResearchItems = ['Кардиограмма', 'МРТ', 'Рентген', 'УЗИ']

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      width: 'auto',
      padding: theme.spacing(1),
      minHeight: '70%',
      marginTop: '15px'
    },
    item: {
      padding: theme.spacing(1),
      backgroundClip: 'padding-box',
      '& > div': {
        marginBottom: theme.spacing(2)
      }
    },
    paper: { color: 'red' }
  })
)

interface Props {
  children: any
}

export default function SlotContainerMasonry({ children }: Props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      {/* DESCTOP BIGGET THAN SM-BREAKPOINT */}
      <Hidden smDown>
        <Masonry
          breakpointCols={2}
          className={classes.root}
          columnClassName={classes.item}
        >
          {children}
        </Masonry>
      </Hidden>
      {/* MOBILE AND SM-BREAKPOINT */}
      <Hidden mdUp>
        <Masonry
          breakpointCols={1}
          className={classes.root}
          columnClassName={classes.item}
        >
          {children}
        </Masonry>
      </Hidden>
    </React.Fragment>
  )
}
