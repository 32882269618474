import React, { useState, useEffect } from 'react'
import chatApi from '../../../api/chatApi'
//@ts-ignore
import { RoutePath } from '../../../types'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AuthAction } from '../../../store/auth'
import CryptoJS from 'crypto-js';
import { Loading } from '@carbon/react';


export default function LoginFormMed() {

  const [error, setError] = useState(false)
  const [loader, setLoader] = useState(true)


  const dispatch = useDispatch()

  const history = useHistory()


  const data = async() => {
    setError(false)
    const dataMed = history.location.pathname.split(':')[1]
    const bytes = CryptoJS.AES.decrypt(dataMed, 'doc');
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    const logPass = decryptedText.split(' ')
    try {
    await chatApi.authenticateTypeNew({
      user_info: {
        id: "5e71e430d46bbc7d3880bf72",
        guid: "kubid_29757",
        first_name: logPass[1],
        last_name: logPass[0],
        middle_name: logPass[2],
        email: logPass[3],
        stud: {
            kub_id: "29757",
            edu_num: "19120612"
        },
      },
      token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsc25faWQiOiI1ZTcxZTQzMGQ0NmJiYzdkMzg4MGJmNzIiLCJzdWIiOiJzdHVkOjVlNzFlNDMwZDQ2YmJjN2QzODgwYmY3MiIsImxzbl90eXBlIjoibHNudXNlciIsImlzcyI6ImxzbiIsImV4cCI6MTcxNDY5NTc4MCwibGFuZyI6InJ1IiwiaWF0IjoxNzE0NjU5NzgwfQ.kIjeu-aPIbQCcXGgQn4hKhT1xXo7IN3NViO3I-PDf8I'
    })
    .then(res => {
      localStorage.setItem('role', res.data.id_token)
      dispatch({ type: AuthAction.LOGIN_SUCCESS, data: res.data })
      history.replace(RoutePath.testRoot)
      return
    })
  } catch (error) {
    setError(true)
    console.log(error)   
  } finally {
    setLoader(false)
  }
  }

  useEffect(() => {
    data()
  }, []);

  function Loader() {
    if (loader) {
      return (
        <Loading className={'some-class'} withOverlay={false} />
      )
    } else return null
  }


  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div>
          <Loader />
        </div >
        {error && 
          <div style={{fontSize: '25px', fontWeight: '500'}}>Произошла ошибка <br></br>Обратитесь в техническую поддержку</div>
        }
    </div>
  )
}
