import { useLocation } from 'react-router-dom';
import TextField from '@material-ui/core/TextField'
import BtnEndIcon from '@material-ui/icons/KeyboardArrowRight'
import BtnStartIcon from '@material-ui/icons/KeyboardArrowLeft'
import React, { FormEvent, FormEventHandler, useState, useEffect } from 'react'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useStyles } from './_Styles'
import chatApi from '../../../api/chatApi'
import { useHistory } from 'react-router'
import ButtonWithProgress from '../../common/ButtonWithProgress'
import { Tooltip } from '@material-ui/core';

interface PasswordResetFormInterface {
  openPassResetModal: () => void
}



export default function PasswordResetForm({ openPassResetModal }: PasswordResetFormInterface) {
  const classes = useStyles()
  const history = useHistory()
  const [email, setEmail] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [openCodeModal, setOpenCodeModal] = useState(false)
  const [newPassModal, setNewPassModal] = useState(false)
  const [recoveryCode, setRecoveryCode] = useState<string>('')
  const [newEmail, setNewEmail] = useState<string>('')
  const [hasError, setHasError] = useState<boolean>(false)
  const location = useLocation();

  const getKeyFromLocation = (location) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('key');
  };


  useEffect(() => {
    const key = getKeyFromLocation(location);
    if (key) {
      setNewPassModal(true);
    }
  }, [location]);

  const submit = async () => {
    setLoading(true)
    setOpenCodeModal(true)
    return chatApi
      .resetPasswordByEmail(email)
      .then(res => {
        console.log('res', res);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  }

  const setNewPassword = async () => {
    setLoading(true);
    const key = getKeyFromLocation(location);
    if (key) {
      try {
        const response = await chatApi.resetPasswordFinish(key, newEmail);
        console.log(response);
        history.replace('/login');
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };


  const isValidEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }


  return (
    <div className={classes.passRepairContainer}>
      <div className={classes.paper}>
        <div className={classes.form}>
          {openCodeModal ?
            <div className={classes.codeContainer}>
              <div className={classes.tooltipText}>
                Если аккаунт с таким адресом электронной почты существует в нашей системе, мы отправили на него инструкции по восстановлению пароля
              </div>
              <ButtonWithProgress
                startIcon={<BtnStartIcon />}
                variant='contained'
                color='primary'
                type='submit'
                onClick={openPassResetModal}
                loading={loading}
                disabled={loading}
                style={{ width: 100, marginTop: 90 }}
              >
                Назад
              </ButtonWithProgress>
            </div>
            : newPassModal ?
              <div>
                <div>Введите новый пароль</div>
                <TextField
                  type='email'
                  label='Введите пароль' />
                <TextField
                  value={newEmail}
                  type='email'
                  label='Повторите пароль' />
                <ButtonWithProgress
                  variant='contained'
                  color='primary'
                  type='submit'
                  onClick={setNewPassword}
                  loading={loading}
                  disabled={loading}
                  endIcon={<BtnEndIcon />}
                >
                  СОХРАНИТЬ НОВЫЙ ПАРОЛЬ
                </ButtonWithProgress>
              </div>
              : <div style={{ marginTop: 79 }}>
                <TextField
                  value={email}
                  disabled={loading}
                  onChange={e => {
                    setEmail(e.target.value);
                    setHasError(!isValidEmail(e.target.value));
                  }}
                  type='email'
                  label='Введите почту'
                  fullWidth />
                <div className={classes.submitWrapperResetPass}>
                  <ButtonWithProgress
                    variant='contained'
                    color='primary'
                    type='submit'
                    onClick={openPassResetModal}
                    startIcon={<BtnStartIcon />}
                  >
                    Назад
                  </ButtonWithProgress>
                  <Tooltip
                    classes={{ tooltip: classes.customTooltip }}
                    title={!isValidEmail(email) && !loading ? "Введите почту в формате example@email.ru" : ""}>
                    <span>
                      <ButtonWithProgress
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={submit}
                        loading={loading}
                        disabled={loading || !isValidEmail(email)}
                        endIcon={<BtnEndIcon />}
                      >
                        ВОССТАНОВИТЬ ПАРОЛЬ
                      </ButtonWithProgress>
                    </span>
                  </Tooltip>
                </div>
              </div>}
        </div>
      </div>
    </div>
  )
}
