import React from 'react'
// import { useHistory } from 'react-router-dom';
import useCurrentTask from '../hooks/useCurrentTask'
import NewChatPage from '../pages/startChatWizard/NewChatPage';
// import { RoutePath } from '../types';

const HaveCurrentTaskGuard = (props: any) => {
  // const history = useHistory();
  const currentTaskState = useCurrentTask()
  const Component = props.component;
  
  return currentTaskState?.value ? <Component /> : <NewChatPage />;
};

export default HaveCurrentTaskGuard