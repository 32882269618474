import { Typography } from '@material-ui/core'
import React, { ReactElement } from 'react'
import Slot from '../../common/Slots/Slot'

export default function DiagnosisNecessaryResearch(): ReactElement {
  return (
    <Slot title='Необходимые исследования' color='info'>
      <Typography variant='h5'>МРТ поджелудочной железы</Typography>
      <Typography variant='h5'>Анализ крови</Typography>
    </Slot>
  )
}
