import React, { Suspense, useRef, useEffect, useState, useMemo } from 'react'
import { Canvas } from '@react-three/fiber'
import { useLocation } from 'react-router-dom';
import { AccumulativeShadows, OrbitControls, PerspectiveCamera, Stage, useProgress } from '@react-three/drei'
import { Physics } from "@react-three/cannon";
import { getTestingstageTaskOnTask } from '../../src/api/chatApi/chatApi'
import Woman1 from "./models/Woman";
// import Pacient from "./models/Pacient";
import Pregnant from "./models/Pregnant_A";
import Aim from "./models/Blend_anim";
import Man1 from './models/Young_male_animation';
import PregnantOld from './models/PregnantBAnim';
import ManOld from './models/PACIENT_B';
import ManTif from './models/Pacient_TIF_ANIM';
// import { BoyAnimKrivoi } from './models/Boy_5year_ANIM_KRASNUHA';
import { Boy5 } from './models/Boy_5year_ANIMATION1';
import { Boy11 } from './models/BOY_11_years_EXPORT';
import { Girl5Months } from './models/Girl_5_monthsAnim';
// import { Katya } from './models/Katya_scene';
import { MiddleWoman } from './models/Middle_age_woman';
import { Sasha } from './models/SASHA_1_RIG';
import { Women16 } from './models/Wman16_22042021_anim';
import { Girl14 } from './models/Girl_22022022';
import { ProgressBar, Modal } from '@carbon/react';
import CustomButton from '../components/common/CustomButton';
import useIncreaseTaskStage from '../hooks/testingTask/useIncreaseTaskStage';
import { RoutePath } from '../types';
import chatApi from '../api/chatApi';
import RatingDialog from '../components/Chat/RatingDialog';
import useTestingStage from '../hooks/testingTask/useTestingStage';
import { Button } from '@material-ui/core';
import './index.css'
import NeuroManH from './models/Neurologia_man_hypersthenic';
import { usePointProcentStore } from '../../src/pages/chat/store'
import NeuroWomH from './models/NeuroWomH';
import ManInsult from './models/ManInsult';
import NeuroManNTrue from './models/Neurologia_man_normostenik2';
import NeuroWomNTrue from './models/Neurologia_wom_normostenik';
import WomanJaundice from './models/Woman_jaundice';
import WomInsult from './models/WomInsult';
import WomanOld from './models/WomanOld';
import WomanAtrophy from './models/Middle_age_woman_atrophy';
import WomanMastectomy from './models/Woman_mastectomy';
import BaldMan from './models/Blend_20052024';
import { useTranslation } from 'react-i18next'


const Model = ({ type }) => {
  const ref = useRef()
  const getPointProcent = usePointProcentStore(state => state.getPoints)
  const {t} = useTranslation()
  ///////////////// Переход на следующий этап

  const [status, setStatus] = useState(false)
  const [openRating, setOpenRating] = React.useState(false)
  const [anim, setAnim] = useState([])
  const [indexAnim, setIndexAnim] = useState(0)
  const [stages, setStages] = useState([]);
  const { routhPathCode, activeStage } = useNextStagePath(stages);
  const [increaseLoading, increase, setAsyncTaskStage] = useIncreaseTaskStage(RoutePath.chat, 0)
  const [increaseLoadingOwnStage, increaseOwnStage, setAsyncTaskOwnStage] = useIncreaseTaskStage(RoutePath[routhPathCode], activeStage)

  // Функция для получения этапов
  async function fetchAllStages() {
    try {
      const result = await getTestingstageTaskOnTask();
      setStages(result.data.testingstage_task_on_task);
    } catch (error) {
      console.error('Ошибка при загрузке этапов:', error);
    }
  }

  useEffect(() => {
    fetchAllStages();
  }, []);

  function useNextStagePath(stages) {
    const location = useLocation();
    const currentPath = location.pathname;

    return useMemo(() => {
      if (!stages || stages.length === 0) {
        return { routePath: '', routhPathCode: '' };
      }
      const sortedStages = stages.sort((a, b) => a.activeStage - b.activeStage);
      const currentIndex = sortedStages.findIndex(stage => stage.routePath === currentPath);

      if (currentIndex !== -1 && currentIndex + 1 < sortedStages.length) {
        return {
          routePath: sortedStages[currentIndex + 1].routePath,
          routhPathCode: sortedStages[currentIndex + 1].routhPathCode,
          activeStage: sortedStages[currentIndex + 1].activeStage
        };
      }

      return { routePath: '', routhPathCode: '' };
    }, [stages, currentPath]);
  }

  useEffect(() => {
    const getDialogStatus = async () => {
      const response = await chatApi.getSettingsPiloting();
      const statusValue = response.result[0].value;

      setStatus(statusValue === 'true');
    };

    getDialogStatus();
    setAsyncTaskStage()
  }, []);

  function showRatingDialog() {
    setIndexAnim(0)
    if (status) {
      setOpenRating(true)
    } else {
      if (routhPathCode) {
        increaseOwnStage()
      } else {
        increase()
      }
      // setAsyncTaskStage()
    }
  }

  function hideRatingDialog() {
    increase()
    // setAsyncTaskStage()
  }
  ////////////////

  const [openMenu, setOpenMenu] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [test, setTest] = useState(0)
  const [refRom1, setRom1] = useState([])
  const [refRom2, setRom2] = useState([])
  const [refs, setRefs] = useState([])
  const [mim, setMim] = useState([])
  const [cor, setCor] = useState([])
  const [refLas, setLas] = useState([])

  function Loader() {
    const { progress } = useProgress()

    return (
      <div style={{ position: 'absolute', top: 280, width: '100%', padding: 100 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ color: '#fff', fontSize: 20 }}>{t("trenModelLoad")}</div> 
          <div style={{ color: '#fff', fontSize: 20 }}>{Math.round(progress)} %</div>
        </div>

        <ProgressBar
          value={progress}
          size='big' status='active' />

      </div>
    )
  }
  const testingStage = useTestingStage()

  useEffect(() => {
    if (anim.length > 0) {
      if (type !== 'ManInsult' && type !== 'WomInsult') {
        const data = anim.findIndex(el => el.name === 'stand')
        setIndexAnim(data)
      }


      if (type === 'ManInsult' || type === 'WomInsult') {
        const data = anim.findIndex(el => el.name === 'lying')
        setIndexAnim(data)
      }
    }


    const rom1 = anim.filter(el => {
      if (el.name) {
        return el.name.includes("1_R")
      }
    })
    const rom2 = anim.filter(el => {
      if (el.name) {
        return el.name.includes("2_R")
      }
    })
    const ref = anim.filter(el => {
      if (el.name) {
        return el.name.includes("3_")
      }
    })
    const mim = anim.filter(el => {
      if (el.name) {
        return el.name.includes("4_")
      }
    })
    const las = anim.filter(el => {
      if (el.name) {
        return el.name.includes("5_")
      }
    })
    const cor = anim.filter(el => {
      if (el.name) {
        return el.name.includes("6_")
      }
    })
    setRom1(rom1)
    setRom2(rom2)
    setRefs(ref)
    setMim(mim)
    setCor(cor)
    setLas(las)
  }, [anim]);

  useEffect(() => {
    getPointProcent()
    // setAsyncTaskStage()
  }, [])

  const typeAnim = (el) => {
    switch (el) {
      //РОМ1
      case '1_Romberg_test1L':
        return t("trenRef1");
      case '1_Romberg_test1R':
        return t("trenRef2");
      case '1_Romberg_test2L':
        return t("trenRef3");
      case '1_Romberg_test2R':
        return t("trenRef4");
      //РОМ2
      case '2_Romberg_test1L':
        return t("trenRef5");
      case '2_Romberg_test1R':
        return t("trenRef6");
      //Глубокие
      case '3_ahillesL':
        return t("trenRef7");
      case '3_ahillesR':
        return t("trenRef8");
      case '3_reflex1L':
        return t("trenRef9");
      case '3_reflex1R':
        return t("trenRef10");
      case '3_bicepsL':
        return t("trenRef11");
      case '3_bicepsR':
        return t("trenRef12");
      case '3_reflex2L':
        return t("trenRef13");
      case '3_reflex2R':
        return t("trenRef14");
      //Мимика
      case '4_moveEyes_1':
        return t("trenRef15");
      case '4_moveEyes_2':
        return t("trenRef16");
      case '4_face1':
        return t("trenRef17");
      case '4_face2':
        return t("trenRef18");
      case '4_face3':
        return t("trenRef19");
      case '4_meningeal':
        return t("trenRef20");
      //Ласега
      case '5_lasegaL':
        return t("trenRef21");
      case '5_lasegaR':
        return t("trenRef22");
      //Коорд
      case '6_coordinationL':
        return t("trenRef23");
      case '6_coordinationR':
        return t("trenRef24");
      default:
        return t("trenRef25");
    }
  }

  const getAnim = (data, type) => {
    if (type === 'rom1') {
      const index = anim.findIndex(el => el.name === data)
      setIndexAnim(index)
      const reset = anim.findIndex(el => el.name === 'Romberg1_pose')
      const timerId = setTimeout(() => {
        setIndexAnim(reset)
        clearTimeout(timerId)
      }, 5000)
    }

    else if (type === 'rom2') {
      const index = anim.findIndex(el => el.name === data)
      setIndexAnim(index)
      const reset = anim.findIndex(el => el.name === 'Romberg2_pose')
      const timerId = setTimeout(() => {
        setIndexAnim(reset)
        clearTimeout(timerId)
      }, 5000)

    }

    else if (type === 'refs') {
      const index = anim.findIndex(el => el.name === data)
      setIndexAnim(index)

      const reset = anim.findIndex(el => el.name === 'sit_idle')
      const timerId = setTimeout(() => {
        setIndexAnim(reset)
        clearTimeout(timerId)
      }, 5000)
    }

    else if (type === 'mim') {
      const index = anim.findIndex(el => el.name === data)
      setIndexAnim(index)
      const reset = anim.findIndex(el => el.name === 'sit_idle')
      const timerId = setTimeout(() => {
        setIndexAnim(reset)
        clearTimeout(timerId)
      }, 5000)
    }

    else if (type === 'cor') {
      const index = anim.findIndex(el => el.name === data)
      setIndexAnim(index)
      const reset = anim.findIndex(el => el.name === 'lying')
      const timerId = setTimeout(() => {
        setIndexAnim(reset)
        clearTimeout(timerId)
      }, 5000)
    }

    else if (type === 'las') {
      const index = anim.findIndex(el => el.name === data)
      setIndexAnim(index)
      const reset = anim.findIndex(el => el.name === 'lying')
      const timerId = setTimeout(() => {
        setIndexAnim(reset)
        clearTimeout(timerId)
      }, 5000)
    }

  }

  const resetAnim = (data) => {
    setTest(0)

    //Простые
    if (data === 'rom1') {
      const data = anim.findIndex(el => el.name === 'Romberg1_output')
      setIndexAnim(data)
      const reset = anim.findIndex(el => el.name === 'stand')
      const resetIns = anim.findIndex(el => el.name === 'lying')
      const timerId = setTimeout(() => {
        setIndexAnim(type !== 'WomInsult' && type !== 'ManInsult' ? reset : resetIns)
        clearTimeout(timerId)
      }, 5000)
    }

    else if (data === 'rom2') {
      const data = anim.findIndex(el => el.name === 'Romberg2_output')
      setIndexAnim(data)
      const reset = anim.findIndex(el => el.name === 'stand')
      const resetIns = anim.findIndex(el => el.name === 'lying')
      const timerId = setTimeout(() => {
        setIndexAnim(type !== 'WomInsult' && type !== 'ManInsult' ? reset : resetIns)
        clearTimeout(timerId)
      }, 5000)
    }


    else if (data === 'refs' || data === 'mim') {
      const data = anim.findIndex(el => el.name === 'stand')
      setIndexAnim(data)
      const reset = anim.findIndex(el => el.name === 'stand')
      const resetIns = anim.findIndex(el => el.name === 'lying')
      const timerId = setTimeout(() => {
        setIndexAnim(type !== 'WomInsult' && type !== 'ManInsult' ? reset : resetIns)
        clearTimeout(timerId)
      }, 5000)
    }

    //Инсульт
    else if (type !== 'WomInsult' && type !== 'ManInsult' && data === 'cor') {
      const data = anim.findIndex(el => el.name === 'lying_standUP')
      setIndexAnim(data)
      const reset = anim.findIndex(el => el.name === 'stand')
      const timerId = setTimeout(() => {
        setIndexAnim(reset)
        clearTimeout(timerId)
      }, 5000)
    }

    else if (type !== 'WomInsult' && type !== 'ManInsult' && data === 'las') {
      const data = anim.findIndex(el => el.name === 'lying_standUP')
      setIndexAnim(data)
      const reset = anim.findIndex(el => el.name === 'stand')
      const timerId = setTimeout(() => {
        setIndexAnim(reset)
        clearTimeout(timerId)
      }, 5000)
    }



    else if (type === 'WomInsult' && type === 'ManInsult' && data === 'las') {
      const data = anim.findIndex(el => el.name === 'lying')
      setIndexAnim(data)
    }

    else if (type === 'WomInsult' && type === 'ManInsult' && data === 'cor') {
      const data = anim.findIndex(el => el.name === 'lying')
      setIndexAnim(data)
    }
  }




  const goRom1 = () => {
    if (type !== 'WomInsult' && type !== 'ManInsult') {
      const data = anim.findIndex(el => el.name === 'Romberg1_input')
      setIndexAnim(data)
    }
    else {
      const data = anim.findIndex(el => el.name === 'lying')
      setIndexAnim(data)
    }
    setTest(1)
  }

  const goRom2 = () => {
    if (type !== 'WomInsult' && type !== 'ManInsult') {
      const data = anim.findIndex(el => el.name === 'Romberg2_input')
      setIndexAnim(data)
    }
    else {
      const data = anim.findIndex(el => el.name === 'lying')
      setIndexAnim(data)
    }
    setTest(2)
  }

  const goRefs = () => {
    if (type !== 'WomInsult' && type !== 'ManInsult') {
      const data = anim.findIndex(el => el.name === 'sit_idle')
      setIndexAnim(data)
    }
    else {
      const data = anim.findIndex(el => el.name === 'lying')
      setIndexAnim(data)
    }
    setTest(3)
  }

  const goMim = () => {
    if (type !== 'WomInsult' && type !== 'ManInsult') {
      const data = anim.findIndex(el => el.name === 'sit_idle')
      setIndexAnim(data)
    }
    else {
      const data = anim.findIndex(el => el.name === 'lying')
      setIndexAnim(data)
    }
    setTest(4)
  }

  const goCor = () => {
    if (type !== 'WomInsult' && type !== 'ManInsult') {
      const data = anim.findIndex(el => el.name === 'stand_lie down')
      setIndexAnim(data)
      const reset = anim.findIndex(el => el.name === 'lying')
      const timerId = setTimeout(() => {
        setIndexAnim(reset)
        clearTimeout(timerId)
      }, 5000)
    }
    else {
      const data = anim.findIndex(el => el.name === 'lying')
      setIndexAnim(data)
    }
    setTest(5)
  }

  const goLas = () => {
    if (type !== 'WomInsult' && type !== 'ManInsult') {
      const data = anim.findIndex(el => el.name === 'stand_lie down')
      setIndexAnim(data)
      const reset = anim.findIndex(el => el.name === 'lying')
      const timerId = setTimeout(() => {
        setIndexAnim(reset)
        clearTimeout(timerId)
      }, 5000)
    }
    else {
      const data = anim.findIndex(el => el.name === 'lying')
      setIndexAnim(data)
    }
    setTest(6)
  }

  return (
    <div style={{ height: '100vh', background: '#33334C', borderRadius: 4 }}>
      {openRating ? (
        <RatingDialog open={openRating} onSubmit={hideRatingDialog} />
      ) : null}
      <div>
        {openMenu && anim.length > 1 &&
          <div>
            <div className='flex'>
              {test === 0 &&
                <div className='flex1'>
                  {
                    refRom1.length > 0 &&
                    <div className='item1' onClick={goRom1}>{t("trenModelReflexes1")}</div> 
                  }
                  {
                    refRom2.length > 0 &&
                    <div className='item1' onClick={goRom2}>{t("trenModelReflexes2")}</div>
                  }
                  {
                    refs.length > 0 &&
                    <div className='item1' onClick={goRefs}>{t("trenModelReflexes3")}</div>
                  }
                  {
                    mim.length > 0 &&
                    <div className='item1' onClick={goMim}>{t("trenModelReflexes4")}</div>
                  }
                  {
                    cor.length > 0 &&
                    <div className='item1' onClick={goCor}>{t("trenModelReflexes5")}</div>
                  }
                  {/* {
                refLas.length > 0 &&
                <div className='item1' onClick={goLas}>Тесты Ласега</div>
              } */}

                </div>
              }
              {
                test === 1 &&
                <>
                  {
                    refRom1.map((el) => {
                      return (
                        <div className='item' onClick={() => getAnim(el.name, 'rom1')}>{typeAnim(el.name)}</div>
                      )
                    })}
                  <div className='item' onClick={() => resetAnim('rom1')}>{t("trenModelBack")}</div>
                </>
              }

              {
                test === 2 &&
                <>
                  {
                    refRom2.map((el) => {
                      return (
                        <div className='item' onClick={() => getAnim(el.name, 'rom2')}>{typeAnim(el.name)}</div>
                      )
                    })}
                  <div className='item' onClick={() => resetAnim('rom2')}>{t("trenModelBack")}</div>
                </>
              }

              {
                test === 3 &&
                <>
                  {
                    refs.map((el) => {
                      return (
                        <div className='item' onClick={() => getAnim(el.name, 'refs')}>{typeAnim(el.name)}</div>
                      )
                    })}
                  <div className='item' onClick={() => resetAnim('refs')}>{t("trenModelBack")}</div>
                </>
              }

              {
                test === 4 &&
                <>
                  {
                    mim.map((el) => {
                      return (
                        <div className='item' onClick={() => getAnim(el.name, 'mim')}>{typeAnim(el.name)}</div>
                      )
                    })}
                  <div className='item' onClick={() => resetAnim('mim')}>{t("trenModelBack")}</div>
                </>
              }

              {
                test === 5 &&
                <>
                  {
                    cor.map((el) => {
                      return (
                        <div className='item' onClick={() => getAnim(el.name, 'cor')}>{typeAnim(el.name)}</div>
                      )
                    })}
                  <div className='item' onClick={() => resetAnim('cor')}>{t("trenModelBack")}</div>
                </>
              }

              {
                test === 6 &&
                <>
                  {
                    refLas.map((el) => {
                      return (
                        <div className='item' onClick={() => getAnim(el.name, 'las')}>{typeAnim(el.name)}</div>
                      )
                    })}
                  <div className='item' onClick={() => resetAnim('las')}>{t("trenModelBack")}</div> 
                </>
              }

            </div>
          </div>
        }
        <div style={{ position: 'absolute', top: 30, left: 30, width: '100%', zIndex: 2000 }}>

        </div>
        {/* <div style={{ position: 'absolute', left: '20px', top: '30px', width: '100%', zIndex: 2000, display: 'flex' }}> */}
        <div style={{ position: 'absolute', bottom: 20, width: '100%', zIndex: 2000, display: 'flex', justifyContent: 'center' }}>
          <div style={{ display: 'flex' }}>
            {anim.length > 1 && type !== 'WomanOld' &&
              <Button aria-controls="simple-menu" aria-haspopup="true" style={{ color: 'white', background: '#1976d2', marginRight: 5 }} onClick={() => setOpenMenu(prev => !prev)}>
                {t("trenModelReflexes")}  
              </Button>
            }
            {anim.length > 1 && type !== 'WomanOld' &&
              <Button style={{ color: 'white', background: '#1976d2', marginRight: 5 }} type="button" onClick={() => setOpenModal(true)}>
                {t("trenModelHelp")}
              </Button>
            }
            <Modal
              modalHeading={t("trenModelModal")}
              passiveModal
              onRequestClose={() => setOpenModal(false)}
              open={openModal}
              hasScrollingContent
            >
              <p> {t("trenModelInstructions")}
              </p>
            </Modal>

            <CustomButton
              disabled={testingStage < 2 ? false : true}
              loading={increaseLoading}
              onClick={showRatingDialog} >
              {t("trenModelNext")}
            </CustomButton>
          </div>

        </div>
      </div>


      <Suspense fallback={<Loader />}>
        <Canvas>

          <Stage controls={ref} preset="rembrandt" environment="city">
            <Physics>
              {type === 'Girl14' &&
                <Girl14 setAnim={setAnim} />
              }

              {type === 'WomanEndomrph' &&
                <Woman1 setAnim={setAnim} />
              }

              {type === 'Pregnant' &&
                <Pregnant setAnim={setAnim} />
              }

              {type === 'Man' &&
                <Aim setAnim={setAnim} />
              }

              {type === 'Woman' &&
                <Women16 setAnim={setAnim} />
              }

              {type === 'ManYoung' &&
                <Man1 setAnim={setAnim} />
              }

              {type === 'PregnantOld' &&
                <PregnantOld setAnim={setAnim} />
              }

              {type === 'ManOld' &&
                <ManOld setAnim={setAnim} />
              }

              {
                type === 'BaldMan' &&
                <BaldMan setAnim={setAnim} />
              }

              {
                type === 'WomanOld' &&
                <WomanOld setAnim={setAnim} index={indexAnim} />
              }

              {
                type === 'ManTif' &&
                <ManTif setAnim={setAnim} />
              }

              {
                type === 'BoyKras' &&
                <Boy5 setAnim={setAnim} />
              }

              {
                type === 'WomanAtrophy' &&
                <WomanAtrophy setAnim={setAnim} />
              }

              {
                type === 'Boy11' &&
                <Boy11 setAnim={setAnim} />
              }

              {
                type === 'ManInsult' &&
                <ManInsult setAnim={setAnim} index={indexAnim} />
              }

              {
                type === 'WomInsult' &&
                <WomInsult setAnim={setAnim} index={indexAnim} />
              }

              {/* {type === 'NeuroManH' &&
                <NeuroManH setAnim={setAnim} index={indexAnim} />
              } */}

              {
                type === 'Jaundice' &&
                <WomanJaundice setAnim={setAnim} />
              }

              {
                type === 'WomanMastectomy' &&
                <WomanMastectomy setAnim={setAnim} />
              }

              {
                type === 'NeuroManN' &&
                <NeuroManNTrue setAnim={setAnim} index={indexAnim} />
              }

              {/* {type === 'NeuroWomH' &&
                <NeuroWomH setAnim={setAnim} index={indexAnim} />
              } */}

              {
                type === 'NeuroWomN' &&
                <NeuroWomNTrue setAnim={setAnim} index={indexAnim} />
              }

              {
                type === 'Girl5Months' &&
                <Girl5Months setAnim={setAnim} />
              }

              {
                type === 'MiddleWoman' &&
                <MiddleWoman setAnim={setAnim} />
              }

              {
                type === 'Boy5Months' &&
                <Sasha setAnim={setAnim} />
              }
            </Physics >
          </Stage >


          <OrbitControls ref={ref} makeDefault
            maxDistance={1.2}
            minDistance={1.5}
            enableZoom={true}
            // enablePan={true}
            zoomSpeed={2} />
          <PerspectiveCamera makeDefault fov={50} position={[0, 2, 5]} />

          {/* <ambientLight intensity={0} />
      <directionalLight color="red" position={[0, 0, 5]} /> */}

        </Canvas >
      </Suspense >

    </div >
  );
}

export default Model;
