import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { ReactElement } from 'react'
import Slot from '../../common/Slots/Slot'
import SlotMedia from '../../common/Slots/SlotMedia'

const useStyles = makeStyles(theme =>
  createStyles({
    image: {
      maxWidth: '100%',
      padding: theme.spacing(0),
      cursor: 'pointer'
    }
  })
)

interface Props {
  title?: string
}

export default function DemoSlotWithPhoto({ title }: Props): ReactElement {
  const classes = useStyles()
  return (
    <Slot title={title ? title : 'Карточка с Фото'}>
      <SlotMedia>
        <div>
          <img
            src='https://storage.yandexcloud.net/cdn.rownarts.com/tmp-assets/cyberdoctor/rentgen1.jpg'
            alt='Фото'
            className={classes.image}
          />
        </div>
        <div>
          <img
            src='https://storage.yandexcloud.net/cdn.rownarts.com/tmp-assets/cyberdoctor/rentgen2.jpg'
            alt='Фото'
            className={classes.image}
          />
        </div>
      </SlotMedia>
      Здесь может быть текст с расшифровкой данных на фото
    </Slot>
  )
}
