import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../store';
import { LocalSettingsAction, SetSkipNewTaskTutorialAction } from '../store/localSettings';

export type TooltipState = {
  open: boolean;
  title?: string;
  content?: string;
  onClose?: () => void;
  valueSkip: boolean;
  onChangeSkip?: (nValue: boolean) => void;
  children?: any;
};

type HookType = () => [TooltipState, () => void];

const settingsSelector = (state: RootState) => state.settings.settingsKeyValue;

const useTooltipState: HookType = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const settingsKeyValue = useSelector(settingsSelector);

  const pageSkipTooltip = settingsKeyValue[`skipTutorial_${history.location.pathname}`] || false;

  const isInitialOpen = useMemo(() => history.location.hash === '#initial', [history]);

  const [open, setOpen] = useState(!pageSkipTooltip && isInitialOpen);

  const onClose = useCallback(() => {
    setOpen(false);
    history.replace(history.location.pathname);
    // window.location.reload();
  }, [history]);

  const onChangeSkip = useCallback(
    (nValue: boolean) => {
      dispatch({
        type: LocalSettingsAction.SET_SETTINGS_KEY_VALUE,
        data: { ...settingsKeyValue, [`skipTutorial_${history.location.pathname}`]: nValue },
      } as SetSkipNewTaskTutorialAction);
    },
    [dispatch, settingsKeyValue, history]
  );

  return [
    {
      open: open,
      onClose: onClose,
      valueSkip: pageSkipTooltip,
      onChangeSkip: onChangeSkip,
    },
    () => setOpen(true),
  ];
};

export default useTooltipState;
