import { ChatApi } from './chatApi';
import { ApiType, buildConfig } from '../../BuildConfig';
import { ChatApiImpl } from './chatApiImpl';
import { MockChatApiImpl } from './mockChatApiImpl';
import { NeurodocGoTest } from './NeurodocGoTest';

let chatApi: ChatApi;

if (buildConfig.apiType === ApiType.mock) {
  chatApi = new MockChatApiImpl();
} else {
  chatApi = ChatApiImpl.createInstance();
}

const neurodocGoTest = NeurodocGoTest.createInstance();

export { neurodocGoTest };
export default chatApi;
