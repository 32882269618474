import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: '100%',
      height: '80%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'

      // display: 'grid',
      // gridGap: `8px`,
      // gridAutoFlow: `row`,
      // gridTemplateColumns: `1fr 1fr`,
      // [theme.breakpoints.down('sm')]: {
      //   gridTemplateColumns: `1fr`,
      //   gridAutoFlow: `unset`,
      // },
    }
  })
)

interface Props {
  children: any
  title?: string
}

const SlotContainerCenter = ({ children }: Props) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}

export default SlotContainerCenter
