import { Dialog } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import Slot from './Slots/Slot'
import SlotMedia from './Slots/SlotMedia'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '90%'
    },
    image: {
      width: '100%'
      //   borderRadius: 3,
    },
    text: {
      color: theme.palette.text.secondary,
      paddingTop: theme.spacing(1),
      fontSize: 14
    }
  })
)

type Props = {
  open: boolean
  title?: string
  src?: string
  content?: string
  onClose?: () => void
  onSubmit?: () => void
  children?: any
}

export default function DialogPhoto({ title, src, children, content }: Props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <Slot title={title} primary noBorder>
        <SlotMedia>
          {src && <img src={src} alt='title' className={classes.image} />}
        </SlotMedia>
        {children}

        {content && <div className={classes.text}>{content}</div>}
      </Slot>
    </Dialog>
  )
}
