import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ArrowRightTop from '../../../components/arrowIcons/ArrowRightTop';
import { getStatMainPage } from '../../../api/chatApi/chatApi';
import { TablePagination } from '@material-ui/core';
import { useTranslation } from 'react-i18next'

interface MainPageComponentInterface {
  role: any
  routeToAdministration: () => void
  routeToConstructor: () => void
  routeToTrainers: () => void
  routeMetodical: () => void
  routeChamp: () => void
  routeRating: () => void
  routeToAccreditation: () => void
}

const useStyles = makeStyles(theme =>
  createStyles({
    mainPageScreen: {
      display: 'flex',
      flexDirection: 'column',
      // width: '100%',
      width: '1443px',
      background: 'white',
      color: '#000000',
      '@media (max-width: 800px)': {
        fontSize: 12,
        // width: `calc(800px - 50px)`,
        width: '100%'
      },

      '@media (max-width: 400px)': {
        fontSize: 12,
        width: '400px'
      },
    },
    ratingAndMetodicalContainer: {
      '@media (max-width: 800px)': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    firstBlockMainPage: {
      display: 'flex',
      width: '100%',
      '@media (max-width: 800px)': {
        flexDirection: 'column'
      },
    },
    firstBlock: {
      width: '50%',
      '@media (max-width: 800px)': {
        width: '100%'
      },
    },
    img: {
      width: 1575, //1308
      height: '639px',
      position: 'absolute',
      top: 0,
      right: 0,
      backgroundImage: `url(${process.env.PUBLIC_URL + '/granite.svg'})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      [theme.breakpoints.down('xs')]: { fontSize: 14, marginRight: 15 }
    },
    horizontalElement: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      height: 300,
      border: '1px solid rgba(154, 154, 154, 0.4);',
      cursor: 'pointer',
      transitionProperty: 'all',
      transitionDuration: '0.4s',
      transitionDelay: '0.1s',
      transition: 'background-size 0.5s ease',
      '& .hoverArrow:hover, &:hover': {
        background: '#1E44CD',
      },
    },
    ratingElement: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      height: 300,
      border: '1px solid rgba(154, 154, 154, 0.4);',
      cursor: 'pointer',
      transitionProperty: 'all',
      transitionDuration: '0.4s',
      transitionDelay: '0.9s',
      transition: 'background-size 0.1s ease',
    },
    hoverEffect: {
      background: '#1E44CD',
    },
    horizontalElementTextBlock: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '35px 30px',
      // width: 300,
      height: '100%',
    },
    ratingBlock: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '35px 30px',
      width: '100%',
      height: '100%',
      '& .MuiTablePagination-input': {
        display: 'none'
      },
      '& .MuiTablePagination-caption:first-child': {
        display: 'none'
      }
    },
    horizontalElementTitle: {
      fontSize: 32,
      fontWeight: 500,
      color: '#000000',
      fontFamily: 'Helvetica Neue',
      lineHeight: '40px',
      whiteSpace: 'nowrap',
      '@media (max-width: 972px)': {
        fontSize: 25,
        fontWeight: 600
      },
      '@media (max-width: 800px)': {
        fontSize: 22,
        fontWeight: 600
      },
    },
    horizontalElementDescription: {
      fontSize: 14,
      fontWeight: 400,
      color: '#000000',
      fontFamily: 'Helvetica Neue',
      lineHeight: '16px',
      letterSpacing: '0.05em'
    },
    cardPicture: {
      padding: '45px 0',
      marginRight: '125px'
    },
    verticalElementsBlock: {
      width: '50%',
      display: 'flex',
      cursor: 'pointer',
      '@media (max-width: 800px)': {
        width: '100%',
        flexDirection: 'column',
        height: '50%'
      },
    },
    verticalElementTextBlock: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '45px 25px',
      height: '600px',
      border: '1px solid rgba(154, 154, 154, 0.4);',
      transitionProperty: 'all',
      transitionDuration: '0.4s',
      transitionDelay: '0.1s',
      transition: 'background-size 0.5s ease',
      '&:hover': {
        background: '#1E44CD',
        // transform: 'translate(5px, -10px)',
      },
      '@media (max-width: 800px)': {
        height: '300px',
      },
    },
    verticalElementTextBlockNoneHover: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '45px 25px',
      height: '600px',
      width: '100%',
      maxWidth: '50%',
      border: '1px solid rgba(154, 154, 154, 0.4);',
      '@media (max-width: 800px)': {
        display: 'none',
      },
    },

    shortBlockElementTextBlock: {
      minWidth: '25%',
      height: 280,
      border: '1px solid rgba(154, 154, 154, 0.4);',
      padding: '45px 25px',
      cursor: 'pointer',
      transitionProperty: 'all',
      transitionDuration: '0.4s',
      transitionDelay: '0.1s',
      transition: 'background-size 0.5s ease',
      '&:hover': {
        background: '#1E44CD',
        // transform: 'translate(5px, -10px)',
      },
    },
    shortBlockElementTextBlockNoneHover: {
      minWidth: '25%',
      height: 280,
      border: '1px solid rgba(154, 154, 154, 0.4);',
      padding: '45px 25px',
      cursor: 'pointer',
      '@media (max-width: 800px)': {
        display: 'none',
      },
    },
    longBlockElementTextBlock: {
      border: '1px solid rgba(154, 154, 154, 0.4);',
      height: 280,
      padding: '45px 25px',
      flexGrow: 1,
      cursor: 'pointer',
      transitionProperty: 'all',
      transitionDuration: '0.4s',
      transitionDelay: '0.1s',
      transition: 'background-size 0.5s ease',
      '&:hover': {
        background: '#1E44CD',
        // transform: 'translate(5px, -10px)',
      },
    },
    longBlockElementTextBlockNoneHover: {
      border: '1px solid rgba(154, 154, 154, 0.4);',
      height: 280,
      padding: '45px 25px',
      flexGrow: 1,
      '@media (max-width: 800px)': {
        display: 'none',
      },
    },
    BlockElementTextBlockContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      '@media (max-width: 800px)': {
        flexDirection: 'column',
      },
    },
    footerLinkText: {
      fontSize: 18,
      fontWeight: 400,
      fontFamily: 'Helvetica Neue',
      marginBottom: 24,
      color: 'white',
      marginLeft: 32,
      textDecoration: 'none'
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 250,
      marginTop: 10,
      marginBottom: 50
    },
    footerLinks: {
      display: 'flex',
      flexDirection: 'column',
    },
    footerIconsLinks: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 250,
      padding: '0 55px'
    },
    hoverArrow: {
      position: 'relative',
      transitionProperty: 'all',
      transitionDuration: '0.4s',
      transitionDelay: '0.1s',
      cursor: 'pointer',
      width: 22,
      transition: 'background-size 0.5s ease',

      '&:hover': {
        // background: '#FFFFFF',
        //  transform: 'translate(5px, -10px)',
      },
    },
    verticalElement: {},
  })
)
export default function MainPageComponent({
  role,
  routeToAdministration,
  routeToConstructor,
  routeToTrainers,
  routeMetodical,
  routeChamp,
  routeRating,
  routeToAccreditation,
}: MainPageComponentInterface): JSX.Element {
  const {t} = useTranslation()

  const style = useStyles()
  const [hover, setHover] = useState(false);

  function changeArrowHandler(event: any) {
    // .classList.contains('blueArrow')
    // console.log(event.target.className);
    let target = event.target.className
    if (event.target.className.indexOf('horizontalElementTextBlock') > -1) {
      console.log('стрелка');
      document.body.classList.toggle('whiteArrow')
      console.log(event.target.className.classList);
    }
  }


  useEffect(() => {
    getStatMainPageHandler()
  }, [])

  interface taskProcentInterface {
    secondname_name: string
    task_procent: number
  }
  interface statListInterface {
    medicine_name: string
    universitylevel_name: string
    task_procent: taskProcentInterface[]
  }
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = (event) => {
      setWidth(event.target.innerWidth);
      if (event.target.innerWidth < 400) {
        setRowsPerPage(1)
      }
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2);
  const [statList, setStatList] = useState<statListInterface[]>([])
  async function getStatMainPageHandler() {
    try {
      const response = await getStatMainPage()
      setStatList(response.data.stat_main_page)
    } catch (err) {
      console.log('при получении рейтинга произошла ошибка')
    }
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number,) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div className={style.mainPageScreen}>
        <div className={style.firstBlockMainPage}>

          <div className={style.ratingAndMetodicalContainer} style={{ display: 'flex', width: '100%' }}>
            <div className={style.horizontalElement} onClick={routeMetodical}>
              <div className={style.horizontalElementTextBlock}>
                <div style={{ marginBottom: '24px', cursor: 'pointer' }}>
                  {/* <p className={style.horizontalElementDescription}>Самостоятельная работа</p> */}
                  <p className={style.horizontalElementTitle} style={{ marginBottom: 24 }}>{t("mainMet")}</p>
                  <p className={style.horizontalElementDescription}>{t("mainMetDes")}</p>
                </div>
                <div className={style.hoverArrow}>
                  <ArrowRightTop color={'#1F4BEC'} />
                </div>
              </div>
            </div>

            <div className={style.horizontalElement}
              onClick={routeRating}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}>
              <div className={style.ratingBlock}>
                <div style={{ marginBottom: '24px' }}>
                  <div
                    onClick={(event) => event.stopPropagation()}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                      <p className={style.horizontalElementTitle} style={{ marginBottom: 24, cursor: 'pointer' }} onClick={routeRating}>{t("mainRating")}</p>
                      <TablePagination
                        component="div"
                        count={statList?.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={''}
                        labelDisplayedRows={() => ''}
                      />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={routeRating}>
                      {
                        statList && statList
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(stat => {
                            return (
                              <div>
                                <div>
                                  <p style={{ fontSize: 18, fontWeight: 400, marginBottom: 18 }}>{stat.medicine_name} | {stat.universitylevel_name}</p>
                                  <div>
                                    {stat.task_procent.map((percent, index: number) => {
                                      return (
                                        index < 3 &&
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                          <p style={{ whiteSpace: 'pre', width: 250 }}>{index + 1}. {percent.secondname_name}</p>
                                          <p style={{ width: 40 }}>{percent.task_procent}</p>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            )
                          })
                      }
                    </div>
                  </div>
                </div>
                <div className={style.hoverArrow}>
                  <ArrowRightTop color={'#1F4BEC'} onClick={routeRating} />
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className={style.BlockElementTextBlockContainer}>
          {
            role.includes('ROLE_CONSTRUCTOR') ||
              role.includes('ROLE_EXPERT') ||
              role.includes('ROLE_ADMIN') ?
              <div className={style.shortBlockElementTextBlock} onClick={routeToConstructor}>
                {/* <p className={style.horizontalElementDescription}>Администрирование</p> */}
                <p className={style.horizontalElementTitle} style={{ marginBottom: 24 }}>{t("mainConstructors")}</p>
                <p style={{ marginBottom: '42px' }} className={style.horizontalElementDescription}>{t("mainConstructorsDes")}</p>
                <div className={style.hoverArrow}>
                  <ArrowRightTop color={'#1F4BEC'} />
                </div>
              </div> : <div className={style.shortBlockElementTextBlockNoneHover}></div>
          }

          {
            role.includes('ROLE_ADMIN') ?
              <div className={style.longBlockElementTextBlock} onClick={routeToAdministration}>
                <div style={{ marginBottom: '24px' }}>
                  {/* <p className={style.horizontalElementDescription}>Администрирование</p> */} 
                  <p className={style.horizontalElementTitle} style={{ marginBottom: 24 }}>{t("mainAdmin")}</p>
                  <p style={{ marginBottom: '42px' }} className={style.horizontalElementDescription}>{t("mainAdminDes")}</p>
                  <div className={style.hoverArrow}>
                    <ArrowRightTop color={'#1F4BEC'} />
                  </div>
                </div>
              </div> : <div className={style.longBlockElementTextBlockNoneHover}></div>
          }

          <div className={style.shortBlockElementTextBlock} onClick={routeToAccreditation}>
            <div style={{ marginBottom: '24px' }}>
              {/* <p className={style.horizontalElementDescription}>Аккредитационное тестирование</p> */} 
              <p className={style.horizontalElementTitle} style={{ marginBottom: 24 }}>{t("mainTesting")}</p>
              <p style={{ marginBottom: '42px' }} className={style.horizontalElementDescription}>{t("mainTestingDes")}</p>
              <div className={style.hoverArrow}>
                <ArrowRightTop color={'#1F4BEC'} />
              </div>
            </div>
          </div>
        </div>

        {/*<div className={style.footer}>*/}
        {/*  <div className={style.footerLinks}>*/}
        {/*    <Link*/}
        {/*      className={style.footerLinkText}*/}
        {/*      to={RoutePath.aboutCompany}>*/}
        {/*      О компании*/}
        {/*    </Link>*/}
        {/*    <Link className={style.footerLinkText} to={RoutePath.connection}>*/}
        {/*      Связаться с нами*/}
        {/*    </Link>*/}
        {/*    <Link className={style.footerLinkText} to={RoutePath.infoUniversity}>*/}
        {/*      Информация для ВУЗов*/}
        {/*    </Link>*/}
        {/*  </div>*/}
        {/*  <div className={style.footerIconsLinks}>*/}
        {/*    <img style={{color: 'white'}} src={Insta} alt="instagramm"/>*/}
        {/*    <img src={YouTube} alt="Youtube"/>*/}
        {/*    <img src={W} alt="Whatsapp"/>*/}
        {/*  </div>*/}
        {/*</div>*/}

      </div>
    </>
  )
}
