import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // width: theme.spacing(32),
      // minHeight: theme.spacing(46),
      padding: theme.spacing(2),
      margin: theme.spacing(1),
      paddingTop: theme.spacing(4),
      borderRadius: 20,
      backgroundColor: theme.palette.background.paper,
      border: '2px dashed',
      borderColor: theme.palette.divider
      // backgroundImage: `url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: RGBA(0,0,0,0.12); stroke-width: 2; stroke-dasharray: 8 8'/></svg>")`,
    },
    rootHovered: {
      border: '2px solid',
      borderColor: theme.palette.primary.main
      // boxShadow: `0 0 10px ${theme.palette.primary.dark}`,
    },
    icon: {
      fontSize: 20,
      textAlign: 'center',
      textTransform: 'uppercase',
      paddingBottom: theme.spacing(2)
    },
    img: {
      padding: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100px'
      }
    },
    levelTitle: {
      // color: theme.palette.text.secondary,
      color: '#DF4D60',
      textTransform: 'none',
      fontSize: '20px',
      fontWeight: 400
    },
    levelTitleActive: {
      color: '#DF4D60',
      textTransform: 'none',
      fontWeight: 400
    },
    description: {
      fontSize: 12,
      fontWeight: 400,
      listStyle: 'none',
      textTransform: 'none',
      letterSpacing: '0.07em'
    },
    description_item: {
      display: 'flex',
      flexDirection: 'column'
    },
    smallIcon: {
      paddingRight: theme.spacing(0.5),
      lineHeight: 0
    },
    description__enabled: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.primary
    },
    description__disabled: {
      display: 'flex',
      alignItems: 'center',
      // color: theme.palette.text.disabled,
      color: theme.palette.text.primary
    },
    stepText: {
      fontSize: '5vw',
      fontWeight: 300,
      color: '#eb5757'
    }
  })
)

interface Props {
  width: number
  height?: string
  item: {
    imageHover?: string
    image?: string
    alt?: string
    title?: string
    stepsDescription?: Array<[string, boolean]>
    stepText?: string
  }
}

export default function HoverImage({
  width,
  height,
  item
}: Props): ReactElement {
  const classes = useStyles()
  const [isHover, setIsHover] = useState(false)

  const activeItems = item?.stepsDescription?.filter(([name, isActive]) => {
    if (isActive) return [name, isActive]
  })
  const disabledItems = item?.stepsDescription?.filter(([name, isActive]) => {
    if (!isActive) return [name, isActive]
  })

  const {t} = useTranslation()

  const translationCard = (key: string) => {
    return t(key)
  }

  return (
    <div
      className={`${classes.root} ${isHover && classes.rootHovered}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{ height: height }}
    >
      <div className={classes.icon}>
        {item.stepText ? (
          <div className={classes.stepText}>{item.stepText}</div>
        ) : (
          <>
            <img
              width={width}
              className={classes.img}
              src={isHover ? item.imageHover : item.image}
              alt={item.alt}
            />
            <div
              className={
                isHover ? classes.levelTitleActive : classes.levelTitle
              }
            >
              {item.title}
            </div>
          </>
        )}
      </div>

      <div className={classes.description}>
        {activeItems && <div style={{ marginBottom: 5 }}>{t("card7")}</div>}
        {activeItems?.map(([name, isActive]) => {
          return (
            <div
              key={name}
              className={
                isActive
                  ? classes.description__enabled
                  : classes.description__disabled
              }
            >
              <div className={classes.smallIcon}>
                {isActive ? (
                  <FiberManualRecordIcon style={{ fontSize: 4 }} />
                ) : (
                  <FiberManualRecordIcon style={{ fontSize: 4 }} />
                )}
              </div>
              <div>{translationCard(name)}</div>
            </div>
          )
        })}
        {!disabledItems?.length ? (
          <div></div>
        ) : (
          <>
            <div style={{ marginBottom: 5, marginTop: 10 }}>{t("card7")}</div>
            {disabledItems?.map(([name, isActive]) => {
              return (
                <div
                  key={name}
                  className={
                    isActive
                      ? classes.description__enabled
                      : classes.description__disabled
                  }
                >
                  <div className={classes.smallIcon}>
                    {isActive ? (
                      <FiberManualRecordIcon style={{ fontSize: 4 }} />
                    ) : (
                      <FiberManualRecordIcon style={{ fontSize: 4 }} />
                    )}
                  </div>
                  <div>{translationCard(name)}</div>
                </div>
              )
            })}
          </>
        )}
      </div>
    </div>
  )
}
