import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextArea from '@carbon/react/es/components/TextArea/TextArea';
import { Typography } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import MainFooter from '../../../../components/MainFooter/MainFooter';
import MainHeader from '../../../../components/MainHeader/MainHeader';
import ProfileCarousel from './profileCarousel';
// @ts-ignore
import { Pagination } from '@carbon/react';

interface organizationDescriptionInterface {
  // description: string | null
  achievements: string,
  adress: string,
  boss: string,
  description: string,
  id: number,
  img: string
  name: string
  media: [{ media: string, id: number }]
}

interface scoreOrganizationInterface {
  task_procent_organization: [
    {
      name: string
      row_number: number
      task_procent: number
    }
  ]
  score: number
}

interface teachersListInterface {
  id: number
  fio: string
  description: string
}


interface MainDescriptionOrganizationInterface {
  organizationDescription: organizationDescriptionInterface | undefined
  scoreOrganization: scoreOrganizationInterface | undefined
  organizationMedia: any[] | undefined
  teachersList: teachersListInterface[]
}
const useStyles = makeStyles((theme: any) => ({

  descriptionContainer: {
    width: 1300,
    fontFamily: 'Helvetica Neue',
    margin: '5% auto 0px auto'
  },
  mainInfoBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  organizationName: {
    fontSize: 26,
    fontWeight: 600,
  },
  organizationBoss: {
    fontSize: 20,
    fontWeight: 500,
  },
  organizationAddressBlock: {
    marginBottom: 45
  },
  organizationAddress: {
    fontSize: 18,
    fontWeight: 500,
  },
  customDescriptionBlock: {
    width: '100%',
    border: '1px solid #DADADA',
    borderRadius: 6,
    overflowY: 'scroll',
    height: 300,
    fontSize: 18,
    padding: '10px 10px',
  },
  customTextArea: {
    '& .cds--text-area': {
      fontSize: 18
    }
  },
  descriptionText: {
    fontSize: 18
  },
  achievementsBlock: {
    width: '100%'
  },
  achievementsText: {
    fontSize: 18,
    wordBreak: 'break-all',
  },
  ratingOrg: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '25px 0 25px 0',
  },
  ratingOrgTitle: {
    fontSize: 24,
    fontWeight: 500
  },
  ratingOrgTable: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DADADA',
  },
  ratingOrgElement: {
    marginBottom: 10,
    marginTop: 10,
    fontSize: 18,
  },
  nonePhotoBlock: {
    border: '1px solid #DADADA',
    borderRadius: 12,
    width: 230,
    height: 120,
    textAlign: 'center',
    padding: '15px 15px'
  },
  nonePhotoText: {
    fontSize: 18,
    lineHeight: '35px'
  },
}))
export default function MainDescriptionOrganization({
  organizationDescription,
  scoreOrganization,
  organizationMedia,
  teachersList,
}: MainDescriptionOrganizationInterface): JSX.Element {

  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const style = useStyles()

  console.log("organizationDescription", organizationDescription);
  console.log("teachersList", teachersList);


  return (
    <><div className={style.descriptionContainer}>
      <MainHeader />
      <div>
        <div className={style.mainInfoBlock}>
          <div>
            <p className={style.organizationName}>{organizationDescription && organizationDescription.name}</p>

            {scoreOrganization &&
              <div style={{ marginTop: '25px' }}>
                <Typography component="legend">Рейтинг организаций</Typography>
                <Rating name="read-only" value={scoreOrganization.score} readOnly={true} />
              </div>}

            <div className={style.organizationAddressBlock}>
              <p
                className={style.organizationAddress}>
                {organizationDescription && organizationDescription.adress}
              </p>
            </div>

            <div style={{ marginBottom: '70px' }}>
              <p
                className={style.organizationBoss}>{organizationDescription && organizationDescription.boss !== null &&
                  organizationDescription.boss.split('<b>').join('').split('</b>').join('')}
              </p>
            </div>
          </div>

          <div>
            {organizationDescription && organizationDescription.img !== null ?
              <img src={organizationDescription.img} alt="organizationDescription" /> :
              <div className={style.nonePhotoBlock}>
                <p className={style.nonePhotoText}>Фоторгафия отсутствует</p>
              </div>}
          </div>
        </div>

        <div>
          <div className={style.customDescriptionBlock}>
            <p className={style.descriptionText}>{organizationDescription &&
              organizationDescription.description !== null &&
              organizationDescription.description.split('</br>').join('')}</p>
          </div>
        </div>

        <div className={style.achievementsBlock}>
          <p className={style.achievementsText}>{organizationDescription &&
            organizationDescription.achievements !== null &&
            organizationDescription.achievements.split('</br>').join('')}</p>
        </div>
        <p style={{ fontSize: 26, fontWeight: 500, marginTop: 30 }}>Рейтинг организаций</p>
        <div style={{ marginBottom: '50px' }}>
          <div className={style.ratingOrg}>
            <p className={style.ratingOrgTitle}>Наименование организации</p>
            <p className={style.ratingOrgTitle}>Процент выполнения работ</p>
          </div>
          {scoreOrganization &&
            <div>
              {scoreOrganization.task_procent_organization.map(org => {
                return (
                  <div className={style.ratingOrgTable}>
                    <p className={style.ratingOrgElement}>{org.name}</p>
                    <p className={style.ratingOrgElement}>{org.task_procent}</p>
                  </div>
                );
              })}
            </div>}
          {scoreOrganization && scoreOrganization.task_procent_organization.length > 5 &&
            <Pagination
              backwardText="Предыдущая вкладка"
              forwardText="Следующая вкладка"
              itemsPerPageText="Показывать по"
              pageRangeText={(_current: any, total: number) => `из ${total} ${total === 1 ? 'страницы' : 'страниц'}`}
              itemText={(min: number, max: number) => `${min}–${max} itsaaaems`}
              itemRangeText={(min: number, max: number, total: number) => `${min}–${max} из ${total} записей`}
              pageSize={currentPageSize}
              pageNumberText="Page Number"
              pageSizes={[
                10,
                20,
                30,
                40,
                50
              ]}
              totalItems={scoreOrganization.task_procent_organization &&
                scoreOrganization.task_procent_organization.length || 0}
              onChange={({ page, pageSize }: any) => {
                if (pageSize !== currentPageSize) {
                  setCurrentPageSize(pageSize);
                }
                setFirstRowIndex(pageSize * (page - 1));
              }} />}
        </div>


        {organizationDescription && teachersList &&
          <div>
            <ProfileCarousel imageList={organizationMedia} />
          </div>}
        {teachersList && <>
          <p>Список преподавателей</p>
          <div style={{ display: 'flex' }}>
            <p style={{ width: 30 }}>№</p>
            <p style={{ width: 400 }}>ФИО</p>
            <p style={{ width: 450 }}>Описание</p>
          </div>
        </>}
        {teachersList &&
          teachersList.map((org, index: number) => {
            return (
              <div>
                <div style={{ display: 'flex' }}>
                  <p style={{ width: 30 }}>{index + 1}</p>
                  <p style={{ width: 400 }}>{org.fio}</p>
                  <p style={{ width: 450 }}>{org.description}</p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
      <MainFooter />
    </>
  )
}
