import { LinearProgress, Box, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
// import { useSelector } from 'react-redux'
// import { useLocation } from 'react-router-dom'
// import { AsyncState } from 'react-use/lib/useAsyncFn'
// import { Task } from 'redux-saga'
import chatApi from '../../api/chatApi'
import { Constructors_Task, getTaskPoint } from '../../api/chatApi/chatApi'
import { SidebarWidth } from '../../constants/default'
import useFinishTaskDialogState from '../../hooks/testingTask/useFinishTaskDialogState'
import { usePointProcentStore } from '../../pages/chat/store'
// import { RootState } from '../../store'
import { MenuItemData, Notes, ProgressBarType, User } from '../../types'
import { pxToRem, pxToRemGutter } from '../../utils'
// import CustomButton from '../common/CustomButton'
import ConfirmDialog from '../ConfirmDialog'
// import SidebarMenu from '../MainSidebar/SidebarMenu';
import UserInfo from '../MainSidebar/UserInfo'
import SidebarSteps from './SidebarSteps'
import './index.css'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  sidebar: {
    width: pxToRem(SidebarWidth),
    height: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    borderRight: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper,
    paddingTop: '15px',
    paddingBottom: '25px'
    // paddingBottom: 90,
  },
  progress_container: {
    width: '100%',
    padding: pxToRemGutter(0, 24, 16),
    margin: 0
  },
  blockWrap: {
    width: '100%',
    padding: pxToRemGutter(0, 24, 16),
    margin: 0,
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  blockModelBtn: {
    textAlign: 'center'
  }
}))
type Props = {
  user: User
  notes?: Notes
  anamnesisProgress: ProgressBarType
  anamnesisErrors: ProgressBarType
  mini: boolean
  menuItems: Array<MenuItemData>
  patientModel: any
}

declare global {
  interface Window {
    ym: any
  }
}

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress style={{ background: 'red', opacity: 0.7, height: 9 }} variant="determinate" value={props.value > 100 ? 100 : props.value} />
      </Box>
      <Box minWidth={35}>
        <Typography style={{ fontSize: 18 }} variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default function MainSidebar({
  user,
  notes,
  anamnesisProgress,
  anamnesisErrors,
  mini,
  patientModel
}: Props) {
  const classes = useStyles()
  const { close, onSubmit, open, show } = useFinishTaskDialogState()
  const [task, setTask] = useState(0);
  const [maxPointTask, setMaxPointTask] = useState(0);
  const [mainPont, setMainPoint] = useState(0);
  const [mainProcent, setMainProcent] = useState<number>(0);
  const champ = localStorage.getItem('idChampForTask')
  const datas = usePointProcentStore(state => state.data)

  useEffect(() => {
    if (datas) {
      const point = datas.reduce(function (acc, obj) { return acc + obj.point; }, 0);
      const procent = datas.reduce(function (acc, obj) { return acc + obj.task_procent; }, 0);
      setMainPoint(point)
      setMainProcent(procent)
    }
  }, [datas]);


  const getTaskPoints = async (task) => {
    await getTaskPoint(task)
      .then(res => setMaxPointTask(res.data.task_point.max_point_task))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    unfinishedTaskHandler()
    // window?.ym(87333080, 'hit', window.location.href);
  }, [])

  async function unfinishedTaskHandler() {
    try {
      const response = await chatApi.unfinishedTask()
      setTask(response.result.taskId)
      getTaskPoints(response.result.taskId)
    } catch (err) {
      console.log('при выполнении unfinishedTaskHandler', err);
    }
  }


  const view3dModel = useQuery('patientModel', async () => {
    const currTask: any = async () => await chatApi.unfinishedTask()
    const task = await currTask()

    return task?.result?.task?.patientModel?.view3dLink
  })

  ///////////////////////////////////////////////////////////////// Убрать
  // setInterval(() => {
  //   if (window.location.hash) {
  //     view3dModel.refetch()
  //   }
  // }, 5000)


  const saveProcced = async () => {
    await chatApi.saveProcced(task, Number(champ), false)
      .then(res => console.log(res))
      .catch(err => console.log(err))
  }

  const {t} = useTranslation()

  return (
    <div style={{ width: pxToRem(SidebarWidth), background: '#fff', borderRight: `1px solid gainsboro` }} className={classes.sidebar}>
      <UserInfo user={user} mini={mini} />
      <div className={classes.progress_container}>
        {/* <div style={{fontSize: 18}}>Максимальное количество баллов: {maxPointTask}</div>
        <div style={{fontSize: 18}}>Мои баллы: {mainPont}</div> */}
        <LinearProgressWithLabel value={mainProcent < 0 ? 0 : mainProcent} />
      </div>
      <div className={classes.blockWrap}>
        {/* show */}
        {champ
          ?
          <div>
            <Button onClick={() => {
              saveProcced()
              onSubmit()

            }} color='primary' variant='outlined'>
              {t("trenSidebar1")}
            </Button>
            <div style={{ marginTop: 10, fontSize: 19 }}>{t("trenSidebar2")}</div>
          </div>
          :
          <Button onClick={() => {
            show()
          }} color='primary' variant='outlined'>
            {t("trenSidebar1")}
          </Button>
        }

        {/* <Button onClick={show}>Закончить задачу</Button> */}
      </div>
      <div className={classes.blockModelBtn}>
        {/* {view3dModel.data && (
          <Button style={{ marginTop: '12px' }} color="primary" variant="contained" href="/testModel">
            Осмотр пациента
          </Button>
        )} */}
      </div>
      <SidebarSteps />
      {/* <SidebarMenu mini={mini} menuItems={menuItems} /> */}
      {/* <MedNotes notes={notes} /> */}
      {/* <AddMedNoteBtn /> */}
      <ConfirmDialog
        open={open}
        title={t("trenSidebar3")}
        content={t("trenSidebar4")}
        onClose={close}
        onSubmit={onSubmit}
      />
    </div>
  )
}
