import React from "react";
import { ArrowRight, ArrowLeft } from '@carbon/icons-react';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    width: 400,
    paddingBottom: 10,
    paddingLeft: 10,
  },
  button: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    padding: 0,
  },
  select: {
    height: 25,
    cursor: 'pointer',
    border: 'none',
    backgroundColor: '#F4F4F4'
  },
};

const CustomTablePagination = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const rowsPerPageOptions = [5, 10, 25, { label: 'Все', value: -1 }];

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < Math.ceil(count / (rowsPerPage === -1 ? count : rowsPerPage))) {
      onPageChange(newPage);
    }
  };

  const handleRowsPerPageChange = (event) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  const numberOfPages = Math.ceil(count / (rowsPerPage === -1 ? count : rowsPerPage));

  return (
    <div style={styles.container}>
      <button
        onClick={() => handlePageChange(page - 1)}
        disabled={page === 0}
        style={styles.button}
      >
        <ArrowLeft disabled={page === 0} />
      </button>

      <span>Страница {page + 1} из {numberOfPages}</span>

      <button
        onClick={() => handlePageChange(page + 1)}
        disabled={page + 1 >= numberOfPages}
        style={styles.button}
      >
        <ArrowRight disabled={page + 1 >= numberOfPages} />
      </button>
      <div>
        Показывать по:
        <select
          value={rowsPerPage}
          onChange={handleRowsPerPageChange}
          style={styles.select}
        >
          {rowsPerPageOptions.map((option) => (
            typeof option === "object" ?
              <option key={option.value} value={option.value}>{option.label}</option> :
              <option key={option} value={option}>{option}</option>
          ))}
        </select>
      </div>

    </div>
  );
};

export default CustomTablePagination;
