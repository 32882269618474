import {
  Button,
  createStyles,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  TextField,
  Tooltip
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { FunctionComponent, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import chatApi from '../../../api/chatApi'
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles(theme =>
  createStyles({
    mkbField: {
      '& .MuiOutlinedInput-multiline': {
        padding: '10px 14px'
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 12px) scale(1)'
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)'
      }
    },
    textFieldContainer: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: 'red'
    },
    savedDiagnosis: {
      color: 'red',
      marginTop: '15px'
    }
  })
)

enum DiagnosisFieldPart {
  diagnosis = 1,
  diagnosisCode = 2
}
export interface DiagnosisFieldData {
  code?: string | undefined
  name?: string | undefined
  diagnosis?: string
  diagnosisCode?: string
  key: number
  id?: any[]
}

type Props = {
  maxLines?: number
  diagnosis: Array<DiagnosisFieldData>
  readOnly?: boolean
  onChange?: (fields: Array<DiagnosisFieldData>) => void
  onClickInput: (id: number | undefined) => void
  idInput?: number | undefined,
  currentInputId: number | undefined,
  setDiagnosis: any,
  items: any,
  setIsButtonActive: any,
  diagnosisCodeValue: string,
  onDiagnosisCodeChange: any,
  level: number | null,
  toggle: (bol: boolean) => void
}
type DiagnosisFieldDataWithKey = DiagnosisFieldData & { key: number };
const DiagnosisFields: FunctionComponent<Props> = props => {
  const classes = useStyles()
  const [fields, setFields] = useState<Array<DiagnosisFieldDataWithKey>>(
    [
      ...props.diagnosis,
      ...(props?.readOnly ? [] : [{ key: new Date().getTime() }])
    ]
  );
  const [diagnosisList, setDiagnosisList] = React.useState<any[]>([])
  const [total, setTotal] = React.useState(0)
  const [maxPage, setMaxPage] = React.useState(0)
  const [currentPage, setCurrentPage] = React.useState(0)

  const [searchText, setSearchText] = React.useState('')
  const [searchCode, setSearchCode] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const size = 50
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);
  const [isDisabledBtnHighLevel, setIsDisabledBtnHighLevel] = useState(true);

  const [savedMainDiagnosis, setSavedMainDiagnosis] = useState<any>('')
  const [savedMainDiagnosisCode, setSavedMainDiagnosisCode] = useState<any>('')
  const [disableClearable, setDisableClearable] = useState(false)
  const [inputValue, setInputValue] = React.useState('');
  const [inputValueBtn, setInputValueBtn] = React.useState('');
  const [diagnosisCodeValue, setDiagnosisCodeValue] = React.useState('');


  const easyLevel = async () => {
    try {
      const req = await chatApi.getDiagnosisOptionsVariantes(
        'IL4',
        '',
        '',
        currentPage,
        size
      )
      const sort = req?.result.results.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setDiagnosisList(sort)

    } catch {
      console.error();
    }

  }

  useEffect(() => {
    if (props.level === 2) {
      easyLevel();
    }
  }, [props.level]);

  const openModal = () => {
    if (props.level === 2) {
      setOpen(!open)
    }
    if (props.level === 3) {
      setOpen(false)
    }
  }
  const onChange = (node: any, index: number, type: DiagnosisFieldPart) => {
    const text = node?.target?.value
    if (text && text.length && props.level === 1) {
      const func = async () => {
        setSearchText(text)
        const req = await chatApi.getDiagnosisOptionsVariantes(
          'IL4',
          String(text),
          String(searchCode),
          currentPage,
          size
        )
        setDiagnosisList(req?.result.results || [])
        setTotal(req?.result.total)
        setMaxPage(req?.result.maxPages)

        if (props.level === 3) {
          props.onChange && props.onChange(fields);
        }
      }
      func()
    }

    if (props?.readOnly === true) return
    let newFields = [...fields]
    const originalItem = fields[index]

    const newItem = Object.assign(
      originalItem,
      type === DiagnosisFieldPart.diagnosis
        ? { diagnosis: text }
        : { diagnosisCode: text }
    )

    newFields.splice(index, 1, newItem)

    if (newFields.length > 1) {
      newFields = newFields.filter(it => !!it.diagnosis || !!it.diagnosisCode)
    }
    const allLinesFilled = !newFields.some(it => it.diagnosis == undefined || it.diagnosisCode == undefined);

    props.onChange &&
      props.onChange(newFields.filter(it => it.diagnosis && it.diagnosisCode))
    setFields(newFields)
    if (props.level !== 3) {
      setOpen(true)
    }
  }

  const onChangeMkb = (node: any, index: number, type: DiagnosisFieldPart) => {
    const searchCode = node?.target?.value
    if (searchCode && searchCode.length && props.level === 1) {
      const func = async () => {
        setSearchCode(searchCode)
        const req = await chatApi.getDiagnosisOptionsVariantes(
          'IL4',
          '',
          String(searchCode),
          currentPage,
          size
        )

        setDiagnosisList(req?.result.results || [])
        setTotal(req?.result.total)
        setMaxPage(req?.result.maxPages)

        if (props.level === 3) {
          props.onChange && props.onChange(fields);
        }
      }
      func()
    }

    if (props?.readOnly === true) return
    let newFields = [...fields]
    const originalItem = fields[index]

    const newItem = Object.assign(
      originalItem,
      type === DiagnosisFieldPart.diagnosisCode
        ? { diagnosis: searchCode }
        : { diagnosisCode: searchCode }
    )

    newFields.splice(index, 1, newItem)

    if (newFields.length > 1) {
      newFields = newFields.filter(it => !!it.diagnosis || !!it.diagnosisCode)
    }
    const allLinesFilled = !newFields.some(it => it.diagnosis == undefined || it.diagnosisCode == undefined);

    props.onChange &&
      props.onChange(newFields.filter(it => it.diagnosis && it.diagnosisCode))
    setFields(newFields)
    if (props.level !== 3) {
      setOpen(true)
    }
  }


  const setDiagnosis = (
    newValue: string | undefined,
    index: number,
    type?: DiagnosisFieldPart,
    item?: any
  ) => {
    if (props.items) {
      if (item) {
        props.setDiagnosis([...props.items, { input: props.currentInputId, id: item.id, name: item.name }])
      }
      if (!item) {
        const filter = props.items.filter((el: any) => el.input !== props.currentInputId)
        props.setDiagnosis(filter)
      }
    }
    setInputValue(newValue || '')
    fields[index].diagnosis = newValue
    if (!newValue) fields[index].diagnosisCode = ''
    if (newValue && newValue.length > 2 && diagnosisList.length > 0) {
      let arr = diagnosisList.filter(el => el.name === newValue)
      if (arr.length) {
        fields[index].diagnosisCode = arr[0].code
        props.onChange &&
          props.onChange(fields.filter(it => it.diagnosis && it.diagnosisCode))
      }
    }

    setIsDisabled(false)
    setIsDisabledBtn(false)
    setOpen(false)
  }

  const setDiagnosisMaxLevel = (
    newValue: string,
    index: number,
  ) => {
    setInputValue(newValue);
    setInputValueBtn(inputValueBtn);
    fields[index].diagnosisCode = newValue;
    setIsDisabled(false);
    setIsDisabledBtn(false);
    setOpen(false);
  }


  const setPage = () => {
    let page = currentPage + 1
    setCurrentPage(page)
  }

  const fetchNext = async () => {
    if (maxPage !== currentPage) {
      try {
        setPage()
        const req = await chatApi.getDiagnosisOptionsVariantes(
          'IL4',
          searchText,
          searchCode,
          currentPage,
          size
        )
        let arr = diagnosisList
        req?.result.results.forEach((el: any) => arr.push(el))
        setDiagnosisList(arr || [])
        setTotal(req?.result.total)
        setMaxPage(req?.result.maxPages)
      } catch (error) {
        console.log(error)
      }

    }
  }

  const saveDiagnosis = () => {
    props.toggle(true)
    setIsDisabled(true)
    setIsDisabledBtn(true)
    setSavedMainDiagnosis(inputValue)
    setSavedMainDiagnosisCode(inputValueBtn)
    setDisableClearable(true)
    props.onDiagnosisCodeChange(inputValueBtn);

  }

  useEffect(() => {
    setIsDisabledBtnHighLevel(fields.some(field => !field.diagnosisCode));
  }, [fields]);

  const {t} = useTranslation()


  return (
    <div>
      {fields.map((it, inx) => {
        return (
          <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }} key={it.key} >
            <Autocomplete
              disabled={isDisabled}
              disableClearable={disableClearable}
              style={{ display: 'inline-block', width: '65%' }}
              value={it?.diagnosis || ''}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                if (props.level === 3) {
                  setDiagnosisMaxLevel(newInputValue, inx)
                } else
                  setDiagnosis(newInputValue, inx, DiagnosisFieldPart.diagnosis)
              }}
              id='controllable-states-demo'
              options={[]?.map((it: any) => it.name)}
              noOptionsText=''
              onMouseDownCapture={e => e.stopPropagation()}
              openOnFocus={false}
              PaperComponent={(param: any) => (
                <InfiniteScroll
                  height={props.level === 3 ? 10 : 200}
                  dataLength={total}
                  style={{
                    backgroundColor: '#fff'
                  }}
                  next={() => {
                    fetchNext()
                  }}
                  hasMore={diagnosisList.length < total}
                  loader={
                    <p
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#1876D1',
                        color: 'white',

                      }}
                    >
                      <b>{t("loadingAll")}</b>
                    </p>
                  }
                  endMessage={
                    <p>
                      <b></b>
                    </p>
                  }
                >
                  <List dense={true}>

                    {diagnosisList?.map((it: any, i) => (
                      <ListItem
                        onClick={() => {
                          setOpen(false)
                          setDiagnosis(
                            it.name,
                            inx,
                            DiagnosisFieldPart.diagnosis,
                            it
                          )
                        }
                        }
                        key={i}
                      >
                        {/* <ListItemText
                          style={{
                            padding: 5
                          }}
                          primary={it.name}
                        /> */}
                        <Tooltip
                          title={
                            <div style={{ padding: 10 }}>
                              <div style={{ fontSize: 14, marginBottom: 10 }}>{t("trenInputCod1")}:  {it.code}</div>
                              <div style={{ fontSize: 14 }}>{t("trenInputDes")}:  {it.description}</div>
                            </div>
                          }
                          //@ts-ignore
                          placement="right-center">
                          <ListItemText
                            style={{
                              padding: 5,
                              cursor: 'pointer'
                            }}
                            primary={it.name}
                          />
                        </Tooltip>
                      </ListItem>
                    ))}
                  </List>
                </InfiniteScroll>
              )}
              renderInput={params => {
                return (
                  <TextField
                    onChange={node =>
                      onChange(node, inx, DiagnosisFieldPart.diagnosis)
                    }
                    {...params}
                    variant='outlined'
                    label= {t("trenInputLabel")}
                    size='small'
                    onClick={openModal}
                    onFocus={() => props.onClickInput(props.idInput)}
                    disabled={isDisabled}
                  />

                )
              }}
              open={open}
              disableCloseOnSelect
            />
            {props.level === 3 ? (
              <>
                <TextField
                  type='text'
                  multiline
                  onFocus={() => setOpen(false)}
                  value={inputValueBtn}
                  onChange={event => {
                    const value = event.target.value;
                    setInputValueBtn(value);
                    setDiagnosisCodeValue(value);
                    setIsDisabledBtnHighLevel(!value);
                  }}
                  label={t("trenInputCod")}
                  style={{ width: '28%', marginLeft: '2%' }}
                  variant='outlined'
                  className={classes.mkbField}
                  disabled={isDisabled}
                />
                <Button onClick={saveDiagnosis} disabled={diagnosisCodeValue === ''}>Ок</Button>
              </>
            ) : (
              <>
                <TextField
                  onChange={node =>
                    onChangeMkb(node, inx, DiagnosisFieldPart.diagnosis)
                  }
                  type='text'
                  multiline
                  // onClick={openModal}
                  // onFocus={() => props.onClickInput(props.idInput)}
                  value={it?.diagnosisCode || ''}
                  label={t("trenInputCod")}
                  style={{ width: '28%', marginLeft: '2%' }}
                  variant='outlined'
                  className={classes.mkbField}
                  disabled={isDisabled}
                />
                <Button onClick={saveDiagnosis} disabled={isDisabledBtn}>Ок</Button>
              </>
            )}
          </div>
        )
      })}
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '12px',
        marginLeft: '12px',
        fontSize: '16px',
        position: 'relative'
      }}>
        <div style={{
          maxWidth: '60%',
          wordWrap: 'break-word',
          letterSpacing: '0.02em',
          fontWeight: 400
        }}>
          {`${savedMainDiagnosis}`}
        </div>
        {fields[0].diagnosisCode !== undefined && savedMainDiagnosis && props.level !== 3 ? (<div style={{
          marginLeft: '66%',
          position: 'absolute',
          letterSpacing: '0.02em',
          fontWeight: 400
        }}>
          {`${fields[0].diagnosisCode}`}
        </div>) : null}
        <div style={{
          marginLeft: '66%',
          position: 'absolute',
          letterSpacing: '0.02em',
          fontWeight: 400
        }}>
          {`${savedMainDiagnosisCode}`}
        </div>
      </div>
    </div>
  )
}

export default DiagnosisFields

