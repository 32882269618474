import React, { useEffect, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function MiddleWoman(props) {
  const { nodes, materials, animations } = useGLTF('patient/Middle_age_woman.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)
  const [index, setIndex] = useState(4)

  console.log(names);
  useEffect(() => {
    props.setAnim(animations)
    actions.rigAction.play()
  }, [actions, names, mixer])

  return (
    <group ref={ref} {...props} dispose={null}>
      <group name="Scene">
        <group name="metarig">
          <group name="spine" position={[0, 0.92, -0.04]} rotation={[0.3, 0, 0]}>
            <group name="spine001" position={[0, 0.1, 0]} rotation={[0.07, 0, 0]}>
              <group name="spine002" position={[0, 0.1, 0]} rotation={[-0.38, 0, 0]}>
                <group name="spine003" position={[0, 0.15, 0]} rotation={[-0.11, 0, 0]}>
                  <group name="spine004" position={[0, 0.16, 0]} rotation={[0.63, 0, 0]}>
                    <group name="spine005" position={[0, 0.05, 0]} rotation={[-0.14, 0, 0]}>
                      <group name="spine006" position={[0, 0.05, 0]} rotation={[-0.35, 0, 0]}>
                        <group name="face" rotation={[-0.03, 0, 0]}>
                          <group name="nose_1" position={[0, 0.1, 0.11]} rotation={[2.76, 0, 0]}>
                            <group name="nose001_1" position={[0, 0.03, 0]} rotation={[-0.22, 0, 0]}>
                              <group name="nose002_1" position={[0, 0.02, 0]} rotation={[1.22, 0, 0]}>
                                <group name="nose003_1" position={[0, 0.01, 0]} rotation={[0.43, 0, 0]}>
                                  <group name="nose004_1" position={[0, 0.01, 0]} rotation={[-1.38, 0, 0]} />
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="lipTL" position={[0, 0.05, 0.1]} rotation={[-1.52, -0.02, -1.3]}>
                            <group name="lipTL001_1" position={[0, 0.02, 0]} rotation={[-0.25, -0.27, 0.51]} />
                          </group>
                          <group name="lipBL" position={[0, 0.04, 0.1]} rotation={[-1.37, -0.19, -1.13]}>
                            <group name="lipBL001_1" position={[0, 0.02, 0]} rotation={[-0.02, 0.15, 0.08]} />
                          </group>
                          <group name="jaw_1" position={[0, 0, 0.04]} rotation={[1.7, 0, 0]}>
                            <group name="chin_1" position={[0, 0.05, 0]} rotation={[-1.16, 0, 0]}>
                              <group name="chin001_1" position={[0, 0.02, 0]} rotation={[-0.35, 0, 0]} />
                            </group>
                          </group>
                          <group name="earL_1" position={[0.07, 0.07, 0.01]} rotation={[0.03, -0.21, -0.28]}>
                            <group name="earL001" position={[0, 0.04, 0]} rotation={[-1.35, -0.25, -0.12]}>
                              <group name="earL002_1" position={[0, 0.02, 0]} rotation={[-1.85, -0.39, -0.1]}>
                                <group name="earL003_1" position={[0, 0.03, 0]} rotation={[-0.29, -0.62, 0.64]}>
                                  <group name="earL004_1" position={[0, 0.04, 0]} rotation={[-3.01, -0.06, 0.81]} />
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="earR_1" position={[-0.07, 0.07, 0.01]} rotation={[0.03, 0.21, 0.28]}>
                            <group name="earR001" position={[0, 0.04, 0]} rotation={[-1.35, 0.25, 0.12]}>
                              <group name="earR002_1" position={[0, 0.02, 0]} rotation={[-1.85, 0.39, 0.1]}>
                                <group name="earR003_1" position={[0, 0.03, 0]} rotation={[-0.29, 0.62, -0.64]}>
                                  <group name="earR004_1" position={[0, 0.04, 0]} rotation={[-3.01, 0.06, -0.81]} />
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="lipTR" position={[0, 0.05, 0.1]} rotation={[-1.52, 0.02, 1.3]}>
                            <group name="lipTR001_1" position={[0, 0.02, 0]} rotation={[-0.25, 0.27, -0.51]} />
                          </group>
                          <group name="lipBR" position={[0, 0.04, 0.1]} rotation={[-1.37, 0.19, 1.13]}>
                            <group name="lipBR001_1" position={[0, 0.02, 0]} rotation={[-0.02, -0.15, -0.08]} />
                          </group>
                          <group name="browBL_1" position={[0.05, 0.11, 0.09]} rotation={[-0.93, 0.91, 1.96]}>
                            <group name="browBL001_1" position={[0, 0.01, 0]} rotation={[-0.46, 0.75, 0.03]}>
                              <group name="browBL002_1" position={[0, 0.01, 0]} rotation={[-0.15, 0.42, -0.37]}>
                                <group name="browBL003_1" position={[0, 0.02, 0]} rotation={[-0.2, 0.6, -0.43]} />
                              </group>
                            </group>
                          </group>
                          <group name="lidTL_1" position={[0.05, 0.1, 0.08]} rotation={[-1.17, 1.22, 2.32]}>
                            <group name="lidTL001_1" position={[0, 0.01, 0]} rotation={[-0.38, 0.87, -0.25]}>
                              <group name="lidTL002_1" position={[0, 0.01, 0]} rotation={[-0.37, 0.42, -0.69]}>
                                <group name="lidTL003_1" position={[0, 0.01, 0]} rotation={[-0.42, 0.57, -0.13]}>
                                  <group name="lidBL_1" position={[0, 0.01, 0]} rotation={[1.23, 0.04, -2.49]}>
                                    <group name="lidBL001_1" position={[0, 0.01, 0]} rotation={[0.41, 0.18, 0.08]}>
                                      <group name="lidBL002_1" position={[0, 0.01, 0]} rotation={[0.27, 0.21, 0.42]}>
                                        <group name="lidBL003_1" position={[0, 0.01, 0]} rotation={[0.05, 0.08, 0.73]} />
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="browBR_1" position={[-0.05, 0.11, 0.09]} rotation={[-0.93, -0.91, -1.96]}>
                            <group name="browBR001_1" position={[0, 0.01, 0]} rotation={[-0.46, -0.75, -0.03]}>
                              <group name="browBR002_1" position={[0, 0.01, 0]} rotation={[-0.15, -0.42, 0.37]}>
                                <group name="browBR003_1" position={[0, 0.02, 0]} rotation={[-0.2, -0.6, 0.43]} />
                              </group>
                            </group>
                          </group>
                          <group name="lidTR_1" position={[-0.05, 0.1, 0.08]} rotation={[-1.17, -1.22, -2.32]}>
                            <group name="lidTR001_1" position={[0, 0.01, 0]} rotation={[-0.38, -0.87, 0.25]}>
                              <group name="lidTR002_1" position={[0, 0.01, 0]} rotation={[-0.37, -0.42, 0.69]}>
                                <group name="lidTR003_1" position={[0, 0.01, 0]} rotation={[-0.42, -0.57, 0.13]}>
                                  <group name="lidBR_1" position={[0, 0.01, 0]} rotation={[1.23, -0.04, 2.49]}>
                                    <group name="lidBR001_1" position={[0, 0.01, 0]} rotation={[0.41, -0.18, -0.08]}>
                                      <group name="lidBR002_1" position={[0, 0.01, 0]} rotation={[0.27, -0.21, -0.42]}>
                                        <group name="lidBR003_1" position={[0, 0.01, 0]} rotation={[0.05, -0.08, -0.73]} />
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="foreheadL" position={[0.01, 0.17, 0.1]} rotation={[-2.18, 1.48, -1.23]}>
                            <group name="foreheadL001" position={[-0.03, -0.01, 0.02]} rotation={[-0.03, -0.26, -0.21]}>
                              <group name="foreheadL002" position={[-0.02, 0, 0.02]} rotation={[-0.04, -0.56, -0.12]}>
                                <group name="templeL" position={[-0.01, -0.01, 0.03]} rotation={[0.64, -0.56, 0.46]}>
                                  <group name="jawL_1" position={[0, 0.05, 0]} rotation={[0.06, -0.26, 0.35]}>
                                    <group name="jawL001_1" position={[0, 0.07, 0]} rotation={[-0.32, -0.68, 0.61]}>
                                      <group name="chinL_1" position={[0, 0.07, 0]} rotation={[-1.95, -0.18, 1.06]}>
                                        <group name="cheekBL" position={[0, 0.05, 0]} rotation={[-1.92, 0.02, -0.67]}>
                                          <group name="cheekBL001_1" position={[0, 0.03, 0]} rotation={[1.25, 0.5, 0.23]}>
                                            <group name="browTL_1" position={[0, 0.05, 0]} rotation={[0.65, 0.51, 0.13]}>
                                              <group name="browTL001_1" position={[0, 0.04, 0]} rotation={[-1.66, 0.48, 1.91]}>
                                                <group name="browTL002_1" position={[0, 0.03, 0]} rotation={[-0.84, 0.9, 0.62]}>
                                                  <group name="browTL003_1" position={[0, 0.03, 0]} rotation={[0.27, 0.22, -1.12]} />
                                                </group>
                                              </group>
                                            </group>
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="foreheadR" position={[-0.01, 0.17, 0.1]} rotation={[-2.18, -1.48, 1.23]}>
                            <group name="foreheadR001" position={[0.03, -0.01, 0.02]} rotation={[-0.03, 0.26, 0.21]}>
                              <group name="foreheadR002" position={[0.02, 0, 0.02]} rotation={[-0.04, 0.56, 0.12]}>
                                <group name="templeR" position={[0.01, -0.01, 0.03]} rotation={[0.64, 0.56, -0.46]}>
                                  <group name="jawR_1" position={[0, 0.05, 0]} rotation={[0.06, 0.26, -0.35]}>
                                    <group name="jawR001_1" position={[0, 0.07, 0]} rotation={[-0.32, 0.68, -0.61]}>
                                      <group name="chinR_1" position={[0, 0.07, 0]} rotation={[-1.95, 0.18, -1.06]}>
                                        <group name="cheekBR" position={[0, 0.05, 0]} rotation={[-1.92, -0.02, 0.67]}>
                                          <group name="cheekBR001_1" position={[0, 0.03, 0]} rotation={[1.25, -0.5, -0.23]}>
                                            <group name="browTR_1" position={[0, 0.05, 0]} rotation={[0.65, -0.51, -0.13]}>
                                              <group name="browTR001_1" position={[0, 0.04, 0]} rotation={[-1.66, -0.48, -1.91]}>
                                                <group name="browTR002_1" position={[0, 0.03, 0]} rotation={[-0.84, -0.9, -0.62]}>
                                                  <group name="browTR003_1" position={[0, 0.03, 0]} rotation={[0.27, -0.22, 1.12]} />
                                                </group>
                                              </group>
                                            </group>
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="eyeL_1" position={[0.03, 0.1, 0.08]} rotation={[-1.55, 0, -Math.PI]} />
                          <group name="eyeR_1" position={[-0.03, 0.1, 0.08]} rotation={[-1.55, 0, -Math.PI]} />
                          <group name="cheekTL" position={[0.06, 0.08, 0.05]} rotation={[-3.11, -1.08, 1.42]}>
                            <group name="cheekTL001_1" position={[0, 0.05, 0]} rotation={[-3, -0.79, 2.2]}>
                              <group name="noseL_1" position={[0, 0.03, 0]} rotation={[2.7, 0.91, 1.32]}>
                                <group name="noseL001_1" position={[0, 0.03, 0]} rotation={[1.48, 0.33, 2.63]} />
                              </group>
                            </group>
                          </group>
                          <group name="cheekTR" position={[-0.06, 0.08, 0.05]} rotation={[-3.11, 1.08, -1.42]}>
                            <group name="cheekTR001_1" position={[0, 0.05, 0]} rotation={[-3, 0.79, -2.2]}>
                              <group name="noseR_1" position={[0, 0.03, 0]} rotation={[2.7, -0.91, -1.32]}>
                                <group name="noseR001_1" position={[0, 0.03, 0]} rotation={[1.48, -0.33, -2.63]} />
                              </group>
                            </group>
                          </group>
                          <group name="teethT_1" position={[0, 0.04, 0.09]} rotation={[-1.23, 0, 0]} />
                          <group name="teethB_1" position={[0, 0.03, 0.09]} rotation={[-1.55, 0, 0]} />
                          <group name="tongue_1" position={[0, 0.04, 0.07]} rotation={[-1.33, 0, 0]}>
                            <group name="tongue001_1" position={[0, 0.02, 0]} rotation={[-0.37, 0, 0]}>
                              <group name="tongue002_1" position={[0, 0.03, 0]} rotation={[-0.74, 0, 0]} />
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="shoulderL_1" position={[0.02, 0.15, 0.05]} rotation={[-1.45, 0.01, -1.38]}>
                    <group name="upper_armL" position={[-0.01, 0.16, -0.02]} rotation={[1.24, 1.44, -2.11]}>
                      <group name="forearmL" position={[0, 0.26, 0]} rotation={[0.74, 0.2, -0.04]}>
                        <group name="handL" position={[0, 0.25, 0]} rotation={[0, -0.19, -0.14]}>
                          <group name="palm01L" position={[-0.01, 0.02, 0.02]} rotation={[2.37, 1.49, -2.21]}>
                            <group name="f_index01L_1" position={[0, 0.06, 0]} rotation={[0.13, -0.27, 0.03]}>
                              <group name="f_index02L_1" position={[0, 0.03, 0]} rotation={[0.18, -0.14, -0.06]}>
                                <group name="f_index03L_1" position={[0, 0.02, 0]} rotation={[0.12, -0.13, -0.08]} />
                              </group>
                            </group>
                            <group name="thumb01L_1" position={[-0.02, 0, 0.02]} rotation={[-0.68, 1.17, 1.26]}>
                              <group name="thumb02L_1" position={[0, 0.04, 0]} rotation={[0.06, -0.09, -0.12]}>
                                <group name="thumb03L_1" position={[0, 0.03, 0]} rotation={[0.22, -0.1, 0.12]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm02L" position={[-0.01, 0.02, 0.01]} rotation={[2.67, 1.52, -2.76]}>
                            <group name="f_middle01L_1" position={[0, 0.07, 0]} rotation={[0.19, -0.24, 0.05]}>
                              <group name="f_middle02L_1" position={[0, 0.03, 0]} rotation={[0.11, -0.18, -0.04]}>
                                <group name="f_middle03L_1" position={[0, 0.03, 0]} rotation={[0.08, -0.11, -0.02]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm03L" position={[-0.02, 0.02, 0]} rotation={[1.06, 1.54, -1.25]}>
                            <group name="f_ring01L_1" position={[0, 0.07, 0]} rotation={[0.13, -0.3, -0.01]}>
                              <group name="f_ring02L_1" position={[0, 0.03, 0]} rotation={[0.15, -0.1, -0.04]}>
                                <group name="f_ring03L_1" position={[0, 0.03, 0]} rotation={[0, -0.13, -0.02]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm04L" position={[-0.02, 0.02, -0.02]} rotation={[-1.39, 1.52, 1.09]}>
                            <group name="f_pinky01L_1" position={[0, 0.06, 0]} rotation={[0.4, -0.4, -0.07]}>
                              <group name="f_pinky02L_1" position={[0, 0.03, 0]} rotation={[0.01, -0.02, 0.03]}>
                                <group name="f_pinky03L_1" position={[0, 0.02, 0]} rotation={[0.09, -0.02, -0.01]} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="shoulderR_1" position={[-0.02, 0.15, 0.05]} rotation={[-1.45, -0.01, 1.38]}>
                    <group name="upper_armR" position={[0.01, 0.16, -0.02]} rotation={[1.24, -1.44, 2.11]}>
                      <group name="forearmR" position={[0, 0.26, 0]} rotation={[0.74, -0.2, 0.04]}>
                        <group name="handR" position={[0, 0.25, 0]} rotation={[0, 0.19, 0.14]}>
                          <group name="palm01R" position={[0.01, 0.02, 0.02]} rotation={[2.37, -1.49, 2.21]}>
                            <group name="f_index01R_1" position={[0, 0.06, 0]} rotation={[0.13, 0.27, -0.03]}>
                              <group name="f_index02R_1" position={[0, 0.03, 0]} rotation={[0.18, 0.14, 0.06]}>
                                <group name="f_index03R_1" position={[0, 0.02, 0]} rotation={[0.12, 0.13, 0.08]} />
                              </group>
                            </group>
                            <group name="thumb01R_1" position={[0.02, 0, 0.02]} rotation={[-0.68, -1.17, -1.26]}>
                              <group name="thumb02R_1" position={[0, 0.04, 0]} rotation={[0.06, 0.09, 0.12]}>
                                <group name="thumb03R_1" position={[0, 0.03, 0]} rotation={[0.22, 0.1, -0.12]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm02R" position={[0.01, 0.02, 0.01]} rotation={[2.67, -1.52, 2.76]}>
                            <group name="f_middle01R_1" position={[0, 0.07, 0]} rotation={[0.19, 0.24, -0.05]}>
                              <group name="f_middle02R_1" position={[0, 0.03, 0]} rotation={[0.11, 0.18, 0.04]}>
                                <group name="f_middle03R_1" position={[0, 0.03, 0]} rotation={[0.08, 0.11, 0.02]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm03R" position={[0.02, 0.02, 0]} rotation={[1.06, -1.54, 1.25]}>
                            <group name="f_ring01R_1" position={[0, 0.07, 0]} rotation={[0.13, 0.3, 0.01]}>
                              <group name="f_ring02R_1" position={[0, 0.03, 0]} rotation={[0.15, 0.1, 0.04]}>
                                <group name="f_ring03R_1" position={[0, 0.03, 0]} rotation={[0, 0.13, 0.02]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm04R" position={[0.02, 0.02, -0.02]} rotation={[-1.39, -1.52, -1.09]}>
                            <group name="f_pinky01R_1" position={[0, 0.06, 0]} rotation={[0.4, 0.4, 0.07]}>
                              <group name="f_pinky02R_1" position={[0, 0.03, 0]} rotation={[0.01, 0.02, -0.03]}>
                                <group name="f_pinky03R_1" position={[0, 0.02, 0]} rotation={[0.09, 0.02, 0.01]} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="breastL_1" position={[0.09, 0.02, -0.06]} rotation={[-1.44, 0, Math.PI]} />
                  <group name="breastR_1" position={[-0.09, 0.02, -0.06]} rotation={[-1.44, 0, Math.PI]} />
                </group>
              </group>
            </group>
            <group name="pelvisL" position={[0, -0.03, 0.08]} rotation={[-2, -0.75, -1.67]} />
            <group name="pelvisR" position={[0, -0.03, 0.08]} rotation={[-2, 0.75, 1.67]} />
            <group name="thighL" position={[0.12, 0, 0.07]} rotation={[2.8, 0, -0.06]}>
              <group name="shinL" position={[0, 0.44, 0]} rotation={[0.1, 0.01, -0.05]}>
                <group name="footL" position={[0, 0.37, 0]} rotation={[-1.04, -0.1, 0.05]}>
                  <group name="toeL_1" position={[0, 0.15, 0]} rotation={[2.56, 0, -Math.PI]} />
                  <group name="heel02L" position={[-0.03, 0.03, 0.08]} rotation={[2.56, 0, -Math.PI / 2]} />
                </group>
              </group>
            </group>
            <group name="thighR" position={[-0.12, 0, 0.07]} rotation={[2.8, 0, 0.06]}>
              <group name="shinR" position={[0, 0.44, 0]} rotation={[0.1, -0.01, 0.05]}>
                <group name="footR" position={[0, 0.37, 0]} rotation={[-1.04, 0.1, -0.05]}>
                  <group name="toeR_1" position={[0, 0.15, 0]} rotation={[2.56, 0, Math.PI]} />
                  <group name="heel02R" position={[0.03, 0.03, 0.08]} rotation={[2.56, 0, Math.PI / 2]} />
                </group>
              </group>
            </group>
          </group>
        </group>
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <group name="middleage_woman">
            <skinnedMesh name="middleage_woman_1" geometry={nodes.middleage_woman_1.geometry} material={materials.ponytail01} skeleton={nodes.middleage_woman_1.skeleton} />
            <skinnedMesh name="middleage_woman_2" geometry={nodes.middleage_woman_2.geometry} material={materials.Eye_ice} skeleton={nodes.middleage_woman_2.skeleton} />
          </group>
          <group name="middleage_woman002">
            <skinnedMesh name="middleage_woman002_1" geometry={nodes.middleage_woman002_1.geometry} material={materials.spaghetti_top} skeleton={nodes.middleage_woman002_1.skeleton} />
            <skinnedMesh name="middleage_woman002_2" geometry={nodes.middleage_woman002_2.geometry} material={materials.simple_pantsMaterial} skeleton={nodes.middleage_woman002_2.skeleton} />
          </group>
          <group name="middleage_woman003">
            <skinnedMesh name="middleage_woman003_1" geometry={nodes.middleage_woman003_1.geometry} material={materials.middleage_asian_female} skeleton={nodes.middleage_woman003_1.skeleton} />
            <skinnedMesh name="middleage_woman003_2" geometry={nodes.middleage_woman003_2.geometry} material={materials.materialMaterial} skeleton={nodes.middleage_woman003_2.skeleton} />
          </group>
          <skinnedMesh name="middleage_woman005" geometry={nodes.middleage_woman005.geometry} material={materials.Eye_ice} skeleton={nodes.middleage_woman005.skeleton} />
        </group>
      </group>
    </group>
  )
}

// useGLTF.preload('/Middle_age_woman.glb')
