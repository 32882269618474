import React, { useEffect, useState } from 'react';
import MainHeader from "../../components/MainHeader/MainHeader";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MainFooter from "../../components/MainFooter/MainFooter";
import { allUsers, allUsersChampRating } from '../../api/chatApi/chatApi';
import chatApi from "../../api/chatApi";
import jwt from 'jsonwebtoken';
//@ts-ignore
import { ToastNotification } from '@carbon/react';
import Loading from '@carbon/react/lib/components/Loading';
import Raiting from './Raiting';
import ProfileSettings from './ProfileSettings';
import EditUserProfile from './EditUserProfile';


const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: 'flex',
    },
    title: {
      fontFamily: 'Helvetica Neue',
      fontWeight: 400,
      fontSize: 20,
      color: '#000000',
      margin: '90px 24px 0px 24px',
    },
    current: {
      minWidth: 350,
      padding: 20,
      boxShadow: '0px 0px 2px rgba(9, 36, 51, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.12)',
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginBottom: 50
    },
    name: {
      fontFamily: 'Helvetica Neue',
      fontWeight: 400,
      fontSize: 20,
      color: '#2196F3',
    },
    wrapper: {
      margin: '20px 50px 50px 5%',
      display: 'flex',
      // justifyContent: 'space-between',
      fontFamily: 'Helvetica Neue',
      fontWeight: 400,
      '@media (max-width: 1228px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'normal',
      },
      '@media (max-width: 730px)': {
        margin: '20px 0px 120px 0px',
      }
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      marginRight: 30
    },
    contentWrapper: {
      display: 'flex',
      width: '80%',
      '@media (max-width: 537px)': {
        width: '95%',
      }
    },
    menuButton: {
      '@media (max-width: 1228px)': {
        display: 'flex',
        justifyContent: 'center',
      },
      '@media (max-width: 500px)': {
        fontSize: '15px'
      },
      display: 'none',
    },
    tabs: {
      '@media (max-width: 1228px)': {
        display: 'none',
        height: 100,
      },
      display: 'flex',
      flexDirection: 'column',
      minWidth: '17%',
      position: 'fixed',
      top: 80,
      right: 20

    },
    activeTab: {
      fontSize: 15,
      fontFamily: 'Helvetica Neue',
      color: '#0E61FE',
      cursor: 'pointer',
      backgroundColor: '#E7F0FE',
      padding: 10,
      borderRadius: 4,
      '@media (max-width: 500px)': {
        fontSize: '12px'
      },
    },
    inActiveTab: {
      fontSize: 15,
      cursor: 'pointer',
      padding: 10,
      '@media (max-width: 500px)': {
        fontSize: '12px'
      },
    },
    profileMainContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
    },
    profileContent: {
      flex: 1
    }
  })
)

interface Current {
  id: number,
  position: number,
  raiting: string,
  raiting_change: number,
  user_name: string,
}

const tabs = ['Рейтинги', 'Настройки', 'Редактирование личных данных']

const Profile = () => {
  const classes = useRowStyles();

  const roleDecode = jwt.decode(localStorage.getItem('role'));
  const role = roleDecode.auth.split(',');
  const [current, setCurrent] = useState<Current>({});
  const [checkRole, setCheckRole] = useState(false);
  const [user, setUser] = useState<any>([]);
  const [allUser, setAllUser] = useState<any>([]);
  const [infoUser, setInfoUser] = useState<any>(null);
  const [org, setOrg] = useState<any>(null)
  const [trn, setTrn] = useState<any>(null)
  const [option, setOption] = useState(1)
  const [myLogin, setMyLogin] = useState<any>(null)
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [stress, setStress] = useState(false);

  const userMy = async () => {
    await chatApi.getUserTrainers()
      .then(res => setMyLogin(res.result.login))
      .catch(err => console.log(err))
  }

  function isEmpty(str: string | null) {
    if (str?.trim() === '')
      return true;
    return false;
  }

  const [spec, setSpec] = useState<any>([])
  const [orgProfile, setOrgProfile] = useState<any>([])
  const [trnProfile, setTrnProfile] = useState<any>([])
  const [teacher, setTeacher] = useState<any>([])


  const speciality = async () => {
    await chatApi.getAllSpeciality()
      .then(res => setSpec(res.result))
      .catch(err => console.log(err))
  }

  const getTeachers = async () => {
    await chatApi.getAllTeacher()
      .then(res => setTeacher(res.result))
      .catch(err => console.log(err))
  }

  const getOrg = async () => {
    await chatApi.getAllOrgProfile()
      .then(res => setOrgProfile(res.result))
      .catch(err => console.log(err))
  }

  const getTrn = async () => {
    await chatApi.getAllTrnProfile()
      .then(res => setTrnProfile(res.result))
      .catch(err => console.log(err))
  }

  const [myOrg, setMyOrg] = useState<any>(null)
  const [myTrn, setMyTrn] = useState<any>(null)
  const [mySp, setMySp] = useState<any>(null)
  const [myTeacher, setMyTeacher] = useState<any>(null)
  const [switchMy, setSwitchMy] = useState<boolean>(false)

  const [switchUser, setSwitchUser] = useState<boolean>(false)


  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const [errorPass, setErrorPass] = useState(false)
  const [successPass, setSuccessPass] = useState(false)

  const [typeProgram, setTypeProgram] = useState<null | string>(null)


  const sp = async (id) => {
    chatApi.getAllSpecialityMy(id)
      .then(res => setMySp(res.result))
      .catch(err => console.log(err))
  }

  const orgMy = async (id) => {
    chatApi.getOrgMy(id)
      .then(res => setMyOrg(res.result))
      .catch(err => console.log(err))
  }

  const trnMy = async (id) => {
    chatApi.getTrnMy(id)
      .then(res => setMyTrn(res.result))
      .catch(err => console.log(err))
  }

  const teacherMy = async (id) => {
    chatApi.getTeacherMy(id)
      .then(res => {
        setMyTeacher(res.result)
      })
      .catch(err => console.log(err))
  }

  const dataTypeProgram = async() => {
    const type = await chatApi.getSettingsType()
    setTypeProgram(type.result[0].value)
  }


  useEffect(() => {
    speciality()
    getOrg()
    getTrn()
    getTeachers()
    dataTypeProgram()
  }, []);


  useEffect(() => {
    userMy()
    if (user && user[0]) {
      teacherMy(user[0].jhi_user_id)
      orgMy(user[0].jhi_user_id)
      trnMy(user[0].jhi_user_id)
      sp(user[0].jhi_user_id)
    }
    if (user && user[0]) {
      getAdminUser(user[0].login)
    }
  }, [user, switchMy]);

  const getData = async () => {
    if (infoUser) {
      await allUsersChampRating(Number(infoUser[1]))
        .then(res => {
          setCurrent(res.data.current_user_championship)
        })
        .catch(err => console.log(err))
    }
    else {
      await allUsersChampRating()
        .then(res => {
          setCurrent(res.data.current_user_championship)
        })
        .catch(err => console.log(err))
    }
  }



  const getDataInfoUser = async () => {
    await chatApi.getUserTrainers()
      .then(async (res) => {
        const login = res.result.login
        if (checkRole && !infoUser) {
          await allUsers()
            .then((data) => {
              setAllUser(data.data.all_users)
            })
            .catch(err => console.log(err))
        }
        if (infoUser) {
          await allUsers(infoUser[0])
            .then((data) => {
              setTrn(data.data.all_users[0].trainer[0]?.trainer_id)
              setOrg(0)
              setUser(data.data.all_users)
            })
            .catch(err => console.log(err))
        }
        else {
          await allUsers(login)
            .then((data) => {
              setTrn(data.data.all_users[0].trainer[0]?.trainer_id)
              setOrg(0)
              setUser(data.data.all_users)
            })
            .catch(err => console.log(err))
        }
      })
  }


  useEffect(() => {
    if (role.includes('ROLE_ADMIN_TRAINER') ||
      role.includes('ROLE_ADMIN_ORG') ||
      role.includes('ROLE_ADMIN')) {
      setCheckRole(true)
    }
    getData()
    getDataInfoUser()
  }, [checkRole, infoUser, selectedUser]);





  function changeUserHandler(value) {
    if (value) {
      setSelectedUser(value.fio !== '  ' ? value.fio : value.login)
      setInfoUser([value.login, value.jhi_user_id])
    }
  }

  async function getAdminUser(login: string) {
    try {
      const response = await chatApi.getAdminUser(login)
      setIsCognitiveLoad(response.data.result.cognitive)
      setIsStressLoad(response.data.result.showStressPage)

    } catch (err) {
      console.log('при получении пользователя произошла ошибка', err);
    }
  }


  async function changeCognitiveCheckHandler() {
    try {
      await chatApi.changeCognitiveCheck(user[0].jhi_user_id, !isCognitiveLoad)
      setIsCognitiveLoad(!isCognitiveLoad)
      // setIsCognitiveLoad()
    } catch (err) {
      console.log('при смене состояния когнитивного теста произошла ошибка')
    }
  }

  async function changeStressCheckHandler() {
    setStress(false)
    try {
      await chatApi.changeStressCheck(user[0].jhi_user_id)
      setIsStressLoad(true)
      setStress(true)
    } catch (err) {
      console.log('при обновлении данных произошла ошибка')
    }
  }


  const [isCognitiveLoad, setIsCognitiveLoad] = useState(false)
  const [isStressLoad, setIsStressLoad] = useState(false)


  function getContent(tab) {
    switch (tab) {
      case 'Рейтинги':
        return <Raiting
          user={user}
          org={org}
          trn={trn}
          option={option}
          checkRole={checkRole}
          allUser={allUser}
          isEmpty={isEmpty}
          changeUserHandler={changeUserHandler}
          current={current}
          isStressLoad={false} />;
      case 'Настройки':
        return <ProfileSettings
          user={user}
          typeProgram={typeProgram}
          setOrg={setOrg}
          setTrn={setTrn}
          isCognitiveLoad={isCognitiveLoad}
          isStressLoad={isStressLoad}
          changeStressCheckHandler={changeStressCheckHandler}
          changeCognitiveCheckHandler={changeCognitiveCheckHandler}
          org={org}
          trn={trn}
          option={option}
          setOption={setOption}
          spec={spec}
          mySp={mySp}
          setSwitchMy={setSwitchMy}
          myOrg={myOrg}
          setError={setError}
          setSuccess={setSuccess}
          teacher={teacher}
          myTeacher={myTeacher}
          trnProfile={trnProfile}
          orgProfile={orgProfile}
          myTrn={myTrn}
        />;
      case 'Редактирование личных данных':
        return <EditUserProfile
          user={user}
          typeProgram={typeProgram}
          setError={setError}
          setSuccess={setSuccess}
          myLogin={myLogin}
          setSuccessPass={setSuccessPass}
          setErrorPass={setErrorPass}
          setSwitchUser={setSwitchUser}
          switchUser={switchUser}
        />
      default:
        return <div>Выберите пункт меню слева</div>;
    }
  }


  return (
    <>
      <MainHeader title={"Профиль"} />
      <div className={classes.profileMainContainer}>
        <div className={classes.profileContent}>
          <Loading active={switchMy || switchUser} small={false} withOverlay={true} description="Loading..." />
          {
            error &&
            <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
              <ToastNotification
                kind="warning"
                hideCloseButton={false}
                lowContrast={false}
                // subtitle={<span>Нет результатов</span>}
                timeout={10000}
                title="Ошибка"
              />
            </div>
          }

          {
            success &&
            <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
              <ToastNotification
                kind="success"
                hideCloseButton={false}
                lowContrast={false}
                title="Сохранено"
                timeout={10000}
              />
            </div>
          }
          {
            errorPass &&
            <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
              <ToastNotification
                kind="error"
                hideCloseButton={false}
                lowContrast={false}
                title="Ошибка"
                timeout={10000}
              />
            </div>
          }
          {
            successPass &&
            <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
              <ToastNotification
                kind="success"
                hideCloseButton={false}
                lowContrast={false}
                title="Пароль сохранен"
                timeout={10000}
              />
            </div>
          }
          {
            stress &&
            <div style={{ position: 'fixed', right: 0, top: 55, zIndex: 1000 }}>
              <ToastNotification
                kind="success"
                hideCloseButton={false}
                lowContrast={false}
                title="Тест стрессовой нагрузки будет автоматически отключён после его прохождения в задаче"
                timeout={10000}
                style={{ paddingBottom: 15, borderRadius: 8 }}
              />
            </div>
          }
          <div
            style={{
              marginTop: 80,
              display: 'flex',
              justifyContent: 'left',
            }
            }>
            <div
              style={{
                padding: '5px 0px',
                marginLeft: '5%'
              }}
            >
              Текущий пользователь:
            </div>
            {user[0] ? <div
              style={{
                padding: '5px 5px',
                backgroundColor: '#E7F0FE',
                color: '#0E61FE',
                borderRadius: 4,
              }}
            >
              {user[0] ? user[0].fio !== '  ' ? user[0].fio : user[0].login : selectedUser}
            </div> : null}
          </div>
          <div className={classes.wrapper}>
            <div className={classes.menuButton}>
              {tabs.map((tab, index) => (
                <div key={index} onClick={() => setActiveTab(tab)} className={activeTab === tab ? `${classes.activeTab}` : `${classes.inActiveTab}`}>
                  {tab}
                </div>
              ))}
            </div>
            <div className={classes.contentWrapper}>
              {getContent(activeTab)}
            </div>
            <div className={classes.tabs}>
              {tabs.map((tab, index) => (
                <div key={index} onClick={() => setActiveTab(tab)} className={activeTab === tab ? `${classes.activeTab}` : `${classes.inActiveTab}`}>
                  {tab}
                </div>
              ))}
            </div>
          </div>
        </div>
        <MainFooter />
      </div>
    </>
  );
}

export default Profile;
