import React, { useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Boy11(props) {
  const { nodes, materials, animations } = useGLTF('patient/BOY_11_years_EXPORT.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)

  useEffect(() => {
    props.setAnim(animations)
    actions.rigAction.play()
  }, [actions, names, mixer])

  return (
    <group ref={ref} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <skinnedMesh name="boys_cloth" geometry={nodes.boys_cloth.geometry} material={materials.sergey_cloth} skeleton={nodes.boys_cloth.skeleton} />
          <skinnedMesh name="eyelashes01Mesh" geometry={nodes.eyelashes01Mesh.geometry} material={materials.eyelashes01} skeleton={nodes.eyelashes01Mesh.skeleton} />
          <group name="high-polyMesh">
            <skinnedMesh name="high-poly" geometry={nodes['high-poly'].geometry} material={materials['high-poly']} skeleton={nodes['high-poly'].skeleton} />
            {/* <skinnedMesh name="high-poly_1" geometry={nodes['high-poly_1'].geometry} material={materials.eyes_2} skeleton={nodes['high-poly_1'].skeleton} /> */}
          </group>
          <skinnedMesh name="PETYA_BODY" geometry={nodes.PETYA_BODY.geometry} material={materials.PETYA_BODY} skeleton={nodes.PETYA_BODY.skeleton} />
          <skinnedMesh name="short01Mesh" geometry={nodes.short01Mesh.geometry} material={materials.short01} skeleton={nodes.short01Mesh.skeleton} />
        </group>
        {/* <mesh name="Cube" geometry={nodes.Cube.geometry} material={nodes.Cube.material} position={[0, 0.99, 0]} scale={[2, 1, 2]} /> */}
      </group>
    </group>
  )
}

// useGLTF.preload('patient/BOY_11_years_EXPORT.glb')
