import { ChatActionType } from '../chat';
import chatApiService from '../../api/chatService';
import chatApi from '../../api/chatApi';
import { all, put, call, takeLeading, select } from 'redux-saga/effects';
import { ResearchActionType } from '../research';
import { SagaActionType } from './sagaActions';
import { ResearchResults_ResponseData, BaseResponse, GroupOfResearch_ResponseData } from '../../api/chatApi/chatApi';
import { RootState } from '..';
import { doAsyncRequest } from './saga-utils';

function* loadCurrentTask() {
  yield doAsyncRequest(ChatActionType.SET_CURRENT_TASK_STATE, [chatApiService, chatApiService.loadCurrentTaskInfo], []);
}

function* loadChatHistory() {
  yield doAsyncRequest(ChatActionType.SET_CHAT_HISTORY, [chatApiService, chatApiService.loadChatHistory], []);
}

function* loadCheckHistory() {
  yield doAsyncRequest(ChatActionType.SET_CHECK_HISTORY, [chatApiService, chatApiService.loadCheckHistory], []);
}

function* loadGroupOfResearch(action: any) {
  try {
    yield put({
      type: ResearchActionType.SET_GROUPS_OF_RESEARCH_LOADING,
      data: true,
    });
    const groupsOfResearchResponse: BaseResponse<GroupOfResearch_ResponseData[]> = yield call([chatApi, chatApi.getGroupsOfResearchByParentId], action.data.group.id);
    yield put({
      type: ResearchActionType.SET_GROUPS_OF_RESEARCH,
      data: { groupsOfResearch: groupsOfResearchResponse.result },
    });
  } catch (e) {
    console.log(e);
  } finally {
    yield put({
      type: ResearchActionType.SET_GROUPS_OF_RESEARCH_LOADING,
      data: false,
    });
  }
}

function* loadTypesOfResearch(action: any) {
  try {
    const groupsOfResearchResponse: BaseResponse<GroupOfResearch_ResponseData> = yield call([chatApi, chatApi.getGroupOfResearchWithTypes], action.data.group.id);
    yield put({
      type: ResearchActionType.SET_GROUPS_OF_RESEARCH,
      data: { groupsOfResearch: [groupsOfResearchResponse.result] },
    });
  } catch (e) {
    console.log(e);
  }
}

function* loadResearchResult(action: any) {
  try {
    const response: BaseResponse<ResearchResults_ResponseData[]> = yield call([chatApi, chatApi.getResearchResults], action.data);
    yield put({
      type: ResearchActionType.ADD_RESEARCH_RESULTS,
      data: response.result as Array<ResearchResults_ResponseData>,
    });
  } catch (e) {
    console.log(e);
  }
}

function* loadResearchResultsHistory() {
  try {
    const response: BaseResponse<ResearchResults_ResponseData[]> = yield call([chatApi, chatApi.getCurrrentTaskResearchResults]);
    yield put({
      type: ResearchActionType.ADD_RESEARCH_RESULTS,
      data: response.result,
    });
  } catch (e) {
    console.log(e);
  }
}

function* loadTaskResults() {
  yield doAsyncRequest(ChatActionType.SET_TASK_RESULT, [chatApi, chatApi.getTaskResults], []);
}

function* loadDiagnosisTypes() {
  yield doAsyncRequest(ChatActionType.SET_DIAGNOSIS_TYPES, [chatApi, chatApi.getDiagnosisTypes], []);
}

function* finishTask() {
  try {
    yield put({
      type: ResearchActionType.SET_RESEARCH_RESULTS,
      data: [],
    });
    yield put({
      type: ChatActionType.EXIT_CHAT,
    });
  } catch (e) {
    console.log(e);
  }
}

function* loadDiagnosisForms() {
  yield doAsyncRequest(ChatActionType.SET_DIAGNOSIS_FORMS, [chatApi, chatApi.getDiagnosisForms], []);
}

function* loadPrescribedMedication() {
  const state: RootState = yield select();
  yield doAsyncRequest(ChatActionType.SET_PRESCRIBED_MEDICATION, [chatApi, chatApi.getPrescribedMedicationByTaskId], [state.chat.currentTaskState?.value?.taskProceedId ?? 0]);
}

export default function* rootSaga() {
  yield all([
    takeLeading(SagaActionType.START_LOAD_PRESCRIBED_MEDICATION, loadPrescribedMedication),
    takeLeading(SagaActionType.START_LOAD_TASK, loadCurrentTask),
    takeLeading(SagaActionType.START_LOAD_HISTORY, loadChatHistory),
    takeLeading(SagaActionType.START_LOAD_CHECK_HISTORY, loadCheckHistory),
    takeLeading(SagaActionType.START_LOAD_GROUP_OF_RESEARCH, loadGroupOfResearch),
    takeLeading(SagaActionType.START_LOAD_TYPES_OF_RESEARCH, loadTypesOfResearch),
    takeLeading(SagaActionType.START_LOAD_RESEARCH_RESULTS, loadResearchResult),
    takeLeading(SagaActionType.START_LOAD_RESEARCH_HISTORY, loadResearchResultsHistory),
    takeLeading(SagaActionType.START_LOAD_TASK_RESULTS, loadTaskResults),
    takeLeading(SagaActionType.START_LOAD_DIAGNOSIS_TYPES, loadDiagnosisTypes),
    takeLeading(SagaActionType.START_LOAD_DIAGNOSIS_FOMRMS, loadDiagnosisForms),
    takeLeading(SagaActionType.FINISH_TASK, finishTask),
  ]);
}
