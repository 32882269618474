import axios from 'axios';
import React, { useState, useEffect } from "react";
import MainFooter from "../../components/MainFooter/MainFooter";
import MainHeader from "../../components/MainHeader/MainHeader";
import { store } from "../../store";
//@ts-ignore
import { TextArea, TextInput, Button, CodeSnippet, Select, SelectItem } from '@carbon/react';
import './index.css'
import JsonFormatter from 'react-json-formatter'

const InformationSystems = () => {

  const [body, setBody] = useState<any>('{}')
  const [http, setHttp] = useState<any>('http://')
  const [response, setResponse] = useState<any>()
  const [method, setMethod] = useState<any>('get')


  //https://en.neurodoc.online/neurodoc-go-test/neurodoc_go_test/getAllUsers
  //{"login": "dukrain"}
  const fetch = async (http, body) => {
    if (body) {
      var json = JSON.parse(body);
      const jwtToken = store.getState().auth.jwtToken;
      await axios({
        method: method,
        url: http,
        data: json,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtToken,
        }
      })
        .then(res => setResponse(res))
        .catch(err => setResponse(err))
    }
  }


  const clear = () => {
    setBody('{}')
    setHttp('http://')
    setResponse(null)
  }

  useEffect(() => {
    if (method === 'get' || method === 'delete') {
      setBody('{}')
    }
  }, [method]);


  return (
    <>
      <MainHeader />
      <div className='wrapper'>
        <div className='title'>Подключение к внешним информационным системам</div>
        <div style={{ marginTop: 40, marginLeft: 100, marginRight: 100 }}>
          <div style={{ marginBottom: 20 }}>
            <div style={{ marginBottom: 10, fontSize: 17 }}>Запрос</div>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <TextInput
                id="text-input-1"
                type="text"
                labelText=""
                value={http}
                onChange={(e) => setHttp(e.target.value)}
              />
            </div>
          </div>

          <div style={{ width: '30%', marginBottom: 20 }}>
            <div style={{ marginBottom: 7, fontSize: 17 }}>Метод</div>
            <Select
              id="select-1"
              onChange={(e) => setMethod(e.target.value)}
              value={method}
              labelText="">
              <SelectItem value="get" text="GET" />
              <SelectItem value="post" text="POST" />
              <SelectItem value="put" text="PUT" />
              <SelectItem value="delete" text="DELETE" />
            </Select>
          </div>
          {method === 'post' || method === 'put' ?
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginBottom: 10, fontSize: 17 }}>Тело запроса</div>
              <TextArea
                labelText=""
                rows={4}
                value={body}
                onChange={(e) => setBody(e.target.value)}
                id="text-area-1"
              />
            </div>
            :
            null
          }

          <Button onClick={() => fetch(http, body)}>Отправить</Button>
          <Button style={{ marginLeft: 10 }} onClick={() => clear()}>Сбросить</Button>
        </div>
        <div style={{ marginTop: 20, marginLeft: 100, marginRight: 100 }}>
          {response &&
            <div style={{ marginBottom: 100 }}>
              <div style={{ marginBottom: 10 }}>Ответ</div>
              <CodeSnippet type="multi" feedback="Скопировано" copyButtonDescription='Скопировать' showMoreText='Развернуть' showLessText='Свернуть'>
                <JsonFormatter json={JSON.stringify(response)} tabWith={4} />
              </CodeSnippet>
            </div>
          }
        </div>
      </div>
      <MainFooter />
    </>
  );
}

export default InformationSystems;