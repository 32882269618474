import React, { useRef, useEffect, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Pregnant(props) {
  const { nodes, materials, animations } = useGLTF('patient/Pregnant_A.glb')

  const { ref, actions, names, mixer } = useAnimations(animations)
  const [index, setIndex] = useState(4)


  useEffect(() => {
    props.setAnim(animations)
    actions.rigAction.play()
  }, [actions, names, mixer])


  return (
    <group ref={ref} {...props} dispose={null}>
      <group position={[0, 0.86, -0.01]} rotation={[-0.1, 0, 0]}>
        <group position={[0, 0.09, 0]} rotation={[0.45, 0, 0]}>
          <group position={[0, 0.11, 0]} rotation={[-0.3, 0, 0]}>
            <group position={[0, 0.15, 0]} rotation={[-0.26, 0, 0]}>
              <group position={[0, 0.16, 0]} rotation={[0.6, 0.01, 0]}>
                <group position={[0, 0.05, 0]} rotation={[0.07, 0.15, 0]}>
                  <group position={[0, 0.03, 0]} rotation={[-0.49, -0.15, -0.07]}>
                    <group rotation={[-0.07, -0.01, 0]}>
                      <group position={[0, 0.09, 0.11]} rotation={[2.99, -0.01, -0.03]}>
                        <group position={[0, 0.02, 0]} rotation={[-0.33, -0.01, 0.02]}>
                          <group position={[0, 0.02, 0]} rotation={[1.07, 0, -0.02]}>
                            <group position={[0, 0.01, 0]} rotation={[0.43, 0.03, 0]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.03, 0.11]} rotation={[-1.49, 0.08, -1.21]} />
                      <group position={[0, 0.02, 0.11]} rotation={[-1.36, 0, -1.25]} />
                      <group position={[0, -0.01, 0.05]} rotation={[1.66, 0, -0.02]}>
                        <group position={[0, 0.05, 0]} rotation={[-1.32, 0.03, 0]} />
                      </group>
                      <group position={[0.07, 0.06, 0.03]} rotation={[-0.09, -0.19, -0.21]}>
                        <group position={[0, 0.03, 0]} rotation={[-1.15, -0.15, -0.12]}>
                          <group position={[0, 0.01, 0]} rotation={[-1.56, -0.45, 0.11]}>
                            <group position={[0, 0.02, 0]} rotation={[-0.76, -0.62, 0.27]} />
                          </group>
                        </group>
                      </group>
                      <group position={[-0.07, 0.06, 0.03]} rotation={[-0.09, 0.22, 0.22]}>
                        <group position={[0, 0.03, 0]} rotation={[-1.15, 0.15, 0.12]}>
                          <group position={[0, 0.01, 0]} rotation={[-1.56, 0.45, -0.11]}>
                            <group position={[0, 0.02, 0]} rotation={[-0.76, 0.62, -0.27]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.03, 0.11]} rotation={[-1.49, -0.08, 1.25]} />
                      <group position={[0, 0.02, 0.11]} rotation={[-1.36, 0.01, 1.28]} />
                      <group position={[0.05, 0.1, 0.09]} rotation={[-0.87, 0.92, 2.09]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.31, 0.79, -0.06]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.18, 0.43, -0.38]} />
                        </group>
                      </group>
                      <group position={[0.05, 0.09, 0.1]} rotation={[-0.94, 1.03, 1.88]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.71, 0.7, -0.01]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.23, 0.32, -0.38]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.44, 0.58, -0.3]}>
                              <group position={[0, 0.01, 0]} rotation={[1, 0.04, -2.34]}>
                                <group position={[0, 0.01, 0]} rotation={[0.33, 0.18, -0.11]}>
                                  <group position={[0, 0.01, 0]} rotation={[0.36, 0.33, 0.33]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.05, 0.1, 0.09]} rotation={[-0.84, -0.9, -2.04]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.31, -0.79, 0.06]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.18, -0.43, 0.38]} />
                        </group>
                      </group>
                      <group position={[-0.04, 0.09, 0.1]} rotation={[-0.9, -1, -1.83]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.71, -0.7, 0.01]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.23, -0.32, 0.38]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.44, -0.58, 0.3]}>
                              <group position={[0, 0.01, 0]} rotation={[1, -0.04, 2.34]}>
                                <group position={[0, 0.01, 0]} rotation={[0.33, -0.18, 0.11]}>
                                  <group position={[0, 0.01, 0]} rotation={[0.36, -0.33, -0.33]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.02, 0.15, 0.11]} rotation={[-2.48, 1.5, -0.82]}>
                        <group position={[-0.02, 0, 0.03]} rotation={[0.1, -0.27, 0.08]}>
                          <group position={[-0.01, 0, 0.01]} rotation={[0.03, -0.56, 0.06]}>
                            <group position={[-0.01, 0.01, 0.03]} rotation={[0.26, -0.69, 0.2]}>
                              <group position={[0, 0.05, 0]} rotation={[-0.09, -0.26, 0.28]}>
                                <group position={[0, 0.07, 0]} rotation={[-0.15, -0.62, 0.73]}>
                                  <group position={[0, 0.06, 0]} rotation={[-1.97, 0.01, 1.16]}>
                                    <group position={[0, 0.04, 0]} rotation={[-1.7, -0.28, -0.57]}>
                                      <group position={[0, 0.04, 0]} rotation={[0.8, 0.52, 0.13]}>
                                        <group position={[0, 0.05, 0]} rotation={[0.95, 0.56, 0.23]}>
                                          <group position={[0, 0.02, 0]} rotation={[-1.97, 1.14, 2.12]}>
                                            <group position={[0, 0.02, 0]} rotation={[-0.53, 1.12, -0.08]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.02, 0.15, 0.11]} rotation={[-2.05, -1.52, 1.25]}>
                        <group position={[0.02, 0, 0.03]} rotation={[0.1, 0.27, -0.08]}>
                          <group position={[0.01, 0, 0.01]} rotation={[0.03, 0.56, -0.06]}>
                            <group position={[0.01, 0.01, 0.03]} rotation={[0.26, 0.69, -0.2]}>
                              <group position={[0, 0.05, 0]} rotation={[-0.09, 0.26, -0.28]}>
                                <group position={[0, 0.07, 0]} rotation={[-0.15, 0.62, -0.73]}>
                                  <group position={[0, 0.06, 0]} rotation={[-1.97, -0.01, -1.16]}>
                                    <group position={[0, 0.04, 0]} rotation={[-1.7, 0.28, 0.57]}>
                                      <group position={[0, 0.04, 0]} rotation={[0.8, -0.52, -0.13]}>
                                        <group position={[0, 0.05, 0]} rotation={[0.95, -0.56, -0.23]}>
                                          <group position={[0, 0.02, 0]} rotation={[-1.97, -1.14, -2.12]}>
                                            <group position={[0, 0.02, 0]} rotation={[-0.53, -1.12, 0.08]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.06, 0.09, 0.06]} rotation={[3.11, -1.05, 0.84]}>
                        <group position={[0, 0.05, 0]} rotation={[2.85, -0.69, 1.56]}>
                          <group position={[0, 0.04, 0]} rotation={[2.64, 0.83, 1.39]} />
                        </group>
                      </group>
                      <group position={[-0.06, 0.09, 0.06]} rotation={[3.12, 1.02, -0.84]}>
                        <group position={[0, 0.05, 0]} rotation={[2.85, 0.69, -1.56]}>
                          <group position={[0, 0.04, 0]} rotation={[2.64, -0.83, -1.39]} />
                        </group>
                      </group>
                      <group position={[0, 0.03, 0.09]} rotation={[-1.27, 0.01, 0.02]}>
                        <group position={[0, 0.02, 0]} rotation={[-0.37, -0.01, 0.07]} />
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[0.01, 0.13, 0.03]} rotation={[-1.32, -0.04, -1.42]}>
                <group position={[0, 0.15, -0.03]} rotation={[0.6, 1.43, -1.56]}>
                  <group position={[0, 0.24, 0]} rotation={[0.69, 0.19, 0.01]}>
                    <group position={[0, 0.24, 0]} rotation={[0.08, -0.08, -0.18]}>
                      <group position={[-0.01, 0.03, 0.02]} rotation={[2.59, 1.45, -2.48]}>
                        <group position={[0, 0.07, 0]} rotation={[0.1, -0.24, 0.08]}>
                          <group position={[0, 0.02, 0]} rotation={[0.22, -0.14, 0.01]} />
                        </group>
                        <group position={[-0.02, 0, 0.02]} rotation={[-1.12, 1.06, 1.53]}>
                          <group position={[0, 0.05, 0]} rotation={[0.31, -0.05, -0.03]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.03, 0.01]} rotation={[2.12, 1.51, -2.18]}>
                        <group position={[0, 0.06, 0]} rotation={[0.16, -0.26, -0.02]}>
                          <group position={[0, 0.03, 0]} rotation={[0.14, -0.24, 0.03]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.03, -0.01]} rotation={[0.53, 1.5, -0.69]}>
                        <group position={[0, 0.06, 0]} rotation={[0.1, -0.32, -0.16]}>
                          <group position={[0, 0.03, 0]} rotation={[0.16, -0.14, 0.08]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.03, -0.02]} rotation={[0.13, 1.45, -0.39]}>
                        <group position={[0, 0.05, 0]} rotation={[0.1, -0.47, -0.17]}>
                          <group position={[0, 0.02, 0]} rotation={[0.18, -0.05, -0.06]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[-0.01, 0.13, 0.03]} rotation={[-1.32, 0.04, 1.42]}>
                <group position={[0, 0.15, -0.03]} rotation={[0.6, -1.43, 1.56]}>
                  <group position={[0, 0.24, 0]} rotation={[0.69, -0.19, -0.01]}>
                    <group position={[0, 0.24, 0]} rotation={[0.08, 0.08, 0.18]}>
                      <group position={[0.01, 0.03, 0.02]} rotation={[2.59, -1.45, 2.48]}>
                        <group position={[0, 0.07, 0]} rotation={[0.1, 0.24, -0.08]}>
                          <group position={[0, 0.02, 0]} rotation={[0.22, 0.14, -0.01]} />
                        </group>
                        <group position={[0.02, 0, 0.02]} rotation={[-1.12, -1.06, -1.53]}>
                          <group position={[0, 0.05, 0]} rotation={[0.31, 0.05, 0.03]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.03, 0.01]} rotation={[2.12, -1.51, 2.18]}>
                        <group position={[0, 0.06, 0]} rotation={[0.16, 0.26, 0.02]}>
                          <group position={[0, 0.03, 0]} rotation={[0.14, 0.24, -0.03]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.03, -0.01]} rotation={[0.53, -1.5, 0.69]}>
                        <group position={[0, 0.06, 0]} rotation={[0.1, 0.32, 0.16]}>
                          <group position={[0, 0.03, 0]} rotation={[0.16, 0.14, -0.08]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.03, -0.02]} rotation={[0.13, -1.45, 0.39]}>
                        <group position={[0, 0.05, 0]} rotation={[0.1, 0.47, 0.17]}>
                          <group position={[0, 0.02, 0]} rotation={[0.18, 0.05, 0.06]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
        <group position={[0.11, 0, 0.02]} rotation={[-3.13, 0, -0.09]}>
          <group position={[0, 0.42, 0]} rotation={[0.19, 0.02, 0]}>
            <group position={[0, 0.37, 0]} rotation={[-1.11, -0.08, 0.04]} />
          </group>
        </group>
        <group position={[-0.11, 0, 0.02]} rotation={[-3.13, 0, 0.09]}>
          <group position={[0, 0.42, 0]} rotation={[0.19, -0.02, 0]}>
            <group position={[0, 0.37, 0]} rotation={[-1.11, 0.08, -0.04]} />
          </group>
        </group>
      </group>
      <group name="rig">
        <primitive object={nodes.root} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
        <primitive object={nodes['MCH-torsoparent']} />
        <primitive object={nodes['MCH-hand_ikparentL']} />
        <primitive object={nodes['MCH-hand_ikparentR']} />
        <primitive object={nodes['MCH-foot_ikparentL']} />
        <primitive object={nodes['MCH-foot_ikparentR']} />
        <skinnedMesh
          geometry={nodes.Plane.geometry}
          material={materials.hairstyleBun_bow}
          skeleton={nodes.Plane.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Plane_1.geometry}
          material={materials.hairstyleBun_1}
          skeleton={nodes.Plane_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Pregnant_A.geometry}
          material={materials.pregnantA_skin}
          skeleton={nodes.Pregnant_A.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Pregnant_A_eyes001_1.geometry}
          material={materials.Eye_lightblue}
          skeleton={nodes.Pregnant_A_eyes001_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Pregnant_A_eyes001_2.geometry}
          material={materials.Eyelashes1}
          skeleton={nodes.Pregnant_A_eyes001_2.skeleton}
        />
        {/* <skinnedMesh
          geometry={nodes.Pregnant_A_eyes001_3.geometry}
          material={materials.EyeGlass}
          skeleton={nodes.Pregnant_A_eyes001_3.skeleton}
        /> */}
        <skinnedMesh
          geometry={nodes.PregnantA_underwear.geometry}
          material={materials.underwear}
          skeleton={nodes.PregnantA_underwear.skeleton}
        />
        <skinnedMesh
          geometry={nodes.pregnantSuite1_1.geometry}
          material={materials.FABRIC_1_FRONT_1931}
          skeleton={nodes.pregnantSuite1_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.pregnantSuite1_2.geometry}
          material={materials.FABRIC_3_FRONT_1945}
          skeleton={nodes.pregnantSuite1_2.skeleton}
        />
        <skinnedMesh
          geometry={nodes.pregnantSuite1_3.geometry}
          material={materials.FABRIC_2_FRONT_1938}
          skeleton={nodes.pregnantSuite1_3.skeleton}
        />
      </group>
    </group>
  )
}

// useGLTF.preload('patient/Pregnant_A.glb')
