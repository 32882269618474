import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: '35px',
      display: 'grid',
      gridGap: `8px`,
      gridAutoFlow: `row`,
      gridTemplateColumns: `1fr 1fr`,
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: `1fr`,
        gridAutoFlow: `unset`
      }
    }
  })
)

interface Props extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode
  title?: string
}

const SlotContainer = ({ children, style, ...rest }: Props) => {
  const classes = useStyles()
  return <div className={classes.root} style={style} {...rest}>{children}</div>
}

export default SlotContainer
