import { makeStyles, createStyles, Theme, TableContainer, Paper } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { getChampioship, getInfoChamp, getMediaContent, getRegisterUsers } from '../../../api/chatApi/chatApi';
import MainFooter from '../../../components/MainFooter/MainFooter';
import MainHeader from '../../../components/MainHeader/MainHeader';
import ChampionshipsStages from './championshipsStages';
//@ts-ignore
import { Button, Loading } from '@carbon/react';
import 'moment/locale/ru';
import { useHistory } from "react-router-dom";
import chatApi from '../../../api/chatApi';
import ChampionshipListUsers from './ChampionshipListUsers';
import { RoutePath } from '../../../types';
import { usePointProcentStore } from '../../chat/store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperList: {
      padding: '0 20px'
    },
    title: {
      padding: 24,
      backgroundColor: '#E7F0FE',
      borderRadius: 4,
      // boxShadow: '0px 0px 2px rgba(9, 36, 51, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.12)',
    },
    // wrapperTitle: {
    //   display: 'flex',
    //   alignItems: 'center',
    //   flexWrap: 'wrap',
    //   justifyContent: 'space-between',
    //   // marginBottom: 16,
    //   // marginTop: 48
    // },
    wrapperData: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 70,
      marginBottom: 20
    },
    nameChamp: {
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 24,
      color: '#000000'
    },
    itemData: {
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      color: '#000000',
      marginRight: 24
    },
    lorem: {
      display: 'flex',
      justifyContent: 'space-between',
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      color: '#000000'
    },
    lorem1: {
      marginTop: 30,
      marginLeft: 30,
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 19,
      color: '#000000'
    },
    stages: {
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 23,
      color: '#000000',
      marginRight: 24,
      marginTop: 32,
      marginBottom: 40
    },
    my: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '33px 551px',

      width: '100%',
      height: 150,

      background: '#DADADA',
      marginBottom: 24,
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      color: '#000000'
    },
    text_gr: {
      backgroundColor: '#a7f0ba',
      padding: '3px 10px',
      borderRadius: 20,
      marginRight: 20,
      color: '#3e8d55',
    },
    text_bl: {
      backgroundColor: '#d0e2ff',
      padding: '3px 10px',
      borderRadius: 20,
      marginRight: 20,
      color: '#346bda',
    },
    text_close: {
      backgroundColor: '#e0e0e0',
      padding: '3px 10px',
      borderRadius: 20,
      marginRight: 20,
    },
    btn: {
      borderRadius: 4,
      color: 'white',
      [theme.breakpoints.down('xs')]: {
        fontSize: 7,
        width: 100
      }
    },
  })
)

interface Championship {
  active: boolean,
  championship_descriptoon: string,
  championship_img: string,
  championship_name: string,
  championship_prize: string,
  championship_registration: string,
  championship_registration_active: boolean,
  championship_registration_quantity: number,
  championship_task: string,
  championship_trainer: number,
  date_start: string,
  date_stop: string,
  start: any,
  end: any,
  id: number,
  rating: number
}

const initial = {
  active: false,
  championship_descriptoon: '',
  championship_img: '',
  championship_name: '',
  championship_prize: '',
  championship_registration: '',
  championship_registration_active: false,
  championship_registration_quantity: 0,
  championship_task: '',
  championship_trainer: 0,
  date_start: '',
  date_stop: '',
  start: new Date,
  end: new Date,
  id: 0,
  rating: 0
}

const ChampionshipsItem = (): JSX.Element => {
  const history = useHistory()
  const style = useStyles()
  const idChamp = localStorage.getItem('idChamp')
  const setChampionships = usePointProcentStore(state => state.getChampionshipsAll)
  const championships = usePointProcentStore(state => state.dataChamp)

  const [championship, setChampionship] = useState<Championship>(initial);
  const [stages, setStages] = useState<any>([]);
  const [prize, setPrize] = useState<any>([]);
  const [date, setDate] = useState(0);
  const [check, setCheck] = useState(true)


  const getInfoChamps = async () => {
    const idUser = await chatApi.getUserTrainers()
    await getChampioship(Number(idChamp), idUser.result.id)
      .then(res => {
        setCheck(res.data.championship.championship_stop_page)
        const champs = [res.data.championship]
        const sortChamp = champs.map(el => {
          const dateStart = el.date_start.split('.')
          dateStart[1] = dateStart.splice(0, 1, dateStart[1])[0]
          const dateStart1 = dateStart.join(".")

          const dateEnd = el.date_stop.split('.')
          dateEnd[1] = dateEnd.splice(0, 1, dateEnd[1])[0]
          const dateEnd1 = dateEnd.join(".")
          el["start"] = new Date(dateStart1);
          el["end"] = new Date(dateEnd1);
          return el;
        });
        setChampionship(sortChamp[0])

        const sortPrize = res.data.championship_prize.sort(function (a, b) {
          if (a.place > b.place) {
            return 1;
          }
          if (a.place < b.place) {
            return -1;
          }
          return 0;
        });
        setPrize(sortPrize)
        const sortStage = res.data.championship_stage.sort(function (a, b) {
          if (a.number > b.number) {
            return 1;
          }
          if (a.number < b.number) {
            return -1;
          }
          return 0;
        });
        setStages(sortStage)
      })
      .catch(err => console.log(err))
  }


  ///////////Новый запрос на медиа ресурс
  // const sow = () => {
  //   const res = prize.map(async(el) => {
  //     if(el.link) {
  //       const newLink = await getMediaContent(el.link)
  //       return {...el, newLink: newLink}
  //     }
  //     else {
  //       return {...el, newLink: ''}
  //     }
  //   }) 
  // }

  useEffect(() => {
    getInfoChamps()
    if (idChamp === '0') {
      history.push(`${RoutePath.championshipList}`)
    }
  }, []);

  const checkStatus = () => {

    if (championship.end.getTime() > date && championship.start.getTime() > date) {
      return 0
    }
    else {
      return 1
    }
  }

  useEffect(() => {
    setDate(new Date().getTime());
    const timer = setInterval(() => {
      setDate(new Date().getTime());
    }, 10000);
    return () => {
      clearInterval(timer);
    }
  }, []);


  const [reg, setReg] = useState(null)

  const toggle = async () => {
    await setChampionships()

    championships.filter(el => {
      if (el.id === Number(idChamp)) {
        setReg(el.registration)
      }
    })
  }

  const [currentId, setCurrentId] = useState(0)

  const registerChamp = async () => {
    try {
      toggle()
      localStorage.setItem('idChampForTask', String(idChamp));
      setCurrentId(championship.id)
      await chatApi.getRegisterChamp(championship.id)
      window.location.reload()
    } catch (err) {
      console.log(err);
    }
    // .then(res => console.log(res))
    // .catch(err => console.log(err))
  }


  const getChampionshipsAll = async () => {
    await getInfoChamp()
      .then(res => {
        res.data.all_championship.filter(el => {
          if (el.id === Number(idChamp)) {
            setReg(el.registration)
          }
        })
      })
      .catch(err => console.log(err))
  }


  useEffect(() => {
    getChampionshipsAll()
  }, []);


  return (
    <>
      <MainHeader />
      {championship.active ?
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '90%' }}>
            <div className={style.wrapperData}>
              <Button style={{
                borderRadius: 4,
                boxShadow: '0px 0px 2px rgba(9, 36, 51, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.3)'
              }} variant='contained'
                onClick={() => history.push(RoutePath.championshipList)}>
                Вернуться к чемпионатам
              </Button>
            </div>
            <TableContainer className={style.title} component={Paper}>
              <div className={style.nameChamp}>{championship.championship_name}</div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 25, flexWrap: 'wrap' }}>

                <div className={style.itemData}>{championship.date_start} - {championship.date_stop}</div>
                {championship.start.getTime() < date && championship.end.getTime() < date
                  ?
                  <div className={style.text_close}>Завершен</div>
                  :
                  null
                }
                {championship.start.getTime() <= date && championship.end.getTime() >= date
                  ?
                  <div className={style.text_bl}>Уже идет</div>
                  :
                  null
                }
                {championship.end.getTime() > date && championship.start.getTime() > date
                  ?
                  <div className={style.text_gr}>Регистрация открыта</div>
                  :
                  null
                }
                {reg
                  ?
                  <div className={style.text_gr}>Зарегистрирован</div>
                  :
                  null
                }
              </div>
              <div className={style.lorem} style={{ display: 'flex' }}>
                <div>
                  {championship.championship_descriptoon}
                </div>
                {championship.championship_img ?
                  <img src={championship.championship_img} alt="img" />
                  :
                  null
                }
                {!reg && championship.start ?
                  <Button
                    className={style.btn}
                    variant='contained'
                    disabled={date > championship.start.getTime() || reg ? true : false}
                    onClick={registerChamp}
                  >
                    Зарегистрироваться
                  </Button>
                  :
                  null
                }
              </div>
            </TableContainer>
            <div>
              {!check ?
                <div className={style.wrapperList}>
                  {stages.length > 0 ?
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <ChampionshipsStages stages={stages} status={checkStatus} />
                      <ChampionshipListUsers prize={prize} status={checkStatus} />
                    </div>

                    :
                    <div className={style.lorem1}>Этапы отсутствуют</div>
                  }

                </div>
                :
                <div className={style.lorem1}>Чемпионат находится на расчете результатов, попробуйте зайти позже</div>}
            </div>
          </div>


        </div>
        :
        <Loading
          description="Загрузка" withOverlay
          small={false} />}
      <MainFooter />
    </>
  )
}

export default ChampionshipsItem
