import React from "react";
import CreateProfile from "./CreateProfile";
import CreatePasswordProfile from './CreatePasswordProfile';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '@media (max-width: 1280px)': {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'normal',
        width: '98%'
      },
      '@media (max-width: 1080px)': {
        width: '98%',
      }
    }
  })
)


interface User {
  jhi_user_id: number
}

type Props = {
  user: User
  setError: boolean
  setSuccess: boolean
  myLogin: unknown
  setSuccessPass: boolean
  setErrorPass: boolean
  setSwitchUser: boolean
  switchUser: boolean
  typeProgram: string | null
}


export default function EditUserProfile({
  user,
  setError,
  setSuccess,
  myLogin,
  setSuccessPass,
  setErrorPass,
  setSwitchUser,
  switchUser,
  typeProgram
}: Props) {
  const classes = useStyles()

  return (
    user[0] && myLogin &&
    <div className={classes.mainContainer}>
      <CreateProfile login={user[0].login} error={setError} success={setSuccess} switch1={setSwitchUser} switchMy={switchUser} />
      {typeProgram === '0' &&
      <CreatePasswordProfile setSuccess={setSuccessPass} setError={setErrorPass} />
      }
    </div>

  )
}

