import React from 'react'
import ConfirmationLayout from '../../components/Layout/ConfirmationLayout'
import { Icon, Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    textAlign: 'center',
    marginTop: 'auto'
  }
}))

export default function EmailPasswordResetPage() {
  const c = useStyles()

  return (
    <ConfirmationLayout title='EMAIL “Сброс пароля”'>
      <p>
        Вы запросили восстановление пароля для сайта CyberDoctor. Ссылка для
        восстановления пароля:{' '}
        <Link href='https://cyberdoctor.ru/auth/resetpass/dlg83tsp45453dfsfdgkdsf'>
          https://cyberdoctor.ru/auth/resetpass/dlg83tsp45453dfsfdgkdsf
        </Link>
      </p>
      <p>
        Если вы не запрашивали восстановление пароля, проигнорируйте данное
        письмо.
        <br />
        <br />
      </p>
      <div className={c.buttonWrapper}>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          endIcon={<Icon>arrow_forward_ios</Icon>}
        >
          СБРОСИТЬ ПАРОЛЬ
        </Button>
      </div>
    </ConfirmationLayout>
  )
}
