import { Box, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { CHAT_MENU_ITEMS, SidebarWidth } from '../../constants/default'
import useTestingStage from '../../hooks/testingTask/useTestingStage'
import { MenuItemData } from '../../types'
import { pxToRem, pxToRemGutter } from '../../utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: pxToRemGutter(0, 27),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    type: {
      width: pxToRem(41),
      marginRight: pxToRem(27)
    },
    steps: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center'
    },
    step: {
      textDecoration: 'none',
      color: theme.palette.text.hint
    },
    step__disabled: {
      color: theme.palette.text.disabled,
      cursor: `no-drop`,
      pointerEvents: `none`
    },
    active: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    logo: {
      width: pxToRem(SidebarWidth),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: '1px solid #E5E5E5'
    },
    logo_text: {
      fontWeight: 900,
      fontSize: pxToRem(20),
      lineHeight: pxToRem(30),
      letterSpacing: 0.15,
      color: theme.palette.primary.main
    },
    navbar: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'uppercase',
      fontSize: pxToRem(20),
      fontWeight: 500,
      lineHeight: pxToRem(30),
      letterSpacing: 0.15
    }
  })
)

type Props = {
  difficultCode?: string
  type?: string
  menuItems?: Array<MenuItemData>
}

export default function ChatPagesMenu(
  props: Props = { menuItems: CHAT_MENU_ITEMS }
) {
  const classes = useStyles()

  const stage = useTestingStage()

  return (
    <>
      <div className={classes.logo}>
        <span className={classes.logo_text}>NeuroDoc</span>
      </div>
      <div className={classes.navbar}>
        <Box className={classes.root}>
          {/* {props.difficultCode && (
            <img src={DIFFICULT_DATA_BY_CODE[props.difficultCode].imageHover} className={classes.type} alt="" />
          )} */}
          <Box className={classes.steps}>
            {props.menuItems?.map((item, index) => {
              const disabled = item.activateStage && stage < item.activateStage
              return (
                <NavLink
                  key={index}
                  to={item.routePath}
                  activeClassName={classes.active}
                  className={[
                    classes.step,
                    disabled ? classes.step__disabled : ''
                  ].join(' ')}
                >
                  <Typography key={index} variant={'button'} component='span'>
                    {item.title}
                  </Typography>
                </NavLink>
              )
            })}
          </Box>
        </Box>
      </div>
    </>
  )
}
