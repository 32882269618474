import React from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'
import { RoutePath } from '../../types'
import { pxToRem } from '../../utils'

const useStyles = makeStyles(theme => ({
  button: {
    color: '#C4C4C4',
    fontSize: pxToRem(20),
    fontWeight: 500,
    '&:hover': {
      background: 'none'
    }
  }
}))

type Props = {
  text: string
}

export default function BackPanel({ text }: Props) {
  const c = useStyles()
  const h = useHistory()

  return (
    <Button
      startIcon={<ArrowBackIcon />}
      className={c.button}
      onClick={() => h.push(RoutePath.login)}
      fullWidth
    >
      {text}
    </Button>
  )
}
