import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from './TablePagination';
import React, { useState, useEffect } from 'react';
import SearchBar from 'material-ui-search-bar';
import chatApi from '../../../../api/chatApi';
import moment from 'moment';
import 'moment/locale/ru';
import { Loading } from '@carbon/react';
import { TrashCan } from '@carbon/icons-react';
import {
  Button, ToastNotification
} from '@carbon/react'
import { allUsers } from '../../../../api/chatApi/chatApi';
import { useDebounce } from 'react-use';


const useRowStyles = makeStyles({
  titleTable: {
    textAlign: 'center',
    fontWeight: 600,
    backgroundColor: '#E7F0FE'

  },
  item: {
    display: 'flex',
  },
  cellText: {
    display: 'inline-flex',
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 20,
    fontWeight: 500,
    margin: '20px 0 0px',
  },
  buttonSwitch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cellInput: {
    width: '100%',
    background: 'transparent',
    border: 'none',
    outline: 'none',
  },
});

const Trn = () => {
  const classes = useRowStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage1, setRowsPerPage1] = useState(5);
  const [rows, setRows] = useState([]);
  const [allUsersTrn, setAllUsersTrn] = useState([]);
  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState(false)
  const [error, setError] = useState(false)
  const [error1, setError1] = useState(false)
  const [success, setSuccess] = useState(false)
  const [textRow, setTextRow] = useState('')
  const [textUser, setTextUser] = useState('')
  const [trainer, setTrainer] = useState([])


  const getTrainerUser = async () => {
    const login = await chatApi.getUserTrainers()
    const res = await allUsers(login.result.login)
    let trainers = []
    res.data.all_users[0].trainer.map(el => trainers.push(el.trainer_id))
    setTrainer(trainers)
  }

  useEffect(() => {
    getTrainerUser()
  }, []);

  const getData = async () => {
    setLoading(true);
    await chatApi
      .addTrn()
      .then(res => {
        setLoading(false);
        const sort = res.result.map(el => ({ ...el, toggle: el.action === 1 ? true : false }));
        const filter = sort.filter(el => trainer.includes(el.trainerOrganizationId) && !el.dateAnswer)
        setRows(filter);
      })
      .catch(err => setLoading(false))
      .finally(() => setLoading(false))
  };

  const getUsers = async () => {
    await chatApi
      .allTrn('all')
      .then(res => {
        const filter = res.result.filter(el => trainer.includes(el.trainerId))
        setAllUsersTrn(filter)
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  };

  useEffect(() => {
    getUsers()
    getData();
  }, [error, error1, success, trainer]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = event => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };

  useDebounce(
    async () => {
      setLoading(true)
      const keys = ['name', 'patronymic', 'surname']
      if (!textRow) {
        return getData();
      }
      await chatApi
        .addTrn()
        .then(res => {
          const sort = res.result.map(el => ({ ...el, toggle: el.action === 1 ? true : false }));
          const filter = sort.filter(el => trainer.includes(el.trainerOrganizationId) && !el.dateAnswer)
          const newFilter = filter.filter((row) => keys.some(key => {
            if (row[key] !== null) {
              return row[key].toLowerCase().includes(textRow.toLowerCase())
            }
          }));
          setRows(newFilter);
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false))
    },
    700,
    [textRow]
  )

  useDebounce(
    async () => {
      setLoading(true)
      const keys = ['name', 'patronymic', 'surname']
      if (!textUser) {
        return getUsers();
      }
      await chatApi
        .allTrn('all')
        .then(res => {
          const filter = res.result.filter(el => trainer.includes(el.trainerId))
          const newFilter = filter.filter((row) => keys.some(key => {
            if (row[key] !== null) {
              return row[key].toLowerCase().includes(textUser.toLowerCase())
            }
          }));
          setAllUsersTrn(newFilter)
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false))
    },
    700,
    [textUser]
  )


  const createAnswer = (id, e) => {
    const newData = rows.map(el => (el.id === id ? { ...el, answer: e } : el));
    setRows(newData);
  };

  const answerOrg = async (id, answer, num) => {
    setError(false)
    setError1(false)
    await chatApi
      .executeTrn(id, answer, num)
      .then(res => {
        getUsers()
        getData()
      })
      .catch(err => {
        if (num === false) {
          setError(true)
          setError1(false)
        }
        if (num === true) {
          setError1(true)
          setError(false)
        }
      });
  };

  const deleteClick = async (id, org) => {
    await chatApi.deleteTrn(id, org)
      .then(() => {
        setSuccess(true)
        setWarning(false)
      })
      .catch(() => {
        setSuccess(false)
        setWarning(true)
      })
  };

  console.log('rows', rows)

  return (
    <>
      <Loading active={loading} small={false} withOverlay={true} description="Loading..." />
      <div style={{ margin: '0 10px' }}>
        {
          success &&
          <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
            <ToastNotification
              kind="success"
              hideCloseButton={false}
              lowContrast={false}
              title="Тренажер удален"
              timeout={10000}
            />
          </div>
        }
        {
          warning &&
          <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
            <ToastNotification
              kind="warning"
              hideCloseButton={false}
              lowContrast={false}
              title="Ошибка"
              timeout={10000}
            />
          </div>
        }
        {
          error &&
          <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
            <ToastNotification
              kind="warning"
              hideCloseButton={false}
              lowContrast={false}
              title="Ошибка"
              subtitle="При отказе необходимо заполнить причину"
              timeout={10000}
            />
          </div>
        }
        {
          error1 &&
          <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
            <ToastNotification
              kind="warning"
              hideCloseButton={false}
              lowContrast={false}
              title="Ошибка"
              timeout={10000}
            />
          </div>
        }

        <div className={classes.title}>Заявки в тренажеры</div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ alignSelf: 'flex-end', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <SearchBar value={textRow} placeholder='Поиск' onChange={searchVal => setTextRow(searchVal)} style={{ width: 300 }} />
          </div>
          <TableContainer component={Paper} style={{ marginTop: 10 }}>
            <Table aria-label="collapsible table">
              <TableHead style={{ background: '#e0e0e0' }}>
                <TableRow>
                  <TableCell className={classes.titleTable}>ФИО</TableCell>
                  <TableCell className={classes.titleTable} align="right">
                    Дата
                  </TableCell>
                  <TableCell className={classes.titleTable} align="right">
                    Действие
                  </TableCell>
                  <TableCell className={classes.titleTable}>Дата заявки</TableCell>
                  <TableCell className={classes.titleTable}>Коментарий</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? rows
                    .filter(row => !allUsersTrn.some(user => user.userId === row.userId))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : rows.filter(row => !allUsersTrn.some(user => user.userId === row.userId)))
                  .map((row, index) => (
                    <TableRow className={classes.bodyTable} key={index}>
                      <TableCell align="center" component="th" scope="row">
                        {`${row.surname ? row.surname : ''} ${row.name ? row.name : ''} ${row.patronymic ? row.patronymic : ''}`}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.data}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <div className={classes.buttonSwitch}>
                          <Button
                            onClick={() => answerOrg(row.id, row.answer, true)}
                            // variant="contained"
                            // color="primary"
                            size='sm' kind='ghost'
                          >
                            Да
                          </Button>
                          <Button onClick={() => answerOrg(row.id, row.answer, false)} size='sm' kind='ghost'
                          // variant="contained" color="secondary"
                          >
                            Нет
                          </Button>
                        </div>
                        {/* {row.action} */}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {moment(row.dateAction).format('LLL')}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        <input
                          type="text"
                          className={classes.cellInput}
                          // value={row.answer}
                          onChange={e => createAnswer(row.id, e.target.value)}
                        />
                      </TableCell>
                    </TableRow>
                  )
                  )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    style={{ background: '#E7F0FE' }}
                    rowsPerPageOptions={[5, 10, 25, { label: 'Все', value: -1 }]}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>

      <div style={{ margin: '50px 0 50px 0' }}>
        <div className={classes.title}>Все пользователи</div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ alignSelf: 'flex-end', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <SearchBar value={textUser} placeholder='Поиск' onChange={searchVal => setTextUser(searchVal)} style={{ width: 300 }} />
          </div>
          <TableContainer component={Paper} style={{ marginTop: 10 }}>
            <Table aria-label="collapsible table">
              <TableHead style={{ background: '#e0e0e0' }}>
                <TableRow>
                  <TableCell className={classes.titleTable}>ФИО</TableCell>

                  <TableCell className={classes.titleTable} align="right">
                    Тренажер
                  </TableCell>
                  <TableCell className={classes.titleTable}>Действие</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage1 > 0
                  ? allUsersTrn.slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1)
                  : allUsersTrn
                ).map((row, index) => (
                  <TableRow className={classes.bodyTable} key={index}>
                    <TableCell align="center" component="th" scope="row">
                      {`${row.surname ? row.surname : ''} ${row.name ? row.name : ''} ${row.patronymic ? row.patronymic : ''}`}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.trainer}
                    </TableCell>
                    <TableCell align="center">
                      <TrashCan
                        onClick={() => {
                          setWarning(false)
                          deleteClick(row.userId, row.trainerId)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    style={{ background: '#E7F0FE' }}
                    rowsPerPageOptions={[5, 10, 25, { label: 'Все', value: -1 }]}
                    count={allUsersTrn.length}
                    rowsPerPage={rowsPerPage1}
                    page={page1}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onPageChange={handleChangePage1}
                    onRowsPerPageChange={handleChangeRowsPerPage1}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default Trn;
