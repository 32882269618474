import React, { FunctionComponent, useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import Cookies from 'js-cookie';
import chatApi from '../api/chatApi'
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleWrapper: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    titleInner: {
      display: 'flex',
      width: '100%',
    },
    textTitle: {
      paddingLeft: '20px',
    },
    mainTextContainer: {
      fontFamily: 'Arial',
      backgroundColor: theme.palette.background.paper,
    },
    closeIcon: {
      zIndex: 1,
    }
  })
)

type Props = {
  taskName: string
}

const TooltipAccordion: FunctionComponent<Props> = (props: Props) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const [tooltipText, setTootipText] = useState('')
  const [open, setOpen] = useState(true)

  const getTooltip = async () => {
    try {
      const res = await chatApi.getHint()
      const hintValue = res.result.filter((el: { key: string; }) =>
        el.key === props.taskName)
      setTootipText(hintValue[0].val)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getTooltip()
  }, [])

  const cookieName = `tooltip-${props.taskName}`;

  useEffect(() => {
    const savedVisibility = Cookies.get(cookieName);
    if (savedVisibility === `hidden`) {
      setOpen(false);
    }
  }, [cookieName, props.taskName]);

  const handleAccordionClose = () => {
    setOpen(false);
    Cookies.set(cookieName, `hidden`);
  };


  return (
    open ?
      (<div>
        <Accordion>
          <AccordionSummary
            onClick={getTooltip}
          >
            <div className={classes.titleWrapper}>
              <div className={classes.titleInner}>
                <Typography className={classes.textTitle}>{t("tren1Title")}</Typography>
                <ExpandMoreIcon />
              </div>
              <CloseIcon onClick={handleAccordionClose}
                className={classes.closeIcon}
              />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.mainTextContainer}>
              {tooltipText}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>) : null
  )
}

export default TooltipAccordion;