import React, { useEffect, useState } from 'react'
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Theme,
  Pagination,
  ToastNotification,
  Loading,
} from '@carbon/react';
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { getDepersonalizedData } from '../../api/chatApi/chatApi';
import CustomTablePagination from '../CustomPagination';

const useStyles = makeStyles((theme: any) => ({

  requiredWarning: {
    '& .cds--toast-notification__details': {
      display: 'flex !important',
      alignItems: 'center !important',
      height: 50
    },
  },
  championTablePageContainer: {
    background: '#F4F4F4',
    // height: '100vh',
    borderRadius: 4,
    '@media (max-width: 800px)': {
      width: '100%',
      overflowX: 'scroll',
    },
  },
  championTablePageContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '98%',
    margin: '0 auto',
    height: ''
  },
  championTableFilterBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 10px',
    background: '#FFFFFF',
    marginRight: '1%',
    width: '100%',
    marginBottom: '1%',
    borderRadius: 4,
    '@media (max-width: 800px)': {
      flexDirection: 'column'
    },
  },
  championSelectBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
    marginRight: '15px',
    alignItems: 'flex-start',
    '& .cds--dropdown': {
      minWidth: 200
    },
    '& .cds--list-box': {
      width: 225
    },
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      marginBottom: 15
    },
  },
  datePickerText: {
    whiteSpace: 'pre',
    fontSize: 14,
    fontFamily: 'Helvetica Neue',
    alignSelf: 'center',
    marginRight: 15,
    '@media (max-width: 800px)': {
      alignSelf: 'baseline'
    },
  },
  championSelectElement: {
    marginRight: '15px',
    display: 'flex',
    '@media (max-width: 800px)': {
      flexDirection: 'column'
    },
  },
  championSelectBlockMultiSelect: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
    alignItems: 'center',
    width: 300
  },
  championTable: {
    width: '100%',
    marginRight: '1%',
    overflowY: 'auto',
    background: '#FFFFFF',
    '& .cds--data-table-content': {
      maxHeight: '65vh',
    }
  },
  maxBallsBlock: {
    background: '#FFFFFF',
    padding: '20px 10px',
    display: 'flex',
    marginBottom: 16,
    // boxShadow: '0px -5px 5px -5px rgba(34, 60, 80, 0.48) inset'
  },
  myResultsBlock: {
    padding: '15px 10px',
    background: '#FFFFFF',
    height: 230,
  },
  maxBalls: {
    borderRight: '2px solid #DADADA',
    width: 180,
  },
  allParticipants: {
    marginLeft: 30,
  },
  titleBlocks: {
    fontFamily: 'Helvetica Neue',
    fontSize: 20,
    lineHeight: '23px',
    fontWeight: 400,
    marginBottom: '6%',
  },
  datePickerContainer: {
    display: 'flex',
    '& div.cds--date-picker': {
      display: 'flex',
    }, '& .cds--date-picker__input': {
      display: 'flex',
      minWidth: 225
    },
    '& .cds--date-picker--range > .cds--date-picker-container': {
      width: '100%',
      marginRight: '15px',
    },
    '@media (max-width: 800px)': {
      '& div.cds--date-picker': {
        flexDirection: 'column'
      },
    },
  },
  statisticsBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '24px',
    marginTop: '24px',
  },
  formationButtonBlock: {
    display: 'flex',
    // justifyContent: 'center',
    '& .cds--btn--sm': {
      height: '40px',
      width: 225,
      textAlign: 'center'
    }
  },
  customBtn: {
    borderRadius: 4,
    height: 40,
    width: 175,
    maxWidth: 225,
    background: '#0f62fe',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    fontSize: 15,
    '&:hover': {
      background: '#0050e6'
    },
    '@media (max-width: 800px)': {
      width: 225,
    },
  },
  comboBoxContainer: {
    marginRight: 15,
    '@media (max-width: 800px)': {
      marginBottom: 15
    },
  },
  customComboBox: {
    width: 300,
    minWidth: 325,
    '& .cds--combo-box': {
      borderRadius: 4
    }
  },
  emptyTableText: {
    whiteSpace: 'pre',
    fontSize: 20,
    fontFamily: 'Helvetica Neue',
    fontWeight: 500,
    '@media (max-width: 800px)': {
      whiteSpace: 'break-spaces',
      textAlign: 'center',
    },
  }
}))

interface DepersonalizedRatingDataInterface {
  countCompleteTask: number
  countPatientTask: number
  countFailedTask: number
}

interface depersonalizedRatingInterface {
  start_date: string
  stop_date: string
  numberOfEntries: number
  trainerId?: number | undefined
  organisationId?: number | undefined
  studentSpecialityId?: number | undefined
  universityLevelId?: number | undefined
}
export default function DepersonalizedRatingData({
  countPatientTask,
  countFailedTask,
  countCompleteTask
}: DepersonalizedRatingDataInterface): JSX.Element {

  const style = useStyles()

  const [allErrorPlan, setAllErrorPlan] = useState<[]>([])
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null)
  const [requiredFieldsState, setRequiredFieldsState] = useState(false)
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [body, setBody] = useState({
    start_date: '',
    stop_date: '',
  })
  const [errorState, setErrorState] = useState(false)

  useEffect(() => {
    if (requiredFieldsState) {
      setTimeout(() => setRequiredFieldsState(false), 3000)
    }
  }, [requiredFieldsState])

  const headerData = [
    {
      key: 'trainer_name',
      header: 'Тренажер',
    },
    {
      key: 'organization_name',
      header: 'Организация',
    },
    {
      key: 'student_speciality',
      header: 'Специальность',
    },
    {
      key: 'procent',
      header: 'Процент',
    },
    {
      key: 'point',
      header: 'Очки',
    },
  ];


  const handleStartDateChange = (date: Date) => {
    setStartTime(date)
    const res = new Date(date).toLocaleDateString().split(/\.|\//).reverse().join('-')
    setBody({
      ...body,
      start_date: res,
    })
  }

  const handleEndDateChange = (date: Date) => {
    setEndTime(date)
    const res = new Date(date).toLocaleDateString().split(/\.|\//).reverse().join('-')
    setBody({
      ...body,
      stop_date: res
    })
  };


  async function depersonalizedRatingDataHandler() {
    try {
      if (!endTime || !startTime) {
        setRequiredFieldsState(true);
        return
      }
      setIsLoading(true)
      let arr = []
      const response = await getDepersonalizedData(body)
      response.data.question.length === 0 ?
        setErrorState(true) :
        setErrorState(false)
      response.data.question.map((res, index) => {
        arr.push({ ...res, id: index })
      })
      setAllErrorPlan(arr)
    } catch (err) {
      setErrorState(true)
      console.log('при получении списка произошла ошибка', err)
    } finally {
      setTimeout(() => setErrorState(false), 5000)
      setIsLoading(false)
    }
  }

  const [isLoading, setIsLoading] = useState(false)

  function Loader() {
    if (isLoading) {
      return (
        <Loading className={'some-class'} withOverlay={false} />
      )
    } else return null
  }

  const indexOfFirstItem = page * currentPageSize;
  const indexOfLastItem = indexOfFirstItem + currentPageSize;
  const currentData = allErrorPlan.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className={style.championTablePageContainer}>
      {
        errorState &&
        <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
          <ToastNotification
            kind="warning"
            hideCloseButton={false}
            lowContrast={false}
            iconDescription="Нет результатов"
            style={{
              paddingBottom: 7,
              borderRadius: 4,
              width: 300,
              backgroundColor: '#656565',
              color: 'white'
            }}
            timeout={4000}
            title="Нет результатов"
          />
        </div>
      }
      {requiredFieldsState && (
        <div style={{ position: "fixed", right: 0, zIndex: 1000 }}>
          <ToastNotification
            kind="warning"
            hideCloseButton={true}
            lowContrast={false}
            className={style.requiredWarning}
            style={{
              paddingBottom: 7,
              borderRadius: 4,
              width: 300,
              backgroundColor: '#656565',
              color: 'white'
            }}
            timeout={4000}
            title=""
          ><span>Обязательные поля не заполнены</span></ToastNotification>
        </div>
      )}
      <div style={{ width: "98%", padding: "20px 0", margin: "0 auto" }}>
        <p style={{ fontFamily: 'Helvetica Neue', fontWeight: 400, fontSize: 32 }}>
          Рейтинговые данные
        </p>
      </div>

      <div
        style={{
          background: '#FFFFFF',
          fontSize: '104%',
          width: '98%',
          padding: '15px 10px',
          margin: 'auto auto 12px auto',
          borderRadius: 4
        }}>
        <p>Обезличенные рейтинговые данные прохождения ситуационных задач в разрезе тренажера,
          организации, специальности</p>
      </div>

      <div className={style.championTablePageContent}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={style.championTableFilterBlock}>
            {/*<p className={style.titleBlocks}>Решавших задачи</p>*/}
            {/*<div className={style.statisticsBlock}>*/}
            {/*  <p className={style.titleBlocks}>Статистика</p>*/}
            {/*  <div>*/}
            {/*    <p>{`Пациенты: ${countPatientTask}`}</p>*/}
            {/*    <p>{`Завершенные задачи: ${countCompleteTask}`}</p>*/}
            {/*    <p>{`Завершенные задачи: ${countCompleteTask}`}</p>*/}
            {/*    <p>{`Заваленные тесты: ${countFailedTask}`}</p>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className={style.championSelectBlock}>
              <p
                className={style.datePickerText}
              >за период
              </p>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd.MM.yyyy"
                  id="date-picker-inline"
                  placeholder="с"
                  value={startTime}
                  onChange={handleStartDateChange}
                />
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd.MM.yyyy"
                  minDate={startTime}
                  id="date-picker-inline"
                  placeholder="по"
                  value={endTime === 'Invalid Date' ? startTime : endTime}
                  onChange={handleEndDateChange}
                  animateYearScrolling={true}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className={style.formationButtonBlock}>
              {/*<Button*/}
              {/*  onClick={depersonalizedRatingDataHandler}*/}
              {/*  kind="secondary"*/}
              {/*  id={'button'}*/}
              {/*  size={'sm'}*/}
              {/*>*/}
              {/*  Сформировать*/}
              {/*</Button>*/}
              <button
                onClick={depersonalizedRatingDataHandler}
                className={style.customBtn}
              >Применить фильтры
              </button>
            </div>
          </div>
        </div>

        {isLoading
          ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <Loader />
          </div>
          : allErrorPlan && allErrorPlan.length !== 0 ?
            <div className={style.championTable}>
              <Theme theme={'g10'}>
                {
                  allErrorPlan &&
                  <DataTable
                    rows={currentData}
                    headers={headerData}
                    isSortable
                  >
                    {({ rows, headers, getHeaderProps, getTableProps }) => {
                      return (
                        <TableContainer title="">
                          <Table {...getTableProps()} style={{ background: '#FFFFFF' }}>
                            <TableHead style={{
                              backgroundColor: "#FFFFFF",
                              position: "sticky",
                              top: 0,
                              zIndex: 1000,
                              borderRadius: 4,
                              boxShadow: '0 0px 4px 0 rgba(0,0,0,.25)'
                            }}>
                              <TableRow>
                                {headers.map((header) => (
                                  <TableHeader {...getHeaderProps({ header })}>
                                    {header.header}
                                  </TableHeader >
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row) => {
                                return (
                                  <TableRow key={row.id}>
                                    {row.cells.map((cell, index) => (
                                      <TableCell key={cell.id}>{index === 3 ? `${cell.value} %` : cell.value}</TableCell>
                                    ))}
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )
                    }}
                  </DataTable>
                }
              </Theme>

            </div> :
            <div style={{ margin: '25px auto 0 auto', paddingBottom: 20 }}>
              <p className={style.emptyTableText}
              >Чтобы увидеть информацию о рейтинговых данных заполните фильтры
              </p>
            </div>
        }
      </div>
      {<div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          paddingRight: 10,
          paddingBottom: 7,
          marginBottom: 10,
          backgroundColor: '#F4F4F4',
          borderRadius: 4
        }}>
        {allErrorPlan.length > 0 &&
          <CustomTablePagination
            count={allErrorPlan.length}
            page={page}
            rowsPerPage={currentPageSize}
            onPageChange={(newPage) => setPage(newPage)}
            onRowsPerPageChange={(newPageSize) => {
              setCurrentPageSize(newPageSize);
              setPage(0);
            }}
          />}
      </div>
      }
    </div>
  )
}
